import { createTheme } from '@mui/material';

export const colors = {
  Silver: '#CCCCCC',
  Mercury: '#E5E5E5',
  Boulder: '#757575',
  ElectricViolet: '#6E53F6',
  RoyalBlue: '#5D40F0',
  MountainMeadow: '#21B75D',
  Selago: '#F0EEFE',
  Flamingo: '#EE5938',
  BrickRed: '#D52E4C',
  GreyAAA: '#AAAAAA',
  Concrete: '#F2F2F2',
  GreyF9: '#F9F9F9',
  TulipTree: '#EEBB38',
};

export enum Spacing {
  px4 = '4px',
  px8 = '8px',
  px12 = '12px',
  px16 = '16px',
  px24 = '24px',
  px32 = '32px',
  px48 = '48px',
  px64 = '64px',
  px96 = '96px',
}

export enum SpacingNamed {
  small1 = '4px',
  small2 = '8px',
  small3 = '12px',
  medium1 = '16px',
  medium2 = '24px',
  medium3 = '32px',
  large1 = '48px',
  large2 = '64px',
  large3 = '96px',
}

export enum BorderRadius {
  px4 = '4px',
  px6 = '6px',
  px12 = '12px',
  px24 = '24px',
  px48 = '48px',
}

export enum FontSize {
  px10 = '10px',
  px12 = '12px',
  px14 = '14px',
  px16 = '16px',
  px18 = '18px',
  px20 = '20px',
  px22 = '22px',
  px24 = '24px',
  px40 = '40px',
}

export enum ZIndex {
  OverContent = 2,
  Elevation1 = 10,
  Elevation2 = 20,
  AlwaysVisible = 100,
}

export enum FontWeight {
  w400 = '400',
  w700 = '700',
}

export const fontFamily = 'akkurat,Helvetica Neue,Helvetica,Arial,sans-serif;';

export const theme = createTheme({
  palette: {
    primary: {
      main: colors.ElectricViolet,
      light: colors.Selago,
      dark: colors.RoyalBlue,
    },
    success: {
      main: colors.MountainMeadow,
      light: '#E9F8EF',
    },
    error: {
      main: colors.BrickRed,
      light: '#FEEFEC',
      dark: colors.BrickRed,
    },
    text: {
      primary: '#000000',
      secondary: colors.Boulder,
      disabled: colors.Silver,
    },
    divider: colors.Mercury,
    grey: {
      A100: colors.Concrete,
      A200: colors.Mercury,
      A400: colors.GreyAAA,
      100: 'rgba(255,255,255,0.25)',
      500: 'rgba(255,255,255,0.5)',
    },
  },
  typography: {
    fontFamily,
  },
});

export const palette = theme.palette;
