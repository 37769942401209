import { ErrorBoundary as DatadogErrorBoundary } from '@Datadog/rum-react-integration';
import { SiteHeaderWithPackagedTranslation } from '@crowdcube/flow-site-header/withPackagedTranslation';
import { extractTldFromUrlString } from '@crowdcube/helpers/tld';
import { AppProvider, store } from '@crowdcube/store';
import { ReactNode, useState } from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { AvoidRiskBanner } from '../../../../sell/src/components/SellRootPage/SellRootPage';
import { useVariables } from '../../hooks/useVariables';
import { Box } from '../Box';
import { RootPage } from '../RootPage';
import { Text } from '../Text';
import { Button } from '../button';

export const ErrorPageLayout = ({
  crowdcubeWebsite,
  title,
  message,
  error,
}: {
  crowdcubeWebsite: string;
  title?: string;
  message?: string;
  error?: Error;
}) => {
  const mode = import.meta.env.VITE_MODE;
  const isProduction = mode === 'production';

  if (!title) {
    title = 'Uh oh, there’s been an unexpected error';
    message = 'Something went wrong. Our team is on it!';
  }

  const isNetworkError = error?.message?.match(/Failed to fetch/);

  if (isNetworkError) {
    title = 'We’re having network difficulties';
    message =
      'Looks like we’re struggling to connect. Please refresh and try again.';
  }

  const [open, setOpen] = useState(false);

  return (
    <RootPage
      header={
        <Box sx={{ width: '100%', display: 'block' }}>
          <AppProvider store={store}>
            <AvoidRiskBanner />
            <SiteHeaderWithPackagedTranslation
              nonRelativeUrl={crowdcubeWebsite}
            />
          </AppProvider>
        </Box>
      }
    >
      <Text sx={{ m: 4, fontSize: 6 }}>⚠️</Text>
      <Box sx={{ gap: 3 }}>
        <Text variant="title">{title}</Text>
        {message && <Text>{message}</Text>}
        {!isProduction && error && error.message ? (
          <Button
            size="tiny"
            variant="secondary"
            onClick={() => setOpen(!open)}
          >
            Show technical details
          </Button>
        ) : null}
      </Box>
      {!isProduction && error && open ? (
        <>
          <code
            style={{
              width: '100%',
              overflow: 'scroll',
            }}
          >
            <pre>{error.name}</pre>
            <pre>{error.message}</pre>
            <pre>{error.stack}</pre>
          </code>
        </>
      ) : null}
    </RootPage>
  );
};

export const RouteErrorPage = () => {
  const error = useRouteError();
  const title = isRouteErrorResponse(error)
    ? `${error.status} ${error.statusText}`
    : undefined;
  const tld = extractTldFromUrlString(window.location.origin);
  const { crowdcube_website } = useVariables(tld);

  return (
    <ErrorPageLayout
      title={title}
      error={error as Error}
      crowdcubeWebsite={crowdcube_website}
    />
  );
};

export const ErrorBoundary = ({
  children,
  crowdcubeWebsite,
}: {
  children: ReactNode;
  crowdcubeWebsite: string;
}) => (
  // @ts-expect-error DD's component missing children prop
  <DatadogErrorBoundary
    fallback={error => (
      <ErrorPageLayout error={error} crowdcubeWebsite={crowdcubeWebsite} />
    )}
  >
    {children}
  </DatadogErrorBoundary>
);
