import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { animated } from 'react-spring';
import { SxProp, useEmotionMUIScopeHack } from '../SemperDS/SxFn';

export const Box = styled('div')<SxProp>(props => ({
  display: 'flex',
  flexDirection: 'column',
  ...useEmotionMUIScopeHack(props),
}));
export const AnimatedBox = animated(Box);

// This is mainly to avoid to TS complication of using `as="button"` with
// <button> props that aren't on `<div>`
export const Touchable = styled('button')<SxProp>(props => ({
  display: 'flex',
  ...useEmotionMUIScopeHack(props),
}));
export const ClearTouchable = styled('button')<SxProp>(props => ({
  display: 'flex',
  appearance: 'none',
  border: 0,
  margin: 0,
  padding: 0,
  background: 'transparent',
  cursor: 'pointer',
  ...useEmotionMUIScopeHack(props),
}));

export const Anchor = styled('a')<SxProp>(({ sx, _sx, ...rest }) => ({
  display: 'flex',
  textDecoration: 'none',
  ...useEmotionMUIScopeHack({
    sx: {
      color: 'primary',
      ...sx,
    },
    _sx: {
      ':hover': {
        textDecoration: 'underline',
      },
      ..._sx,
    },
    ...rest,
  }),
}));

export const BoxLink = styled(Link)<SxProp>(props => ({
  display: 'flex',
  textDecoration: 'none',
  color: 'text',
  ...useEmotionMUIScopeHack(props),
}));

export const Input = styled('input')<SxProp>(props => ({
  ...useEmotionMUIScopeHack(props),
}));

export const Summary = styled('summary')<SxProp>(props => ({
  ...useEmotionMUIScopeHack(props),
}));

export const Details = styled('details')<SxProp>(props => ({
  ...useEmotionMUIScopeHack(props),
}));

export const _Select = styled('select')<SxProp>(props => ({
  ...useEmotionMUIScopeHack(props),
}));

export const Image = styled('img')<SxProp>(props => ({
  ...useEmotionMUIScopeHack(props),
}));
