import { extractTldFromUrlString } from '@crowdcube/helpers/tld';
import { CssBaseline, ThemeProvider } from '@mui/material';
import {
  ApolloLayer,
  AuthProvider,
  CurrentUserProvider,
  theme,
} from '@semper/shared-components';
import { HelmetProvider } from 'react-helmet-async';

import { datadogRum } from '@datadog/browser-rum';
import init, { Variables } from '@semper/rust/lib/core/pkg-web';
import { ErrorBoundary } from '@semper/shared-components';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { AuthRouter } from './AuthRouter';

const load = async () => {
  if (typeof process === 'undefined') {
    // Only code that is running in the browser needs to have init() called.
    await init();
  }

  const { mode, postgraphile_api, crowdcube_website } = Variables.load_core(
    import.meta.env.VITE_MODE,
    import.meta.env.VITE_BRANCH,
    extractTldFromUrlString(window.location.origin),
  );

  datadogRum.init({
    applicationId: 'a2d6444a-2640-4c9c-a97c-8b7fbf3279bf',
    clientToken: 'pub5ac5d78dd848ee216b1b50adee45d3fa',
    site: 'datadoghq.eu',
    service: 'auth',
    env: mode,
    version: import.meta.env.VITE_RELEASE_VERSION || mode,
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'allow',
  });
  datadogRum.startSessionReplayRecording();

  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(
    <React.StrictMode>
      <ErrorBoundary crowdcubeWebsite={crowdcube_website}>
        <HelmetProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline>
              <ApolloLayer apiUrl={postgraphile_api}>
                <CurrentUserProvider refreshIfNoUser={false}>
                  <AuthProvider>
                    <AuthRouter />
                  </AuthProvider>
                </CurrentUserProvider>
              </ApolloLayer>
            </CssBaseline>
          </ThemeProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </React.StrictMode>,
  );
};

load();
