import { gql } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import { SemperCookie } from '@semper/rust/lib/core/pkg-web';
import { LoadingLayoutPage } from '@semper/shared-components';
import { pickBy } from 'lodash';
import React, { createContext, useContext, useEffect } from 'react';
import { useCurrentUserQuery } from '../generated/graphql';

gql`
  query CurrentUser {
    currentUserIsAdmin
    loggedInUser {
      userId
      user {
        id
        userId: id
        shortName
        fullName
        email
      }
      team {
        nodeId
        teamId: id
        allowedToViewDeals
        buyerOnboarding {
          nodeId
          buyerOnboardingCompleted
        }
        sellerOnboarding {
          nodeId
          taxResidence
          sellerOnboardingCompleted
        }
        teamName
        activeTransactionGroup {
          nodeId
          id
          liquidityEventId
        }
      }
    }
  }
`;

const initCurrentUser = {
  email: '',
  fullName: '',
  shortName: '',
  teamName: '',
  teamId: '',
  userId: '',
  allowedToViewDeals: false,
  buyerOnboardingCompleted: false,
  sellerOnboardingCompleted: false,
  refetch: () => null,
  hasBuyerOnboarding: false,
  hasSellerOnboarding: false,
  seller: {},
  isAdmin: false,
  // TOOD: Buyer, move buyer-specific stuff there (allowedToViewDeals etc)

  // NOTE: This is how we differentiate between a SellerOffer being an
  // expression of interest vs being a sell order; AKA gathering selling
  // interest (supply) when we don't know the buyer demand vs splitting &
  // allocationg a known buyer quantity.
  sellSideFirst: true,
};
const CurrentUserContext = createContext<{
  email: string;
  fullName: string;
  shortName: string;
  teamName?: string;
  teamId: string;
  userId: string;
  allowedToViewDeals: boolean;
  buyerOnboardingCompleted: boolean;
  sellerOnboardingCompleted: boolean;
  refetch: () => void;
  hasBuyerOnboarding: boolean;
  hasSellerOnboarding: boolean;
  seller: {
    transactionGroupId?: string;
    liquidityEventId?: string;
  };
  isAdmin: boolean;
  sellSideFirst: boolean;
}>(initCurrentUser);

export const useCurrentUser = () => useContext(CurrentUserContext);

export const CurrentUserProvider = ({
  children,
  refreshIfNoUser = true,
}: {
  children: React.ReactNode;
  refreshIfNoUser?: boolean;
}) => {
  const { loading, data, refetch } = useCurrentUserQuery();

  useEffect(() => {
    if (!data?.loggedInUser?.user) return;
    datadogRum.setUser({
      id: data.loggedInUser.team?.teamId,
      name: data.loggedInUser.user.fullName,
      email: data.loggedInUser.user.email,
    });
  }, [loading, data]);

  if (loading) {
    return <LoadingLayoutPage />;
  }

  if (!data?.loggedInUser && refreshIfNoUser) {
    SemperCookie.logout();
    window.location.reload();
    return null;
  }

  const user = pickBy(data?.loggedInUser?.user || {}, Boolean);
  const team = pickBy(data?.loggedInUser?.team || {}, Boolean);
  const activeTransaction = data?.loggedInUser?.team?.activeTransactionGroup;
  const seller = activeTransaction
    ? {
        transactionGroupId: activeTransaction.id!,
        liquidityEventId: activeTransaction.liquidityEventId!,
      }
    : {};

  const values = {
    ...initCurrentUser,
    ...user,
    ...team,
    hasBuyerOnboarding: !!data?.loggedInUser?.team?.buyerOnboarding,
    hasSellerOnboarding: !!data?.loggedInUser?.team?.sellerOnboarding,
    sellerOnboardingCompleted:
      data?.loggedInUser?.team?.sellerOnboarding?.sellerOnboardingCompleted ||
      false,
    buyerOnboardingCompleted:
      data?.loggedInUser?.team?.buyerOnboarding?.buyerOnboardingCompleted ||
      false,
    seller,
    isAdmin: data?.currentUserIsAdmin || false,
    refetch,
  };

  return (
    <CurrentUserContext.Provider value={values}>
      {children}
    </CurrentUserContext.Provider>
  );
};
