import { Box } from '@semper/shared-components';
import { ReactNode } from 'react';

type Props = {
  header?: ReactNode;
  children: ReactNode;
};
export const RootPage = ({ header, children }: Props) => (
  <Box sx={{ bg: 'background', alignItems: 'center' }}>
    {header}
    <Box
      sx={{
        px: [0, 1, 2],
        width: '100%',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          bg: 'background',
          width: '100%',
          alignItems: 'center',
          minHeight: 'calc(100vh - 72px - 72px)',
        }}
      >
        {children}
      </Box>
    </Box>
    {/* This wortks with `@crowdcube/flow-site-header` inside `SelRootPage` */}
    <Box
      as="footer"
      id="footer-portal"
      sx={{
        width: '100%',
      }}
    />
  </Box>
);
