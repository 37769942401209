import styled from '@emotion/styled';
import {
  Colors,
  DSComponent,
  SxProp,
  SxType,
  useEmotionMUIScopeHack,
} from '@semper/shared-components';
import { ElementType } from 'react';

const BaseText = styled('span')<SxProp>(useEmotionMUIScopeHack);
BaseText.displayName = 'BaseText';

type Variants =
  | 'title'
  | 'heading'
  | 'subheading'
  | 'body'
  | 'small'
  | 'caption'
  | 'punch'
  | 'error'
  | 'brandBody'
  | 'brandCaption'
  | 'brandTitle'
  | 'formHeading';

const variants: Record<Variants, SxType> = {
  title: {
    fontSize: [5, 6],
    lineHeight: 'title',
    fontWeight: 'bold',
    letterSpacing: '-0.02em',
  },
  heading: {
    fontSize: [4, 5],
    lineHeight: 'heading',
  },
  subheading: {
    fontSize: 3,
    lineHeight: 'heading',
  },
  body: {
    fontFamily: 'primary',
    fontSize: 2,
    lineHeight: 'body',
    fontFeatureSettings: "'tnum' on, 'lnum' on, 'salt' on",
  },
  small: {
    fontSize: 1,
  },
  caption: {
    fontSize: 0,
    lineHeight: 'title',
  },
  error: {
    fontSize: 1,
    color: 'error100',
  },

  formHeading: {
    fontSize: 3,
    lineHeight: 'heading',
    fontWeight: 'medium',
    mb: 3,
  },

  // Temp IOP styles
  // These need to be consolidated with existing variants so we don't end up
  // with dozens of variants spread across the codebase
  punch: {
    fontSize: 0,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing: '0.12em',
  },

  brandBody: {
    fontFamily: 'decorative',
    fontSize: 3,
    letterSpacing: '-0.02em',
  },
  brandCaption: {
    fontFamily: 'decorative',
    fontSize: 2,
    letterSpacing: '-0.02em',
  },
  brandTitle: {
    fontFamily: 'decorative',
    fontSize: 5,
    fontWeight: 'bold',
    letterSpacing: '-0.02em',
  },
};

export const __variantsForStorybook = Object.keys(variants) as Variants[];

export type TextProps = DSComponent & {
  variant?: Variants;
  color?: Colors;
  as?: ElementType;
};
export const Text = ({
  variant = 'body',
  sx,
  _sx,
  color,
  as,
  children,
}: TextProps) => {
  const variantSx = variants[variant];
  return (
    <BaseText
      as={as}
      sx={{
        color,
        ...variants.body,
        ...variantSx,
        ...sx,
      }}
      _sx={_sx}
    >
      {children}
    </BaseText>
  );
};
