import { mapValues } from 'lodash';
import { generatePath, useParams } from 'react-router-dom';
import { useVariables } from '..';

export enum SharedHrefs {
  privacy = 'https://www.meetsemper.com/privacy-policy',
  sellerTerms = 'https://www.meetsemper.com/seller-terms-and-conditions',
}

export enum AuthPaths {
  slash = '/',
  retool = '/retool',
}

export enum SellPaths {
  slash = '/',

  event = '/event',
  eventById = '/event/:id',
  confirmEquity = '/event/:id/confirm-equity',
  taxes = '/event/:id/taxes',
  order = '/event/:id/order',
  sign = '/event/:id/sign',
  faq = '/event/:id/faq',
  timeline = '/event/:id/timeline',
  summary = '/event/:id/summary',
  documents = '/event/:id/documents',
  confirm = '/event/:id/confirm',
  transactionSummary = '/transaction-summary/:transactionGroup',
}

export const useURLs = (tld: string, withOrigin = false) => {
  const { auth_url, sell_url } = useVariables(tld);

  return {
    AuthURLs: withOrigin
      ? mapValues(AuthPaths, path => `${auth_url}${path}`)
      : AuthPaths,
    SellUrls: withOrigin
      ? mapValues(SellPaths, path => `${sell_url}${path}`)
      : SellPaths,
  };
};

// Generates functions for valid paths using the current URL.
// You can also pass params to the outputted fn for any that are missing
export const useSellPaths = () => {
  const params = useParams();
  const paths = mapValues(
    SellPaths,
    path => (optionalParams?: Record<string, string>) =>
      generatePath(path as string, { ...params, ...optionalParams }),
  );
  return paths;
};
