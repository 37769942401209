import { BorderColour, BorderRadius, TextColour } from '@crowdcube/styles';
import { useDebouncedValue } from '@mantine/hooks';
import { format, parseISO } from 'date-fns';
import { FieldValidator, useField } from 'formik';
import {
  ChangeEventHandler,
  ElementType,
  FocusEvent,
  FocusEventHandler,
  InputHTMLAttributes,
  ReactNode,
  SelectHTMLAttributes,
  TextareaHTMLAttributes,
  useEffect,
  useState,
} from 'react';
import { Box, Details, Input, Summary } from '../Box';
import { Checkbox } from '../Checkbox';
import { RadioGroup, RadioGroupProps } from '../RadioGroup';
import { Select, SelectProps } from '../Select';
import { DSComponent } from '../SemperDS';
import { Text } from '../Text';
import { useUserAction } from '../Tracking';
import { TaxResidence } from './TaxResidence';

type FieldInputProps = {
  name: string;
  label?: ReactNode;
  error?: boolean;
  disabled?: boolean;
  explanation?: string;
};
export type FieldProps<T = Record<string, unknown>> = T &
  FieldInputProps &
  DSComponent & {
    validate?: FieldValidator;
  };
type Opts = {
  type?: 'checkbox' | 'radio';
  directValueAPI?: boolean;
};

const makeFieldComponent = <T,>(InputComponent: ElementType, opts?: Opts) => {
  // TODO: Field registration for scroll-to-error
  const FormField = ({
    name,
    sx,
    validate,
    explanation,
    ...rest
  }: FieldProps<T>) => {
    const focusAction = useUserAction('focus');
    const errorAction = useUserAction('field-error');
    const [field, { error, touched }, { setValue }] = useField({
      name,
      validate,
      ...opts,
    });
    const inputProps = {
      ...field,
      ...rest,
      onFocus: () => focusAction({ name }),
      onChange: opts?.directValueAPI ? setValue : field.onChange,
    };

    useEffect(() => {
      if (touched && error) {
        errorAction({ name, error });
      }
    }, [touched, error]);

    return (
      <Box sx={{ width: '100%', position: 'relative', minWidth: 0, ...sx }}>
        <InputComponent error={Boolean(touched && error)} {...inputProps} />
        {touched && error ? (
          <Text
            color="error100"
            variant="caption"
            sx={{
              mt: 2,
              mb: 1,
              pl: 2,
              display: 'block',
            }}
          >
            {error}
          </Text>
        ) : null}

        {explanation && (
          <Details
            sx={{
              maxWidth: '100ch',
            }}
          >
            <Summary
              sx={{
                pt: 3,
                pb: 3,
                opacity: 0.7,
                cursor: 'pointer',
              }}
              _sx={{
                '&:hover': {
                  opacity: 1,
                },
              }}
            >
              Help with {rest.label}
            </Summary>
            <span style={{ color: `rgb(${TextColour.Subtle})` }}>
              {explanation}
            </span>
          </Details>
        )}
      </Box>
    );
  };
  // @ts-expect-error `displayName` does exist
  FormField.displayName = `Field.${InputComponent.displayName || 'Unknown'}`;
  return FormField;
};

export const Label = ({
  children,
  error,
  sx,
}: DSComponent & { error?: boolean }) => (
  <Text
    variant="small"
    sx={{
      mb: 1,
      color: error ? 'error100' : 'text',
      fontWeight: 'medium',
      ...sx,
    }}
  >
    {children}
  </Text>
);

const Control = ({
  children,
  sx,
  disabled,
}: { disabled?: boolean } & DSComponent) => (
  <Box
    as="label"
    sx={{
      flexDirection: 'column',
      width: '100%',
      opacity: disabled ? 0.7 : 1,
      cursor: disabled ? null : 'pointer',
      ...sx,
    }}
  >
    {children}
  </Box>
);

const TextInput = ({
  error,
  disabled,
  sx,
  ...props
}: FieldProps & InputHTMLAttributes<HTMLInputElement>) => (
  <Input
    sx={{
      appearance: 'none',
      boxSizing: 'content-box',
      border: '2px solid',
      borderColor: `rgb(${error ? BorderColour.Error : BorderColour.Default})`,
      borderRadius: BorderRadius.SM,
      bg: 'background',
      py: 5,
      px: 5,
      color: error ? 'error100' : 'text',
      ...sx,
    }}
    _sx={{
      fontFamily: "'Aeonik', sans-serif",
      fontSize: '16px',
      cursor: disabled ? 'not-allowed' : 'text',
      ':focus': {
        borderColor: `rgb(${BorderColour.Focus})`,
        outline: `3px solid rgba(${BorderColour.FocusOuter}) `,
      },
    }}
    disabled={disabled}
    {...props}
  />
);

export const dateToDateField = (date?: Date | null) => {
  if (!date) {
    return undefined;
  }
  try {
    return format(date, 'yyyy-MM-dd');
  } catch (e) {
    return format(parseISO(String(date)), 'yyyy-MM-dd');
  }
};

const DateField = ({
  disabled,
  label,
  error,
  sx,
  value,
  onChange,
  name,
  onBlur,
}: FieldProps &
  Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
    onChange: (val: string) => void;
  }) => {
  if (typeof value !== 'string') {
    throw new Error(
      `Only strings supported, you have passed a value of type ${typeof value}
      with value ${value}`,
    );
  }
  const [val, setVal] = useState(value);
  const [yyyy = '', mm = '', dd = ''] = val.split('-');
  const handleDay: ChangeEventHandler<HTMLInputElement> = e =>
    setVal(`${yyyy}-${mm}-${e.target.value}`);
  const handleMonth: ChangeEventHandler<HTMLInputElement> = e =>
    setVal(`${yyyy}-${e.target.value}-${dd}`);
  const handleYear: ChangeEventHandler<HTMLInputElement> = e =>
    setVal(`${e.target.value}-${mm}-${dd}`);

  const [blurEvent, setBlurEvent] =
    useState<FocusEvent<HTMLInputElement> | null>(null);

  const handleFocus = () => {
    setBlurEvent(null);
  };
  const handleBlur: FocusEventHandler<HTMLInputElement> = e => {
    setBlurEvent(e);
  };

  const [debouncedEvent] = useDebouncedValue(blurEvent, 50);

  useEffect(() => {
    if (debouncedEvent) onBlur?.(debouncedEvent);
  }, [debouncedEvent]);

  useEffect(() => {
    if (val && onChange) {
      onChange(val);
    }
  }, [val]);

  return (
    <Control disabled={disabled} sx={sx}>
      <Label error={error}>{label}</Label>
      <Box
        sx={{
          width: '100%',
          opacity: disabled ? 0.7 : 1,
          cursor: disabled ? null : 'pointer',
          flexDirection: 'row',
          gap: 3,
          ...sx,
        }}
      >
        <TextInput
          name={name}
          onFocus={handleFocus}
          onBlur={handleBlur}
          error={error}
          onChange={handleDay}
          value={dd}
          inputMode="numeric"
          placeholder="dd"
          sx={{
            minWidth: 0,
            flex: '2 1 0',
          }}
        />
        <TextInput
          name={name}
          onFocus={handleFocus}
          onBlur={handleBlur}
          error={error}
          inputMode="numeric"
          onChange={handleMonth}
          value={mm}
          placeholder="mm"
          sx={{
            minWidth: 0,
            flex: '2 1 0',
          }}
        />
        <TextInput
          name={name}
          onFocus={handleFocus}
          onBlur={handleBlur}
          error={error}
          inputMode="numeric"
          onChange={handleYear}
          value={yyyy}
          placeholder="yyyy"
          sx={{
            minWidth: 0,
            flex: '3 1 0',
          }}
        />
      </Box>
    </Control>
  );
};

const CustomInput = ({
  disabled,
  label,
  error,
  sx,
  ...props
}: FieldProps & InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <Control disabled={disabled} sx={sx}>
      <Label error={error}>{label}</Label>
      <TextInput error={error} disabled={disabled} {...props} />
    </Control>
  );
};

const CustomTextarea = ({
  disabled,
  label,
  error,
  sx,
  ...props
}: FieldProps & InputHTMLAttributes<HTMLInputElement>) => (
  <Control disabled={disabled} sx={sx}>
    <Label error={error}>{label}</Label>
    <TextInput disabled={disabled} as="textarea" {...props} />
  </Control>
);

const LabelledSelect = ({
  label,
  disabled,
  sx,
  ...rest
}: SelectProps & FieldInputProps & SelectHTMLAttributes<HTMLSelectElement>) => (
  <Control disabled={disabled} sx={sx}>
    <Label error={rest.error}>{label}</Label>
    <Select disabled={disabled} {...rest} />
  </Control>
);

export const _forStoryBook = {
  Input: CustomInput,
  DateField,
};

const InputWithUnits = ({
  label,
  unit,
  name,
  inputLength = 1000,
  ...rest
}: FieldProps & { unit: string; inputLength: number } & Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'onChange'
  > & {
    onChange: (val: string) => void;
  }) => {
  return (
    <Box as="label">
      <Label error={rest.error}>{label}</Label>
      <Box
        sx={{
          flexDirection: 'row',
          display: 'flex',
        }}
      >
        <TextInput
          name={name}
          sx={{
            width: `${inputLength}ch`,
            maxWidth: '100%',
            borderRight: 'none',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            zIndex: 10,
          }}
          inputMode="numeric"
          pattern="[0-9\.]*"
          {...rest}
          value={
            isNaN(Number(rest.value)) ||
            rest.value === '' ||
            (typeof rest.value == 'string' && rest.value?.includes('.'))
              ? rest.value
              : new Intl.NumberFormat().format(Number(rest.value))
          }
          onChange={e => {
            const val = e.target.value.replace(/[^0-9.]/g, '');
            rest.onChange(val);
          }}
        />

        <Box
          sx={{
            flex: '0 0 auto',
            border: '2px solid',
            borderColor: `rgb(${
              rest.error ? BorderColour.Error : BorderColour.Default
            })`,
            borderRadius: BorderRadius.SM,
            backgroundColor: 'UiNeutral200',
            color: 'UiNeutral700',
            py: 5,
            px: 5,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            width: '80px',
            textAlign: 'center',
          }}
        >
          {unit}
        </Box>
      </Box>
    </Box>
  );
};

export const Fields = {
  TaxResidence: makeFieldComponent(TaxResidence, { directValueAPI: true }),
  Label,
  Input: makeFieldComponent<InputHTMLAttributes<HTMLInputElement>>(CustomInput),
  Textarea:
    makeFieldComponent<TextareaHTMLAttributes<HTMLTextAreaElement>>(
      CustomTextarea,
    ),
  Checkbox: makeFieldComponent(Checkbox, { type: 'checkbox' }),
  Select: makeFieldComponent<SelectProps>(LabelledSelect),
  RadioGroup: makeFieldComponent<Omit<RadioGroupProps, 'onChange'>>(RadioGroup),
  Date: makeFieldComponent(DateField, { directValueAPI: true }),
  InputWithUnits: makeFieldComponent(InputWithUnits, { directValueAPI: true }),
};
