import { Colour, TextColour } from '@crowdcube/styles';
import { Theme } from '@styled-system/css';
import { createContext, ReactNode, useContext } from 'react';
import { $enum } from 'ts-enum-util';

const C = {
  ElectricViolet: '#6E53F6',
  RoyalBlue: '#5D40F0',
  MountainMeadow: '#21B75D',
  Flamingo: '#EE5938',
  BrickRed: '#D52E4C',
  Selago: '#F0EEFE',
  Concrete: '#F2F2F2',
  Mercury: '#E5E5E5',
  Silver: '#CCCCCC',
  GreyAAA: '#AAAAAA',
  Boulder: '#757575',
  TulipTree: '#EEBB38',
};

const ccColours = {} as Record<string, string>;
for (const [key, value] of $enum(Colour).entries()) {
  ccColours[key] = `rgb(${value})`;
}

const makeTheme = <T extends Theme>(t: T) => t;

const theme = makeTheme({
  space: [
    0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 64,
  ] as const,
  fontSizes: [13, 15, 18, 21, 24, 32, 46, 60] as const,
  fonts: {
    primary: 'akkurat,Helvetica Neue,Helvetica,Arial,sans-serif',
    decorative: '"EB Garamond", serif',
    monospace: `ui-monospace,
    Menlo, Monaco,
    "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono",
    "Oxygen Mono",
    "Ubuntu Monospace",
    "Source Code Pro",
    "Fira Mono",
    "Droid Sans Mono",
    "Courier New", monospace;`,
  },
  fontWeights: {
    normal: '400',
    medium: '500',
    bold: '700',
  },
  lineHeights: {
    body: 1.5,
    title: 1.2,
    heading: 1.33,
  },
  colors: {
    text: `rgb(${TextColour.Default})`, // Body foreground color
    muted: C.Boulder, // Body FG with lower contrast to BG
    background: '#fff', // Body background color
    backgroundAlt: '#F8F8F8',
    primary: `rgb(${Colour.CcOrange500})`, // Primary brand color for links, buttons, etc.
    primary100: `rgb(${Colour.CcOrange100})`,
    primary10: C.Selago,

    secondary: `rgb(${Colour.CcOrange400})`,
    highlight: `rgb(${Colour.CcBlue500})`,
    grey10: C.Concrete,
    grey20: C.Mercury,
    grey30: C.Silver,
    grey40: C.GreyAAA,
    grey50: C.Boulder,
    transparent: 'transparent',

    success100: C.MountainMeadow,
    success60: '#7AD49E',
    success10: '#E9F8EF',

    warning100: '#B78A16',
    warning50: C.TulipTree,
    warning10: '#fdf4dd',
    warning5: '#FEFBF3',

    error100: C.BrickRed,
    error60: '#DA7688',

    accent: C.Flamingo, // A contrast color for emphasizing UI
    accent100: C.Flamingo,
    accent10: '#FDEEEB',
    accent5: '#FEF7F5',

    black: 'black',
    white: 'white',
    white70: 'rgba(255,255,255,0.7)',
    white20: 'rgba(255,255,255,0.2)',

    // Insert Crowdcube colours so we can just directly reference as needed
    ...Colour, // insert the enum so we get autocomplete
    ...ccColours, // then overwrite to get colours as rgb strings
  },
  breakpoints: [
    // These are just copy-pasted from MUI's breakpoints for now
    '600px',
    '900px',
    '1200px',
    '1536xpx',
  ],
  zIndicies: [0, 10, 20, 40, 50],
});

export type DSTheme = typeof theme;
// HACK: Hand-coded becuase getting indicies from TS is mad
export type SpaceChoices = [
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  -1,
  -2,
  -3,
  -4,
  -5,
  -6,
  -7,
  -8,
  -9,
  -10,
  -11,
  -12,
  -13,
  -14,
  -15,
  -16,
][number];
export type FontSizeChoices = [0, 1, 2, 3, 4, 5, 6][number];

// NOTE: Never statically import the theme. Use `useDSTheme` or DS components
export const __forStorybook = { theme };

const DSContext = createContext({
  theme,
});

export const SemperDSProvider = (props: { children: ReactNode }) => (
  <DSContext.Provider value={{ theme }} {...props} />
);
export const useDSTheme = () => useContext(DSContext);
