import { BorderColour, BorderRadius, TextColour } from '@crowdcube/styles';
import {
  Autocomplete,
  CloseButton,
  MantineProvider,
  SelectItemProps,
} from '@mantine/core';
import { useColorScheme } from '@mantine/hooks';
import { forwardRef, InputHTMLAttributes } from 'react';
import { z } from 'zod';
import { IsoCountry } from '../../generated/graphql';
import { FieldProps, Label } from './Fields';
import locations from './location-autocomplete-canonical-list.json';
import locationsGraph from './location-autocomplete-graph.json';

const getCountry = (code: string) => {
  let tempCode = code;
  let escape = 3;
  while (escape > 0) {
    if (
      Object.keys(locationsGraph).includes(tempCode) &&
      Object.entries(locationsGraph).find(
        ([countryCode, { edges }]) =>
          countryCode === tempCode && edges.from.length > 0,
      )
    ) {
      const countryObject = Object.entries(locationsGraph).find(
        ([countryCode, _]) => countryCode === tempCode,
      )?.[1];
      tempCode = countryObject?.edges.from[0] as string;
    } else {
      break;
    }
    escape--;
  }
  return tempCode.replace('country:', '');
};

const namesToCountries: [
  string,
  {
    country: string;
    stable: boolean;
  },
][] = Object.entries(locationsGraph).map(([a, b]) => [
  b.names['en-GB'],
  { country: getCountry(a), stable: b.meta['stable-name'] },
]);

interface ItemProps extends SelectItemProps {
  countryCode: string;
  cleanedCountry: string;
}

const AutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ value, countryCode, cleanedCountry, ...others }, ref) => {
    const filteredName = namesToCountries.find(
      ([name, { country: filteredCountryCode, stable }]) =>
        name.toLowerCase().includes(cleanedCountry) &&
        filteredCountryCode === countryCode &&
        stable,
    )?.[0];

    return (
      <div ref={ref} {...others}>
        {value} {filteredName && filteredName !== value && `(${filteredName})`}
      </div>
    );
  },
);
AutoCompleteItem.displayName = 'AutoCompleteItem';

export const TaxResidence = ({
  error,
  disabled,
  onChange,
  label,
  ...rest
}: FieldProps &
  Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
    onChange: (val: string) => void;
  }) => {
  // This is typescript being a bit silly, there is probs a better way to do this
  let value: string;
  if (rest.value && typeof rest.value !== 'string') {
    throw new Error('value must be a string');
  } else {
    value = rest.value as string;
  }
  const country = value;

  const cleanedCountry = country?.toLowerCase()?.trim() || '';

  const filteredLocations = namesToCountries
    .filter(([name, { country: countryCode }]) => {
      if (cleanedCountry.length === 0) {
        return ['GB', 'US', 'FR', 'DE', 'IT', 'CA', 'JP'].includes(countryCode);
      }
      return name.toLowerCase().includes(cleanedCountry);
    })
    .sort((a, b) => {
      const overlapA = Math.abs(a[0].length - cleanedCountry.length);
      const overlapB = Math.abs(b[0].length - cleanedCountry.length);
      return overlapA - overlapB;
    })
    .map(([_, { country: countryCode }]) => ({
      value: locations.find(
        ([_, code]) => code === `country:${countryCode}`,
      )?.[0],
      countryCode,
      cleanedCountry,
    }))
    .filter(x => !!x.value && typeof x.value === 'string')
    .filter((v, i, a) => a.findIndex(x => x.value === v.value) === i);
  return (
    <MantineProvider
      theme={{
        colorScheme: useColorScheme(),
        fontFamily: "'Aeonik', sans-serif",
        primaryColor: 'violet',
        colors: {
          dark: [
            '#f9fafb',
            '#f3f4f6',
            '#e5e7eb',
            '#d1d5db',
            '#9ca3af',
            '#6b7280',
            '#1C124E',
            '#150435',
            '#090617',
            '#04030C',
          ],
        },
      }}
    >
      <Autocomplete
        label={<Label error={error}>{label}</Label>}
        value={value}
        onChange={onChange}
        data={filteredLocations.map(
          ({ value, countryCode, cleanedCountry }) => ({
            value: value as string,
            countryCode,
            cleanedCountry,
          }),
        )}
        placeholder="Enter the full name of your country of tax residence"
        itemComponent={AutoCompleteItem}
        filter={() => true}
        limit={7}
        rightSection={
          <CloseButton
            aria-label="Clear input"
            onClick={() => onChange('')}
            style={{ display: value ? undefined : 'none' }}
          />
        }
        sx={{
          input: {
            appearance: 'none',
            boxSizing: 'border-box',
            width: '100%',
            border: '2px solid',
            borderColor: `rgb(${
              error ? BorderColour.Error : BorderColour.Default
            })`,
            borderRadius: BorderRadius.SM,
            background: '#fff',
            paddingTop: '20px',
            paddingBottom: '20px',
            color: `rgb(${TextColour.Default})`,
            fontFamily: 'akkurat,Helvetica Neue,Helvetica,Arial,sans-serif;',
            fontSize: '16px',
            cursor: disabled ? 'not-allowed' : 'text',
            minHeight: 'initial',
            height: 'initial',
            lineHeight: 'initial',
            flex: '1 1 auto',
            '&:focus': {
              borderColor: `rgb(${BorderColour.Focus})`,
              outline: `3px solid rgb(${BorderColour.FocusOuter}) `,
            },
          },
        }}
      />
    </MantineProvider>
  );
};

export const getCountryCode = (country: string): IsoCountry | null => {
  const countryCode = namesToCountries.find(
    ([name, { stable }]) => name === country && stable,
  )?.[1].country;

  const validCountryCodes = Object.values(IsoCountry) as string[];

  if (countryCode && validCountryCodes.includes(countryCode)) {
    return countryCode as IsoCountry;
  }
  return null;
};

export const countryFromCode = (code: string) => {
  const country = namesToCountries.find(
    ([_, { country: countryCode }]) => countryCode === code,
  )?.[0];
  return country;
};

export const ValidCountry = z.string().refine(
  val => {
    return !!getCountryCode(val);
  },
  { message: 'Please enter a valid tax residence' },
);
