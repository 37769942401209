import { Box, CircularProgress } from '@mui/material';

export const CenteredSpinner = ({ size = '4rem' }: { size?: string }) => (
  <Box
    width="100%"
    height="100%"
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    <CircularProgress size={size} />
  </Box>
);
