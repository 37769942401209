/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { extractTldFromUrlString } from '@crowdcube/helpers/tld';
import { useClipboard } from '@mantine/hooks';
import { SemperCookie, Variables } from '@semper/rust/lib/core/pkg-web';
import { PageTitle, Text, useCurrentUser } from '@semper/shared-components';

const redirectAway = (url: string) => {
  window.location.replace(url);

  return <></>;
};

export const Retool = () => {
  const { postgraphile_api: siteUrl, crowdcube_website } = Variables.load_core(
    import.meta.env.VITE_MODE,
    import.meta.env.VITE_BRANCH,
    extractTldFromUrlString(window.location.origin),
  );

  // use graphql to set the auth token
  const { isAdmin, shortName } = useCurrentUser();
  const semperAuthJwt = SemperCookie.get_jwt();

  const [clipboardUrl, clipboardAuthToken] = [
    useClipboard({ timeout: 500 }),
    useClipboard({ timeout: 500 }),
  ];

  if (!semperAuthJwt || !isAdmin) {
    return redirectAway(crowdcube_website);
  }

  return (
    <>
      <PageTitle title="Retool" />
      <Text
        variant="heading"
        sx={{
          mt: 3,
          mb: 6,
          display: 'block',
          textAlign: 'center',
        }}
      >
        Hello {shortName},
      </Text>
      <Text
        variant="title"
        sx={{
          mt: 3,
          mb: 6,
          display: 'block',
          textAlign: 'center',
        }}
      >
        Your Site Url is
      </Text>
      <div onClick={() => clipboardUrl.copy(siteUrl)}>
        <Text
          variant="title"
          sx={{
            mt: 3,
            mb: 6,
            display: 'block',
            textAlign: 'center',
            color: clipboardUrl.copied ? 'accent' : 'primary',
          }}
        >
          {clipboardUrl.copied ? 'Copied!' : siteUrl}
        </Text>
      </div>
      <Text
        variant="title"
        sx={{
          mt: 3,
          mb: 6,
          display: 'block',
          textAlign: 'center',
        }}
      >
        Your auth token is
      </Text>
      <div onClick={() => clipboardAuthToken.copy(semperAuthJwt)}>
        <Text
          variant="title"
          sx={{
            mt: 3,
            mb: 6,
            display: 'block',
            textAlign: 'center',
            color: clipboardAuthToken.copied ? 'accent' : 'primary',
          }}
        >
          {clipboardAuthToken.copied ? 'Copied!' : semperAuthJwt}
        </Text>
      </div>
    </>
  );
};
