import { SemperCookie } from '@semper/rust/lib/core/pkg-web';
import { createContext, ReactNode, useContext, useState } from 'react';

export const AuthContext = createContext<{
  loggedIn: boolean;
  email: string;
  setLoggedIn: (loggedIn: boolean) => void;
  setEmail: (email: string) => void;
}>({
  loggedIn: false,
  email: '',
  setLoggedIn: () => null,
  setEmail: () => null,
});

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [loggedIn, setLoggedIn] = useState(!!SemperCookie.load());
  const [email, setEmail] = useState(SemperCookie.load()?.email || '');

  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        setLoggedIn,
        email,
        setEmail,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
