/* eslint-disable  @typescript-eslint/no-explicit-any */

import { CircularProgress } from '@mui/material';
import { Box, Button, Text } from '@semper/shared-components';
import { parse, subYears } from 'date-fns';
import { Form as FormikForm, Formik } from 'formik';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { IsoCountry } from '../../generated/graphql';
import { FormSummary } from './FormSummary';

export type UserDetails = {
  teamId: string;
  fullName: string;
  companyName: string;
  companyTeamId: string;
  taxResidence?: IsoCountry;
  transactionGroup: string;
};

export const RequiredString = z.string({
  required_error: 'Please complete this field',
});
export const ValidDate = z.string().transform((val, ctx) => {
  const dateSchema = z
    .date()
    .min(subYears(new Date(), 130), 'That doesn’t look right...')
    .max(new Date(), 'That’s in the future!');
  try {
    const d = parse(val, 'yyyy-mm-dd', new Date());
    const res = dateSchema.safeParse(d);
    if (!res.success) {
      ctx.addIssue(res.error.issues[0]);
    }
    if (!isFinite(d.getTime())) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'That doesn’t look right...',
      });
    }
  } catch (e) {
    return z.NEVER;
  }
  return val;
});

export const getFormUrl = (form: any) => {
  return form.title.toLowerCase().replace(/ /g, '-');
};

type RadioOption = {
  label: string;
  value: string;
};

export type FormField = {
  label: string;
  hide?: (formData: any) => boolean;
  explanation?: string;
  placeholder?: string;
  type: any;
  validation?: any;
  options?: RadioOption[];
  unit?: string;
  inputLength?: number;
  default?: string | number;
};

export type FormDefinition = {
  title: string;
  description: string;
  content?: React.ReactNode;
  validation?: any;
  onSubmit?: (formData: any) => Promise<any>;
  fields?: {
    [key: string]: FormField;
  };
};

const FormControls = ({
  onSubmit,
  onBack,
  showBack,
  submitDisabled,
}: {
  onSubmit: () => void;
  onBack: () => void;
  showBack: boolean;
  submitDisabled: boolean;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        gap: 2,
      }}
    >
      {showBack && (
        <Button
          variant="secondary"
          sx={{ width: 'fit-content' }}
          onClick={() => onBack()}
          type="button"
        >
          Back
        </Button>
      )}
      <Button
        iconName="arrow"
        onClick={() => {
          onSubmit();
        }}
        disabled={submitDisabled}
        type="button"
      >
        Continue
      </Button>
    </Box>
  );
};

const generateValidation = (form: FormDefinition) => {
  if (form.validation) {
    return toFormikValidationSchema(form.validation);
  }
  return toFormikValidationSchema(
    z.object(
      Object.fromEntries(
        Object.entries(form.fields || {}).map(([key, value]) => {
          return [key, value.validation];
        }),
      ),
    ),
  );
};

export const Form = ({
  forms,
  returnHref,
  formData,
}: {
  forms: FormDefinition[];
  returnHref: string;
  formData: any;
}) => {
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams.entries());

  let page = getFormUrl(forms[0]);
  if (params.formPage) {
    page = params.formPage;
  }
  const returnToSummary = params.returnToSummary !== undefined;

  useEffect(() => {
    document.body.scrollIntoView({ behavior: 'auto' });
  }, [page]);

  if (page === 'summary') {
    return (
      <FormSummary forms={forms} returnHref={returnHref} formData={formData} />
    );
  }

  const index = forms.findIndex(x => getFormUrl(x) === page) || 0;
  const position = index < 0 ? 0 : index;
  const stepsRemaining = position < forms.length - 1;

  const back = () => {
    const previousFormUrl = getFormUrl(forms[position - 1]);
    setSearchParams({
      ...params,
      formPage: previousFormUrl,
    });
  };

  const advance = () => {
    if (stepsRemaining && !returnToSummary) {
      setSearchParams({
        ...params,
        formPage: getFormUrl(forms[position + 1]),
      });
    } else {
      setSearchParams({
        ...params,
        formPage: 'summary',
      });
    }
  };

  const currentForm = forms[position];

  return (
    <Box
      sx={{
        minHeight: ['100vh', 'initial'],
        padding: 6,
        width: '100%',
        maxWidth: '100ch',
      }}
    >
      <Text variant="heading" sx={{ mb: 1, color: 'muted' }}>
        Step {position + 1} of {forms.length}
      </Text>
      <Text variant="title" sx={{ mb: 1, color: 'text' }}>
        {currentForm.title}
      </Text>
      <Text
        variant="subheading"
        sx={{
          mb: 6,
        }}
      >
        <ReactMarkdown>{currentForm.description}</ReactMarkdown>
      </Text>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {currentForm.content}
          {currentForm.fields ? (
            <Formik
              key={currentForm.title}
              enableReinitialize
              initialValues={Object.fromEntries(
                Object.entries(currentForm.fields).map(([key]) => [
                  key,
                  formData[currentForm.title]?.[key] ??
                    currentForm.fields?.[key].default ??
                    '',
                ]),
              )}
              validationSchema={generateValidation(currentForm)}
              onSubmit={async data => {
                setLoading(true);
                await currentForm.onSubmit?.(data);
                setLoading(false);
                advance();
              }}
            >
              {({ submitForm, errors, values, submitCount }) => {
                return (
                  <FormikForm key={currentForm.title}>
                    <Box sx={{ mb: 6, display: 'flex', gap: 6 }}>
                      {Object.entries(currentForm.fields || {})
                        .filter(([_, v]) => !(v.hide && v.hide(values ?? {})))
                        .map(
                          (
                            [key, { type: FormElementType, ...formElement }],
                            i,
                          ) => (
                            <FormElementType
                              name={key}
                              key={`${i}${key}`}
                              {...formElement}
                              tabIndex={i}
                            />
                          ),
                        )}
                    </Box>
                    <FormControls
                      onSubmit={submitForm}
                      onBack={back}
                      showBack={!returnToSummary && position !== 0}
                      submitDisabled={Boolean(
                        submitCount > 0 && !isEmpty(errors),
                      )}
                    />
                  </FormikForm>
                );
              }}
            </Formik>
          ) : (
            <FormControls
              onSubmit={advance}
              onBack={back}
              showBack={!returnToSummary && position !== 0}
              submitDisabled={false}
            />
          )}
        </>
      )}
    </Box>
  );
};
