import { Box } from '@mui/material';
import { CenteredSpinner } from '../graphics/CenteredSpinner';
import { Layout } from './Layout';

export const LoadingLayout = () => (
  <Layout>
    <Box width="100%" height="80vh">
      <CenteredSpinner />
    </Box>
  </Layout>
);

export const LoadingLayoutPage = () => (
  <Box sx={{ width: '100vw', height: '100vh' }}>
    <CenteredSpinner />
  </Box>
);
