import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A floating point number that requires more precision than IEEE 754 binary 64 */
  BigFloat: number;
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: string;
  /** The day, does not include a time. */
  Date: Date;
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: Date;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: string;
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: string;
};

/** A connection to a list of `AmountExercisableRecord` values. */
export type AmountExercisableConnection = {
  __typename?: 'AmountExercisableConnection';
  /** A list of edges which contains the `AmountExercisableRecord` and cursor to aid in pagination. */
  edges: Array<AmountExercisableEdge>;
  /** A list of `AmountExercisableRecord` objects. */
  nodes: Array<AmountExercisableRecord>;
  /** The count of *all* `AmountExercisableRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `AmountExercisableRecord` edge in the connection. */
export type AmountExercisableEdge = {
  __typename?: 'AmountExercisableEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AmountExercisableRecord` at the end of the edge. */
  node: AmountExercisableRecord;
};

/** The return type of our `amountExercisable` query. */
export type AmountExercisableRecord = {
  __typename?: 'AmountExercisableRecord';
  amount?: Maybe<Scalars['Int']>;
  equityScheme?: Maybe<Scalars['String']>;
};

/** A filter to be used against `AmountExercisableRecord` object types. All fields are combined with a logical ‘and.’ */
export type AmountExercisableRecordFilter = {
  /** Filter by the object’s `amount` field. */
  amount?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AmountExercisableRecordFilter>>;
  /** Filter by the object’s `equityScheme` field. */
  equityScheme?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AmountExercisableRecordFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AmountExercisableRecordFilter>>;
};

/** A connection to a list of `AmountExerciseableRecord` values. */
export type AmountExerciseableConnection = {
  __typename?: 'AmountExerciseableConnection';
  /** A list of edges which contains the `AmountExerciseableRecord` and cursor to aid in pagination. */
  edges: Array<AmountExerciseableEdge>;
  /** A list of `AmountExerciseableRecord` objects. */
  nodes: Array<AmountExerciseableRecord>;
  /** The count of *all* `AmountExerciseableRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `AmountExerciseableRecord` edge in the connection. */
export type AmountExerciseableEdge = {
  __typename?: 'AmountExerciseableEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AmountExerciseableRecord` at the end of the edge. */
  node: AmountExerciseableRecord;
};

/** The return type of our `amountExerciseable` query. */
export type AmountExerciseableRecord = {
  __typename?: 'AmountExerciseableRecord';
  amount?: Maybe<Scalars['Int']>;
  equityScheme?: Maybe<Scalars['String']>;
};

/** A filter to be used against `AmountExerciseableRecord` object types. All fields are combined with a logical ‘and.’ */
export type AmountExerciseableRecordFilter = {
  /** Filter by the object’s `amount` field. */
  amount?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AmountExerciseableRecordFilter>>;
  /** Filter by the object’s `equityScheme` field. */
  equityScheme?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AmountExerciseableRecordFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AmountExerciseableRecordFilter>>;
};

/** A connection to a list of `AmountOwnedRecord` values. */
export type AmountOwnedConnection = {
  __typename?: 'AmountOwnedConnection';
  /** A list of edges which contains the `AmountOwnedRecord` and cursor to aid in pagination. */
  edges: Array<AmountOwnedEdge>;
  /** A list of `AmountOwnedRecord` objects. */
  nodes: Array<AmountOwnedRecord>;
  /** The count of *all* `AmountOwnedRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `AmountOwnedRecord` edge in the connection. */
export type AmountOwnedEdge = {
  __typename?: 'AmountOwnedEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AmountOwnedRecord` at the end of the edge. */
  node: AmountOwnedRecord;
};

/** The return type of our `amountOwned` query. */
export type AmountOwnedRecord = {
  __typename?: 'AmountOwnedRecord';
  amount?: Maybe<Scalars['Int']>;
  equityScheme?: Maybe<Scalars['String']>;
};

/** A filter to be used against `AmountOwnedRecord` object types. All fields are combined with a logical ‘and.’ */
export type AmountOwnedRecordFilter = {
  /** Filter by the object’s `amount` field. */
  amount?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AmountOwnedRecordFilter>>;
  /** Filter by the object’s `equityScheme` field. */
  equityScheme?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AmountOwnedRecordFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AmountOwnedRecordFilter>>;
};

/** A connection to a list of `AmountSoldByOriginalEquityRecord` values. */
export type AmountSoldByOriginalEquityConnection = {
  __typename?: 'AmountSoldByOriginalEquityConnection';
  /** A list of edges which contains the `AmountSoldByOriginalEquityRecord` and cursor to aid in pagination. */
  edges: Array<AmountSoldByOriginalEquityEdge>;
  /** A list of `AmountSoldByOriginalEquityRecord` objects. */
  nodes: Array<AmountSoldByOriginalEquityRecord>;
  /** The count of *all* `AmountSoldByOriginalEquityRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `AmountSoldByOriginalEquityRecord` edge in the connection. */
export type AmountSoldByOriginalEquityEdge = {
  __typename?: 'AmountSoldByOriginalEquityEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AmountSoldByOriginalEquityRecord` at the end of the edge. */
  node: AmountSoldByOriginalEquityRecord;
};

/** The return type of our `amountSoldByOriginalEquity` query. */
export type AmountSoldByOriginalEquityRecord = {
  __typename?: 'AmountSoldByOriginalEquityRecord';
  amount?: Maybe<Scalars['Int']>;
  equityScheme?: Maybe<Scalars['String']>;
};

/** A filter to be used against `AmountSoldByOriginalEquityRecord` object types. All fields are combined with a logical ‘and.’ */
export type AmountSoldByOriginalEquityRecordFilter = {
  /** Filter by the object’s `amount` field. */
  amount?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AmountSoldByOriginalEquityRecordFilter>>;
  /** Filter by the object’s `equityScheme` field. */
  equityScheme?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AmountSoldByOriginalEquityRecordFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AmountSoldByOriginalEquityRecordFilter>>;
};

/** A connection to a list of `AmountSoldRecord` values. */
export type AmountSoldConnection = {
  __typename?: 'AmountSoldConnection';
  /** A list of edges which contains the `AmountSoldRecord` and cursor to aid in pagination. */
  edges: Array<AmountSoldEdge>;
  /** A list of `AmountSoldRecord` objects. */
  nodes: Array<AmountSoldRecord>;
  /** The count of *all* `AmountSoldRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `AmountSoldRecord` edge in the connection. */
export type AmountSoldEdge = {
  __typename?: 'AmountSoldEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AmountSoldRecord` at the end of the edge. */
  node: AmountSoldRecord;
};

/** The return type of our `amountSold` query. */
export type AmountSoldRecord = {
  __typename?: 'AmountSoldRecord';
  amount?: Maybe<Scalars['Int']>;
  equityScheme?: Maybe<Scalars['String']>;
};

/** A filter to be used against `AmountSoldRecord` object types. All fields are combined with a logical ‘and.’ */
export type AmountSoldRecordFilter = {
  /** Filter by the object’s `amount` field. */
  amount?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AmountSoldRecordFilter>>;
  /** Filter by the object’s `equityScheme` field. */
  equityScheme?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AmountSoldRecordFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AmountSoldRecordFilter>>;
};

/** A connection to a list of `AmountThatWillBeExercisableInTheFutureRecord` values. */
export type AmountThatWillBeExercisableInTheFutureConnection = {
  __typename?: 'AmountThatWillBeExercisableInTheFutureConnection';
  /** A list of edges which contains the `AmountThatWillBeExercisableInTheFutureRecord` and cursor to aid in pagination. */
  edges: Array<AmountThatWillBeExercisableInTheFutureEdge>;
  /** A list of `AmountThatWillBeExercisableInTheFutureRecord` objects. */
  nodes: Array<AmountThatWillBeExercisableInTheFutureRecord>;
  /** The count of *all* `AmountThatWillBeExercisableInTheFutureRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `AmountThatWillBeExercisableInTheFutureRecord` edge in the connection. */
export type AmountThatWillBeExercisableInTheFutureEdge = {
  __typename?: 'AmountThatWillBeExercisableInTheFutureEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AmountThatWillBeExercisableInTheFutureRecord` at the end of the edge. */
  node: AmountThatWillBeExercisableInTheFutureRecord;
};

/** The return type of our `amountThatWillBeExercisableInTheFuture` query. */
export type AmountThatWillBeExercisableInTheFutureRecord = {
  __typename?: 'AmountThatWillBeExercisableInTheFutureRecord';
  amount?: Maybe<Scalars['Int']>;
  equityScheme?: Maybe<Scalars['String']>;
};

/** A filter to be used against `AmountThatWillBeExercisableInTheFutureRecord` object types. All fields are combined with a logical ‘and.’ */
export type AmountThatWillBeExercisableInTheFutureRecordFilter = {
  /** Filter by the object’s `amount` field. */
  amount?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AmountThatWillBeExercisableInTheFutureRecordFilter>>;
  /** Filter by the object’s `equityScheme` field. */
  equityScheme?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AmountThatWillBeExercisableInTheFutureRecordFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AmountThatWillBeExercisableInTheFutureRecordFilter>>;
};

/** A connection to a list of `AmountThatWillBeExerciseableInTheFutureRecord` values. */
export type AmountThatWillBeExerciseableInTheFutureConnection = {
  __typename?: 'AmountThatWillBeExerciseableInTheFutureConnection';
  /** A list of edges which contains the `AmountThatWillBeExerciseableInTheFutureRecord` and cursor to aid in pagination. */
  edges: Array<AmountThatWillBeExerciseableInTheFutureEdge>;
  /** A list of `AmountThatWillBeExerciseableInTheFutureRecord` objects. */
  nodes: Array<AmountThatWillBeExerciseableInTheFutureRecord>;
  /** The count of *all* `AmountThatWillBeExerciseableInTheFutureRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `AmountThatWillBeExerciseableInTheFutureRecord` edge in the connection. */
export type AmountThatWillBeExerciseableInTheFutureEdge = {
  __typename?: 'AmountThatWillBeExerciseableInTheFutureEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AmountThatWillBeExerciseableInTheFutureRecord` at the end of the edge. */
  node: AmountThatWillBeExerciseableInTheFutureRecord;
};

/** The return type of our `amountThatWillBeExerciseableInTheFuture` query. */
export type AmountThatWillBeExerciseableInTheFutureRecord = {
  __typename?: 'AmountThatWillBeExerciseableInTheFutureRecord';
  amount?: Maybe<Scalars['Int']>;
  equityScheme?: Maybe<Scalars['String']>;
};

/** A filter to be used against `AmountThatWillBeExerciseableInTheFutureRecord` object types. All fields are combined with a logical ‘and.’ */
export type AmountThatWillBeExerciseableInTheFutureRecordFilter = {
  /** Filter by the object’s `amount` field. */
  amount?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AmountThatWillBeExerciseableInTheFutureRecordFilter>>;
  /** Filter by the object’s `equityScheme` field. */
  equityScheme?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AmountThatWillBeExerciseableInTheFutureRecordFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AmountThatWillBeExerciseableInTheFutureRecordFilter>>;
};

/** All input for the `approveTransaction` mutation. */
export type ApproveTransactionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  transactionId?: InputMaybe<Scalars['Int']>;
};

/** The output of our `approveTransaction` mutation. */
export type ApproveTransactionPayload = {
  __typename?: 'ApproveTransactionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** A filter to be used against BigFloat fields. All fields are combined with a logical ‘and.’ */
export type BigFloatFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['BigFloat']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['BigFloat']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['BigFloat']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['BigFloat']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['BigFloat']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['BigFloat']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['BigFloat']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['BigFloat']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['BigFloat']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['BigFloat']>>;
};

export type BlacklistedUsersForEvent = {
  __typename?: 'BlacklistedUsersForEvent';
  /** Reads a single `LiquidityEvent` that is related to this `BlacklistedUsersForEvent`. */
  liquidityEvent?: Maybe<LiquidityEvent>;
  liquidityEventId: Scalars['String'];
  /** Reads a single `Team` that is related to this `BlacklistedUsersForEvent`. */
  team?: Maybe<Team>;
  teamId: Scalars['String'];
};

/**
 * A condition to be used against `BlacklistedUsersForEvent` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type BlacklistedUsersForEventCondition = {
  /** Checks for equality with the object’s `liquidityEventId` field. */
  liquidityEventId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `teamId` field. */
  teamId?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `BlacklistedUsersForEvent` object types. All fields are combined with a logical ‘and.’ */
export type BlacklistedUsersForEventFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BlacklistedUsersForEventFilter>>;
  /** Filter by the object’s `liquidityEventId` field. */
  liquidityEventId?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BlacklistedUsersForEventFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BlacklistedUsersForEventFilter>>;
  /** Filter by the object’s `teamId` field. */
  teamId?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `BlacklistedUsersForEvent` */
export type BlacklistedUsersForEventInput = {
  liquidityEventId: Scalars['String'];
  teamId: Scalars['String'];
};

/** A connection to a list of `BlacklistedUsersForEvent` values. */
export type BlacklistedUsersForEventsConnection = {
  __typename?: 'BlacklistedUsersForEventsConnection';
  /** A list of edges which contains the `BlacklistedUsersForEvent` and cursor to aid in pagination. */
  edges: Array<BlacklistedUsersForEventsEdge>;
  /** A list of `BlacklistedUsersForEvent` objects. */
  nodes: Array<BlacklistedUsersForEvent>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BlacklistedUsersForEvent` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `BlacklistedUsersForEvent` edge in the connection. */
export type BlacklistedUsersForEventsEdge = {
  __typename?: 'BlacklistedUsersForEventsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `BlacklistedUsersForEvent` at the end of the edge. */
  node: BlacklistedUsersForEvent;
};

/** Methods to use when ordering `BlacklistedUsersForEvent`. */
export enum BlacklistedUsersForEventsOrderBy {
  LiquidityEventIdAsc = 'LIQUIDITY_EVENT_ID_ASC',
  LiquidityEventIdDesc = 'LIQUIDITY_EVENT_ID_DESC',
  Natural = 'NATURAL',
  TeamIdAsc = 'TEAM_ID_ASC',
  TeamIdDesc = 'TEAM_ID_DESC'
}

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Boolean']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Boolean']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Boolean']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Boolean']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Boolean']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type BuyerOffer = Node & {
  __typename?: 'BuyerOffer';
  approvalProcess?: Maybe<Scalars['String']>;
  lastViewed: Scalars['Datetime'];
  liquidityEvent: Scalars['String'];
  /** Reads a single `LiquidityEvent` that is related to this `BuyerOffer`. */
  liquidityEventByLiquidityEvent?: Maybe<LiquidityEvent>;
  managementQuestions?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  orderCreated?: Maybe<Scalars['Datetime']>;
  signedNda?: Maybe<Scalars['Boolean']>;
  stage2Access?: Maybe<Scalars['Boolean']>;
  /** Reads a single `Team` that is related to this `BuyerOffer`. */
  team?: Maybe<Team>;
  teamId: Scalars['String'];
  timesViewed: Scalars['Int'];
  valuation?: Maybe<Scalars['String']>;
  volume?: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `BuyerOffer` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type BuyerOfferCondition = {
  /** Checks for equality with the object’s `approvalProcess` field. */
  approvalProcess?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `lastViewed` field. */
  lastViewed?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `liquidityEvent` field. */
  liquidityEvent?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `managementQuestions` field. */
  managementQuestions?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `orderCreated` field. */
  orderCreated?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `teamId` field. */
  teamId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `timesViewed` field. */
  timesViewed?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `valuation` field. */
  valuation?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `volume` field. */
  volume?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `BuyerOffer` object types. All fields are combined with a logical ‘and.’ */
export type BuyerOfferFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BuyerOfferFilter>>;
  /** Filter by the object’s `approvalProcess` field. */
  approvalProcess?: InputMaybe<StringFilter>;
  /** Filter by the object’s `lastViewed` field. */
  lastViewed?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `liquidityEvent` field. */
  liquidityEvent?: InputMaybe<StringFilter>;
  /** Filter by the object’s `managementQuestions` field. */
  managementQuestions?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BuyerOfferFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BuyerOfferFilter>>;
  /** Filter by the object’s `orderCreated` field. */
  orderCreated?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `signedNda` field. */
  signedNda?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `stage2Access` field. */
  stage2Access?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `teamId` field. */
  teamId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timesViewed` field. */
  timesViewed?: InputMaybe<IntFilter>;
  /** Filter by the object’s `valuation` field. */
  valuation?: InputMaybe<StringFilter>;
  /** Filter by the object’s `volume` field. */
  volume?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `BuyerOffer` */
export type BuyerOfferInput = {
  approvalProcess?: InputMaybe<Scalars['String']>;
  lastViewed?: InputMaybe<Scalars['Datetime']>;
  liquidityEvent: Scalars['String'];
  managementQuestions?: InputMaybe<Scalars['String']>;
  orderCreated?: InputMaybe<Scalars['Datetime']>;
  teamId: Scalars['String'];
  timesViewed?: InputMaybe<Scalars['Int']>;
  valuation?: InputMaybe<Scalars['String']>;
  volume?: InputMaybe<Scalars['String']>;
};

/** Represents an update to a `BuyerOffer`. Fields that are set will be updated. */
export type BuyerOfferPatch = {
  approvalProcess?: InputMaybe<Scalars['String']>;
  lastViewed?: InputMaybe<Scalars['Datetime']>;
  liquidityEvent?: InputMaybe<Scalars['String']>;
  managementQuestions?: InputMaybe<Scalars['String']>;
  orderCreated?: InputMaybe<Scalars['Datetime']>;
  teamId?: InputMaybe<Scalars['String']>;
  timesViewed?: InputMaybe<Scalars['Int']>;
  valuation?: InputMaybe<Scalars['String']>;
  volume?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `BuyerOffer` values. */
export type BuyerOffersConnection = {
  __typename?: 'BuyerOffersConnection';
  /** A list of edges which contains the `BuyerOffer` and cursor to aid in pagination. */
  edges: Array<BuyerOffersEdge>;
  /** A list of `BuyerOffer` objects. */
  nodes: Array<BuyerOffer>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BuyerOffer` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `BuyerOffer` edge in the connection. */
export type BuyerOffersEdge = {
  __typename?: 'BuyerOffersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `BuyerOffer` at the end of the edge. */
  node: BuyerOffer;
};

/** Methods to use when ordering `BuyerOffer`. */
export enum BuyerOffersOrderBy {
  ApprovalProcessAsc = 'APPROVAL_PROCESS_ASC',
  ApprovalProcessDesc = 'APPROVAL_PROCESS_DESC',
  LastViewedAsc = 'LAST_VIEWED_ASC',
  LastViewedDesc = 'LAST_VIEWED_DESC',
  LiquidityEventAsc = 'LIQUIDITY_EVENT_ASC',
  LiquidityEventDesc = 'LIQUIDITY_EVENT_DESC',
  ManagementQuestionsAsc = 'MANAGEMENT_QUESTIONS_ASC',
  ManagementQuestionsDesc = 'MANAGEMENT_QUESTIONS_DESC',
  Natural = 'NATURAL',
  OrderCreatedAsc = 'ORDER_CREATED_ASC',
  OrderCreatedDesc = 'ORDER_CREATED_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TeamIdAsc = 'TEAM_ID_ASC',
  TeamIdDesc = 'TEAM_ID_DESC',
  TimesViewedAsc = 'TIMES_VIEWED_ASC',
  TimesViewedDesc = 'TIMES_VIEWED_DESC',
  ValuationAsc = 'VALUATION_ASC',
  ValuationDesc = 'VALUATION_DESC',
  VolumeAsc = 'VOLUME_ASC',
  VolumeDesc = 'VOLUME_DESC'
}

export type BuyerOnboarding = Node & {
  __typename?: 'BuyerOnboarding';
  address: Scalars['String'];
  businessOccupation?: Maybe<Scalars['String']>;
  buyerOnboardingCompleted?: Maybe<Scalars['Boolean']>;
  companyType?: Maybe<Companytype>;
  countryOfRegistration?: Maybe<Scalars['String']>;
  criteria?: Maybe<Array<Maybe<Scalars['String']>>>;
  detrimentalLoss?: Maybe<Scalars['String']>;
  experience?: Maybe<Scalars['String']>;
  hoursChaseIn: Scalars['Int'];
  isCompany?: Maybe<Scalars['Boolean']>;
  knowledge?: Maybe<Scalars['String']>;
  lastChased: Scalars['Datetime'];
  lastUpdated?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  nationality?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  phone?: Maybe<Scalars['String']>;
  registrationNumber?: Maybe<Scalars['String']>;
  selfClassified?: Maybe<Scalars['Boolean']>;
  /** Reads a single `Team` that is related to this `BuyerOnboarding`. */
  team?: Maybe<Team>;
  teamId: Scalars['String'];
  timesChased: Scalars['Int'];
  understandRisk?: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `BuyerOnboarding` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type BuyerOnboardingCondition = {
  /** Checks for equality with the object’s `address` field. */
  address?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `businessOccupation` field. */
  businessOccupation?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `buyerOnboardingCompleted` field. */
  buyerOnboardingCompleted?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `companyType` field. */
  companyType?: InputMaybe<Companytype>;
  /** Checks for equality with the object’s `countryOfRegistration` field. */
  countryOfRegistration?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `criteria` field. */
  criteria?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `detrimentalLoss` field. */
  detrimentalLoss?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `experience` field. */
  experience?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `hoursChaseIn` field. */
  hoursChaseIn?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isCompany` field. */
  isCompany?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `knowledge` field. */
  knowledge?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `lastChased` field. */
  lastChased?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `lastUpdated` field. */
  lastUpdated?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `nationality` field. */
  nationality?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `phone` field. */
  phone?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `registrationNumber` field. */
  registrationNumber?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `selfClassified` field. */
  selfClassified?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `teamId` field. */
  teamId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `timesChased` field. */
  timesChased?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `understandRisk` field. */
  understandRisk?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `BuyerOnboarding` object types. All fields are combined with a logical ‘and.’ */
export type BuyerOnboardingFilter = {
  /** Filter by the object’s `address` field. */
  address?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BuyerOnboardingFilter>>;
  /** Filter by the object’s `businessOccupation` field. */
  businessOccupation?: InputMaybe<StringFilter>;
  /** Filter by the object’s `buyerOnboardingCompleted` field. */
  buyerOnboardingCompleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `companyType` field. */
  companyType?: InputMaybe<CompanytypeFilter>;
  /** Filter by the object’s `countryOfRegistration` field. */
  countryOfRegistration?: InputMaybe<StringFilter>;
  /** Filter by the object’s `criteria` field. */
  criteria?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `detrimentalLoss` field. */
  detrimentalLoss?: InputMaybe<StringFilter>;
  /** Filter by the object’s `experience` field. */
  experience?: InputMaybe<StringFilter>;
  /** Filter by the object’s `hoursChaseIn` field. */
  hoursChaseIn?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isCompany` field. */
  isCompany?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `knowledge` field. */
  knowledge?: InputMaybe<StringFilter>;
  /** Filter by the object’s `lastChased` field. */
  lastChased?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lastUpdated` field. */
  lastUpdated?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Filter by the object’s `nationality` field. */
  nationality?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BuyerOnboardingFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BuyerOnboardingFilter>>;
  /** Filter by the object’s `phone` field. */
  phone?: InputMaybe<StringFilter>;
  /** Filter by the object’s `registrationNumber` field. */
  registrationNumber?: InputMaybe<StringFilter>;
  /** Filter by the object’s `selfClassified` field. */
  selfClassified?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `teamId` field. */
  teamId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timesChased` field. */
  timesChased?: InputMaybe<IntFilter>;
  /** Filter by the object’s `understandRisk` field. */
  understandRisk?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `BuyerOnboarding` */
export type BuyerOnboardingInput = {
  address: Scalars['String'];
  businessOccupation?: InputMaybe<Scalars['String']>;
  buyerOnboardingCompleted?: InputMaybe<Scalars['Boolean']>;
  companyType?: InputMaybe<Companytype>;
  countryOfRegistration?: InputMaybe<Scalars['String']>;
  criteria?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  detrimentalLoss?: InputMaybe<Scalars['String']>;
  experience?: InputMaybe<Scalars['String']>;
  hoursChaseIn?: InputMaybe<Scalars['Int']>;
  isCompany?: InputMaybe<Scalars['Boolean']>;
  knowledge?: InputMaybe<Scalars['String']>;
  lastChased?: InputMaybe<Scalars['Datetime']>;
  lastUpdated?: InputMaybe<Scalars['Datetime']>;
  name: Scalars['String'];
  nationality?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  registrationNumber?: InputMaybe<Scalars['String']>;
  selfClassified?: InputMaybe<Scalars['Boolean']>;
  teamId: Scalars['String'];
  timesChased?: InputMaybe<Scalars['Int']>;
  understandRisk?: InputMaybe<Scalars['String']>;
};

/** Represents an update to a `BuyerOnboarding`. Fields that are set will be updated. */
export type BuyerOnboardingPatch = {
  address?: InputMaybe<Scalars['String']>;
  businessOccupation?: InputMaybe<Scalars['String']>;
  buyerOnboardingCompleted?: InputMaybe<Scalars['Boolean']>;
  companyType?: InputMaybe<Companytype>;
  countryOfRegistration?: InputMaybe<Scalars['String']>;
  criteria?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  detrimentalLoss?: InputMaybe<Scalars['String']>;
  experience?: InputMaybe<Scalars['String']>;
  hoursChaseIn?: InputMaybe<Scalars['Int']>;
  isCompany?: InputMaybe<Scalars['Boolean']>;
  knowledge?: InputMaybe<Scalars['String']>;
  lastChased?: InputMaybe<Scalars['Datetime']>;
  lastUpdated?: InputMaybe<Scalars['Datetime']>;
  name?: InputMaybe<Scalars['String']>;
  nationality?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  registrationNumber?: InputMaybe<Scalars['String']>;
  selfClassified?: InputMaybe<Scalars['Boolean']>;
  teamId?: InputMaybe<Scalars['String']>;
  timesChased?: InputMaybe<Scalars['Int']>;
  understandRisk?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `BuyerOnboarding` values. */
export type BuyerOnboardingsConnection = {
  __typename?: 'BuyerOnboardingsConnection';
  /** A list of edges which contains the `BuyerOnboarding` and cursor to aid in pagination. */
  edges: Array<BuyerOnboardingsEdge>;
  /** A list of `BuyerOnboarding` objects. */
  nodes: Array<BuyerOnboarding>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BuyerOnboarding` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `BuyerOnboarding` edge in the connection. */
export type BuyerOnboardingsEdge = {
  __typename?: 'BuyerOnboardingsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `BuyerOnboarding` at the end of the edge. */
  node: BuyerOnboarding;
};

/** Methods to use when ordering `BuyerOnboarding`. */
export enum BuyerOnboardingsOrderBy {
  AddressAsc = 'ADDRESS_ASC',
  AddressDesc = 'ADDRESS_DESC',
  BusinessOccupationAsc = 'BUSINESS_OCCUPATION_ASC',
  BusinessOccupationDesc = 'BUSINESS_OCCUPATION_DESC',
  BuyerOnboardingCompletedAsc = 'BUYER_ONBOARDING_COMPLETED_ASC',
  BuyerOnboardingCompletedDesc = 'BUYER_ONBOARDING_COMPLETED_DESC',
  CompanyTypeAsc = 'COMPANY_TYPE_ASC',
  CompanyTypeDesc = 'COMPANY_TYPE_DESC',
  CountryOfRegistrationAsc = 'COUNTRY_OF_REGISTRATION_ASC',
  CountryOfRegistrationDesc = 'COUNTRY_OF_REGISTRATION_DESC',
  CriteriaAsc = 'CRITERIA_ASC',
  CriteriaDesc = 'CRITERIA_DESC',
  DetrimentalLossAsc = 'DETRIMENTAL_LOSS_ASC',
  DetrimentalLossDesc = 'DETRIMENTAL_LOSS_DESC',
  ExperienceAsc = 'EXPERIENCE_ASC',
  ExperienceDesc = 'EXPERIENCE_DESC',
  HoursChaseInAsc = 'HOURS_CHASE_IN_ASC',
  HoursChaseInDesc = 'HOURS_CHASE_IN_DESC',
  IsCompanyAsc = 'IS_COMPANY_ASC',
  IsCompanyDesc = 'IS_COMPANY_DESC',
  KnowledgeAsc = 'KNOWLEDGE_ASC',
  KnowledgeDesc = 'KNOWLEDGE_DESC',
  LastChasedAsc = 'LAST_CHASED_ASC',
  LastChasedDesc = 'LAST_CHASED_DESC',
  LastUpdatedAsc = 'LAST_UPDATED_ASC',
  LastUpdatedDesc = 'LAST_UPDATED_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  NationalityAsc = 'NATIONALITY_ASC',
  NationalityDesc = 'NATIONALITY_DESC',
  Natural = 'NATURAL',
  PhoneAsc = 'PHONE_ASC',
  PhoneDesc = 'PHONE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RegistrationNumberAsc = 'REGISTRATION_NUMBER_ASC',
  RegistrationNumberDesc = 'REGISTRATION_NUMBER_DESC',
  SelfClassifiedAsc = 'SELF_CLASSIFIED_ASC',
  SelfClassifiedDesc = 'SELF_CLASSIFIED_DESC',
  TeamIdAsc = 'TEAM_ID_ASC',
  TeamIdDesc = 'TEAM_ID_DESC',
  TimesChasedAsc = 'TIMES_CHASED_ASC',
  TimesChasedDesc = 'TIMES_CHASED_DESC',
  UnderstandRiskAsc = 'UNDERSTAND_RISK_ASC',
  UnderstandRiskDesc = 'UNDERSTAND_RISK_DESC'
}

export type BuyerPurchaseAgreement = {
  __typename?: 'BuyerPurchaseAgreement';
  /** Reads a single `Team` that is related to this `BuyerPurchaseAgreement`. */
  buyerTeam?: Maybe<Team>;
  buyerTeamId: Scalars['String'];
  /** Reads a single `LiquidityEvent` that is related to this `BuyerPurchaseAgreement`. */
  liquidityEvent?: Maybe<LiquidityEvent>;
  liquidityEventId: Scalars['String'];
  valuation: Currency;
  volume: Currency;
};

/**
 * A condition to be used against `BuyerPurchaseAgreement` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type BuyerPurchaseAgreementCondition = {
  /** Checks for equality with the object’s `buyerTeamId` field. */
  buyerTeamId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `liquidityEventId` field. */
  liquidityEventId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `valuation` field. */
  valuation?: InputMaybe<CurrencyInput>;
  /** Checks for equality with the object’s `volume` field. */
  volume?: InputMaybe<CurrencyInput>;
};

/** A filter to be used against `BuyerPurchaseAgreement` object types. All fields are combined with a logical ‘and.’ */
export type BuyerPurchaseAgreementFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BuyerPurchaseAgreementFilter>>;
  /** Filter by the object’s `buyerTeamId` field. */
  buyerTeamId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `liquidityEventId` field. */
  liquidityEventId?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BuyerPurchaseAgreementFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BuyerPurchaseAgreementFilter>>;
  /** Filter by the object’s `valuation` field. */
  valuation?: InputMaybe<CurrencyFilter>;
  /** Filter by the object’s `volume` field. */
  volume?: InputMaybe<CurrencyFilter>;
};

/** An input for mutations affecting `BuyerPurchaseAgreement` */
export type BuyerPurchaseAgreementInput = {
  buyerTeamId: Scalars['String'];
  liquidityEventId: Scalars['String'];
  valuation: CurrencyInput;
  volume: CurrencyInput;
};

/** A connection to a list of `BuyerPurchaseAgreement` values. */
export type BuyerPurchaseAgreementsConnection = {
  __typename?: 'BuyerPurchaseAgreementsConnection';
  /** A list of edges which contains the `BuyerPurchaseAgreement` and cursor to aid in pagination. */
  edges: Array<BuyerPurchaseAgreementsEdge>;
  /** A list of `BuyerPurchaseAgreement` objects. */
  nodes: Array<BuyerPurchaseAgreement>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BuyerPurchaseAgreement` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `BuyerPurchaseAgreement` edge in the connection. */
export type BuyerPurchaseAgreementsEdge = {
  __typename?: 'BuyerPurchaseAgreementsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `BuyerPurchaseAgreement` at the end of the edge. */
  node: BuyerPurchaseAgreement;
};

/** Methods to use when ordering `BuyerPurchaseAgreement`. */
export enum BuyerPurchaseAgreementsOrderBy {
  BuyerTeamIdAsc = 'BUYER_TEAM_ID_ASC',
  BuyerTeamIdDesc = 'BUYER_TEAM_ID_DESC',
  LiquidityEventIdAsc = 'LIQUIDITY_EVENT_ID_ASC',
  LiquidityEventIdDesc = 'LIQUIDITY_EVENT_ID_DESC',
  Natural = 'NATURAL',
  ValuationAsc = 'VALUATION_ASC',
  ValuationDesc = 'VALUATION_DESC',
  VolumeAsc = 'VOLUME_ASC',
  VolumeDesc = 'VOLUME_DESC'
}

/** All input for the `claimInvestorInvite` mutation. */
export type ClaimInvestorInviteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** The output of our `claimInvestorInvite` mutation. */
export type ClaimInvestorInvitePayload = {
  __typename?: 'ClaimInvestorInvitePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export enum Colour {
  Black = 'BLACK',
  Red = 'RED'
}

/** A filter to be used against Colour fields. All fields are combined with a logical ‘and.’ */
export type ColourFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Colour>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Colour>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Colour>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Colour>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Colour>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Colour>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Colour>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Colour>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Colour>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Colour>>;
};

export type CommentsAboutTeam = {
  __typename?: 'CommentsAboutTeam';
  comment: Scalars['String'];
  created?: Maybe<Scalars['Datetime']>;
  /** Reads a single `Team` that is related to this `CommentsAboutTeam`. */
  team?: Maybe<Team>;
  teamId: Scalars['String'];
  /** Reads a single `User` that is related to this `CommentsAboutTeam`. */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `CommentsAboutTeam` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CommentsAboutTeamCondition = {
  /** Checks for equality with the object’s `comment` field. */
  comment?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `created` field. */
  created?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `teamId` field. */
  teamId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `CommentsAboutTeam` object types. All fields are combined with a logical ‘and.’ */
export type CommentsAboutTeamFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CommentsAboutTeamFilter>>;
  /** Filter by the object’s `comment` field. */
  comment?: InputMaybe<StringFilter>;
  /** Filter by the object’s `created` field. */
  created?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CommentsAboutTeamFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CommentsAboutTeamFilter>>;
  /** Filter by the object’s `teamId` field. */
  teamId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `CommentsAboutTeam` */
export type CommentsAboutTeamInput = {
  comment: Scalars['String'];
  created?: InputMaybe<Scalars['Datetime']>;
  teamId: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `CommentsAboutTeam` values. */
export type CommentsAboutTeamsConnection = {
  __typename?: 'CommentsAboutTeamsConnection';
  /** A list of edges which contains the `CommentsAboutTeam` and cursor to aid in pagination. */
  edges: Array<CommentsAboutTeamsEdge>;
  /** A list of `CommentsAboutTeam` objects. */
  nodes: Array<CommentsAboutTeam>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CommentsAboutTeam` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `CommentsAboutTeam` edge in the connection. */
export type CommentsAboutTeamsEdge = {
  __typename?: 'CommentsAboutTeamsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CommentsAboutTeam` at the end of the edge. */
  node: CommentsAboutTeam;
};

/** Methods to use when ordering `CommentsAboutTeam`. */
export enum CommentsAboutTeamsOrderBy {
  CommentAsc = 'COMMENT_ASC',
  CommentDesc = 'COMMENT_DESC',
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  Natural = 'NATURAL',
  TeamIdAsc = 'TEAM_ID_ASC',
  TeamIdDesc = 'TEAM_ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** A connection to a list of `Company` values. */
export type CompaniesConnection = {
  __typename?: 'CompaniesConnection';
  /** A list of edges which contains the `Company` and cursor to aid in pagination. */
  edges: Array<CompaniesEdge>;
  /** A list of `Company` objects. */
  nodes: Array<Company>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Company` edge in the connection. */
export type CompaniesEdge = {
  __typename?: 'CompaniesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Company` at the end of the edge. */
  node: Company;
};

/** Methods to use when ordering `Company`. */
export enum CompaniesOrderBy {
  AddressAsc = 'ADDRESS_ASC',
  AddressDesc = 'ADDRESS_DESC',
  BannerImagePathAsc = 'BANNER_IMAGE_PATH_ASC',
  BannerImagePathDesc = 'BANNER_IMAGE_PATH_DESC',
  CardColourAsc = 'CARD_COLOUR_ASC',
  CardColourDesc = 'CARD_COLOUR_DESC',
  CountryOfIncorporationAsc = 'COUNTRY_OF_INCORPORATION_ASC',
  CountryOfIncorporationDesc = 'COUNTRY_OF_INCORPORATION_DESC',
  FoundedAsc = 'FOUNDED_ASC',
  FoundedDesc = 'FOUNDED_DESC',
  FullNameAsc = 'FULL_NAME_ASC',
  FullNameDesc = 'FULL_NAME_DESC',
  HrefAsc = 'HREF_ASC',
  HrefDesc = 'HREF_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IndustryAsc = 'INDUSTRY_ASC',
  IndustryDesc = 'INDUSTRY_DESC',
  LogoPathAsc = 'LOGO_PATH_ASC',
  LogoPathDesc = 'LOGO_PATH_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RegistrationNumberAsc = 'REGISTRATION_NUMBER_ASC',
  RegistrationNumberDesc = 'REGISTRATION_NUMBER_DESC',
  ShortNameAsc = 'SHORT_NAME_ASC',
  ShortNameDesc = 'SHORT_NAME_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC'
}

export type Company = Node & {
  __typename?: 'Company';
  address: Scalars['String'];
  bannerImagePath: Scalars['String'];
  cardColour: Scalars['String'];
  /** Reads and enables pagination through a set of `CompanyKeyPerson`. */
  companyKeyPeople: CompanyKeyPeopleConnection;
  countryOfIncorporation: Scalars['String'];
  founded: Scalars['String'];
  fullName: Scalars['String'];
  href: Scalars['String'];
  id: Scalars['String'];
  industry: Scalars['String'];
  /** Reads and enables pagination through a set of `LiquidityEvent`. */
  liquidityEvents: LiquidityEventsConnection;
  logoPath: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  registrationNumber: Scalars['String'];
  shortName: Scalars['String'];
  slug: Scalars['String'];
  /** Reads and enables pagination through a set of `TransactionGroup`. */
  transactionGroups: TransactionGroupsConnection;
  type: Scalars['String'];
};


export type CompanyCompanyKeyPeopleArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CompanyKeyPersonCondition>;
  filter?: InputMaybe<CompanyKeyPersonFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CompanyKeyPeopleOrderBy>>;
};


export type CompanyLiquidityEventsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LiquidityEventCondition>;
  filter?: InputMaybe<LiquidityEventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LiquidityEventsOrderBy>>;
};


export type CompanyTransactionGroupsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionGroupCondition>;
  filter?: InputMaybe<TransactionGroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionGroupsOrderBy>>;
};

/** A condition to be used against `Company` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type CompanyCondition = {
  /** Checks for equality with the object’s `address` field. */
  address?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `bannerImagePath` field. */
  bannerImagePath?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `cardColour` field. */
  cardColour?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `countryOfIncorporation` field. */
  countryOfIncorporation?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `founded` field. */
  founded?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `fullName` field. */
  fullName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `href` field. */
  href?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `industry` field. */
  industry?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `logoPath` field. */
  logoPath?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `registrationNumber` field. */
  registrationNumber?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `shortName` field. */
  shortName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `Company` object types. All fields are combined with a logical ‘and.’ */
export type CompanyFilter = {
  /** Filter by the object’s `address` field. */
  address?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CompanyFilter>>;
  /** Filter by the object’s `bannerImagePath` field. */
  bannerImagePath?: InputMaybe<StringFilter>;
  /** Filter by the object’s `cardColour` field. */
  cardColour?: InputMaybe<StringFilter>;
  /** Filter by the object’s `countryOfIncorporation` field. */
  countryOfIncorporation?: InputMaybe<StringFilter>;
  /** Filter by the object’s `founded` field. */
  founded?: InputMaybe<StringFilter>;
  /** Filter by the object’s `fullName` field. */
  fullName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `href` field. */
  href?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `industry` field. */
  industry?: InputMaybe<StringFilter>;
  /** Filter by the object’s `logoPath` field. */
  logoPath?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CompanyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CompanyFilter>>;
  /** Filter by the object’s `registrationNumber` field. */
  registrationNumber?: InputMaybe<StringFilter>;
  /** Filter by the object’s `shortName` field. */
  shortName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `slug` field. */
  slug?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `Company` */
export type CompanyInput = {
  address: Scalars['String'];
  bannerImagePath: Scalars['String'];
  cardColour: Scalars['String'];
  countryOfIncorporation: Scalars['String'];
  founded: Scalars['String'];
  fullName: Scalars['String'];
  href: Scalars['String'];
  id: Scalars['String'];
  industry: Scalars['String'];
  logoPath: Scalars['String'];
  registrationNumber: Scalars['String'];
  shortName: Scalars['String'];
  slug: Scalars['String'];
  type: Scalars['String'];
};

/** A connection to a list of `CompanyKeyPerson` values. */
export type CompanyKeyPeopleConnection = {
  __typename?: 'CompanyKeyPeopleConnection';
  /** A list of edges which contains the `CompanyKeyPerson` and cursor to aid in pagination. */
  edges: Array<CompanyKeyPeopleEdge>;
  /** A list of `CompanyKeyPerson` objects. */
  nodes: Array<CompanyKeyPerson>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyKeyPerson` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `CompanyKeyPerson` edge in the connection. */
export type CompanyKeyPeopleEdge = {
  __typename?: 'CompanyKeyPeopleEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompanyKeyPerson` at the end of the edge. */
  node: CompanyKeyPerson;
};

/** Methods to use when ordering `CompanyKeyPerson`. */
export enum CompanyKeyPeopleOrderBy {
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ImagePathAsc = 'IMAGE_PATH_ASC',
  ImagePathDesc = 'IMAGE_PATH_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC'
}

export type CompanyKeyPerson = Node & {
  __typename?: 'CompanyKeyPerson';
  /** Reads a single `Company` that is related to this `CompanyKeyPerson`. */
  company?: Maybe<Company>;
  companyId: Scalars['String'];
  id: Scalars['Int'];
  imagePath: Scalars['String'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  title: Scalars['String'];
};

/**
 * A condition to be used against `CompanyKeyPerson` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CompanyKeyPersonCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `imagePath` field. */
  imagePath?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `CompanyKeyPerson` object types. All fields are combined with a logical ‘and.’ */
export type CompanyKeyPersonFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CompanyKeyPersonFilter>>;
  /** Filter by the object’s `companyId` field. */
  companyId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `imagePath` field. */
  imagePath?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CompanyKeyPersonFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CompanyKeyPersonFilter>>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `CompanyKeyPerson` */
export type CompanyKeyPersonInput = {
  companyId: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  imagePath: Scalars['String'];
  name: Scalars['String'];
  title: Scalars['String'];
};

/** Represents an update to a `CompanyKeyPerson`. Fields that are set will be updated. */
export type CompanyKeyPersonPatch = {
  companyId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  imagePath?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** Represents an update to a `Company`. Fields that are set will be updated. */
export type CompanyPatch = {
  address?: InputMaybe<Scalars['String']>;
  bannerImagePath?: InputMaybe<Scalars['String']>;
  cardColour?: InputMaybe<Scalars['String']>;
  countryOfIncorporation?: InputMaybe<Scalars['String']>;
  founded?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  href?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  industry?: InputMaybe<Scalars['String']>;
  logoPath?: InputMaybe<Scalars['String']>;
  registrationNumber?: InputMaybe<Scalars['String']>;
  shortName?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export enum Companytype {
  Individual = 'INDIVIDUAL',
  Large = 'LARGE',
  Other = 'OTHER',
  Regulated = 'REGULATED'
}

/** A filter to be used against Companytype fields. All fields are combined with a logical ‘and.’ */
export type CompanytypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Companytype>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Companytype>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Companytype>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Companytype>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Companytype>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Companytype>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Companytype>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Companytype>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Companytype>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Companytype>>;
};

/** All input for the `confirmMyOffer` mutation. */
export type ConfirmMyOfferInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  transactionGroup?: InputMaybe<Scalars['String']>;
};

/** The output of our `confirmMyOffer` mutation. */
export type ConfirmMyOfferPayload = {
  __typename?: 'ConfirmMyOfferPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `confirmOfferInTransaction` mutation. */
export type ConfirmOfferInTransactionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  inTransactionGroup?: InputMaybe<Scalars['String']>;
};

/** The output of our `confirmOfferInTransaction` mutation. */
export type ConfirmOfferInTransactionPayload = {
  __typename?: 'ConfirmOfferInTransactionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `BlacklistedUsersForEvent` mutation. */
export type CreateBlacklistedUsersForEventInput = {
  /** The `BlacklistedUsersForEvent` to be created by this mutation. */
  blacklistedUsersForEvent: BlacklistedUsersForEventInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `BlacklistedUsersForEvent` mutation. */
export type CreateBlacklistedUsersForEventPayload = {
  __typename?: 'CreateBlacklistedUsersForEventPayload';
  /** The `BlacklistedUsersForEvent` that was created by this mutation. */
  blacklistedUsersForEvent?: Maybe<BlacklistedUsersForEvent>;
  /** An edge for our `BlacklistedUsersForEvent`. May be used by Relay 1. */
  blacklistedUsersForEventEdge?: Maybe<BlacklistedUsersForEventsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `LiquidityEvent` that is related to this `BlacklistedUsersForEvent`. */
  liquidityEvent?: Maybe<LiquidityEvent>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `BlacklistedUsersForEvent`. */
  team?: Maybe<Team>;
};


/** The output of our create `BlacklistedUsersForEvent` mutation. */
export type CreateBlacklistedUsersForEventPayloadBlacklistedUsersForEventEdgeArgs = {
  orderBy?: InputMaybe<Array<BlacklistedUsersForEventsOrderBy>>;
};

/** All input for the create `BuyerOffer` mutation. */
export type CreateBuyerOfferInput = {
  /** The `BuyerOffer` to be created by this mutation. */
  buyerOffer: BuyerOfferInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `BuyerOffer` mutation. */
export type CreateBuyerOfferPayload = {
  __typename?: 'CreateBuyerOfferPayload';
  /** The `BuyerOffer` that was created by this mutation. */
  buyerOffer?: Maybe<BuyerOffer>;
  /** An edge for our `BuyerOffer`. May be used by Relay 1. */
  buyerOfferEdge?: Maybe<BuyerOffersEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `LiquidityEvent` that is related to this `BuyerOffer`. */
  liquidityEventByLiquidityEvent?: Maybe<LiquidityEvent>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `BuyerOffer`. */
  team?: Maybe<Team>;
};


/** The output of our create `BuyerOffer` mutation. */
export type CreateBuyerOfferPayloadBuyerOfferEdgeArgs = {
  orderBy?: InputMaybe<Array<BuyerOffersOrderBy>>;
};

/** All input for the create `BuyerOnboarding` mutation. */
export type CreateBuyerOnboardingInput = {
  /** The `BuyerOnboarding` to be created by this mutation. */
  buyerOnboarding: BuyerOnboardingInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `BuyerOnboarding` mutation. */
export type CreateBuyerOnboardingPayload = {
  __typename?: 'CreateBuyerOnboardingPayload';
  /** The `BuyerOnboarding` that was created by this mutation. */
  buyerOnboarding?: Maybe<BuyerOnboarding>;
  /** An edge for our `BuyerOnboarding`. May be used by Relay 1. */
  buyerOnboardingEdge?: Maybe<BuyerOnboardingsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `BuyerOnboarding`. */
  team?: Maybe<Team>;
};


/** The output of our create `BuyerOnboarding` mutation. */
export type CreateBuyerOnboardingPayloadBuyerOnboardingEdgeArgs = {
  orderBy?: InputMaybe<Array<BuyerOnboardingsOrderBy>>;
};

/** All input for the create `BuyerPurchaseAgreement` mutation. */
export type CreateBuyerPurchaseAgreementInput = {
  /** The `BuyerPurchaseAgreement` to be created by this mutation. */
  buyerPurchaseAgreement: BuyerPurchaseAgreementInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `BuyerPurchaseAgreement` mutation. */
export type CreateBuyerPurchaseAgreementPayload = {
  __typename?: 'CreateBuyerPurchaseAgreementPayload';
  /** The `BuyerPurchaseAgreement` that was created by this mutation. */
  buyerPurchaseAgreement?: Maybe<BuyerPurchaseAgreement>;
  /** An edge for our `BuyerPurchaseAgreement`. May be used by Relay 1. */
  buyerPurchaseAgreementEdge?: Maybe<BuyerPurchaseAgreementsEdge>;
  /** Reads a single `Team` that is related to this `BuyerPurchaseAgreement`. */
  buyerTeam?: Maybe<Team>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `LiquidityEvent` that is related to this `BuyerPurchaseAgreement`. */
  liquidityEvent?: Maybe<LiquidityEvent>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `BuyerPurchaseAgreement` mutation. */
export type CreateBuyerPurchaseAgreementPayloadBuyerPurchaseAgreementEdgeArgs = {
  orderBy?: InputMaybe<Array<BuyerPurchaseAgreementsOrderBy>>;
};

/** All input for the create `CommentsAboutTeam` mutation. */
export type CreateCommentsAboutTeamInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `CommentsAboutTeam` to be created by this mutation. */
  commentsAboutTeam: CommentsAboutTeamInput;
};

/** The output of our create `CommentsAboutTeam` mutation. */
export type CreateCommentsAboutTeamPayload = {
  __typename?: 'CreateCommentsAboutTeamPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CommentsAboutTeam` that was created by this mutation. */
  commentsAboutTeam?: Maybe<CommentsAboutTeam>;
  /** An edge for our `CommentsAboutTeam`. May be used by Relay 1. */
  commentsAboutTeamEdge?: Maybe<CommentsAboutTeamsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `CommentsAboutTeam`. */
  team?: Maybe<Team>;
  /** Reads a single `User` that is related to this `CommentsAboutTeam`. */
  user?: Maybe<User>;
};


/** The output of our create `CommentsAboutTeam` mutation. */
export type CreateCommentsAboutTeamPayloadCommentsAboutTeamEdgeArgs = {
  orderBy?: InputMaybe<Array<CommentsAboutTeamsOrderBy>>;
};

/** All input for the create `Company` mutation. */
export type CreateCompanyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Company` to be created by this mutation. */
  company: CompanyInput;
};

/** All input for the create `CompanyKeyPerson` mutation. */
export type CreateCompanyKeyPersonInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `CompanyKeyPerson` to be created by this mutation. */
  companyKeyPerson: CompanyKeyPersonInput;
};

/** The output of our create `CompanyKeyPerson` mutation. */
export type CreateCompanyKeyPersonPayload = {
  __typename?: 'CreateCompanyKeyPersonPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Company` that is related to this `CompanyKeyPerson`. */
  company?: Maybe<Company>;
  /** The `CompanyKeyPerson` that was created by this mutation. */
  companyKeyPerson?: Maybe<CompanyKeyPerson>;
  /** An edge for our `CompanyKeyPerson`. May be used by Relay 1. */
  companyKeyPersonEdge?: Maybe<CompanyKeyPeopleEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `CompanyKeyPerson` mutation. */
export type CreateCompanyKeyPersonPayloadCompanyKeyPersonEdgeArgs = {
  orderBy?: InputMaybe<Array<CompanyKeyPeopleOrderBy>>;
};

/** The output of our create `Company` mutation. */
export type CreateCompanyPayload = {
  __typename?: 'CreateCompanyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Company` that was created by this mutation. */
  company?: Maybe<Company>;
  /** An edge for our `Company`. May be used by Relay 1. */
  companyEdge?: Maybe<CompaniesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `Company` mutation. */
export type CreateCompanyPayloadCompanyEdgeArgs = {
  orderBy?: InputMaybe<Array<CompaniesOrderBy>>;
};

/** All input for the create `CurrentUser` mutation. */
export type CreateCurrentUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `CurrentUser` to be created by this mutation. */
  currentUser: CurrentUserInput;
};

/** The output of our create `CurrentUser` mutation. */
export type CreateCurrentUserPayload = {
  __typename?: 'CreateCurrentUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CurrentUser` that was created by this mutation. */
  currentUser?: Maybe<CurrentUser>;
  /** An edge for our `CurrentUser`. May be used by Relay 1. */
  currentUserEdge?: Maybe<CurrentUsersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `CurrentUser`. */
  team?: Maybe<Team>;
  /** Reads a single `User` that is related to this `CurrentUser`. */
  user?: Maybe<User>;
};


/** The output of our create `CurrentUser` mutation. */
export type CreateCurrentUserPayloadCurrentUserEdgeArgs = {
  orderBy?: InputMaybe<Array<CurrentUsersOrderBy>>;
};

/** All input for the create `Email` mutation. */
export type CreateEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Email` to be created by this mutation. */
  email: EmailInput;
};

/** The output of our create `Email` mutation. */
export type CreateEmailPayload = {
  __typename?: 'CreateEmailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Email` that was created by this mutation. */
  email?: Maybe<Email>;
  /** An edge for our `Email`. May be used by Relay 1. */
  emailEdge?: Maybe<EmailsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Email`. */
  user?: Maybe<User>;
};


/** The output of our create `Email` mutation. */
export type CreateEmailPayloadEmailEdgeArgs = {
  orderBy?: InputMaybe<Array<EmailsOrderBy>>;
};

/** All input for the create `Employment` mutation. */
export type CreateEmploymentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Employment` to be created by this mutation. */
  employment: EmploymentInput;
};

/** The output of our create `Employment` mutation. */
export type CreateEmploymentPayload = {
  __typename?: 'CreateEmploymentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Team` that is related to this `Employment`. */
  companyTeam?: Maybe<Team>;
  /** Reads a single `Team` that is related to this `Employment`. */
  employeeTeam?: Maybe<Team>;
  /** The `Employment` that was created by this mutation. */
  employment?: Maybe<Employment>;
  /** An edge for our `Employment`. May be used by Relay 1. */
  employmentEdge?: Maybe<EmploymentsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `Employment` mutation. */
export type CreateEmploymentPayloadEmploymentEdgeArgs = {
  orderBy?: InputMaybe<Array<EmploymentsOrderBy>>;
};

/** All input for the create `EquityGrant` mutation. */
export type CreateEquityGrantInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `EquityGrant` to be created by this mutation. */
  equityGrant: EquityGrantInput;
};

/** The output of our create `EquityGrant` mutation. */
export type CreateEquityGrantPayload = {
  __typename?: 'CreateEquityGrantPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EquityGrant` that was created by this mutation. */
  equityGrant?: Maybe<EquityGrant>;
  /** An edge for our `EquityGrant`. May be used by Relay 1. */
  equityGrantEdge?: Maybe<EquityGrantsEdge>;
  /** Reads a single `EquityTypeInfo` that is related to this `EquityGrant`. */
  equityTypeInfoByEquityScheme?: Maybe<EquityTypeInfo>;
  /** Reads a single `Team` that is related to this `EquityGrant`. */
  holderTeam?: Maybe<Team>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `EquityGrant` mutation. */
export type CreateEquityGrantPayloadEquityGrantEdgeArgs = {
  orderBy?: InputMaybe<Array<EquityGrantsOrderBy>>;
};

/** All input for the create `EquitySummary` mutation. */
export type CreateEquitySummaryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `EquitySummary` to be created by this mutation. */
  equitySummary: EquitySummaryInput;
};

/** The output of our create `EquitySummary` mutation. */
export type CreateEquitySummaryPayload = {
  __typename?: 'CreateEquitySummaryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `EquityGrant` that is related to this `EquitySummary`. */
  equityGrantByOriginalGrant?: Maybe<EquityGrant>;
  /** The `EquitySummary` that was created by this mutation. */
  equitySummary?: Maybe<EquitySummary>;
  /** An edge for our `EquitySummary`. May be used by Relay 1. */
  equitySummaryEdge?: Maybe<EquitySummariesEdge>;
  /** Reads a single `EquityTypeInfo` that is related to this `EquitySummary`. */
  equityTypeInfoById?: Maybe<EquityTypeInfo>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `EquitySummary` mutation. */
export type CreateEquitySummaryPayloadEquitySummaryEdgeArgs = {
  orderBy?: InputMaybe<Array<EquitySummariesOrderBy>>;
};

/** All input for the create `EquityTypeInfo` mutation. */
export type CreateEquityTypeInfoInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `EquityTypeInfo` to be created by this mutation. */
  equityTypeInfo: EquityTypeInfoInput;
};

/** The output of our create `EquityTypeInfo` mutation. */
export type CreateEquityTypeInfoPayload = {
  __typename?: 'CreateEquityTypeInfoPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `EquityType` that is related to this `EquityTypeInfo`. */
  equityTypeByTypeOfEquity?: Maybe<EquityType>;
  /** The `EquityTypeInfo` that was created by this mutation. */
  equityTypeInfo?: Maybe<EquityTypeInfo>;
  /** Reads a single `EquityTypeInfo` that is related to this `EquityTypeInfo`. */
  equityTypeInfoByConvertsTo?: Maybe<EquityTypeInfo>;
  /** An edge for our `EquityTypeInfo`. May be used by Relay 1. */
  equityTypeInfoEdge?: Maybe<EquityTypeInfosEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `EquityTypeInfo` mutation. */
export type CreateEquityTypeInfoPayloadEquityTypeInfoEdgeArgs = {
  orderBy?: InputMaybe<Array<EquityTypeInfosOrderBy>>;
};

/** All input for the create `EquityType` mutation. */
export type CreateEquityTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `EquityType` to be created by this mutation. */
  equityType: EquityTypeInput;
};

/** The output of our create `EquityType` mutation. */
export type CreateEquityTypePayload = {
  __typename?: 'CreateEquityTypePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EquityType` that was created by this mutation. */
  equityType?: Maybe<EquityType>;
  /** An edge for our `EquityType`. May be used by Relay 1. */
  equityTypeEdge?: Maybe<EquityTypesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `EquityType` mutation. */
export type CreateEquityTypePayloadEquityTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<EquityTypesOrderBy>>;
};

/** All input for the create `File` mutation. */
export type CreateFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `File` to be created by this mutation. */
  file: FileInput;
};

/** The output of our create `File` mutation. */
export type CreateFilePayload = {
  __typename?: 'CreateFilePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `File` that was created by this mutation. */
  file?: Maybe<File>;
  /** An edge for our `File`. May be used by Relay 1. */
  fileEdge?: Maybe<FilesEdge>;
  /** Reads a single `LiquidityEvent` that is related to this `File`. */
  liquidityEvent?: Maybe<LiquidityEvent>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `File` mutation. */
export type CreateFilePayloadFileEdgeArgs = {
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** All input for the create `FileView` mutation. */
export type CreateFileViewInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `FileView` to be created by this mutation. */
  fileView: FileViewInput;
};

/** The output of our create `FileView` mutation. */
export type CreateFileViewPayload = {
  __typename?: 'CreateFileViewPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `File` that is related to this `FileView`. */
  fileByLiquidityEventIdAndSlug?: Maybe<File>;
  /** The `FileView` that was created by this mutation. */
  fileView?: Maybe<FileView>;
  /** An edge for our `FileView`. May be used by Relay 1. */
  fileViewEdge?: Maybe<FileViewsEdge>;
  /** Reads a single `LiquidityEvent` that is related to this `FileView`. */
  liquidityEvent?: Maybe<LiquidityEvent>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `FileView`. */
  user?: Maybe<User>;
};


/** The output of our create `FileView` mutation. */
export type CreateFileViewPayloadFileViewEdgeArgs = {
  orderBy?: InputMaybe<Array<FileViewsOrderBy>>;
};

/** All input for the create `InvestorApproval` mutation. */
export type CreateInvestorApprovalInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `InvestorApproval` to be created by this mutation. */
  investorApproval: InvestorApprovalInput;
};

/** The output of our create `InvestorApproval` mutation. */
export type CreateInvestorApprovalPayload = {
  __typename?: 'CreateInvestorApprovalPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `InvestorApproval` that was created by this mutation. */
  investorApproval?: Maybe<InvestorApproval>;
  /** An edge for our `InvestorApproval`. May be used by Relay 1. */
  investorApprovalEdge?: Maybe<InvestorApprovalsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `InvestorApproval`. */
  team?: Maybe<Team>;
  /** Reads a single `User` that is related to this `InvestorApproval`. */
  userByApprovedBy?: Maybe<User>;
};


/** The output of our create `InvestorApproval` mutation. */
export type CreateInvestorApprovalPayloadInvestorApprovalEdgeArgs = {
  orderBy?: InputMaybe<Array<InvestorApprovalsOrderBy>>;
};

/** All input for the create `InvestorInvite` mutation. */
export type CreateInvestorInviteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `InvestorInvite` to be created by this mutation. */
  investorInvite: InvestorInviteInput;
};

/** The output of our create `InvestorInvite` mutation. */
export type CreateInvestorInvitePayload = {
  __typename?: 'CreateInvestorInvitePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `InvestorInvite` that was created by this mutation. */
  investorInvite?: Maybe<InvestorInvite>;
  /** An edge for our `InvestorInvite`. May be used by Relay 1. */
  investorInviteEdge?: Maybe<InvestorInvitesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `InvestorInvite`. */
  userByUsedBy?: Maybe<User>;
};


/** The output of our create `InvestorInvite` mutation. */
export type CreateInvestorInvitePayloadInvestorInviteEdgeArgs = {
  orderBy?: InputMaybe<Array<InvestorInvitesOrderBy>>;
};

/** All input for the create `LiquidityEventFaq` mutation. */
export type CreateLiquidityEventFaqInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `LiquidityEventFaq` to be created by this mutation. */
  liquidityEventFaq: LiquidityEventFaqInput;
};

/** The output of our create `LiquidityEventFaq` mutation. */
export type CreateLiquidityEventFaqPayload = {
  __typename?: 'CreateLiquidityEventFaqPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `LiquidityEvent` that is related to this `LiquidityEventFaq`. */
  liquidityEvent?: Maybe<LiquidityEvent>;
  /** The `LiquidityEventFaq` that was created by this mutation. */
  liquidityEventFaq?: Maybe<LiquidityEventFaq>;
  /** An edge for our `LiquidityEventFaq`. May be used by Relay 1. */
  liquidityEventFaqEdge?: Maybe<LiquidityEventFaqsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `LiquidityEventFaq` mutation. */
export type CreateLiquidityEventFaqPayloadLiquidityEventFaqEdgeArgs = {
  orderBy?: InputMaybe<Array<LiquidityEventFaqsOrderBy>>;
};

/** All input for the create `LiquidityEvent` mutation. */
export type CreateLiquidityEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `LiquidityEvent` to be created by this mutation. */
  liquidityEvent: LiquidityEventInput;
};

/** The output of our create `LiquidityEvent` mutation. */
export type CreateLiquidityEventPayload = {
  __typename?: 'CreateLiquidityEventPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Company` that is related to this `LiquidityEvent`. */
  company?: Maybe<Company>;
  /** The `LiquidityEvent` that was created by this mutation. */
  liquidityEvent?: Maybe<LiquidityEvent>;
  /** An edge for our `LiquidityEvent`. May be used by Relay 1. */
  liquidityEventEdge?: Maybe<LiquidityEventsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `LiquidityEvent` mutation. */
export type CreateLiquidityEventPayloadLiquidityEventEdgeArgs = {
  orderBy?: InputMaybe<Array<LiquidityEventsOrderBy>>;
};

/** All input for the create `NdaMetadatum` mutation. */
export type CreateNdaMetadatumInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `NdaMetadatum` to be created by this mutation. */
  ndaMetadatum: NdaMetadatumInput;
};

/** The output of our create `NdaMetadatum` mutation. */
export type CreateNdaMetadatumPayload = {
  __typename?: 'CreateNdaMetadatumPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `LiquidityEvent` that is related to this `NdaMetadatum`. */
  liquidityEventByLiquidityEvent?: Maybe<LiquidityEvent>;
  /** The `NdaMetadatum` that was created by this mutation. */
  ndaMetadatum?: Maybe<NdaMetadatum>;
  /** An edge for our `NdaMetadatum`. May be used by Relay 1. */
  ndaMetadatumEdge?: Maybe<NdaMetadataEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `NdaMetadatum`. */
  team?: Maybe<Team>;
};


/** The output of our create `NdaMetadatum` mutation. */
export type CreateNdaMetadatumPayloadNdaMetadatumEdgeArgs = {
  orderBy?: InputMaybe<Array<NdaMetadataOrderBy>>;
};

/** All input for the create `OverallAllocation` mutation. */
export type CreateOverallAllocationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `OverallAllocation` to be created by this mutation. */
  overallAllocation: OverallAllocationInput;
};

/** The output of our create `OverallAllocation` mutation. */
export type CreateOverallAllocationPayload = {
  __typename?: 'CreateOverallAllocationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `OverallAllocation` that was created by this mutation. */
  overallAllocation?: Maybe<OverallAllocation>;
  /** An edge for our `OverallAllocation`. May be used by Relay 1. */
  overallAllocationEdge?: Maybe<OverallAllocationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `OverallAllocation`. */
  teamBySeller?: Maybe<Team>;
  /** Reads a single `TransactionGroup` that is related to this `OverallAllocation`. */
  transactionGroup?: Maybe<TransactionGroup>;
};


/** The output of our create `OverallAllocation` mutation. */
export type CreateOverallAllocationPayloadOverallAllocationEdgeArgs = {
  orderBy?: InputMaybe<Array<OverallAllocationsOrderBy>>;
};

/** All input for the create `Permission` mutation. */
export type CreatePermissionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Permission` to be created by this mutation. */
  permission: PermissionInput;
};

/** The output of our create `Permission` mutation. */
export type CreatePermissionPayload = {
  __typename?: 'CreatePermissionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Permission` that was created by this mutation. */
  permission?: Maybe<Permission>;
  /** An edge for our `Permission`. May be used by Relay 1. */
  permissionEdge?: Maybe<PermissionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Permission`. */
  user?: Maybe<User>;
};


/** The output of our create `Permission` mutation. */
export type CreatePermissionPayloadPermissionEdgeArgs = {
  orderBy?: InputMaybe<Array<PermissionsOrderBy>>;
};

/** All input for the `createSeller` mutation. */
export type CreateSellerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  fullName: Scalars['String'];
  inputOriginalCrowdcubeId: Scalars['Int'];
  inputTeamId: Scalars['String'];
  isoCountry: IsoCountry;
  shortName: Scalars['String'];
  userEmail: Scalars['String'];
  userId: Scalars['String'];
};

/** All input for the create `SellerOffer` mutation. */
export type CreateSellerOfferInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `SellerOffer` to be created by this mutation. */
  sellerOffer: SellerOfferInput;
};

/** The output of our create `SellerOffer` mutation. */
export type CreateSellerOfferPayload = {
  __typename?: 'CreateSellerOfferPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `EquityGrant` that is related to this `SellerOffer`. */
  grant?: Maybe<EquityGrant>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `SellerOffer`. */
  seller?: Maybe<Team>;
  /** The `SellerOffer` that was created by this mutation. */
  sellerOffer?: Maybe<SellerOffer>;
  /** An edge for our `SellerOffer`. May be used by Relay 1. */
  sellerOfferEdge?: Maybe<SellerOffersEdge>;
  /** Reads a single `TransactionGroup` that is related to this `SellerOffer`. */
  transactionGroupByTransactionGroup?: Maybe<TransactionGroup>;
};


/** The output of our create `SellerOffer` mutation. */
export type CreateSellerOfferPayloadSellerOfferEdgeArgs = {
  orderBy?: InputMaybe<Array<SellerOffersOrderBy>>;
};

/** All input for the create `SellerOnboarding` mutation. */
export type CreateSellerOnboardingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `SellerOnboarding` to be created by this mutation. */
  sellerOnboarding: SellerOnboardingInput;
};

/** The output of our create `SellerOnboarding` mutation. */
export type CreateSellerOnboardingPayload = {
  __typename?: 'CreateSellerOnboardingPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SellerOnboarding` that was created by this mutation. */
  sellerOnboarding?: Maybe<SellerOnboarding>;
  /** An edge for our `SellerOnboarding`. May be used by Relay 1. */
  sellerOnboardingEdge?: Maybe<SellerOnboardingsEdge>;
  /** Reads a single `Team` that is related to this `SellerOnboarding`. */
  team?: Maybe<Team>;
};


/** The output of our create `SellerOnboarding` mutation. */
export type CreateSellerOnboardingPayloadSellerOnboardingEdgeArgs = {
  orderBy?: InputMaybe<Array<SellerOnboardingsOrderBy>>;
};

/** The output of our `createSeller` mutation. */
export type CreateSellerPayload = {
  __typename?: 'CreateSellerPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  string?: Maybe<Scalars['String']>;
};

/** All input for the create `SignedDocument` mutation. */
export type CreateSignedDocumentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `SignedDocument` to be created by this mutation. */
  signedDocument: SignedDocumentInput;
};

/** The output of our create `SignedDocument` mutation. */
export type CreateSignedDocumentPayload = {
  __typename?: 'CreateSignedDocumentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SignedDocument` that was created by this mutation. */
  signedDocument?: Maybe<SignedDocument>;
  /** An edge for our `SignedDocument`. May be used by Relay 1. */
  signedDocumentEdge?: Maybe<SignedDocumentsEdge>;
  /** Reads a single `User` that is related to this `SignedDocument`. */
  user?: Maybe<User>;
};


/** The output of our create `SignedDocument` mutation. */
export type CreateSignedDocumentPayloadSignedDocumentEdgeArgs = {
  orderBy?: InputMaybe<Array<SignedDocumentsOrderBy>>;
};

/** All input for the create `Stage2Access` mutation. */
export type CreateStage2AccessInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Stage2Access` to be created by this mutation. */
  stage2Access: Stage2AccessInput;
};

/** The output of our create `Stage2Access` mutation. */
export type CreateStage2AccessPayload = {
  __typename?: 'CreateStage2AccessPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `LiquidityEvent` that is related to this `Stage2Access`. */
  liquidityEventByLiquidityEvent?: Maybe<LiquidityEvent>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Stage2Access` that was created by this mutation. */
  stage2Access?: Maybe<Stage2Access>;
  /** An edge for our `Stage2Access`. May be used by Relay 1. */
  stage2AccessEdge?: Maybe<Stage2AccessesEdge>;
  /** Reads a single `Team` that is related to this `Stage2Access`. */
  team?: Maybe<Team>;
};


/** The output of our create `Stage2Access` mutation. */
export type CreateStage2AccessPayloadStage2AccessEdgeArgs = {
  orderBy?: InputMaybe<Array<Stage2AccessesOrderBy>>;
};

/** All input for the create `SuggestedTax` mutation. */
export type CreateSuggestedTaxInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `SuggestedTax` to be created by this mutation. */
  suggestedTax: SuggestedTaxInput;
};

/** The output of our create `SuggestedTax` mutation. */
export type CreateSuggestedTaxPayload = {
  __typename?: 'CreateSuggestedTaxPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SuggestedTax` that was created by this mutation. */
  suggestedTax?: Maybe<SuggestedTax>;
  /** An edge for our `SuggestedTax`. May be used by Relay 1. */
  suggestedTaxEdge?: Maybe<SuggestedTaxesEdge>;
};


/** The output of our create `SuggestedTax` mutation. */
export type CreateSuggestedTaxPayloadSuggestedTaxEdgeArgs = {
  orderBy?: InputMaybe<Array<SuggestedTaxesOrderBy>>;
};

/** All input for the create `TaxRate` mutation. */
export type CreateTaxRateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TaxRate` to be created by this mutation. */
  taxRate: TaxRateInput;
};

/** The output of our create `TaxRate` mutation. */
export type CreateTaxRatePayload = {
  __typename?: 'CreateTaxRatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `EquityType` that is related to this `TaxRate`. */
  equityTypeByEquityType?: Maybe<EquityType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TaxRate` that was created by this mutation. */
  taxRate?: Maybe<TaxRate>;
  /** An edge for our `TaxRate`. May be used by Relay 1. */
  taxRateEdge?: Maybe<TaxRatesEdge>;
};


/** The output of our create `TaxRate` mutation. */
export type CreateTaxRatePayloadTaxRateEdgeArgs = {
  orderBy?: InputMaybe<Array<TaxRatesOrderBy>>;
};

/** All input for the create `TaxVariable` mutation. */
export type CreateTaxVariableInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TaxVariable` to be created by this mutation. */
  taxVariable: TaxVariableInput;
};

/** The output of our create `TaxVariable` mutation. */
export type CreateTaxVariablePayload = {
  __typename?: 'CreateTaxVariablePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TaxVariable` that was created by this mutation. */
  taxVariable?: Maybe<TaxVariable>;
  /** An edge for our `TaxVariable`. May be used by Relay 1. */
  taxVariableEdge?: Maybe<TaxVariablesEdge>;
};


/** The output of our create `TaxVariable` mutation. */
export type CreateTaxVariablePayloadTaxVariableEdgeArgs = {
  orderBy?: InputMaybe<Array<TaxVariablesOrderBy>>;
};

/** All input for the create `Team` mutation. */
export type CreateTeamInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Team` to be created by this mutation. */
  team: TeamInput;
};

/** All input for the create `TeamMembership` mutation. */
export type CreateTeamMembershipInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TeamMembership` to be created by this mutation. */
  teamMembership: TeamMembershipInput;
};

/** The output of our create `TeamMembership` mutation. */
export type CreateTeamMembershipPayload = {
  __typename?: 'CreateTeamMembershipPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `TeamMembership`. */
  team?: Maybe<Team>;
  /** The `TeamMembership` that was created by this mutation. */
  teamMembership?: Maybe<TeamMembership>;
  /** An edge for our `TeamMembership`. May be used by Relay 1. */
  teamMembershipEdge?: Maybe<TeamMembershipsEdge>;
  /** Reads a single `User` that is related to this `TeamMembership`. */
  user?: Maybe<User>;
};


/** The output of our create `TeamMembership` mutation. */
export type CreateTeamMembershipPayloadTeamMembershipEdgeArgs = {
  orderBy?: InputMaybe<Array<TeamMembershipsOrderBy>>;
};

/** The output of our create `Team` mutation. */
export type CreateTeamPayload = {
  __typename?: 'CreateTeamPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Team` that was created by this mutation. */
  team?: Maybe<Team>;
  /** An edge for our `Team`. May be used by Relay 1. */
  teamEdge?: Maybe<TeamsEdge>;
};


/** The output of our create `Team` mutation. */
export type CreateTeamPayloadTeamEdgeArgs = {
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
};

/** All input for the create `TransactionAllowance` mutation. */
export type CreateTransactionAllowanceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TransactionAllowance` to be created by this mutation. */
  transactionAllowance: TransactionAllowanceInput;
};

/** The output of our create `TransactionAllowance` mutation. */
export type CreateTransactionAllowancePayload = {
  __typename?: 'CreateTransactionAllowancePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `TransactionAllowance`. */
  team?: Maybe<Team>;
  /** The `TransactionAllowance` that was created by this mutation. */
  transactionAllowance?: Maybe<TransactionAllowance>;
  /** An edge for our `TransactionAllowance`. May be used by Relay 1. */
  transactionAllowanceEdge?: Maybe<TransactionAllowancesEdge>;
  /** Reads a single `TransactionGroup` that is related to this `TransactionAllowance`. */
  transactionGroupByTransactionGroup?: Maybe<TransactionGroup>;
};


/** The output of our create `TransactionAllowance` mutation. */
export type CreateTransactionAllowancePayloadTransactionAllowanceEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionAllowancesOrderBy>>;
};

/** All input for the create `TransactionApproval` mutation. */
export type CreateTransactionApprovalInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TransactionApproval` to be created by this mutation. */
  transactionApproval: TransactionApprovalInput;
};

/** The output of our create `TransactionApproval` mutation. */
export type CreateTransactionApprovalPayload = {
  __typename?: 'CreateTransactionApprovalPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Transaction` that is related to this `TransactionApproval`. */
  transaction?: Maybe<Transaction>;
  /** The `TransactionApproval` that was created by this mutation. */
  transactionApproval?: Maybe<TransactionApproval>;
  /** An edge for our `TransactionApproval`. May be used by Relay 1. */
  transactionApprovalEdge?: Maybe<TransactionApprovalsEdge>;
  /** Reads a single `User` that is related to this `TransactionApproval`. */
  userByApprovedBy?: Maybe<User>;
};


/** The output of our create `TransactionApproval` mutation. */
export type CreateTransactionApprovalPayloadTransactionApprovalEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionApprovalsOrderBy>>;
};

/** All input for the create `TransactionDetail` mutation. */
export type CreateTransactionDetailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TransactionDetail` to be created by this mutation. */
  transactionDetail: TransactionDetailInput;
};

/** The output of our create `TransactionDetail` mutation. */
export type CreateTransactionDetailPayload = {
  __typename?: 'CreateTransactionDetailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `EquityTypeInfo` that is related to this `TransactionDetail`. */
  equityTypeInfoByScheme?: Maybe<EquityTypeInfo>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TransactionDetail` that was created by this mutation. */
  transactionDetail?: Maybe<TransactionDetail>;
  /** An edge for our `TransactionDetail`. May be used by Relay 1. */
  transactionDetailEdge?: Maybe<TransactionDetailsEdge>;
};


/** The output of our create `TransactionDetail` mutation. */
export type CreateTransactionDetailPayloadTransactionDetailEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionDetailsOrderBy>>;
};

/** All input for the create `TransactionDocumentField` mutation. */
export type CreateTransactionDocumentFieldInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TransactionDocumentField` to be created by this mutation. */
  transactionDocumentField: TransactionDocumentFieldInput;
};

/** The output of our create `TransactionDocumentField` mutation. */
export type CreateTransactionDocumentFieldPayload = {
  __typename?: 'CreateTransactionDocumentFieldPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `TransactionDocument` that is related to this `TransactionDocumentField`. */
  document?: Maybe<TransactionDocument>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TransactionDocumentField` that was created by this mutation. */
  transactionDocumentField?: Maybe<TransactionDocumentField>;
  /** An edge for our `TransactionDocumentField`. May be used by Relay 1. */
  transactionDocumentFieldEdge?: Maybe<TransactionDocumentFieldsEdge>;
};


/** The output of our create `TransactionDocumentField` mutation. */
export type CreateTransactionDocumentFieldPayloadTransactionDocumentFieldEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionDocumentFieldsOrderBy>>;
};

/** All input for the create `TransactionDocument` mutation. */
export type CreateTransactionDocumentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TransactionDocument` to be created by this mutation. */
  transactionDocument: TransactionDocumentInput;
};

/** The output of our create `TransactionDocument` mutation. */
export type CreateTransactionDocumentPayload = {
  __typename?: 'CreateTransactionDocumentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `EquityTypeInfo` that is related to this `TransactionDocument`. */
  equityTypeInfoByEquityScheme?: Maybe<EquityTypeInfo>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TransactionDocument` that was created by this mutation. */
  transactionDocument?: Maybe<TransactionDocument>;
  /** An edge for our `TransactionDocument`. May be used by Relay 1. */
  transactionDocumentEdge?: Maybe<TransactionDocumentsEdge>;
  /** Reads a single `TransactionGroup` that is related to this `TransactionDocument`. */
  transactionGroup?: Maybe<TransactionGroup>;
};


/** The output of our create `TransactionDocument` mutation. */
export type CreateTransactionDocumentPayloadTransactionDocumentEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionDocumentsOrderBy>>;
};

/** All input for the create `TransactionDocumentSignature` mutation. */
export type CreateTransactionDocumentSignatureInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TransactionDocumentSignature` to be created by this mutation. */
  transactionDocumentSignature: TransactionDocumentSignatureInput;
};

/** The output of our create `TransactionDocumentSignature` mutation. */
export type CreateTransactionDocumentSignaturePayload = {
  __typename?: 'CreateTransactionDocumentSignaturePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `TransactionDocument` that is related to this `TransactionDocumentSignature`. */
  document?: Maybe<TransactionDocument>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TransactionDocumentSignature` that was created by this mutation. */
  transactionDocumentSignature?: Maybe<TransactionDocumentSignature>;
  /** An edge for our `TransactionDocumentSignature`. May be used by Relay 1. */
  transactionDocumentSignatureEdge?: Maybe<TransactionDocumentSignaturesEdge>;
  /** Reads a single `User` that is related to this `TransactionDocumentSignature`. */
  user?: Maybe<User>;
};


/** The output of our create `TransactionDocumentSignature` mutation. */
export type CreateTransactionDocumentSignaturePayloadTransactionDocumentSignatureEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionDocumentSignaturesOrderBy>>;
};

/** All input for the create `TransactionFee` mutation. */
export type CreateTransactionFeeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TransactionFee` to be created by this mutation. */
  transactionFee: TransactionFeeInput;
};

/** The output of our create `TransactionFee` mutation. */
export type CreateTransactionFeePayload = {
  __typename?: 'CreateTransactionFeePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `TransactionFee`. */
  teamByReciever?: Maybe<Team>;
  /** The `TransactionFee` that was created by this mutation. */
  transactionFee?: Maybe<TransactionFee>;
  /** An edge for our `TransactionFee`. May be used by Relay 1. */
  transactionFeeEdge?: Maybe<TransactionFeesEdge>;
  /** Reads a single `TransactionGroup` that is related to this `TransactionFee`. */
  transactionGroupByTransactionGroup?: Maybe<TransactionGroup>;
};


/** The output of our create `TransactionFee` mutation. */
export type CreateTransactionFeePayloadTransactionFeeEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionFeesOrderBy>>;
};

/** All input for the create `TransactionGroupCurrency` mutation. */
export type CreateTransactionGroupCurrencyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TransactionGroupCurrency` to be created by this mutation. */
  transactionGroupCurrency: TransactionGroupCurrencyInput;
};

/** The output of our create `TransactionGroupCurrency` mutation. */
export type CreateTransactionGroupCurrencyPayload = {
  __typename?: 'CreateTransactionGroupCurrencyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `TransactionGroup` that is related to this `TransactionGroupCurrency`. */
  transactionGroupByTransactionGroup?: Maybe<TransactionGroup>;
  /** The `TransactionGroupCurrency` that was created by this mutation. */
  transactionGroupCurrency?: Maybe<TransactionGroupCurrency>;
  /** An edge for our `TransactionGroupCurrency`. May be used by Relay 1. */
  transactionGroupCurrencyEdge?: Maybe<TransactionGroupCurrenciesEdge>;
};


/** The output of our create `TransactionGroupCurrency` mutation. */
export type CreateTransactionGroupCurrencyPayloadTransactionGroupCurrencyEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionGroupCurrenciesOrderBy>>;
};

/** All input for the create `TransactionGroup` mutation. */
export type CreateTransactionGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TransactionGroup` to be created by this mutation. */
  transactionGroup: TransactionGroupInput;
};

/** The output of our create `TransactionGroup` mutation. */
export type CreateTransactionGroupPayload = {
  __typename?: 'CreateTransactionGroupPayload';
  /** Reads a single `Team` that is related to this `TransactionGroup`. */
  buyerTeam?: Maybe<Team>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Company` that is related to this `TransactionGroup`. */
  company?: Maybe<Company>;
  /** Reads a single `LiquidityEvent` that is related to this `TransactionGroup`. */
  liquidityEvent?: Maybe<LiquidityEvent>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TransactionGroup` that was created by this mutation. */
  transactionGroup?: Maybe<TransactionGroup>;
  /** An edge for our `TransactionGroup`. May be used by Relay 1. */
  transactionGroupEdge?: Maybe<TransactionGroupsEdge>;
};


/** The output of our create `TransactionGroup` mutation. */
export type CreateTransactionGroupPayloadTransactionGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionGroupsOrderBy>>;
};

/** All input for the create `Transaction` mutation. */
export type CreateTransactionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Transaction` to be created by this mutation. */
  transaction: TransactionInput;
};

/** All input for the create `TransactionNote` mutation. */
export type CreateTransactionNoteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TransactionNote` to be created by this mutation. */
  transactionNote: TransactionNoteInput;
};

/** The output of our create `TransactionNote` mutation. */
export type CreateTransactionNotePayload = {
  __typename?: 'CreateTransactionNotePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Transaction` that is related to this `TransactionNote`. */
  transaction?: Maybe<Transaction>;
  /** The `TransactionNote` that was created by this mutation. */
  transactionNote?: Maybe<TransactionNote>;
  /** An edge for our `TransactionNote`. May be used by Relay 1. */
  transactionNoteEdge?: Maybe<TransactionNotesEdge>;
  /** Reads a single `User` that is related to this `TransactionNote`. */
  user?: Maybe<User>;
};


/** The output of our create `TransactionNote` mutation. */
export type CreateTransactionNotePayloadTransactionNoteEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionNotesOrderBy>>;
};

/** The output of our create `Transaction` mutation. */
export type CreateTransactionPayload = {
  __typename?: 'CreateTransactionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `EquityTypeInfo` that is related to this `Transaction`. */
  equityTypeInfoByPartyAEquityIncreaseScheme?: Maybe<EquityTypeInfo>;
  /** Reads a single `EquityGrant` that is related to this `Transaction`. */
  originalGrant?: Maybe<EquityGrant>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `Transaction`. */
  teamByPartyA?: Maybe<Team>;
  /** Reads a single `Team` that is related to this `Transaction`. */
  teamByPartyB?: Maybe<Team>;
  /** The `Transaction` that was created by this mutation. */
  transaction?: Maybe<Transaction>;
  /** Reads a single `Transaction` that is related to this `Transaction`. */
  transactionByPartyAEquityTransactionFrom?: Maybe<Transaction>;
  /** An edge for our `Transaction`. May be used by Relay 1. */
  transactionEdge?: Maybe<TransactionsEdge>;
  /** Reads a single `TransactionGroup` that is related to this `Transaction`. */
  transactionGroup?: Maybe<TransactionGroup>;
};


/** The output of our create `Transaction` mutation. */
export type CreateTransactionPayloadTransactionEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
};

/** All input for the create `TransactionSummaryExerciseSale` mutation. */
export type CreateTransactionSummaryExerciseSaleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TransactionSummaryExerciseSale` to be created by this mutation. */
  transactionSummaryExerciseSale: TransactionSummaryExerciseSaleInput;
};

/** The output of our create `TransactionSummaryExerciseSale` mutation. */
export type CreateTransactionSummaryExerciseSalePayload = {
  __typename?: 'CreateTransactionSummaryExerciseSalePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `EquityGrant` that is related to this `TransactionSummaryExerciseSale`. */
  equityGrantByEquityGrant?: Maybe<EquityGrant>;
  /** Reads a single `EquityTypeInfo` that is related to this `TransactionSummaryExerciseSale`. */
  equityTypeInfoByEquityScheme?: Maybe<EquityTypeInfo>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `TransactionSummaryExerciseSale`. */
  teamBySeller?: Maybe<Team>;
  /** The `TransactionSummaryExerciseSale` that was created by this mutation. */
  transactionSummaryExerciseSale?: Maybe<TransactionSummaryExerciseSale>;
  /** An edge for our `TransactionSummaryExerciseSale`. May be used by Relay 1. */
  transactionSummaryExerciseSaleEdge?: Maybe<TransactionSummaryExerciseSalesEdge>;
};


/** The output of our create `TransactionSummaryExerciseSale` mutation. */
export type CreateTransactionSummaryExerciseSalePayloadTransactionSummaryExerciseSaleEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionSummaryExerciseSalesOrderBy>>;
};

/** All input for the create `TransactionTaxRate` mutation. */
export type CreateTransactionTaxRateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TransactionTaxRate` to be created by this mutation. */
  transactionTaxRate: TransactionTaxRateInput;
};

/** The output of our create `TransactionTaxRate` mutation. */
export type CreateTransactionTaxRatePayload = {
  __typename?: 'CreateTransactionTaxRatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `EquityGrant` that is related to this `TransactionTaxRate`. */
  equity?: Maybe<EquityGrant>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `TransactionGroup` that is related to this `TransactionTaxRate`. */
  transactionGroupByTransactionGroup?: Maybe<TransactionGroup>;
  /** The `TransactionTaxRate` that was created by this mutation. */
  transactionTaxRate?: Maybe<TransactionTaxRate>;
  /** An edge for our `TransactionTaxRate`. May be used by Relay 1. */
  transactionTaxRateEdge?: Maybe<TransactionTaxRatesEdge>;
};


/** The output of our create `TransactionTaxRate` mutation. */
export type CreateTransactionTaxRatePayloadTransactionTaxRateEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionTaxRatesOrderBy>>;
};

/** All input for the create `User` mutation. */
export type CreateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `User` to be created by this mutation. */
  user: UserInput;
};

/** The output of our create `User` mutation. */
export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `User` that was created by this mutation. */
  user?: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our create `User` mutation. */
export type CreateUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** All input for the create `Vesting` mutation. */
export type CreateVestingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Vesting` to be created by this mutation. */
  vesting: VestingInput;
};

/** The output of our create `Vesting` mutation. */
export type CreateVestingPayload = {
  __typename?: 'CreateVestingPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Vesting` that was created by this mutation. */
  vesting?: Maybe<Vesting>;
  /** An edge for our `Vesting`. May be used by Relay 1. */
  vestingEdge?: Maybe<VestingsEdge>;
};


/** The output of our create `Vesting` mutation. */
export type CreateVestingPayloadVestingEdgeArgs = {
  orderBy?: InputMaybe<Array<VestingsOrderBy>>;
};

/** All input for the create `VestingSchedule` mutation. */
export type CreateVestingScheduleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `VestingSchedule` to be created by this mutation. */
  vestingSchedule: VestingScheduleInput;
};

/** The output of our create `VestingSchedule` mutation. */
export type CreateVestingSchedulePayload = {
  __typename?: 'CreateVestingSchedulePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Vesting` that is related to this `VestingSchedule`. */
  vestingById?: Maybe<Vesting>;
  /** The `VestingSchedule` that was created by this mutation. */
  vestingSchedule?: Maybe<VestingSchedule>;
  /** An edge for our `VestingSchedule`. May be used by Relay 1. */
  vestingScheduleEdge?: Maybe<VestingSchedulesEdge>;
};


/** The output of our create `VestingSchedule` mutation. */
export type CreateVestingSchedulePayloadVestingScheduleEdgeArgs = {
  orderBy?: InputMaybe<Array<VestingSchedulesOrderBy>>;
};

export type Currency = {
  __typename?: 'Currency';
  amount: Scalars['BigFloat'];
  currencyCode: CurrencyCode;
};

export enum CurrencyCode {
  Eur = 'EUR',
  Gbp = 'GBP',
  Nok = 'NOK',
  Sek = 'SEK'
}

/** A filter to be used against CurrencyCode fields. All fields are combined with a logical ‘and.’ */
export type CurrencyCodeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<CurrencyCode>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<CurrencyCode>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<CurrencyCode>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<CurrencyCode>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<CurrencyCode>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<CurrencyCode>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<CurrencyCode>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<CurrencyCode>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<CurrencyCode>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<CurrencyCode>>;
};

/** A filter to be used against `Currency` object types. All fields are combined with a logical ‘and.’ */
export type CurrencyFilter = {
  /** Filter by the object’s `amount` field. */
  amount?: InputMaybe<BigFloatFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CurrencyFilter>>;
  /** Filter by the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<CurrencyCodeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CurrencyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CurrencyFilter>>;
};

/** An input for mutations affecting `Currency` */
export type CurrencyInput = {
  amount: Scalars['BigFloat'];
  currencyCode: CurrencyCode;
};

/** This is a dummy table for postgraphile, don't write/read from it \n @omit all */
export type CurrentUser = {
  __typename?: 'CurrentUser';
  /** Reads a single `Team` that is related to this `CurrentUser`. */
  team?: Maybe<Team>;
  teamId?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `CurrentUser`. */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `CurrentUser` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type CurrentUserCondition = {
  /** Checks for equality with the object’s `teamId` field. */
  teamId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `CurrentUser` object types. All fields are combined with a logical ‘and.’ */
export type CurrentUserFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CurrentUserFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<CurrentUserFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CurrentUserFilter>>;
  /** Filter by the object’s `teamId` field. */
  teamId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `CurrentUser` */
export type CurrentUserInput = {
  teamId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `CurrentUser` values. */
export type CurrentUsersConnection = {
  __typename?: 'CurrentUsersConnection';
  /** A list of edges which contains the `CurrentUser` and cursor to aid in pagination. */
  edges: Array<CurrentUsersEdge>;
  /** A list of `CurrentUser` objects. */
  nodes: Array<CurrentUser>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CurrentUser` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `CurrentUser` edge in the connection. */
export type CurrentUsersEdge = {
  __typename?: 'CurrentUsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CurrentUser` at the end of the edge. */
  node: CurrentUser;
};

/** Methods to use when ordering `CurrentUser`. */
export enum CurrentUsersOrderBy {
  Natural = 'NATURAL',
  TeamIdAsc = 'TEAM_ID_ASC',
  TeamIdDesc = 'TEAM_ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export enum DataroomFileCategory {
  DecksStrategy = 'DECKS_STRATEGY',
  Financials = 'FINANCIALS',
  Legals = 'LEGALS',
  Misc = 'MISC',
  Research = 'RESEARCH',
  Stage1 = 'STAGE1'
}

/** A filter to be used against DataroomFileCategory fields. All fields are combined with a logical ‘and.’ */
export type DataroomFileCategoryFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<DataroomFileCategory>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<DataroomFileCategory>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<DataroomFileCategory>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<DataroomFileCategory>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<DataroomFileCategory>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<DataroomFileCategory>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<DataroomFileCategory>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<DataroomFileCategory>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<DataroomFileCategory>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<DataroomFileCategory>>;
};

/** A filter to be used against Date fields. All fields are combined with a logical ‘and.’ */
export type DateFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Date']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Date']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Date']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Date']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Date']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Date']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Date']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Date']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Date']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Date']>>;
};

/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Datetime']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Datetime']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Datetime']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Datetime']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Datetime']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Datetime']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Datetime']>>;
};

/** All input for the `deleteBuyerOfferByNodeId` mutation. */
export type DeleteBuyerOfferByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `BuyerOffer` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteBuyerOffer` mutation. */
export type DeleteBuyerOfferInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  liquidityEvent: Scalars['String'];
  teamId: Scalars['String'];
};

/** The output of our delete `BuyerOffer` mutation. */
export type DeleteBuyerOfferPayload = {
  __typename?: 'DeleteBuyerOfferPayload';
  /** The `BuyerOffer` that was deleted by this mutation. */
  buyerOffer?: Maybe<BuyerOffer>;
  /** An edge for our `BuyerOffer`. May be used by Relay 1. */
  buyerOfferEdge?: Maybe<BuyerOffersEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedBuyerOfferNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `LiquidityEvent` that is related to this `BuyerOffer`. */
  liquidityEventByLiquidityEvent?: Maybe<LiquidityEvent>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `BuyerOffer`. */
  team?: Maybe<Team>;
};


/** The output of our delete `BuyerOffer` mutation. */
export type DeleteBuyerOfferPayloadBuyerOfferEdgeArgs = {
  orderBy?: InputMaybe<Array<BuyerOffersOrderBy>>;
};

/** All input for the `deleteBuyerOnboardingByNodeId` mutation. */
export type DeleteBuyerOnboardingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `BuyerOnboarding` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteBuyerOnboarding` mutation. */
export type DeleteBuyerOnboardingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  teamId: Scalars['String'];
};

/** The output of our delete `BuyerOnboarding` mutation. */
export type DeleteBuyerOnboardingPayload = {
  __typename?: 'DeleteBuyerOnboardingPayload';
  /** The `BuyerOnboarding` that was deleted by this mutation. */
  buyerOnboarding?: Maybe<BuyerOnboarding>;
  /** An edge for our `BuyerOnboarding`. May be used by Relay 1. */
  buyerOnboardingEdge?: Maybe<BuyerOnboardingsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedBuyerOnboardingNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `BuyerOnboarding`. */
  team?: Maybe<Team>;
};


/** The output of our delete `BuyerOnboarding` mutation. */
export type DeleteBuyerOnboardingPayloadBuyerOnboardingEdgeArgs = {
  orderBy?: InputMaybe<Array<BuyerOnboardingsOrderBy>>;
};

/** All input for the `deleteCompanyByNodeId` mutation. */
export type DeleteCompanyByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Company` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCompany` mutation. */
export type DeleteCompanyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

/** All input for the `deleteCompanyKeyPersonByNodeId` mutation. */
export type DeleteCompanyKeyPersonByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyKeyPerson` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCompanyKeyPerson` mutation. */
export type DeleteCompanyKeyPersonInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `CompanyKeyPerson` mutation. */
export type DeleteCompanyKeyPersonPayload = {
  __typename?: 'DeleteCompanyKeyPersonPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Company` that is related to this `CompanyKeyPerson`. */
  company?: Maybe<Company>;
  /** The `CompanyKeyPerson` that was deleted by this mutation. */
  companyKeyPerson?: Maybe<CompanyKeyPerson>;
  /** An edge for our `CompanyKeyPerson`. May be used by Relay 1. */
  companyKeyPersonEdge?: Maybe<CompanyKeyPeopleEdge>;
  deletedCompanyKeyPersonNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `CompanyKeyPerson` mutation. */
export type DeleteCompanyKeyPersonPayloadCompanyKeyPersonEdgeArgs = {
  orderBy?: InputMaybe<Array<CompanyKeyPeopleOrderBy>>;
};

/** The output of our delete `Company` mutation. */
export type DeleteCompanyPayload = {
  __typename?: 'DeleteCompanyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Company` that was deleted by this mutation. */
  company?: Maybe<Company>;
  /** An edge for our `Company`. May be used by Relay 1. */
  companyEdge?: Maybe<CompaniesEdge>;
  deletedCompanyNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `Company` mutation. */
export type DeleteCompanyPayloadCompanyEdgeArgs = {
  orderBy?: InputMaybe<Array<CompaniesOrderBy>>;
};

/** All input for the `deleteEmploymentByNodeId` mutation. */
export type DeleteEmploymentByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Employment` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteEmployment` mutation. */
export type DeleteEmploymentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyTeamId: Scalars['String'];
  employeeTeamId: Scalars['String'];
};

/** The output of our delete `Employment` mutation. */
export type DeleteEmploymentPayload = {
  __typename?: 'DeleteEmploymentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Team` that is related to this `Employment`. */
  companyTeam?: Maybe<Team>;
  deletedEmploymentNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Team` that is related to this `Employment`. */
  employeeTeam?: Maybe<Team>;
  /** The `Employment` that was deleted by this mutation. */
  employment?: Maybe<Employment>;
  /** An edge for our `Employment`. May be used by Relay 1. */
  employmentEdge?: Maybe<EmploymentsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `Employment` mutation. */
export type DeleteEmploymentPayloadEmploymentEdgeArgs = {
  orderBy?: InputMaybe<Array<EmploymentsOrderBy>>;
};

/** All input for the `deleteEquityGrantByHolderTeamIdAndEquityScheme` mutation. */
export type DeleteEquityGrantByHolderTeamIdAndEquitySchemeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  equityScheme: Scalars['String'];
  holderTeamId: Scalars['String'];
};

/** All input for the `deleteEquityGrantByNodeId` mutation. */
export type DeleteEquityGrantByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `EquityGrant` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteEquityGrant` mutation. */
export type DeleteEquityGrantInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `EquityGrant` mutation. */
export type DeleteEquityGrantPayload = {
  __typename?: 'DeleteEquityGrantPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedEquityGrantNodeId?: Maybe<Scalars['ID']>;
  /** The `EquityGrant` that was deleted by this mutation. */
  equityGrant?: Maybe<EquityGrant>;
  /** An edge for our `EquityGrant`. May be used by Relay 1. */
  equityGrantEdge?: Maybe<EquityGrantsEdge>;
  /** Reads a single `EquityTypeInfo` that is related to this `EquityGrant`. */
  equityTypeInfoByEquityScheme?: Maybe<EquityTypeInfo>;
  /** Reads a single `Team` that is related to this `EquityGrant`. */
  holderTeam?: Maybe<Team>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `EquityGrant` mutation. */
export type DeleteEquityGrantPayloadEquityGrantEdgeArgs = {
  orderBy?: InputMaybe<Array<EquityGrantsOrderBy>>;
};

/** All input for the `deleteEquityTypeByNodeId` mutation. */
export type DeleteEquityTypeByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `EquityType` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteEquityTypeInfoByNodeId` mutation. */
export type DeleteEquityTypeInfoByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `EquityTypeInfo` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteEquityTypeInfo` mutation. */
export type DeleteEquityTypeInfoInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

/** The output of our delete `EquityTypeInfo` mutation. */
export type DeleteEquityTypeInfoPayload = {
  __typename?: 'DeleteEquityTypeInfoPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedEquitySchemeNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `EquityType` that is related to this `EquityTypeInfo`. */
  equityTypeByTypeOfEquity?: Maybe<EquityType>;
  /** The `EquityTypeInfo` that was deleted by this mutation. */
  equityTypeInfo?: Maybe<EquityTypeInfo>;
  /** Reads a single `EquityTypeInfo` that is related to this `EquityTypeInfo`. */
  equityTypeInfoByConvertsTo?: Maybe<EquityTypeInfo>;
  /** An edge for our `EquityTypeInfo`. May be used by Relay 1. */
  equityTypeInfoEdge?: Maybe<EquityTypeInfosEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `EquityTypeInfo` mutation. */
export type DeleteEquityTypeInfoPayloadEquityTypeInfoEdgeArgs = {
  orderBy?: InputMaybe<Array<EquityTypeInfosOrderBy>>;
};

/** All input for the `deleteEquityType` mutation. */
export type DeleteEquityTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

/** The output of our delete `EquityType` mutation. */
export type DeleteEquityTypePayload = {
  __typename?: 'DeleteEquityTypePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedEquityTypeNodeId?: Maybe<Scalars['ID']>;
  /** The `EquityType` that was deleted by this mutation. */
  equityType?: Maybe<EquityType>;
  /** An edge for our `EquityType`. May be used by Relay 1. */
  equityTypeEdge?: Maybe<EquityTypesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `EquityType` mutation. */
export type DeleteEquityTypePayloadEquityTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<EquityTypesOrderBy>>;
};

/** All input for the `deleteFileByNodeId` mutation. */
export type DeleteFileByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `File` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteFile` mutation. */
export type DeleteFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  liquidityEventId: Scalars['String'];
  slug: Scalars['String'];
};

/** The output of our delete `File` mutation. */
export type DeleteFilePayload = {
  __typename?: 'DeleteFilePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedFileNodeId?: Maybe<Scalars['ID']>;
  /** The `File` that was deleted by this mutation. */
  file?: Maybe<File>;
  /** An edge for our `File`. May be used by Relay 1. */
  fileEdge?: Maybe<FilesEdge>;
  /** Reads a single `LiquidityEvent` that is related to this `File`. */
  liquidityEvent?: Maybe<LiquidityEvent>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `File` mutation. */
export type DeleteFilePayloadFileEdgeArgs = {
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** All input for the `deleteInvestorApprovalByNodeId` mutation. */
export type DeleteInvestorApprovalByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `InvestorApproval` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteInvestorApproval` mutation. */
export type DeleteInvestorApprovalInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  teamId: Scalars['String'];
};

/** The output of our delete `InvestorApproval` mutation. */
export type DeleteInvestorApprovalPayload = {
  __typename?: 'DeleteInvestorApprovalPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedInvestorApprovalNodeId?: Maybe<Scalars['ID']>;
  /** The `InvestorApproval` that was deleted by this mutation. */
  investorApproval?: Maybe<InvestorApproval>;
  /** An edge for our `InvestorApproval`. May be used by Relay 1. */
  investorApprovalEdge?: Maybe<InvestorApprovalsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `InvestorApproval`. */
  team?: Maybe<Team>;
  /** Reads a single `User` that is related to this `InvestorApproval`. */
  userByApprovedBy?: Maybe<User>;
};


/** The output of our delete `InvestorApproval` mutation. */
export type DeleteInvestorApprovalPayloadInvestorApprovalEdgeArgs = {
  orderBy?: InputMaybe<Array<InvestorApprovalsOrderBy>>;
};

/** All input for the `deleteInvestorInviteByNodeId` mutation. */
export type DeleteInvestorInviteByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `InvestorInvite` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteInvestorInvite` mutation. */
export type DeleteInvestorInviteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  inviteCode: Scalars['String'];
};

/** The output of our delete `InvestorInvite` mutation. */
export type DeleteInvestorInvitePayload = {
  __typename?: 'DeleteInvestorInvitePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedInvestorInviteNodeId?: Maybe<Scalars['ID']>;
  /** The `InvestorInvite` that was deleted by this mutation. */
  investorInvite?: Maybe<InvestorInvite>;
  /** An edge for our `InvestorInvite`. May be used by Relay 1. */
  investorInviteEdge?: Maybe<InvestorInvitesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `InvestorInvite`. */
  userByUsedBy?: Maybe<User>;
};


/** The output of our delete `InvestorInvite` mutation. */
export type DeleteInvestorInvitePayloadInvestorInviteEdgeArgs = {
  orderBy?: InputMaybe<Array<InvestorInvitesOrderBy>>;
};

/** All input for the `deleteLiquidityEventByNodeId` mutation. */
export type DeleteLiquidityEventByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LiquidityEvent` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteLiquidityEventFaqByNodeId` mutation. */
export type DeleteLiquidityEventFaqByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LiquidityEventFaq` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteLiquidityEventFaq` mutation. */
export type DeleteLiquidityEventFaqInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `LiquidityEventFaq` mutation. */
export type DeleteLiquidityEventFaqPayload = {
  __typename?: 'DeleteLiquidityEventFaqPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedLiquidityEventFaqNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `LiquidityEvent` that is related to this `LiquidityEventFaq`. */
  liquidityEvent?: Maybe<LiquidityEvent>;
  /** The `LiquidityEventFaq` that was deleted by this mutation. */
  liquidityEventFaq?: Maybe<LiquidityEventFaq>;
  /** An edge for our `LiquidityEventFaq`. May be used by Relay 1. */
  liquidityEventFaqEdge?: Maybe<LiquidityEventFaqsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `LiquidityEventFaq` mutation. */
export type DeleteLiquidityEventFaqPayloadLiquidityEventFaqEdgeArgs = {
  orderBy?: InputMaybe<Array<LiquidityEventFaqsOrderBy>>;
};

/** All input for the `deleteLiquidityEvent` mutation. */
export type DeleteLiquidityEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

/** The output of our delete `LiquidityEvent` mutation. */
export type DeleteLiquidityEventPayload = {
  __typename?: 'DeleteLiquidityEventPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Company` that is related to this `LiquidityEvent`. */
  company?: Maybe<Company>;
  deletedLiquidityEventNodeId?: Maybe<Scalars['ID']>;
  /** The `LiquidityEvent` that was deleted by this mutation. */
  liquidityEvent?: Maybe<LiquidityEvent>;
  /** An edge for our `LiquidityEvent`. May be used by Relay 1. */
  liquidityEventEdge?: Maybe<LiquidityEventsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `LiquidityEvent` mutation. */
export type DeleteLiquidityEventPayloadLiquidityEventEdgeArgs = {
  orderBy?: InputMaybe<Array<LiquidityEventsOrderBy>>;
};

/** All input for the `deleteNdaMetadatumByNodeId` mutation. */
export type DeleteNdaMetadatumByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `NdaMetadatum` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteNdaMetadatum` mutation. */
export type DeleteNdaMetadatumInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  liquidityEvent: Scalars['String'];
  teamId: Scalars['String'];
};

/** The output of our delete `NdaMetadatum` mutation. */
export type DeleteNdaMetadatumPayload = {
  __typename?: 'DeleteNdaMetadatumPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedNdaMetadatumNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `LiquidityEvent` that is related to this `NdaMetadatum`. */
  liquidityEventByLiquidityEvent?: Maybe<LiquidityEvent>;
  /** The `NdaMetadatum` that was deleted by this mutation. */
  ndaMetadatum?: Maybe<NdaMetadatum>;
  /** An edge for our `NdaMetadatum`. May be used by Relay 1. */
  ndaMetadatumEdge?: Maybe<NdaMetadataEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `NdaMetadatum`. */
  team?: Maybe<Team>;
};


/** The output of our delete `NdaMetadatum` mutation. */
export type DeleteNdaMetadatumPayloadNdaMetadatumEdgeArgs = {
  orderBy?: InputMaybe<Array<NdaMetadataOrderBy>>;
};

/** All input for the `deleteOverallAllocationByNodeId` mutation. */
export type DeleteOverallAllocationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `OverallAllocation` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteOverallAllocation` mutation. */
export type DeleteOverallAllocationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  seller: Scalars['String'];
  transactionGroupId: Scalars['String'];
};

/** The output of our delete `OverallAllocation` mutation. */
export type DeleteOverallAllocationPayload = {
  __typename?: 'DeleteOverallAllocationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedOverallAllocationNodeId?: Maybe<Scalars['ID']>;
  /** The `OverallAllocation` that was deleted by this mutation. */
  overallAllocation?: Maybe<OverallAllocation>;
  /** An edge for our `OverallAllocation`. May be used by Relay 1. */
  overallAllocationEdge?: Maybe<OverallAllocationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `OverallAllocation`. */
  teamBySeller?: Maybe<Team>;
  /** Reads a single `TransactionGroup` that is related to this `OverallAllocation`. */
  transactionGroup?: Maybe<TransactionGroup>;
};


/** The output of our delete `OverallAllocation` mutation. */
export type DeleteOverallAllocationPayloadOverallAllocationEdgeArgs = {
  orderBy?: InputMaybe<Array<OverallAllocationsOrderBy>>;
};

/** All input for the `deleteSellerOfferByNodeId` mutation. */
export type DeleteSellerOfferByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SellerOffer` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteSellerOffer` mutation. */
export type DeleteSellerOfferInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  grantId: Scalars['Int'];
  sellerId: Scalars['String'];
  transactionGroup: Scalars['String'];
};

/** The output of our delete `SellerOffer` mutation. */
export type DeleteSellerOfferPayload = {
  __typename?: 'DeleteSellerOfferPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedSellerOfferNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `EquityGrant` that is related to this `SellerOffer`. */
  grant?: Maybe<EquityGrant>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `SellerOffer`. */
  seller?: Maybe<Team>;
  /** The `SellerOffer` that was deleted by this mutation. */
  sellerOffer?: Maybe<SellerOffer>;
  /** An edge for our `SellerOffer`. May be used by Relay 1. */
  sellerOfferEdge?: Maybe<SellerOffersEdge>;
  /** Reads a single `TransactionGroup` that is related to this `SellerOffer`. */
  transactionGroupByTransactionGroup?: Maybe<TransactionGroup>;
};


/** The output of our delete `SellerOffer` mutation. */
export type DeleteSellerOfferPayloadSellerOfferEdgeArgs = {
  orderBy?: InputMaybe<Array<SellerOffersOrderBy>>;
};

/** All input for the `deleteSellerOnboardingByNodeId` mutation. */
export type DeleteSellerOnboardingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SellerOnboarding` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteSellerOnboarding` mutation. */
export type DeleteSellerOnboardingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  teamId: Scalars['String'];
};

/** The output of our delete `SellerOnboarding` mutation. */
export type DeleteSellerOnboardingPayload = {
  __typename?: 'DeleteSellerOnboardingPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedSellerOnboardingNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SellerOnboarding` that was deleted by this mutation. */
  sellerOnboarding?: Maybe<SellerOnboarding>;
  /** An edge for our `SellerOnboarding`. May be used by Relay 1. */
  sellerOnboardingEdge?: Maybe<SellerOnboardingsEdge>;
  /** Reads a single `Team` that is related to this `SellerOnboarding`. */
  team?: Maybe<Team>;
};


/** The output of our delete `SellerOnboarding` mutation. */
export type DeleteSellerOnboardingPayloadSellerOnboardingEdgeArgs = {
  orderBy?: InputMaybe<Array<SellerOnboardingsOrderBy>>;
};

/** All input for the `deleteStage2AccessByNodeId` mutation. */
export type DeleteStage2AccessByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Stage2Access` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteStage2Access` mutation. */
export type DeleteStage2AccessInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  liquidityEvent: Scalars['String'];
  teamId: Scalars['String'];
};

/** The output of our delete `Stage2Access` mutation. */
export type DeleteStage2AccessPayload = {
  __typename?: 'DeleteStage2AccessPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedStage2AccessNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `LiquidityEvent` that is related to this `Stage2Access`. */
  liquidityEventByLiquidityEvent?: Maybe<LiquidityEvent>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Stage2Access` that was deleted by this mutation. */
  stage2Access?: Maybe<Stage2Access>;
  /** An edge for our `Stage2Access`. May be used by Relay 1. */
  stage2AccessEdge?: Maybe<Stage2AccessesEdge>;
  /** Reads a single `Team` that is related to this `Stage2Access`. */
  team?: Maybe<Team>;
};


/** The output of our delete `Stage2Access` mutation. */
export type DeleteStage2AccessPayloadStage2AccessEdgeArgs = {
  orderBy?: InputMaybe<Array<Stage2AccessesOrderBy>>;
};

/** All input for the `deleteTaxRateByNodeId` mutation. */
export type DeleteTaxRateByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TaxRate` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTaxRate` mutation. */
export type DeleteTaxRateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  equityType: Scalars['String'];
  fiscalResidency: IsoCountry;
  moreThanXMonthsEmployed: Scalars['Int'];
  moreThanXMonthsHeldGrant: Scalars['Int'];
};

/** The output of our delete `TaxRate` mutation. */
export type DeleteTaxRatePayload = {
  __typename?: 'DeleteTaxRatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTaxRateNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `EquityType` that is related to this `TaxRate`. */
  equityTypeByEquityType?: Maybe<EquityType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TaxRate` that was deleted by this mutation. */
  taxRate?: Maybe<TaxRate>;
  /** An edge for our `TaxRate`. May be used by Relay 1. */
  taxRateEdge?: Maybe<TaxRatesEdge>;
};


/** The output of our delete `TaxRate` mutation. */
export type DeleteTaxRatePayloadTaxRateEdgeArgs = {
  orderBy?: InputMaybe<Array<TaxRatesOrderBy>>;
};

/** All input for the `deleteTeamByNodeId` mutation. */
export type DeleteTeamByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Team` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTeam` mutation. */
export type DeleteTeamInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

/** All input for the `deleteTeamMembershipByNodeId` mutation. */
export type DeleteTeamMembershipByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TeamMembership` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTeamMembership` mutation. */
export type DeleteTeamMembershipInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

/** The output of our delete `TeamMembership` mutation. */
export type DeleteTeamMembershipPayload = {
  __typename?: 'DeleteTeamMembershipPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTeamMembershipNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `TeamMembership`. */
  team?: Maybe<Team>;
  /** The `TeamMembership` that was deleted by this mutation. */
  teamMembership?: Maybe<TeamMembership>;
  /** An edge for our `TeamMembership`. May be used by Relay 1. */
  teamMembershipEdge?: Maybe<TeamMembershipsEdge>;
  /** Reads a single `User` that is related to this `TeamMembership`. */
  user?: Maybe<User>;
};


/** The output of our delete `TeamMembership` mutation. */
export type DeleteTeamMembershipPayloadTeamMembershipEdgeArgs = {
  orderBy?: InputMaybe<Array<TeamMembershipsOrderBy>>;
};

/** The output of our delete `Team` mutation. */
export type DeleteTeamPayload = {
  __typename?: 'DeleteTeamPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTeamNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Team` that was deleted by this mutation. */
  team?: Maybe<Team>;
  /** An edge for our `Team`. May be used by Relay 1. */
  teamEdge?: Maybe<TeamsEdge>;
};


/** The output of our delete `Team` mutation. */
export type DeleteTeamPayloadTeamEdgeArgs = {
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
};

/** All input for the `deleteTransactionAllowanceByNodeId` mutation. */
export type DeleteTransactionAllowanceByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TransactionAllowance` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTransactionAllowance` mutation. */
export type DeleteTransactionAllowanceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  teamId: Scalars['String'];
  transactionGroup: Scalars['String'];
};

/** The output of our delete `TransactionAllowance` mutation. */
export type DeleteTransactionAllowancePayload = {
  __typename?: 'DeleteTransactionAllowancePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTransactionAllowanceNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `TransactionAllowance`. */
  team?: Maybe<Team>;
  /** The `TransactionAllowance` that was deleted by this mutation. */
  transactionAllowance?: Maybe<TransactionAllowance>;
  /** An edge for our `TransactionAllowance`. May be used by Relay 1. */
  transactionAllowanceEdge?: Maybe<TransactionAllowancesEdge>;
  /** Reads a single `TransactionGroup` that is related to this `TransactionAllowance`. */
  transactionGroupByTransactionGroup?: Maybe<TransactionGroup>;
};


/** The output of our delete `TransactionAllowance` mutation. */
export type DeleteTransactionAllowancePayloadTransactionAllowanceEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionAllowancesOrderBy>>;
};

/** All input for the `deleteTransactionApprovalByNodeId` mutation. */
export type DeleteTransactionApprovalByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TransactionApproval` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTransactionApproval` mutation. */
export type DeleteTransactionApprovalInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  transactionId: Scalars['Int'];
};

/** The output of our delete `TransactionApproval` mutation. */
export type DeleteTransactionApprovalPayload = {
  __typename?: 'DeleteTransactionApprovalPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTransactionApprovalNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Transaction` that is related to this `TransactionApproval`. */
  transaction?: Maybe<Transaction>;
  /** The `TransactionApproval` that was deleted by this mutation. */
  transactionApproval?: Maybe<TransactionApproval>;
  /** An edge for our `TransactionApproval`. May be used by Relay 1. */
  transactionApprovalEdge?: Maybe<TransactionApprovalsEdge>;
  /** Reads a single `User` that is related to this `TransactionApproval`. */
  userByApprovedBy?: Maybe<User>;
};


/** The output of our delete `TransactionApproval` mutation. */
export type DeleteTransactionApprovalPayloadTransactionApprovalEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionApprovalsOrderBy>>;
};

/** All input for the `deleteTransactionByNodeId` mutation. */
export type DeleteTransactionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Transaction` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTransactionDocumentByNodeId` mutation. */
export type DeleteTransactionDocumentByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TransactionDocument` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTransactionDocumentFieldByNodeId` mutation. */
export type DeleteTransactionDocumentFieldByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TransactionDocumentField` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTransactionDocumentField` mutation. */
export type DeleteTransactionDocumentFieldInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  documentId: Scalars['String'];
  variable: TransactionDocumentFieldType;
  xStart: Scalars['BigFloat'];
  yStart: Scalars['BigFloat'];
};

/** The output of our delete `TransactionDocumentField` mutation. */
export type DeleteTransactionDocumentFieldPayload = {
  __typename?: 'DeleteTransactionDocumentFieldPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTransactionDocumentFieldNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `TransactionDocument` that is related to this `TransactionDocumentField`. */
  document?: Maybe<TransactionDocument>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TransactionDocumentField` that was deleted by this mutation. */
  transactionDocumentField?: Maybe<TransactionDocumentField>;
  /** An edge for our `TransactionDocumentField`. May be used by Relay 1. */
  transactionDocumentFieldEdge?: Maybe<TransactionDocumentFieldsEdge>;
};


/** The output of our delete `TransactionDocumentField` mutation. */
export type DeleteTransactionDocumentFieldPayloadTransactionDocumentFieldEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionDocumentFieldsOrderBy>>;
};

/** All input for the `deleteTransactionDocument` mutation. */
export type DeleteTransactionDocumentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

/** The output of our delete `TransactionDocument` mutation. */
export type DeleteTransactionDocumentPayload = {
  __typename?: 'DeleteTransactionDocumentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTransactionDocumentNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `EquityTypeInfo` that is related to this `TransactionDocument`. */
  equityTypeInfoByEquityScheme?: Maybe<EquityTypeInfo>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TransactionDocument` that was deleted by this mutation. */
  transactionDocument?: Maybe<TransactionDocument>;
  /** An edge for our `TransactionDocument`. May be used by Relay 1. */
  transactionDocumentEdge?: Maybe<TransactionDocumentsEdge>;
  /** Reads a single `TransactionGroup` that is related to this `TransactionDocument`. */
  transactionGroup?: Maybe<TransactionGroup>;
};


/** The output of our delete `TransactionDocument` mutation. */
export type DeleteTransactionDocumentPayloadTransactionDocumentEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionDocumentsOrderBy>>;
};

/** All input for the `deleteTransactionDocumentSignatureByNodeId` mutation. */
export type DeleteTransactionDocumentSignatureByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TransactionDocumentSignature` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTransactionDocumentSignature` mutation. */
export type DeleteTransactionDocumentSignatureInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  documentId: Scalars['String'];
  userId: Scalars['String'];
};

/** The output of our delete `TransactionDocumentSignature` mutation. */
export type DeleteTransactionDocumentSignaturePayload = {
  __typename?: 'DeleteTransactionDocumentSignaturePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTransactionDocumentSignatureNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `TransactionDocument` that is related to this `TransactionDocumentSignature`. */
  document?: Maybe<TransactionDocument>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TransactionDocumentSignature` that was deleted by this mutation. */
  transactionDocumentSignature?: Maybe<TransactionDocumentSignature>;
  /** An edge for our `TransactionDocumentSignature`. May be used by Relay 1. */
  transactionDocumentSignatureEdge?: Maybe<TransactionDocumentSignaturesEdge>;
  /** Reads a single `User` that is related to this `TransactionDocumentSignature`. */
  user?: Maybe<User>;
};


/** The output of our delete `TransactionDocumentSignature` mutation. */
export type DeleteTransactionDocumentSignaturePayloadTransactionDocumentSignatureEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionDocumentSignaturesOrderBy>>;
};

/** All input for the `deleteTransactionFeeByNodeId` mutation. */
export type DeleteTransactionFeeByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TransactionFee` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTransactionFee` mutation. */
export type DeleteTransactionFeeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  transactionGroup: Scalars['String'];
};

/** The output of our delete `TransactionFee` mutation. */
export type DeleteTransactionFeePayload = {
  __typename?: 'DeleteTransactionFeePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTransactionFeeNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `TransactionFee`. */
  teamByReciever?: Maybe<Team>;
  /** The `TransactionFee` that was deleted by this mutation. */
  transactionFee?: Maybe<TransactionFee>;
  /** An edge for our `TransactionFee`. May be used by Relay 1. */
  transactionFeeEdge?: Maybe<TransactionFeesEdge>;
  /** Reads a single `TransactionGroup` that is related to this `TransactionFee`. */
  transactionGroupByTransactionGroup?: Maybe<TransactionGroup>;
};


/** The output of our delete `TransactionFee` mutation. */
export type DeleteTransactionFeePayloadTransactionFeeEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionFeesOrderBy>>;
};

/** All input for the `deleteTransactionGroupByNodeId` mutation. */
export type DeleteTransactionGroupByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TransactionGroup` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTransactionGroup` mutation. */
export type DeleteTransactionGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

/** The output of our delete `TransactionGroup` mutation. */
export type DeleteTransactionGroupPayload = {
  __typename?: 'DeleteTransactionGroupPayload';
  /** Reads a single `Team` that is related to this `TransactionGroup`. */
  buyerTeam?: Maybe<Team>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Company` that is related to this `TransactionGroup`. */
  company?: Maybe<Company>;
  deletedTransactionGroupNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `LiquidityEvent` that is related to this `TransactionGroup`. */
  liquidityEvent?: Maybe<LiquidityEvent>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TransactionGroup` that was deleted by this mutation. */
  transactionGroup?: Maybe<TransactionGroup>;
  /** An edge for our `TransactionGroup`. May be used by Relay 1. */
  transactionGroupEdge?: Maybe<TransactionGroupsEdge>;
};


/** The output of our delete `TransactionGroup` mutation. */
export type DeleteTransactionGroupPayloadTransactionGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionGroupsOrderBy>>;
};

/** All input for the `deleteTransaction` mutation. */
export type DeleteTransactionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** All input for the `deleteTransactionNoteByNodeId` mutation. */
export type DeleteTransactionNoteByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TransactionNote` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTransactionNote` mutation. */
export type DeleteTransactionNoteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  transactionId: Scalars['Int'];
};

/** The output of our delete `TransactionNote` mutation. */
export type DeleteTransactionNotePayload = {
  __typename?: 'DeleteTransactionNotePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTransactionNoteNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Transaction` that is related to this `TransactionNote`. */
  transaction?: Maybe<Transaction>;
  /** The `TransactionNote` that was deleted by this mutation. */
  transactionNote?: Maybe<TransactionNote>;
  /** An edge for our `TransactionNote`. May be used by Relay 1. */
  transactionNoteEdge?: Maybe<TransactionNotesEdge>;
  /** Reads a single `User` that is related to this `TransactionNote`. */
  user?: Maybe<User>;
};


/** The output of our delete `TransactionNote` mutation. */
export type DeleteTransactionNotePayloadTransactionNoteEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionNotesOrderBy>>;
};

/** The output of our delete `Transaction` mutation. */
export type DeleteTransactionPayload = {
  __typename?: 'DeleteTransactionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTransactionNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `EquityTypeInfo` that is related to this `Transaction`. */
  equityTypeInfoByPartyAEquityIncreaseScheme?: Maybe<EquityTypeInfo>;
  /** Reads a single `EquityGrant` that is related to this `Transaction`. */
  originalGrant?: Maybe<EquityGrant>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `Transaction`. */
  teamByPartyA?: Maybe<Team>;
  /** Reads a single `Team` that is related to this `Transaction`. */
  teamByPartyB?: Maybe<Team>;
  /** The `Transaction` that was deleted by this mutation. */
  transaction?: Maybe<Transaction>;
  /** Reads a single `Transaction` that is related to this `Transaction`. */
  transactionByPartyAEquityTransactionFrom?: Maybe<Transaction>;
  /** An edge for our `Transaction`. May be used by Relay 1. */
  transactionEdge?: Maybe<TransactionsEdge>;
  /** Reads a single `TransactionGroup` that is related to this `Transaction`. */
  transactionGroup?: Maybe<TransactionGroup>;
};


/** The output of our delete `Transaction` mutation. */
export type DeleteTransactionPayloadTransactionEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
};

/** All input for the `deleteTransactionTaxRateByNodeId` mutation. */
export type DeleteTransactionTaxRateByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TransactionTaxRate` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTransactionTaxRate` mutation. */
export type DeleteTransactionTaxRateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  equityId: Scalars['Int'];
  transactionGroup: Scalars['String'];
};

/** The output of our delete `TransactionTaxRate` mutation. */
export type DeleteTransactionTaxRatePayload = {
  __typename?: 'DeleteTransactionTaxRatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTransactionTaxRateNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `EquityGrant` that is related to this `TransactionTaxRate`. */
  equity?: Maybe<EquityGrant>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `TransactionGroup` that is related to this `TransactionTaxRate`. */
  transactionGroupByTransactionGroup?: Maybe<TransactionGroup>;
  /** The `TransactionTaxRate` that was deleted by this mutation. */
  transactionTaxRate?: Maybe<TransactionTaxRate>;
  /** An edge for our `TransactionTaxRate`. May be used by Relay 1. */
  transactionTaxRateEdge?: Maybe<TransactionTaxRatesEdge>;
};


/** The output of our delete `TransactionTaxRate` mutation. */
export type DeleteTransactionTaxRatePayloadTransactionTaxRateEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionTaxRatesOrderBy>>;
};

/** All input for the `deleteUserByNodeId` mutation. */
export type DeleteUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `User` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteUser` mutation. */
export type DeleteUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

/** The output of our delete `User` mutation. */
export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedUserNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `User` that was deleted by this mutation. */
  user?: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our delete `User` mutation. */
export type DeleteUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** All input for the `deleteVestingByNodeId` mutation. */
export type DeleteVestingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Vesting` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteVesting` mutation. */
export type DeleteVestingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

/** The output of our delete `Vesting` mutation. */
export type DeleteVestingPayload = {
  __typename?: 'DeleteVestingPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedVestingNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Vesting` that was deleted by this mutation. */
  vesting?: Maybe<Vesting>;
  /** An edge for our `Vesting`. May be used by Relay 1. */
  vestingEdge?: Maybe<VestingsEdge>;
};


/** The output of our delete `Vesting` mutation. */
export type DeleteVestingPayloadVestingEdgeArgs = {
  orderBy?: InputMaybe<Array<VestingsOrderBy>>;
};

export type Email = {
  __typename?: 'Email';
  email: Scalars['JSON'];
  emailType: EmailType;
  id: Scalars['String'];
  sent?: Maybe<Scalars['Datetime']>;
  /** Reads a single `User` that is related to this `Email`. */
  user?: Maybe<User>;
  userId: Scalars['String'];
};

/** A condition to be used against `Email` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type EmailCondition = {
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `emailType` field. */
  emailType?: InputMaybe<EmailType>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `sent` field. */
  sent?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `Email` object types. All fields are combined with a logical ‘and.’ */
export type EmailFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<EmailFilter>>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `emailType` field. */
  emailType?: InputMaybe<EmailTypeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<EmailFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<EmailFilter>>;
  /** Filter by the object’s `sent` field. */
  sent?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `Email` */
export type EmailInput = {
  email: Scalars['JSON'];
  emailType?: InputMaybe<EmailType>;
  id: Scalars['String'];
  sent?: InputMaybe<Scalars['Datetime']>;
  userId: Scalars['String'];
};

export enum EmailType {
  DocumentsReadyForSigning = 'DOCUMENTS_READY_FOR_SIGNING',
  None = 'NONE'
}

/** A filter to be used against EmailType fields. All fields are combined with a logical ‘and.’ */
export type EmailTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<EmailType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<EmailType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<EmailType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<EmailType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<EmailType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<EmailType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<EmailType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<EmailType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<EmailType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<EmailType>>;
};

/** A connection to a list of `Email` values. */
export type EmailsConnection = {
  __typename?: 'EmailsConnection';
  /** A list of edges which contains the `Email` and cursor to aid in pagination. */
  edges: Array<EmailsEdge>;
  /** A list of `Email` objects. */
  nodes: Array<Email>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Email` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Email` edge in the connection. */
export type EmailsEdge = {
  __typename?: 'EmailsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Email` at the end of the edge. */
  node: Email;
};

/** Methods to use when ordering `Email`. */
export enum EmailsOrderBy {
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  EmailTypeAsc = 'EMAIL_TYPE_ASC',
  EmailTypeDesc = 'EMAIL_TYPE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  SentAsc = 'SENT_ASC',
  SentDesc = 'SENT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type Employment = Node & {
  __typename?: 'Employment';
  /** Reads a single `Team` that is related to this `Employment`. */
  companyTeam?: Maybe<Team>;
  companyTeamId: Scalars['String'];
  /** Reads a single `Team` that is related to this `Employment`. */
  employeeTeam?: Maybe<Team>;
  employeeTeamId: Scalars['String'];
  employmentEnd?: Maybe<Scalars['Datetime']>;
  employmentStart?: Maybe<Scalars['Datetime']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

/**
 * A condition to be used against `Employment` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type EmploymentCondition = {
  /** Checks for equality with the object’s `companyTeamId` field. */
  companyTeamId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `employeeTeamId` field. */
  employeeTeamId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `employmentEnd` field. */
  employmentEnd?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `employmentStart` field. */
  employmentStart?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `Employment` object types. All fields are combined with a logical ‘and.’ */
export type EmploymentFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<EmploymentFilter>>;
  /** Filter by the object’s `companyTeamId` field. */
  companyTeamId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `employeeTeamId` field. */
  employeeTeamId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `employmentEnd` field. */
  employmentEnd?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `employmentStart` field. */
  employmentStart?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<EmploymentFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<EmploymentFilter>>;
};

/** An input for mutations affecting `Employment` */
export type EmploymentInput = {
  companyTeamId: Scalars['String'];
  employeeTeamId: Scalars['String'];
  employmentEnd?: InputMaybe<Scalars['Datetime']>;
  employmentStart?: InputMaybe<Scalars['Datetime']>;
};

/** Represents an update to a `Employment`. Fields that are set will be updated. */
export type EmploymentPatch = {
  companyTeamId?: InputMaybe<Scalars['String']>;
  employeeTeamId?: InputMaybe<Scalars['String']>;
  employmentEnd?: InputMaybe<Scalars['Datetime']>;
  employmentStart?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `Employment` values. */
export type EmploymentsConnection = {
  __typename?: 'EmploymentsConnection';
  /** A list of edges which contains the `Employment` and cursor to aid in pagination. */
  edges: Array<EmploymentsEdge>;
  /** A list of `Employment` objects. */
  nodes: Array<Employment>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Employment` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Employment` edge in the connection. */
export type EmploymentsEdge = {
  __typename?: 'EmploymentsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Employment` at the end of the edge. */
  node: Employment;
};

/** Methods to use when ordering `Employment`. */
export enum EmploymentsOrderBy {
  CompanyTeamIdAsc = 'COMPANY_TEAM_ID_ASC',
  CompanyTeamIdDesc = 'COMPANY_TEAM_ID_DESC',
  EmployeeTeamIdAsc = 'EMPLOYEE_TEAM_ID_ASC',
  EmployeeTeamIdDesc = 'EMPLOYEE_TEAM_ID_DESC',
  EmploymentEndAsc = 'EMPLOYMENT_END_ASC',
  EmploymentEndDesc = 'EMPLOYMENT_END_DESC',
  EmploymentStartAsc = 'EMPLOYMENT_START_ASC',
  EmploymentStartDesc = 'EMPLOYMENT_START_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type Equity = {
  __typename?: 'Equity';
  amount?: Maybe<Scalars['Int']>;
  equityScheme?: Maybe<Scalars['String']>;
  transactionFrom?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `Equity` object types. All fields are combined with a logical ‘and.’ */
export type EquityFilter = {
  /** Filter by the object’s `amount` field. */
  amount?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<EquityFilter>>;
  /** Filter by the object’s `equityScheme` field. */
  equityScheme?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<EquityFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<EquityFilter>>;
  /** Filter by the object’s `transactionFrom` field. */
  transactionFrom?: InputMaybe<IntFilter>;
};

export type EquityGrant = Node & {
  __typename?: 'EquityGrant';
  equityAmount?: Maybe<Scalars['Int']>;
  equityScheme?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `EquitySummary`. */
  equitySummariesByOriginalGrant: EquitySummariesConnection;
  /** Reads a single `EquityTypeInfo` that is related to this `EquityGrant`. */
  equityTypeInfoByEquityScheme?: Maybe<EquityTypeInfo>;
  grantDate?: Maybe<Scalars['Datetime']>;
  /** Reads a single `Team` that is related to this `EquityGrant`. */
  holderTeam?: Maybe<Team>;
  holderTeamId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `SellerOffer`. */
  sellerOffersByGrantId: SellerOffersConnection;
  taxDetails?: Maybe<TaxVariable>;
  /** Reads and enables pagination through a set of `TransactionSummaryExerciseSale`. */
  transactionSummaryExerciseSalesByEquityGrant: TransactionSummaryExerciseSalesConnection;
  /** Reads and enables pagination through a set of `TransactionTaxRate`. */
  transactionTaxRatesByEquityId: TransactionTaxRatesConnection;
  /** Reads and enables pagination through a set of `Transaction`. */
  transactionsByOriginalGrantId: TransactionsConnection;
};


export type EquityGrantEquitySummariesByOriginalGrantArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EquitySummaryCondition>;
  filter?: InputMaybe<EquitySummaryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EquitySummariesOrderBy>>;
};


export type EquityGrantSellerOffersByGrantIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SellerOfferCondition>;
  filter?: InputMaybe<SellerOfferFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SellerOffersOrderBy>>;
};


export type EquityGrantTaxDetailsArgs = {
  transactionGroup?: InputMaybe<Scalars['String']>;
};


export type EquityGrantTransactionSummaryExerciseSalesByEquityGrantArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionSummaryExerciseSaleCondition>;
  filter?: InputMaybe<TransactionSummaryExerciseSaleFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionSummaryExerciseSalesOrderBy>>;
};


export type EquityGrantTransactionTaxRatesByEquityIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionTaxRateCondition>;
  filter?: InputMaybe<TransactionTaxRateFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionTaxRatesOrderBy>>;
};


export type EquityGrantTransactionsByOriginalGrantIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionCondition>;
  filter?: InputMaybe<TransactionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
};

/**
 * A condition to be used against `EquityGrant` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type EquityGrantCondition = {
  /** Checks for equality with the object’s `equityAmount` field. */
  equityAmount?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `equityScheme` field. */
  equityScheme?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `grantDate` field. */
  grantDate?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `holderTeamId` field. */
  holderTeamId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `EquityGrant` object types. All fields are combined with a logical ‘and.’ */
export type EquityGrantFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<EquityGrantFilter>>;
  /** Filter by the object’s `equityAmount` field. */
  equityAmount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `equityScheme` field. */
  equityScheme?: InputMaybe<StringFilter>;
  /** Filter by the object’s `grantDate` field. */
  grantDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `holderTeamId` field. */
  holderTeamId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<EquityGrantFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<EquityGrantFilter>>;
};

/** An input for mutations affecting `EquityGrant` */
export type EquityGrantInput = {
  equityAmount?: InputMaybe<Scalars['Int']>;
  equityScheme?: InputMaybe<Scalars['String']>;
  grantDate?: InputMaybe<Scalars['Datetime']>;
  holderTeamId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `EquityGrant`. Fields that are set will be updated. */
export type EquityGrantPatch = {
  equityAmount?: InputMaybe<Scalars['Int']>;
  equityScheme?: InputMaybe<Scalars['String']>;
  grantDate?: InputMaybe<Scalars['Datetime']>;
  holderTeamId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `EquityGrant` values. */
export type EquityGrantsConnection = {
  __typename?: 'EquityGrantsConnection';
  /** A list of edges which contains the `EquityGrant` and cursor to aid in pagination. */
  edges: Array<EquityGrantsEdge>;
  /** A list of `EquityGrant` objects. */
  nodes: Array<EquityGrant>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EquityGrant` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `EquityGrant` edge in the connection. */
export type EquityGrantsEdge = {
  __typename?: 'EquityGrantsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `EquityGrant` at the end of the edge. */
  node: EquityGrant;
};

/** Methods to use when ordering `EquityGrant`. */
export enum EquityGrantsOrderBy {
  EquityAmountAsc = 'EQUITY_AMOUNT_ASC',
  EquityAmountDesc = 'EQUITY_AMOUNT_DESC',
  EquitySchemeAsc = 'EQUITY_SCHEME_ASC',
  EquitySchemeDesc = 'EQUITY_SCHEME_DESC',
  GrantDateAsc = 'GRANT_DATE_ASC',
  GrantDateDesc = 'GRANT_DATE_DESC',
  HolderTeamIdAsc = 'HOLDER_TEAM_ID_ASC',
  HolderTeamIdDesc = 'HOLDER_TEAM_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** An input for mutations affecting `Equity` */
export type EquityInput = {
  amount?: InputMaybe<Scalars['Int']>;
  equityScheme?: InputMaybe<Scalars['String']>;
  transactionFrom?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `EquitySummary` values. */
export type EquitySummariesConnection = {
  __typename?: 'EquitySummariesConnection';
  /** A list of edges which contains the `EquitySummary` and cursor to aid in pagination. */
  edges: Array<EquitySummariesEdge>;
  /** A list of `EquitySummary` objects. */
  nodes: Array<EquitySummary>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EquitySummary` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `EquitySummary` edge in the connection. */
export type EquitySummariesEdge = {
  __typename?: 'EquitySummariesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `EquitySummary` at the end of the edge. */
  node: EquitySummary;
};

/** Methods to use when ordering `EquitySummary`. */
export enum EquitySummariesOrderBy {
  AmountAvailableForSaleAsc = 'AMOUNT_AVAILABLE_FOR_SALE_ASC',
  AmountAvailableForSaleDesc = 'AMOUNT_AVAILABLE_FOR_SALE_DESC',
  AmountLapsedOrExpiredAsc = 'AMOUNT_LAPSED_OR_EXPIRED_ASC',
  AmountLapsedOrExpiredDesc = 'AMOUNT_LAPSED_OR_EXPIRED_DESC',
  AmountOriginallyGrantedAsc = 'AMOUNT_ORIGINALLY_GRANTED_ASC',
  AmountOriginallyGrantedDesc = 'AMOUNT_ORIGINALLY_GRANTED_DESC',
  AmountOwnedAsc = 'AMOUNT_OWNED_ASC',
  AmountOwnedDesc = 'AMOUNT_OWNED_DESC',
  AmountPreviouslyExercisedAsc = 'AMOUNT_PREVIOUSLY_EXERCISED_ASC',
  AmountPreviouslyExercisedDesc = 'AMOUNT_PREVIOUSLY_EXERCISED_DESC',
  AmountSoldAsc = 'AMOUNT_SOLD_ASC',
  AmountSoldDesc = 'AMOUNT_SOLD_DESC',
  AmountThatCanBeExercisedNowAsc = 'AMOUNT_THAT_CAN_BE_EXERCISED_NOW_ASC',
  AmountThatCanBeExercisedNowDesc = 'AMOUNT_THAT_CAN_BE_EXERCISED_NOW_DESC',
  AmountThatWillBeExercisableInTheFutureAsc = 'AMOUNT_THAT_WILL_BE_EXERCISABLE_IN_THE_FUTURE_ASC',
  AmountThatWillBeExercisableInTheFutureDesc = 'AMOUNT_THAT_WILL_BE_EXERCISABLE_IN_THE_FUTURE_DESC',
  CompanyAsc = 'COMPANY_ASC',
  CompanyDesc = 'COMPANY_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  OriginalGrantAsc = 'ORIGINAL_GRANT_ASC',
  OriginalGrantDesc = 'ORIGINAL_GRANT_DESC'
}

/** This is a dummy table for postgraphile, don't write/read from it \n @omit all */
export type EquitySummary = {
  __typename?: 'EquitySummary';
  amountAvailableForSale?: Maybe<Scalars['Int']>;
  amountLapsedOrExpired?: Maybe<Scalars['Int']>;
  amountOriginallyGranted?: Maybe<Scalars['Int']>;
  amountOwned?: Maybe<Scalars['Int']>;
  amountPreviouslyExercised?: Maybe<Scalars['Int']>;
  amountSold?: Maybe<Scalars['Int']>;
  amountThatCanBeExercisedNow?: Maybe<Scalars['Int']>;
  amountThatWillBeExercisableInTheFuture?: Maybe<Scalars['Int']>;
  company?: Maybe<Scalars['String']>;
  /** Reads a single `EquityGrant` that is related to this `EquitySummary`. */
  equityGrantByOriginalGrant?: Maybe<EquityGrant>;
  /** Reads a single `EquityTypeInfo` that is related to this `EquitySummary`. */
  equityTypeInfoById?: Maybe<EquityTypeInfo>;
  id?: Maybe<Scalars['String']>;
  originalGrant?: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `EquitySummary` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type EquitySummaryCondition = {
  /** Checks for equality with the object’s `amountAvailableForSale` field. */
  amountAvailableForSale?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `amountLapsedOrExpired` field. */
  amountLapsedOrExpired?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `amountOriginallyGranted` field. */
  amountOriginallyGranted?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `amountOwned` field. */
  amountOwned?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `amountPreviouslyExercised` field. */
  amountPreviouslyExercised?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `amountSold` field. */
  amountSold?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `amountThatCanBeExercisedNow` field. */
  amountThatCanBeExercisedNow?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `amountThatWillBeExercisableInTheFuture` field. */
  amountThatWillBeExercisableInTheFuture?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `company` field. */
  company?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `originalGrant` field. */
  originalGrant?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `EquitySummary` object types. All fields are combined with a logical ‘and.’ */
export type EquitySummaryFilter = {
  /** Filter by the object’s `amountAvailableForSale` field. */
  amountAvailableForSale?: InputMaybe<IntFilter>;
  /** Filter by the object’s `amountLapsedOrExpired` field. */
  amountLapsedOrExpired?: InputMaybe<IntFilter>;
  /** Filter by the object’s `amountOriginallyGranted` field. */
  amountOriginallyGranted?: InputMaybe<IntFilter>;
  /** Filter by the object’s `amountOwned` field. */
  amountOwned?: InputMaybe<IntFilter>;
  /** Filter by the object’s `amountPreviouslyExercised` field. */
  amountPreviouslyExercised?: InputMaybe<IntFilter>;
  /** Filter by the object’s `amountSold` field. */
  amountSold?: InputMaybe<IntFilter>;
  /** Filter by the object’s `amountThatCanBeExercisedNow` field. */
  amountThatCanBeExercisedNow?: InputMaybe<IntFilter>;
  /** Filter by the object’s `amountThatWillBeExercisableInTheFuture` field. */
  amountThatWillBeExercisableInTheFuture?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<EquitySummaryFilter>>;
  /** Filter by the object’s `company` field. */
  company?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<EquitySummaryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<EquitySummaryFilter>>;
  /** Filter by the object’s `originalGrant` field. */
  originalGrant?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `EquitySummary` */
export type EquitySummaryInput = {
  amountAvailableForSale?: InputMaybe<Scalars['Int']>;
  amountLapsedOrExpired?: InputMaybe<Scalars['Int']>;
  amountOriginallyGranted?: InputMaybe<Scalars['Int']>;
  amountOwned?: InputMaybe<Scalars['Int']>;
  amountPreviouslyExercised?: InputMaybe<Scalars['Int']>;
  amountSold?: InputMaybe<Scalars['Int']>;
  amountThatCanBeExercisedNow?: InputMaybe<Scalars['Int']>;
  amountThatWillBeExercisableInTheFuture?: InputMaybe<Scalars['Int']>;
  company?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  originalGrant?: InputMaybe<Scalars['Int']>;
};

export type EquityType = Node & {
  __typename?: 'EquityType';
  /** Reads and enables pagination through a set of `EquityTypeInfo`. */
  equityTypeInfosByTypeOfEquity: EquityTypeInfosConnection;
  id: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `TaxRate`. */
  taxRatesByEquityType: TaxRatesConnection;
};


export type EquityTypeEquityTypeInfosByTypeOfEquityArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EquityTypeInfoCondition>;
  filter?: InputMaybe<EquityTypeInfoFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EquityTypeInfosOrderBy>>;
};


export type EquityTypeTaxRatesByEquityTypeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TaxRateCondition>;
  filter?: InputMaybe<TaxRateFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TaxRatesOrderBy>>;
};

/**
 * A condition to be used against `EquityType` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type EquityTypeCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `EquityType` object types. All fields are combined with a logical ‘and.’ */
export type EquityTypeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<EquityTypeFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<EquityTypeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<EquityTypeFilter>>;
};

export type EquityTypeInfo = Node & {
  __typename?: 'EquityTypeInfo';
  company: Scalars['String'];
  convertsTo?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `EquityGrant`. */
  equityGrantsByEquityScheme: EquityGrantsConnection;
  /** Reads and enables pagination through a set of `EquitySummary`. */
  equitySummariesById: EquitySummariesConnection;
  /** Reads a single `EquityType` that is related to this `EquityTypeInfo`. */
  equityTypeByTypeOfEquity?: Maybe<EquityType>;
  /** Reads a single `EquityTypeInfo` that is related to this `EquityTypeInfo`. */
  equityTypeInfoByConvertsTo?: Maybe<EquityTypeInfo>;
  /** Reads and enables pagination through a set of `EquityTypeInfo`. */
  equityTypeInfosByConvertsTo: EquityTypeInfosConnection;
  id: Scalars['String'];
  marketValueAtGrant?: Maybe<Currency>;
  minSalePrice?: Maybe<Currency>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  planName: Scalars['String'];
  strikePrice?: Maybe<Currency>;
  subscriptionPrice?: Maybe<Currency>;
  /** Reads and enables pagination through a set of `TransactionDetail`. */
  transactionDetailsByScheme: TransactionDetailsConnection;
  /** Reads and enables pagination through a set of `TransactionDocument`. */
  transactionDocumentsByEquityScheme: TransactionDocumentsConnection;
  /** Reads and enables pagination through a set of `TransactionSummaryExerciseSale`. */
  transactionSummaryExerciseSalesByEquityScheme: TransactionSummaryExerciseSalesConnection;
  /** Reads and enables pagination through a set of `Transaction`. */
  transactionsByPartyAEquityIncreaseScheme: TransactionsConnection;
  typeOfEquity: Scalars['String'];
};


export type EquityTypeInfoEquityGrantsByEquitySchemeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EquityGrantCondition>;
  filter?: InputMaybe<EquityGrantFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EquityGrantsOrderBy>>;
};


export type EquityTypeInfoEquitySummariesByIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EquitySummaryCondition>;
  filter?: InputMaybe<EquitySummaryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EquitySummariesOrderBy>>;
};


export type EquityTypeInfoEquityTypeInfosByConvertsToArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EquityTypeInfoCondition>;
  filter?: InputMaybe<EquityTypeInfoFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EquityTypeInfosOrderBy>>;
};


export type EquityTypeInfoTransactionDetailsBySchemeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionDetailCondition>;
  filter?: InputMaybe<TransactionDetailFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionDetailsOrderBy>>;
};


export type EquityTypeInfoTransactionDocumentsByEquitySchemeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionDocumentCondition>;
  filter?: InputMaybe<TransactionDocumentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionDocumentsOrderBy>>;
};


export type EquityTypeInfoTransactionSummaryExerciseSalesByEquitySchemeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionSummaryExerciseSaleCondition>;
  filter?: InputMaybe<TransactionSummaryExerciseSaleFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionSummaryExerciseSalesOrderBy>>;
};


export type EquityTypeInfoTransactionsByPartyAEquityIncreaseSchemeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionCondition>;
  filter?: InputMaybe<TransactionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
};

/**
 * A condition to be used against `EquityTypeInfo` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type EquityTypeInfoCondition = {
  /** Checks for equality with the object’s `company` field. */
  company?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `convertsTo` field. */
  convertsTo?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `marketValueAtGrant` field. */
  marketValueAtGrant?: InputMaybe<CurrencyInput>;
  /** Checks for equality with the object’s `minSalePrice` field. */
  minSalePrice?: InputMaybe<CurrencyInput>;
  /** Checks for equality with the object’s `planName` field. */
  planName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `strikePrice` field. */
  strikePrice?: InputMaybe<CurrencyInput>;
  /** Checks for equality with the object’s `subscriptionPrice` field. */
  subscriptionPrice?: InputMaybe<CurrencyInput>;
  /** Checks for equality with the object’s `typeOfEquity` field. */
  typeOfEquity?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `EquityTypeInfo` object types. All fields are combined with a logical ‘and.’ */
export type EquityTypeInfoFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<EquityTypeInfoFilter>>;
  /** Filter by the object’s `company` field. */
  company?: InputMaybe<StringFilter>;
  /** Filter by the object’s `convertsTo` field. */
  convertsTo?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `marketValueAtGrant` field. */
  marketValueAtGrant?: InputMaybe<CurrencyFilter>;
  /** Filter by the object’s `minSalePrice` field. */
  minSalePrice?: InputMaybe<CurrencyFilter>;
  /** Negates the expression. */
  not?: InputMaybe<EquityTypeInfoFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<EquityTypeInfoFilter>>;
  /** Filter by the object’s `planName` field. */
  planName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `strikePrice` field. */
  strikePrice?: InputMaybe<CurrencyFilter>;
  /** Filter by the object’s `subscriptionPrice` field. */
  subscriptionPrice?: InputMaybe<CurrencyFilter>;
  /** Filter by the object’s `typeOfEquity` field. */
  typeOfEquity?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `EquityTypeInfo` */
export type EquityTypeInfoInput = {
  company: Scalars['String'];
  convertsTo?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  marketValueAtGrant?: InputMaybe<CurrencyInput>;
  minSalePrice?: InputMaybe<CurrencyInput>;
  planName: Scalars['String'];
  strikePrice?: InputMaybe<CurrencyInput>;
  subscriptionPrice?: InputMaybe<CurrencyInput>;
  typeOfEquity: Scalars['String'];
};

/** Represents an update to a `EquityTypeInfo`. Fields that are set will be updated. */
export type EquityTypeInfoPatch = {
  company?: InputMaybe<Scalars['String']>;
  convertsTo?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  marketValueAtGrant?: InputMaybe<CurrencyInput>;
  minSalePrice?: InputMaybe<CurrencyInput>;
  planName?: InputMaybe<Scalars['String']>;
  strikePrice?: InputMaybe<CurrencyInput>;
  subscriptionPrice?: InputMaybe<CurrencyInput>;
  typeOfEquity?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `EquityTypeInfo` values. */
export type EquityTypeInfosConnection = {
  __typename?: 'EquityTypeInfosConnection';
  /** A list of edges which contains the `EquityTypeInfo` and cursor to aid in pagination. */
  edges: Array<EquityTypeInfosEdge>;
  /** A list of `EquityTypeInfo` objects. */
  nodes: Array<EquityTypeInfo>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EquityTypeInfo` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `EquityTypeInfo` edge in the connection. */
export type EquityTypeInfosEdge = {
  __typename?: 'EquityTypeInfosEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `EquityTypeInfo` at the end of the edge. */
  node: EquityTypeInfo;
};

/** Methods to use when ordering `EquityTypeInfo`. */
export enum EquityTypeInfosOrderBy {
  CompanyAsc = 'COMPANY_ASC',
  CompanyDesc = 'COMPANY_DESC',
  ConvertsToAsc = 'CONVERTS_TO_ASC',
  ConvertsToDesc = 'CONVERTS_TO_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MarketValueAtGrantAsc = 'MARKET_VALUE_AT_GRANT_ASC',
  MarketValueAtGrantDesc = 'MARKET_VALUE_AT_GRANT_DESC',
  MinSalePriceAsc = 'MIN_SALE_PRICE_ASC',
  MinSalePriceDesc = 'MIN_SALE_PRICE_DESC',
  Natural = 'NATURAL',
  PlanNameAsc = 'PLAN_NAME_ASC',
  PlanNameDesc = 'PLAN_NAME_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StrikePriceAsc = 'STRIKE_PRICE_ASC',
  StrikePriceDesc = 'STRIKE_PRICE_DESC',
  SubscriptionPriceAsc = 'SUBSCRIPTION_PRICE_ASC',
  SubscriptionPriceDesc = 'SUBSCRIPTION_PRICE_DESC',
  TypeOfEquityAsc = 'TYPE_OF_EQUITY_ASC',
  TypeOfEquityDesc = 'TYPE_OF_EQUITY_DESC'
}

/** An input for mutations affecting `EquityType` */
export type EquityTypeInput = {
  id: Scalars['String'];
};

/** Represents an update to a `EquityType`. Fields that are set will be updated. */
export type EquityTypePatch = {
  id?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `EquityType` values. */
export type EquityTypesConnection = {
  __typename?: 'EquityTypesConnection';
  /** A list of edges which contains the `EquityType` and cursor to aid in pagination. */
  edges: Array<EquityTypesEdge>;
  /** A list of `EquityType` objects. */
  nodes: Array<EquityType>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EquityType` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `EquityType` edge in the connection. */
export type EquityTypesEdge = {
  __typename?: 'EquityTypesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `EquityType` at the end of the edge. */
  node: EquityType;
};

/** Methods to use when ordering `EquityType`. */
export enum EquityTypesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type File = Node & {
  __typename?: 'File';
  displayName: Scalars['String'];
  displayOrder: Scalars['Int'];
  fileCategory: DataroomFileCategory;
  fileName: Scalars['String'];
  /** Reads and enables pagination through a set of `FileView`. */
  fileViewsByLiquidityEventIdAndSlug: FileViewsConnection;
  /** Reads a single `LiquidityEvent` that is related to this `File`. */
  liquidityEvent?: Maybe<LiquidityEvent>;
  liquidityEventId: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  slug: Scalars['String'];
};


export type FileFileViewsByLiquidityEventIdAndSlugArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FileViewCondition>;
  filter?: InputMaybe<FileViewFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FileViewsOrderBy>>;
};

/** A condition to be used against `File` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type FileCondition = {
  /** Checks for equality with the object’s `displayName` field. */
  displayName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `displayOrder` field. */
  displayOrder?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `fileCategory` field. */
  fileCategory?: InputMaybe<DataroomFileCategory>;
  /** Checks for equality with the object’s `fileName` field. */
  fileName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `liquidityEventId` field. */
  liquidityEventId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `File` object types. All fields are combined with a logical ‘and.’ */
export type FileFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FileFilter>>;
  /** Filter by the object’s `displayName` field. */
  displayName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `displayOrder` field. */
  displayOrder?: InputMaybe<IntFilter>;
  /** Filter by the object’s `fileCategory` field. */
  fileCategory?: InputMaybe<DataroomFileCategoryFilter>;
  /** Filter by the object’s `fileName` field. */
  fileName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `liquidityEventId` field. */
  liquidityEventId?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FileFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FileFilter>>;
  /** Filter by the object’s `slug` field. */
  slug?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `File` */
export type FileInput = {
  displayName: Scalars['String'];
  displayOrder: Scalars['Int'];
  fileCategory: DataroomFileCategory;
  fileName: Scalars['String'];
  liquidityEventId: Scalars['String'];
  slug: Scalars['String'];
};

/** Represents an update to a `File`. Fields that are set will be updated. */
export type FilePatch = {
  displayName?: InputMaybe<Scalars['String']>;
  displayOrder?: InputMaybe<Scalars['Int']>;
  fileCategory?: InputMaybe<DataroomFileCategory>;
  fileName?: InputMaybe<Scalars['String']>;
  liquidityEventId?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type FileView = {
  __typename?: 'FileView';
  /** Reads a single `File` that is related to this `FileView`. */
  fileByLiquidityEventIdAndSlug?: Maybe<File>;
  /** Reads a single `LiquidityEvent` that is related to this `FileView`. */
  liquidityEvent?: Maybe<LiquidityEvent>;
  liquidityEventId: Scalars['String'];
  slug: Scalars['String'];
  /** Reads a single `User` that is related to this `FileView`. */
  user?: Maybe<User>;
  userId: Scalars['String'];
  when?: Maybe<Scalars['Datetime']>;
};

/**
 * A condition to be used against `FileView` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type FileViewCondition = {
  /** Checks for equality with the object’s `liquidityEventId` field. */
  liquidityEventId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `when` field. */
  when?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `FileView` object types. All fields are combined with a logical ‘and.’ */
export type FileViewFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FileViewFilter>>;
  /** Filter by the object’s `liquidityEventId` field. */
  liquidityEventId?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FileViewFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FileViewFilter>>;
  /** Filter by the object’s `slug` field. */
  slug?: InputMaybe<StringFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `when` field. */
  when?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `FileView` */
export type FileViewInput = {
  liquidityEventId: Scalars['String'];
  slug: Scalars['String'];
  userId: Scalars['String'];
  when?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `FileView` values. */
export type FileViewsConnection = {
  __typename?: 'FileViewsConnection';
  /** A list of edges which contains the `FileView` and cursor to aid in pagination. */
  edges: Array<FileViewsEdge>;
  /** A list of `FileView` objects. */
  nodes: Array<FileView>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FileView` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `FileView` edge in the connection. */
export type FileViewsEdge = {
  __typename?: 'FileViewsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FileView` at the end of the edge. */
  node: FileView;
};

/** Methods to use when ordering `FileView`. */
export enum FileViewsOrderBy {
  LiquidityEventIdAsc = 'LIQUIDITY_EVENT_ID_ASC',
  LiquidityEventIdDesc = 'LIQUIDITY_EVENT_ID_DESC',
  Natural = 'NATURAL',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  WhenAsc = 'WHEN_ASC',
  WhenDesc = 'WHEN_DESC'
}

/** A connection to a list of `File` values. */
export type FilesConnection = {
  __typename?: 'FilesConnection';
  /** A list of edges which contains the `File` and cursor to aid in pagination. */
  edges: Array<FilesEdge>;
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `File` edge in the connection. */
export type FilesEdge = {
  __typename?: 'FilesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
};

/** Methods to use when ordering `File`. */
export enum FilesOrderBy {
  DisplayNameAsc = 'DISPLAY_NAME_ASC',
  DisplayNameDesc = 'DISPLAY_NAME_DESC',
  DisplayOrderAsc = 'DISPLAY_ORDER_ASC',
  DisplayOrderDesc = 'DISPLAY_ORDER_DESC',
  FileCategoryAsc = 'FILE_CATEGORY_ASC',
  FileCategoryDesc = 'FILE_CATEGORY_DESC',
  FileNameAsc = 'FILE_NAME_ASC',
  FileNameDesc = 'FILE_NAME_DESC',
  LiquidityEventIdAsc = 'LIQUIDITY_EVENT_ID_ASC',
  LiquidityEventIdDesc = 'LIQUIDITY_EVENT_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC'
}

/** A filter to be used against Float fields. All fields are combined with a logical ‘and.’ */
export type FloatFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Float']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Float']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Float']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Float']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Float']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Float']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Float']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Float']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Float']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export enum Font {
  Roboto = 'ROBOTO',
  Satisfy = 'SATISFY'
}

/** A filter to be used against Font fields. All fields are combined with a logical ‘and.’ */
export type FontFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Font>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Font>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Font>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Font>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Font>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Font>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Font>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Font>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Font>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Font>>;
};

/** A `GetTaxesRecord` edge in the connection. */
export type GetTaxEdge = {
  __typename?: 'GetTaxEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GetTaxesRecord` at the end of the edge. */
  node: GetTaxesRecord;
};

/** A connection to a list of `GetTaxesRecord` values. */
export type GetTaxesConnection = {
  __typename?: 'GetTaxesConnection';
  /** A list of edges which contains the `GetTaxesRecord` and cursor to aid in pagination. */
  edges: Array<GetTaxEdge>;
  /** A list of `GetTaxesRecord` objects. */
  nodes: Array<GetTaxesRecord>;
  /** The count of *all* `GetTaxesRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** The return type of our `getTaxes` query. */
export type GetTaxesRecord = {
  __typename?: 'GetTaxesRecord';
  amountExerciseTaxIsPaidOn?: Maybe<Scalars['BigFloat']>;
  amountSalesTaxIsPaidOn?: Maybe<Scalars['BigFloat']>;
  amountSharesWorthOnExercise?: Maybe<Scalars['BigFloat']>;
  capitalGain?: Maybe<Scalars['BigFloat']>;
  exerciseAllowanceUsed?: Maybe<Scalars['BigFloat']>;
  exerciseCost?: Maybe<Scalars['BigFloat']>;
  exerciseCostSold?: Maybe<Scalars['BigFloat']>;
  exerciseTax?: Maybe<Scalars['BigFloat']>;
  grossProfit?: Maybe<Scalars['BigFloat']>;
  netProfit?: Maybe<Scalars['BigFloat']>;
  rcaTax?: Maybe<Scalars['BigFloat']>;
  rcaTaxPaidOn?: Maybe<Scalars['BigFloat']>;
  saleAllowanceUsed?: Maybe<Scalars['BigFloat']>;
  saleTax?: Maybe<Scalars['BigFloat']>;
  saleValue?: Maybe<Scalars['BigFloat']>;
  semperFee?: Maybe<Scalars['BigFloat']>;
  subscriptionFee?: Maybe<Scalars['BigFloat']>;
  wiredForTaxes?: Maybe<Scalars['BigFloat']>;
  wiredToSpend?: Maybe<Scalars['BigFloat']>;
  wiredTotal?: Maybe<Scalars['BigFloat']>;
  withheldTax?: Maybe<Scalars['BigFloat']>;
};

/** A filter to be used against `GetTaxesRecord` object types. All fields are combined with a logical ‘and.’ */
export type GetTaxesRecordFilter = {
  /** Filter by the object’s `amountExerciseTaxIsPaidOn` field. */
  amountExerciseTaxIsPaidOn?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `amountSalesTaxIsPaidOn` field. */
  amountSalesTaxIsPaidOn?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `amountSharesWorthOnExercise` field. */
  amountSharesWorthOnExercise?: InputMaybe<BigFloatFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<GetTaxesRecordFilter>>;
  /** Filter by the object’s `capitalGain` field. */
  capitalGain?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `exerciseAllowanceUsed` field. */
  exerciseAllowanceUsed?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `exerciseCost` field. */
  exerciseCost?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `exerciseCostSold` field. */
  exerciseCostSold?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `exerciseTax` field. */
  exerciseTax?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `grossProfit` field. */
  grossProfit?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `netProfit` field. */
  netProfit?: InputMaybe<BigFloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<GetTaxesRecordFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<GetTaxesRecordFilter>>;
  /** Filter by the object’s `rcaTax` field. */
  rcaTax?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `rcaTaxPaidOn` field. */
  rcaTaxPaidOn?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `saleAllowanceUsed` field. */
  saleAllowanceUsed?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `saleTax` field. */
  saleTax?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `saleValue` field. */
  saleValue?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `semperFee` field. */
  semperFee?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `subscriptionFee` field. */
  subscriptionFee?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `wiredForTaxes` field. */
  wiredForTaxes?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `wiredToSpend` field. */
  wiredToSpend?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `wiredTotal` field. */
  wiredTotal?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `withheldTax` field. */
  withheldTax?: InputMaybe<BigFloatFilter>;
};

/** A connection to a list of `GetTransactionExerciseRecord` values. */
export type GetTransactionExerciseConnection = {
  __typename?: 'GetTransactionExerciseConnection';
  /** A list of edges which contains the `GetTransactionExerciseRecord` and cursor to aid in pagination. */
  edges: Array<GetTransactionExerciseEdge>;
  /** A list of `GetTransactionExerciseRecord` objects. */
  nodes: Array<GetTransactionExerciseRecord>;
  /** The count of *all* `GetTransactionExerciseRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GetTransactionExerciseRecord` edge in the connection. */
export type GetTransactionExerciseEdge = {
  __typename?: 'GetTransactionExerciseEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GetTransactionExerciseRecord` at the end of the edge. */
  node: GetTransactionExerciseRecord;
};

/** The return type of our `getTransactionExercise` query. */
export type GetTransactionExerciseRecord = {
  __typename?: 'GetTransactionExerciseRecord';
  amount?: Maybe<Scalars['Int']>;
  equityGrant?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `GetTransactionExerciseRecord` object types. All fields are combined with a logical ‘and.’ */
export type GetTransactionExerciseRecordFilter = {
  /** Filter by the object’s `amount` field. */
  amount?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<GetTransactionExerciseRecordFilter>>;
  /** Filter by the object’s `equityGrant` field. */
  equityGrant?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<GetTransactionExerciseRecordFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<GetTransactionExerciseRecordFilter>>;
};

/** A `GetTransactionSalesRecord` edge in the connection. */
export type GetTransactionSaleEdge = {
  __typename?: 'GetTransactionSaleEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GetTransactionSalesRecord` at the end of the edge. */
  node: GetTransactionSalesRecord;
};

/** A connection to a list of `GetTransactionSalesRecord` values. */
export type GetTransactionSalesConnection = {
  __typename?: 'GetTransactionSalesConnection';
  /** A list of edges which contains the `GetTransactionSalesRecord` and cursor to aid in pagination. */
  edges: Array<GetTransactionSaleEdge>;
  /** A list of `GetTransactionSalesRecord` objects. */
  nodes: Array<GetTransactionSalesRecord>;
  /** The count of *all* `GetTransactionSalesRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** The return type of our `getTransactionSales` query. */
export type GetTransactionSalesRecord = {
  __typename?: 'GetTransactionSalesRecord';
  amount?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['Date']>;
  equityGrant?: Maybe<Scalars['Int']>;
  saleValue?: Maybe<Scalars['BigFloat']>;
};

/** A filter to be used against `GetTransactionSalesRecord` object types. All fields are combined with a logical ‘and.’ */
export type GetTransactionSalesRecordFilter = {
  /** Filter by the object’s `amount` field. */
  amount?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<GetTransactionSalesRecordFilter>>;
  /** Filter by the object’s `date` field. */
  date?: InputMaybe<DateFilter>;
  /** Filter by the object’s `equityGrant` field. */
  equityGrant?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<GetTransactionSalesRecordFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<GetTransactionSalesRecordFilter>>;
  /** Filter by the object’s `saleValue` field. */
  saleValue?: InputMaybe<BigFloatFilter>;
};

/** A connection to a list of `GrantedEquityRecord` values. */
export type GrantedEquityConnection = {
  __typename?: 'GrantedEquityConnection';
  /** A list of edges which contains the `GrantedEquityRecord` and cursor to aid in pagination. */
  edges: Array<GrantedEquityEdge>;
  /** A list of `GrantedEquityRecord` objects. */
  nodes: Array<GrantedEquityRecord>;
  /** The count of *all* `GrantedEquityRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GrantedEquityRecord` edge in the connection. */
export type GrantedEquityEdge = {
  __typename?: 'GrantedEquityEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GrantedEquityRecord` at the end of the edge. */
  node: GrantedEquityRecord;
};

/** The return type of our `grantedEquity` query. */
export type GrantedEquityRecord = {
  __typename?: 'GrantedEquityRecord';
  amount?: Maybe<Scalars['Int']>;
  equityScheme?: Maybe<Scalars['String']>;
};

/** A filter to be used against `GrantedEquityRecord` object types. All fields are combined with a logical ‘and.’ */
export type GrantedEquityRecordFilter = {
  /** Filter by the object’s `amount` field. */
  amount?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<GrantedEquityRecordFilter>>;
  /** Filter by the object’s `equityScheme` field. */
  equityScheme?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<GrantedEquityRecordFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<GrantedEquityRecordFilter>>;
};

/** All input for the `importSeller` mutation. */
export type ImportSellerInput = {
  buyerId: Scalars['String'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyName: Scalars['String'];
  email: Scalars['String'];
  equityAmount: Scalars['Int'];
  equityType: Scalars['String'];
  fullName: Scalars['String'];
  grantDate: Scalars['Datetime'];
  isoCountry: IsoCountry;
  originalCrowdcubeId: Scalars['Int'];
  shortName: Scalars['String'];
  teamId: Scalars['String'];
  transactionGroup: Scalars['String'];
  userId: Scalars['String'];
};

/** The output of our `importSeller` mutation. */
export type ImportSellerPayload = {
  __typename?: 'ImportSellerPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  string?: Maybe<Scalars['String']>;
};

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Int']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Int']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Int']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Int']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Int']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Int']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Int']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Int']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Int']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type InvestorApproval = Node & {
  __typename?: 'InvestorApproval';
  allowed?: Maybe<Scalars['Boolean']>;
  approvedBy?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Datetime']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Team` that is related to this `InvestorApproval`. */
  team?: Maybe<Team>;
  teamId: Scalars['String'];
  /** Reads a single `User` that is related to this `InvestorApproval`. */
  userByApprovedBy?: Maybe<User>;
};

/**
 * A condition to be used against `InvestorApproval` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type InvestorApprovalCondition = {
  /** Checks for equality with the object’s `allowed` field. */
  allowed?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `approvedBy` field. */
  approvedBy?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `created` field. */
  created?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `teamId` field. */
  teamId?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `InvestorApproval` object types. All fields are combined with a logical ‘and.’ */
export type InvestorApprovalFilter = {
  /** Filter by the object’s `allowed` field. */
  allowed?: InputMaybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<InvestorApprovalFilter>>;
  /** Filter by the object’s `approvedBy` field. */
  approvedBy?: InputMaybe<StringFilter>;
  /** Filter by the object’s `created` field. */
  created?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<InvestorApprovalFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<InvestorApprovalFilter>>;
  /** Filter by the object’s `teamId` field. */
  teamId?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `InvestorApproval` */
export type InvestorApprovalInput = {
  allowed?: InputMaybe<Scalars['Boolean']>;
  approvedBy?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['Datetime']>;
  teamId: Scalars['String'];
};

/** Represents an update to a `InvestorApproval`. Fields that are set will be updated. */
export type InvestorApprovalPatch = {
  allowed?: InputMaybe<Scalars['Boolean']>;
  approvedBy?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['Datetime']>;
  teamId?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `InvestorApproval` values. */
export type InvestorApprovalsConnection = {
  __typename?: 'InvestorApprovalsConnection';
  /** A list of edges which contains the `InvestorApproval` and cursor to aid in pagination. */
  edges: Array<InvestorApprovalsEdge>;
  /** A list of `InvestorApproval` objects. */
  nodes: Array<InvestorApproval>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `InvestorApproval` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `InvestorApproval` edge in the connection. */
export type InvestorApprovalsEdge = {
  __typename?: 'InvestorApprovalsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `InvestorApproval` at the end of the edge. */
  node: InvestorApproval;
};

/** Methods to use when ordering `InvestorApproval`. */
export enum InvestorApprovalsOrderBy {
  AllowedAsc = 'ALLOWED_ASC',
  AllowedDesc = 'ALLOWED_DESC',
  ApprovedByAsc = 'APPROVED_BY_ASC',
  ApprovedByDesc = 'APPROVED_BY_DESC',
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TeamIdAsc = 'TEAM_ID_ASC',
  TeamIdDesc = 'TEAM_ID_DESC'
}

export type InvestorInvite = Node & {
  __typename?: 'InvestorInvite';
  email: Scalars['String'];
  fullName: Scalars['String'];
  inviteCode: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  usedBy?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `InvestorInvite`. */
  userByUsedBy?: Maybe<User>;
};

/**
 * A condition to be used against `InvestorInvite` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type InvestorInviteCondition = {
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `fullName` field. */
  fullName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `inviteCode` field. */
  inviteCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `usedBy` field. */
  usedBy?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `InvestorInvite` object types. All fields are combined with a logical ‘and.’ */
export type InvestorInviteFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<InvestorInviteFilter>>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Filter by the object’s `fullName` field. */
  fullName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `inviteCode` field. */
  inviteCode?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<InvestorInviteFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<InvestorInviteFilter>>;
  /** Filter by the object’s `usedBy` field. */
  usedBy?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `InvestorInvite` */
export type InvestorInviteInput = {
  email: Scalars['String'];
  fullName: Scalars['String'];
  inviteCode: Scalars['String'];
  usedBy?: InputMaybe<Scalars['String']>;
};

/** Represents an update to a `InvestorInvite`. Fields that are set will be updated. */
export type InvestorInvitePatch = {
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  inviteCode?: InputMaybe<Scalars['String']>;
  usedBy?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `InvestorInvite` values. */
export type InvestorInvitesConnection = {
  __typename?: 'InvestorInvitesConnection';
  /** A list of edges which contains the `InvestorInvite` and cursor to aid in pagination. */
  edges: Array<InvestorInvitesEdge>;
  /** A list of `InvestorInvite` objects. */
  nodes: Array<InvestorInvite>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `InvestorInvite` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `InvestorInvite` edge in the connection. */
export type InvestorInvitesEdge = {
  __typename?: 'InvestorInvitesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `InvestorInvite` at the end of the edge. */
  node: InvestorInvite;
};

/** Methods to use when ordering `InvestorInvite`. */
export enum InvestorInvitesOrderBy {
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  FullNameAsc = 'FULL_NAME_ASC',
  FullNameDesc = 'FULL_NAME_DESC',
  InviteCodeAsc = 'INVITE_CODE_ASC',
  InviteCodeDesc = 'INVITE_CODE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UsedByAsc = 'USED_BY_ASC',
  UsedByDesc = 'USED_BY_DESC'
}

/** All input for the `investorViewTlp` mutation. */
export type InvestorViewTlpInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  liquidityEvent?: InputMaybe<Scalars['String']>;
};

/** The output of our `investorViewTlp` mutation. */
export type InvestorViewTlpPayload = {
  __typename?: 'InvestorViewTlpPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export enum IsoCountry {
  Ad = 'AD',
  Ae = 'AE',
  Af = 'AF',
  Ag = 'AG',
  Ai = 'AI',
  Al = 'AL',
  Am = 'AM',
  Ao = 'AO',
  Aq = 'AQ',
  Ar = 'AR',
  As = 'AS',
  At = 'AT',
  Au = 'AU',
  Aw = 'AW',
  Ax = 'AX',
  Az = 'AZ',
  Ba = 'BA',
  Bb = 'BB',
  Bd = 'BD',
  Be = 'BE',
  Bf = 'BF',
  Bg = 'BG',
  Bh = 'BH',
  Bi = 'BI',
  Bj = 'BJ',
  Bl = 'BL',
  Bm = 'BM',
  Bn = 'BN',
  Bo = 'BO',
  Bq = 'BQ',
  Br = 'BR',
  Bs = 'BS',
  Bt = 'BT',
  Bv = 'BV',
  Bw = 'BW',
  By = 'BY',
  Bz = 'BZ',
  Ca = 'CA',
  Cc = 'CC',
  Cd = 'CD',
  Cf = 'CF',
  Cg = 'CG',
  Ch = 'CH',
  Ci = 'CI',
  Ck = 'CK',
  Cl = 'CL',
  Cm = 'CM',
  Cn = 'CN',
  Co = 'CO',
  Cr = 'CR',
  Cu = 'CU',
  Cv = 'CV',
  Cw = 'CW',
  Cx = 'CX',
  Cy = 'CY',
  Cz = 'CZ',
  De = 'DE',
  Dj = 'DJ',
  Dk = 'DK',
  Dm = 'DM',
  Do = 'DO',
  Dz = 'DZ',
  Ec = 'EC',
  Ee = 'EE',
  Eg = 'EG',
  Eh = 'EH',
  Er = 'ER',
  Es = 'ES',
  Et = 'ET',
  Fi = 'FI',
  Fj = 'FJ',
  Fk = 'FK',
  Fm = 'FM',
  Fo = 'FO',
  Fr = 'FR',
  Ga = 'GA',
  Gb = 'GB',
  Gd = 'GD',
  Ge = 'GE',
  Gf = 'GF',
  Gg = 'GG',
  Gh = 'GH',
  Gi = 'GI',
  Gl = 'GL',
  Gm = 'GM',
  Gn = 'GN',
  Gp = 'GP',
  Gq = 'GQ',
  Gr = 'GR',
  Gs = 'GS',
  Gt = 'GT',
  Gu = 'GU',
  Gw = 'GW',
  Gy = 'GY',
  Hk = 'HK',
  Hm = 'HM',
  Hn = 'HN',
  Hr = 'HR',
  Ht = 'HT',
  Hu = 'HU',
  Id = 'ID',
  Ie = 'IE',
  Il = 'IL',
  Im = 'IM',
  In = 'IN',
  Io = 'IO',
  Iq = 'IQ',
  Ir = 'IR',
  Is = 'IS',
  It = 'IT',
  Je = 'JE',
  Jm = 'JM',
  Jo = 'JO',
  Jp = 'JP',
  Ke = 'KE',
  Kg = 'KG',
  Kh = 'KH',
  Ki = 'KI',
  Km = 'KM',
  Kn = 'KN',
  Kp = 'KP',
  Kr = 'KR',
  Kw = 'KW',
  Ky = 'KY',
  Kz = 'KZ',
  La = 'LA',
  Lb = 'LB',
  Lc = 'LC',
  Li = 'LI',
  Lk = 'LK',
  Lr = 'LR',
  Ls = 'LS',
  Lt = 'LT',
  Lu = 'LU',
  Lv = 'LV',
  Ly = 'LY',
  Ma = 'MA',
  Mc = 'MC',
  Md = 'MD',
  Me = 'ME',
  Mf = 'MF',
  Mg = 'MG',
  Mh = 'MH',
  Mk = 'MK',
  Ml = 'ML',
  Mm = 'MM',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Mq = 'MQ',
  Mr = 'MR',
  Ms = 'MS',
  Mt = 'MT',
  Mu = 'MU',
  Mv = 'MV',
  Mw = 'MW',
  Mx = 'MX',
  My = 'MY',
  Mz = 'MZ',
  Na = 'NA',
  Nc = 'NC',
  Ne = 'NE',
  Nf = 'NF',
  Ng = 'NG',
  Ni = 'NI',
  Nl = 'NL',
  No = 'NO',
  Np = 'NP',
  Nr = 'NR',
  Nu = 'NU',
  Nz = 'NZ',
  Om = 'OM',
  Pa = 'PA',
  Pe = 'PE',
  Pf = 'PF',
  Pg = 'PG',
  Ph = 'PH',
  Pk = 'PK',
  Pl = 'PL',
  Pm = 'PM',
  Pn = 'PN',
  Pr = 'PR',
  Ps = 'PS',
  Pt = 'PT',
  Pw = 'PW',
  Py = 'PY',
  Qa = 'QA',
  Re = 'RE',
  Ro = 'RO',
  Rs = 'RS',
  Ru = 'RU',
  Rw = 'RW',
  Sa = 'SA',
  Sb = 'SB',
  Sc = 'SC',
  Sd = 'SD',
  Se = 'SE',
  Sg = 'SG',
  Sh = 'SH',
  Si = 'SI',
  Sj = 'SJ',
  Sk = 'SK',
  Sl = 'SL',
  Sm = 'SM',
  Sn = 'SN',
  So = 'SO',
  Sr = 'SR',
  Ss = 'SS',
  St = 'ST',
  Sv = 'SV',
  Sx = 'SX',
  Sy = 'SY',
  Sz = 'SZ',
  Tc = 'TC',
  Td = 'TD',
  Tf = 'TF',
  Tg = 'TG',
  Th = 'TH',
  Tj = 'TJ',
  Tk = 'TK',
  Tl = 'TL',
  Tm = 'TM',
  Tn = 'TN',
  To = 'TO',
  Tr = 'TR',
  Tt = 'TT',
  Tv = 'TV',
  Tw = 'TW',
  Tz = 'TZ',
  Ua = 'UA',
  Ug = 'UG',
  Um = 'UM',
  Unknown = 'UNKNOWN',
  Us = 'US',
  Uy = 'UY',
  Uz = 'UZ',
  Va = 'VA',
  Vc = 'VC',
  Ve = 'VE',
  Vg = 'VG',
  Vi = 'VI',
  Vn = 'VN',
  Vu = 'VU',
  Wf = 'WF',
  Ws = 'WS',
  Ye = 'YE',
  Yt = 'YT',
  Za = 'ZA',
  Zm = 'ZM',
  Zw = 'ZW'
}

/** A filter to be used against IsoCountry fields. All fields are combined with a logical ‘and.’ */
export type IsoCountryFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<IsoCountry>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<IsoCountry>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<IsoCountry>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<IsoCountry>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<IsoCountry>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<IsoCountry>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<IsoCountry>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<IsoCountry>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<IsoCountry>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<IsoCountry>>;
};

/** A filter to be used against JSON fields. All fields are combined with a logical ‘and.’ */
export type JsonFilter = {
  /** Contained by the specified JSON. */
  containedBy?: InputMaybe<Scalars['JSON']>;
  /** Contains the specified JSON. */
  contains?: InputMaybe<Scalars['JSON']>;
  /** Contains all of the specified keys. */
  containsAllKeys?: InputMaybe<Array<Scalars['String']>>;
  /** Contains any of the specified keys. */
  containsAnyKeys?: InputMaybe<Array<Scalars['String']>>;
  /** Contains the specified key. */
  containsKey?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['JSON']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['JSON']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['JSON']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['JSON']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['JSON']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['JSON']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['JSON']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['JSON']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['JSON']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['JSON']>>;
};

/** A connection to a list of `LapsedEquityRecord` values. */
export type LapsedEquityConnection = {
  __typename?: 'LapsedEquityConnection';
  /** A list of edges which contains the `LapsedEquityRecord` and cursor to aid in pagination. */
  edges: Array<LapsedEquityEdge>;
  /** A list of `LapsedEquityRecord` objects. */
  nodes: Array<LapsedEquityRecord>;
  /** The count of *all* `LapsedEquityRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LapsedEquityRecord` edge in the connection. */
export type LapsedEquityEdge = {
  __typename?: 'LapsedEquityEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LapsedEquityRecord` at the end of the edge. */
  node: LapsedEquityRecord;
};

/** The return type of our `lapsedEquity` query. */
export type LapsedEquityRecord = {
  __typename?: 'LapsedEquityRecord';
  amount?: Maybe<Scalars['Int']>;
  equityScheme?: Maybe<Scalars['String']>;
};

/** A filter to be used against `LapsedEquityRecord` object types. All fields are combined with a logical ‘and.’ */
export type LapsedEquityRecordFilter = {
  /** Filter by the object’s `amount` field. */
  amount?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LapsedEquityRecordFilter>>;
  /** Filter by the object’s `equityScheme` field. */
  equityScheme?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LapsedEquityRecordFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LapsedEquityRecordFilter>>;
};

export type LiquidityEvent = Node & {
  __typename?: 'LiquidityEvent';
  /** Reads and enables pagination through a set of `BlacklistedUsersForEvent`. */
  blacklistedUsersForEvents: BlacklistedUsersForEventsConnection;
  buyerDataroomAccess: Scalars['Datetime'];
  buyerManagementCall: Scalars['Datetime'];
  /** Reads and enables pagination through a set of `BuyerOffer`. */
  buyerOffersByLiquidityEvent: BuyerOffersConnection;
  buyerOrderDeadline: Scalars['Datetime'];
  /** Reads and enables pagination through a set of `BuyerPurchaseAgreement`. */
  buyerPurchaseAgreements: BuyerPurchaseAgreementsConnection;
  /** Reads a single `Company` that is related to this `LiquidityEvent`. */
  company?: Maybe<Company>;
  companyId: Scalars['String'];
  competitiveInvestmentsDefinition?: Maybe<Scalars['String']>;
  dataroomDisclaimer?: Maybe<Scalars['String']>;
  displayOrder: Scalars['Int'];
  fee: Scalars['String'];
  /** Reads and enables pagination through a set of `FileView`. */
  fileViews: FileViewsConnection;
  /** Reads and enables pagination through a set of `File`. */
  files: FilesConnection;
  funding: Currency;
  id: Scalars['String'];
  /** Reads and enables pagination through a set of `LiquidityEventFaq`. */
  liquidityEventFaqs: LiquidityEventFaqsConnection;
  maxVolume: Currency;
  minInvestment: Currency;
  minVolume: Currency;
  /** Reads and enables pagination through a set of `NdaMetadatum`. */
  ndaMetadataByLiquidityEvent: NdaMetadataConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  sellerClosing?: Maybe<Scalars['Datetime']>;
  sellerExecuteDeadline?: Maybe<Scalars['Datetime']>;
  sellerOfferDeadline?: Maybe<Scalars['Datetime']>;
  sellerOpening?: Maybe<Scalars['Datetime']>;
  sellerPaymentDeadline?: Maybe<Scalars['Datetime']>;
  /** Reads and enables pagination through a set of `Stage2Access`. */
  stage2AccessesByLiquidityEvent: Stage2AccessesConnection;
  status: LiquidityEventStatus;
  teamSize: Scalars['String'];
  /** Reads and enables pagination through a set of `TransactionGroup`. */
  transactionGroups: TransactionGroupsConnection;
  valuation: Currency;
};


export type LiquidityEventBlacklistedUsersForEventsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BlacklistedUsersForEventCondition>;
  filter?: InputMaybe<BlacklistedUsersForEventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BlacklistedUsersForEventsOrderBy>>;
};


export type LiquidityEventBuyerOffersByLiquidityEventArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BuyerOfferCondition>;
  filter?: InputMaybe<BuyerOfferFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BuyerOffersOrderBy>>;
};


export type LiquidityEventBuyerPurchaseAgreementsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BuyerPurchaseAgreementCondition>;
  filter?: InputMaybe<BuyerPurchaseAgreementFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BuyerPurchaseAgreementsOrderBy>>;
};


export type LiquidityEventFileViewsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FileViewCondition>;
  filter?: InputMaybe<FileViewFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FileViewsOrderBy>>;
};


export type LiquidityEventFilesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FileCondition>;
  filter?: InputMaybe<FileFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


export type LiquidityEventLiquidityEventFaqsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LiquidityEventFaqCondition>;
  filter?: InputMaybe<LiquidityEventFaqFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LiquidityEventFaqsOrderBy>>;
};


export type LiquidityEventNdaMetadataByLiquidityEventArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<NdaMetadatumCondition>;
  filter?: InputMaybe<NdaMetadatumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NdaMetadataOrderBy>>;
};


export type LiquidityEventStage2AccessesByLiquidityEventArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<Stage2AccessCondition>;
  filter?: InputMaybe<Stage2AccessFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Stage2AccessesOrderBy>>;
};


export type LiquidityEventTransactionGroupsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionGroupCondition>;
  filter?: InputMaybe<TransactionGroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionGroupsOrderBy>>;
};

/**
 * A condition to be used against `LiquidityEvent` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LiquidityEventCondition = {
  /** Checks for equality with the object’s `buyerDataroomAccess` field. */
  buyerDataroomAccess?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `buyerManagementCall` field. */
  buyerManagementCall?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `buyerOrderDeadline` field. */
  buyerOrderDeadline?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `competitiveInvestmentsDefinition` field. */
  competitiveInvestmentsDefinition?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `dataroomDisclaimer` field. */
  dataroomDisclaimer?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `displayOrder` field. */
  displayOrder?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `fee` field. */
  fee?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `funding` field. */
  funding?: InputMaybe<CurrencyInput>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `maxVolume` field. */
  maxVolume?: InputMaybe<CurrencyInput>;
  /** Checks for equality with the object’s `minInvestment` field. */
  minInvestment?: InputMaybe<CurrencyInput>;
  /** Checks for equality with the object’s `minVolume` field. */
  minVolume?: InputMaybe<CurrencyInput>;
  /** Checks for equality with the object’s `sellerClosing` field. */
  sellerClosing?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `sellerExecuteDeadline` field. */
  sellerExecuteDeadline?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `sellerOfferDeadline` field. */
  sellerOfferDeadline?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `sellerOpening` field. */
  sellerOpening?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `sellerPaymentDeadline` field. */
  sellerPaymentDeadline?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<LiquidityEventStatus>;
  /** Checks for equality with the object’s `teamSize` field. */
  teamSize?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `valuation` field. */
  valuation?: InputMaybe<CurrencyInput>;
};

export type LiquidityEventFaq = Node & {
  __typename?: 'LiquidityEventFaq';
  createdAt?: Maybe<Scalars['Datetime']>;
  faqContent: Scalars['String'];
  faqOrder: Scalars['Int'];
  id: Scalars['UUID'];
  /** Reads a single `LiquidityEvent` that is related to this `LiquidityEventFaq`. */
  liquidityEvent?: Maybe<LiquidityEvent>;
  liquidityEventId: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/**
 * A condition to be used against `LiquidityEventFaq` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LiquidityEventFaqCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `faqContent` field. */
  faqContent?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `faqOrder` field. */
  faqOrder?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `liquidityEventId` field. */
  liquidityEventId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `LiquidityEventFaq` object types. All fields are combined with a logical ‘and.’ */
export type LiquidityEventFaqFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LiquidityEventFaqFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `faqContent` field. */
  faqContent?: InputMaybe<StringFilter>;
  /** Filter by the object’s `faqOrder` field. */
  faqOrder?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `liquidityEventId` field. */
  liquidityEventId?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LiquidityEventFaqFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LiquidityEventFaqFilter>>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `LiquidityEventFaq` */
export type LiquidityEventFaqInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  faqContent: Scalars['String'];
  faqOrder: Scalars['Int'];
  id?: InputMaybe<Scalars['UUID']>;
  liquidityEventId: Scalars['String'];
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Represents an update to a `LiquidityEventFaq`. Fields that are set will be updated. */
export type LiquidityEventFaqPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  faqContent?: InputMaybe<Scalars['String']>;
  faqOrder?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['UUID']>;
  liquidityEventId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `LiquidityEventFaq` values. */
export type LiquidityEventFaqsConnection = {
  __typename?: 'LiquidityEventFaqsConnection';
  /** A list of edges which contains the `LiquidityEventFaq` and cursor to aid in pagination. */
  edges: Array<LiquidityEventFaqsEdge>;
  /** A list of `LiquidityEventFaq` objects. */
  nodes: Array<LiquidityEventFaq>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LiquidityEventFaq` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LiquidityEventFaq` edge in the connection. */
export type LiquidityEventFaqsEdge = {
  __typename?: 'LiquidityEventFaqsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LiquidityEventFaq` at the end of the edge. */
  node: LiquidityEventFaq;
};

/** Methods to use when ordering `LiquidityEventFaq`. */
export enum LiquidityEventFaqsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  FaqContentAsc = 'FAQ_CONTENT_ASC',
  FaqContentDesc = 'FAQ_CONTENT_DESC',
  FaqOrderAsc = 'FAQ_ORDER_ASC',
  FaqOrderDesc = 'FAQ_ORDER_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LiquidityEventIdAsc = 'LIQUIDITY_EVENT_ID_ASC',
  LiquidityEventIdDesc = 'LIQUIDITY_EVENT_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** A filter to be used against `LiquidityEvent` object types. All fields are combined with a logical ‘and.’ */
export type LiquidityEventFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LiquidityEventFilter>>;
  /** Filter by the object’s `buyerDataroomAccess` field. */
  buyerDataroomAccess?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `buyerManagementCall` field. */
  buyerManagementCall?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `buyerOrderDeadline` field. */
  buyerOrderDeadline?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `competitiveInvestmentsDefinition` field. */
  competitiveInvestmentsDefinition?: InputMaybe<StringFilter>;
  /** Filter by the object’s `dataroomDisclaimer` field. */
  dataroomDisclaimer?: InputMaybe<StringFilter>;
  /** Filter by the object’s `displayOrder` field. */
  displayOrder?: InputMaybe<IntFilter>;
  /** Filter by the object’s `fee` field. */
  fee?: InputMaybe<StringFilter>;
  /** Filter by the object’s `funding` field. */
  funding?: InputMaybe<CurrencyFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `maxVolume` field. */
  maxVolume?: InputMaybe<CurrencyFilter>;
  /** Filter by the object’s `minInvestment` field. */
  minInvestment?: InputMaybe<CurrencyFilter>;
  /** Filter by the object’s `minVolume` field. */
  minVolume?: InputMaybe<CurrencyFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LiquidityEventFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LiquidityEventFilter>>;
  /** Filter by the object’s `sellerClosing` field. */
  sellerClosing?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `sellerExecuteDeadline` field. */
  sellerExecuteDeadline?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `sellerOfferDeadline` field. */
  sellerOfferDeadline?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `sellerOpening` field. */
  sellerOpening?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `sellerPaymentDeadline` field. */
  sellerPaymentDeadline?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<LiquidityEventStatusFilter>;
  /** Filter by the object’s `teamSize` field. */
  teamSize?: InputMaybe<StringFilter>;
  /** Filter by the object’s `valuation` field. */
  valuation?: InputMaybe<CurrencyFilter>;
};

/** An input for mutations affecting `LiquidityEvent` */
export type LiquidityEventInput = {
  buyerDataroomAccess: Scalars['Datetime'];
  buyerManagementCall: Scalars['Datetime'];
  buyerOrderDeadline: Scalars['Datetime'];
  companyId: Scalars['String'];
  competitiveInvestmentsDefinition?: InputMaybe<Scalars['String']>;
  dataroomDisclaimer?: InputMaybe<Scalars['String']>;
  displayOrder: Scalars['Int'];
  fee: Scalars['String'];
  funding: CurrencyInput;
  id: Scalars['String'];
  maxVolume?: InputMaybe<CurrencyInput>;
  minInvestment: CurrencyInput;
  minVolume?: InputMaybe<CurrencyInput>;
  sellerClosing?: InputMaybe<Scalars['Datetime']>;
  sellerExecuteDeadline?: InputMaybe<Scalars['Datetime']>;
  sellerOfferDeadline?: InputMaybe<Scalars['Datetime']>;
  sellerOpening?: InputMaybe<Scalars['Datetime']>;
  sellerPaymentDeadline?: InputMaybe<Scalars['Datetime']>;
  status: LiquidityEventStatus;
  teamSize: Scalars['String'];
  valuation: CurrencyInput;
};

/** Represents an update to a `LiquidityEvent`. Fields that are set will be updated. */
export type LiquidityEventPatch = {
  buyerDataroomAccess?: InputMaybe<Scalars['Datetime']>;
  buyerManagementCall?: InputMaybe<Scalars['Datetime']>;
  buyerOrderDeadline?: InputMaybe<Scalars['Datetime']>;
  companyId?: InputMaybe<Scalars['String']>;
  competitiveInvestmentsDefinition?: InputMaybe<Scalars['String']>;
  dataroomDisclaimer?: InputMaybe<Scalars['String']>;
  displayOrder?: InputMaybe<Scalars['Int']>;
  fee?: InputMaybe<Scalars['String']>;
  funding?: InputMaybe<CurrencyInput>;
  id?: InputMaybe<Scalars['String']>;
  maxVolume?: InputMaybe<CurrencyInput>;
  minInvestment?: InputMaybe<CurrencyInput>;
  minVolume?: InputMaybe<CurrencyInput>;
  sellerClosing?: InputMaybe<Scalars['Datetime']>;
  sellerExecuteDeadline?: InputMaybe<Scalars['Datetime']>;
  sellerOfferDeadline?: InputMaybe<Scalars['Datetime']>;
  sellerOpening?: InputMaybe<Scalars['Datetime']>;
  sellerPaymentDeadline?: InputMaybe<Scalars['Datetime']>;
  status?: InputMaybe<LiquidityEventStatus>;
  teamSize?: InputMaybe<Scalars['String']>;
  valuation?: InputMaybe<CurrencyInput>;
};

export enum LiquidityEventStatus {
  Closed = 'CLOSED',
  Hidden = 'HIDDEN',
  Live = 'LIVE',
  Upcoming = 'UPCOMING'
}

/** A filter to be used against LiquidityEventStatus fields. All fields are combined with a logical ‘and.’ */
export type LiquidityEventStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<LiquidityEventStatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<LiquidityEventStatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<LiquidityEventStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<LiquidityEventStatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<LiquidityEventStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<LiquidityEventStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<LiquidityEventStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<LiquidityEventStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<LiquidityEventStatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<LiquidityEventStatus>>;
};

/** A connection to a list of `LiquidityEvent` values. */
export type LiquidityEventsConnection = {
  __typename?: 'LiquidityEventsConnection';
  /** A list of edges which contains the `LiquidityEvent` and cursor to aid in pagination. */
  edges: Array<LiquidityEventsEdge>;
  /** A list of `LiquidityEvent` objects. */
  nodes: Array<LiquidityEvent>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LiquidityEvent` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LiquidityEvent` edge in the connection. */
export type LiquidityEventsEdge = {
  __typename?: 'LiquidityEventsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LiquidityEvent` at the end of the edge. */
  node: LiquidityEvent;
};

/** Methods to use when ordering `LiquidityEvent`. */
export enum LiquidityEventsOrderBy {
  BuyerDataroomAccessAsc = 'BUYER_DATAROOM_ACCESS_ASC',
  BuyerDataroomAccessDesc = 'BUYER_DATAROOM_ACCESS_DESC',
  BuyerManagementCallAsc = 'BUYER_MANAGEMENT_CALL_ASC',
  BuyerManagementCallDesc = 'BUYER_MANAGEMENT_CALL_DESC',
  BuyerOrderDeadlineAsc = 'BUYER_ORDER_DEADLINE_ASC',
  BuyerOrderDeadlineDesc = 'BUYER_ORDER_DEADLINE_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CompetitiveInvestmentsDefinitionAsc = 'COMPETITIVE_INVESTMENTS_DEFINITION_ASC',
  CompetitiveInvestmentsDefinitionDesc = 'COMPETITIVE_INVESTMENTS_DEFINITION_DESC',
  DataroomDisclaimerAsc = 'DATAROOM_DISCLAIMER_ASC',
  DataroomDisclaimerDesc = 'DATAROOM_DISCLAIMER_DESC',
  DisplayOrderAsc = 'DISPLAY_ORDER_ASC',
  DisplayOrderDesc = 'DISPLAY_ORDER_DESC',
  FeeAsc = 'FEE_ASC',
  FeeDesc = 'FEE_DESC',
  FundingAsc = 'FUNDING_ASC',
  FundingDesc = 'FUNDING_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MaxVolumeAsc = 'MAX_VOLUME_ASC',
  MaxVolumeDesc = 'MAX_VOLUME_DESC',
  MinInvestmentAsc = 'MIN_INVESTMENT_ASC',
  MinInvestmentDesc = 'MIN_INVESTMENT_DESC',
  MinVolumeAsc = 'MIN_VOLUME_ASC',
  MinVolumeDesc = 'MIN_VOLUME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SellerClosingAsc = 'SELLER_CLOSING_ASC',
  SellerClosingDesc = 'SELLER_CLOSING_DESC',
  SellerExecuteDeadlineAsc = 'SELLER_EXECUTE_DEADLINE_ASC',
  SellerExecuteDeadlineDesc = 'SELLER_EXECUTE_DEADLINE_DESC',
  SellerOfferDeadlineAsc = 'SELLER_OFFER_DEADLINE_ASC',
  SellerOfferDeadlineDesc = 'SELLER_OFFER_DEADLINE_DESC',
  SellerOpeningAsc = 'SELLER_OPENING_ASC',
  SellerOpeningDesc = 'SELLER_OPENING_DESC',
  SellerPaymentDeadlineAsc = 'SELLER_PAYMENT_DEADLINE_ASC',
  SellerPaymentDeadlineDesc = 'SELLER_PAYMENT_DEADLINE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  TeamSizeAsc = 'TEAM_SIZE_ASC',
  TeamSizeDesc = 'TEAM_SIZE_DESC',
  ValuationAsc = 'VALUATION_ASC',
  ValuationDesc = 'VALUATION_DESC'
}

/** All input for the `makeInvestorInvite` mutation. */
export type MakeInvestorInviteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
};

/** The output of our `makeInvestorInvite` mutation. */
export type MakeInvestorInvitePayload = {
  __typename?: 'MakeInvestorInvitePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  string?: Maybe<Scalars['String']>;
};

export type MeResult = {
  __typename?: 'MeResult';
  allowedToViewDeals?: Maybe<Scalars['Boolean']>;
  buyerOnboardingCompleted?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  hasBuyerOnboarding?: Maybe<Scalars['Boolean']>;
  hasSellerOnboarding?: Maybe<Scalars['Boolean']>;
  sellerOnboardingCompleted?: Maybe<Scalars['Boolean']>;
  shortName?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  teamName?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation';
  approveTransaction?: Maybe<ApproveTransactionPayload>;
  claimInvestorInvite?: Maybe<ClaimInvestorInvitePayload>;
  confirmMyOffer?: Maybe<ConfirmMyOfferPayload>;
  confirmOfferInTransaction?: Maybe<ConfirmOfferInTransactionPayload>;
  /** Creates a single `BlacklistedUsersForEvent`. */
  createBlacklistedUsersForEvent?: Maybe<CreateBlacklistedUsersForEventPayload>;
  /** Creates a single `BuyerOffer`. */
  createBuyerOffer?: Maybe<CreateBuyerOfferPayload>;
  /** Creates a single `BuyerOnboarding`. */
  createBuyerOnboarding?: Maybe<CreateBuyerOnboardingPayload>;
  /** Creates a single `BuyerPurchaseAgreement`. */
  createBuyerPurchaseAgreement?: Maybe<CreateBuyerPurchaseAgreementPayload>;
  /** Creates a single `CommentsAboutTeam`. */
  createCommentsAboutTeam?: Maybe<CreateCommentsAboutTeamPayload>;
  /** Creates a single `Company`. */
  createCompany?: Maybe<CreateCompanyPayload>;
  /** Creates a single `CompanyKeyPerson`. */
  createCompanyKeyPerson?: Maybe<CreateCompanyKeyPersonPayload>;
  /** Creates a single `CurrentUser`. */
  createCurrentUser?: Maybe<CreateCurrentUserPayload>;
  /** Creates a single `Email`. */
  createEmail?: Maybe<CreateEmailPayload>;
  /** Creates a single `Employment`. */
  createEmployment?: Maybe<CreateEmploymentPayload>;
  /** Creates a single `EquityGrant`. */
  createEquityGrant?: Maybe<CreateEquityGrantPayload>;
  /** Creates a single `EquitySummary`. */
  createEquitySummary?: Maybe<CreateEquitySummaryPayload>;
  /** Creates a single `EquityType`. */
  createEquityType?: Maybe<CreateEquityTypePayload>;
  /** Creates a single `EquityTypeInfo`. */
  createEquityTypeInfo?: Maybe<CreateEquityTypeInfoPayload>;
  /** Creates a single `File`. */
  createFile?: Maybe<CreateFilePayload>;
  /** Creates a single `FileView`. */
  createFileView?: Maybe<CreateFileViewPayload>;
  /** Creates a single `InvestorApproval`. */
  createInvestorApproval?: Maybe<CreateInvestorApprovalPayload>;
  /** Creates a single `InvestorInvite`. */
  createInvestorInvite?: Maybe<CreateInvestorInvitePayload>;
  /** Creates a single `LiquidityEvent`. */
  createLiquidityEvent?: Maybe<CreateLiquidityEventPayload>;
  /** Creates a single `LiquidityEventFaq`. */
  createLiquidityEventFaq?: Maybe<CreateLiquidityEventFaqPayload>;
  /** Creates a single `NdaMetadatum`. */
  createNdaMetadatum?: Maybe<CreateNdaMetadatumPayload>;
  /** Creates a single `OverallAllocation`. */
  createOverallAllocation?: Maybe<CreateOverallAllocationPayload>;
  /** Creates a single `Permission`. */
  createPermission?: Maybe<CreatePermissionPayload>;
  createSeller?: Maybe<CreateSellerPayload>;
  /** Creates a single `SellerOffer`. */
  createSellerOffer?: Maybe<CreateSellerOfferPayload>;
  /** Creates a single `SellerOnboarding`. */
  createSellerOnboarding?: Maybe<CreateSellerOnboardingPayload>;
  /** Creates a single `SignedDocument`. */
  createSignedDocument?: Maybe<CreateSignedDocumentPayload>;
  /** Creates a single `Stage2Access`. */
  createStage2Access?: Maybe<CreateStage2AccessPayload>;
  /** Creates a single `SuggestedTax`. */
  createSuggestedTax?: Maybe<CreateSuggestedTaxPayload>;
  /** Creates a single `TaxRate`. */
  createTaxRate?: Maybe<CreateTaxRatePayload>;
  /** Creates a single `TaxVariable`. */
  createTaxVariable?: Maybe<CreateTaxVariablePayload>;
  /** Creates a single `Team`. */
  createTeam?: Maybe<CreateTeamPayload>;
  /** Creates a single `TeamMembership`. */
  createTeamMembership?: Maybe<CreateTeamMembershipPayload>;
  /** Creates a single `Transaction`. */
  createTransaction?: Maybe<CreateTransactionPayload>;
  /** Creates a single `TransactionAllowance`. */
  createTransactionAllowance?: Maybe<CreateTransactionAllowancePayload>;
  /** Creates a single `TransactionApproval`. */
  createTransactionApproval?: Maybe<CreateTransactionApprovalPayload>;
  /** Creates a single `TransactionDetail`. */
  createTransactionDetail?: Maybe<CreateTransactionDetailPayload>;
  /** Creates a single `TransactionDocument`. */
  createTransactionDocument?: Maybe<CreateTransactionDocumentPayload>;
  /** Creates a single `TransactionDocumentField`. */
  createTransactionDocumentField?: Maybe<CreateTransactionDocumentFieldPayload>;
  /** Creates a single `TransactionDocumentSignature`. */
  createTransactionDocumentSignature?: Maybe<CreateTransactionDocumentSignaturePayload>;
  /** Creates a single `TransactionFee`. */
  createTransactionFee?: Maybe<CreateTransactionFeePayload>;
  /** Creates a single `TransactionGroup`. */
  createTransactionGroup?: Maybe<CreateTransactionGroupPayload>;
  /** Creates a single `TransactionGroupCurrency`. */
  createTransactionGroupCurrency?: Maybe<CreateTransactionGroupCurrencyPayload>;
  /** Creates a single `TransactionNote`. */
  createTransactionNote?: Maybe<CreateTransactionNotePayload>;
  /** Creates a single `TransactionSummaryExerciseSale`. */
  createTransactionSummaryExerciseSale?: Maybe<CreateTransactionSummaryExerciseSalePayload>;
  /** Creates a single `TransactionTaxRate`. */
  createTransactionTaxRate?: Maybe<CreateTransactionTaxRatePayload>;
  /** Creates a single `User`. */
  createUser?: Maybe<CreateUserPayload>;
  /** Creates a single `Vesting`. */
  createVesting?: Maybe<CreateVestingPayload>;
  /** Creates a single `VestingSchedule`. */
  createVestingSchedule?: Maybe<CreateVestingSchedulePayload>;
  /** Deletes a single `BuyerOffer` using a unique key. */
  deleteBuyerOffer?: Maybe<DeleteBuyerOfferPayload>;
  /** Deletes a single `BuyerOffer` using its globally unique id. */
  deleteBuyerOfferByNodeId?: Maybe<DeleteBuyerOfferPayload>;
  /** Deletes a single `BuyerOnboarding` using a unique key. */
  deleteBuyerOnboarding?: Maybe<DeleteBuyerOnboardingPayload>;
  /** Deletes a single `BuyerOnboarding` using its globally unique id. */
  deleteBuyerOnboardingByNodeId?: Maybe<DeleteBuyerOnboardingPayload>;
  /** Deletes a single `Company` using a unique key. */
  deleteCompany?: Maybe<DeleteCompanyPayload>;
  /** Deletes a single `Company` using its globally unique id. */
  deleteCompanyByNodeId?: Maybe<DeleteCompanyPayload>;
  /** Deletes a single `CompanyKeyPerson` using a unique key. */
  deleteCompanyKeyPerson?: Maybe<DeleteCompanyKeyPersonPayload>;
  /** Deletes a single `CompanyKeyPerson` using its globally unique id. */
  deleteCompanyKeyPersonByNodeId?: Maybe<DeleteCompanyKeyPersonPayload>;
  /** Deletes a single `Employment` using a unique key. */
  deleteEmployment?: Maybe<DeleteEmploymentPayload>;
  /** Deletes a single `Employment` using its globally unique id. */
  deleteEmploymentByNodeId?: Maybe<DeleteEmploymentPayload>;
  /** Deletes a single `EquityGrant` using a unique key. */
  deleteEquityGrant?: Maybe<DeleteEquityGrantPayload>;
  /** Deletes a single `EquityGrant` using a unique key. */
  deleteEquityGrantByHolderTeamIdAndEquityScheme?: Maybe<DeleteEquityGrantPayload>;
  /** Deletes a single `EquityGrant` using its globally unique id. */
  deleteEquityGrantByNodeId?: Maybe<DeleteEquityGrantPayload>;
  /** Deletes a single `EquityType` using a unique key. */
  deleteEquityType?: Maybe<DeleteEquityTypePayload>;
  /** Deletes a single `EquityType` using its globally unique id. */
  deleteEquityTypeByNodeId?: Maybe<DeleteEquityTypePayload>;
  /** Deletes a single `EquityTypeInfo` using a unique key. */
  deleteEquityTypeInfo?: Maybe<DeleteEquityTypeInfoPayload>;
  /** Deletes a single `EquityTypeInfo` using its globally unique id. */
  deleteEquityTypeInfoByNodeId?: Maybe<DeleteEquityTypeInfoPayload>;
  /** Deletes a single `File` using a unique key. */
  deleteFile?: Maybe<DeleteFilePayload>;
  /** Deletes a single `File` using its globally unique id. */
  deleteFileByNodeId?: Maybe<DeleteFilePayload>;
  /** Deletes a single `InvestorApproval` using a unique key. */
  deleteInvestorApproval?: Maybe<DeleteInvestorApprovalPayload>;
  /** Deletes a single `InvestorApproval` using its globally unique id. */
  deleteInvestorApprovalByNodeId?: Maybe<DeleteInvestorApprovalPayload>;
  /** Deletes a single `InvestorInvite` using a unique key. */
  deleteInvestorInvite?: Maybe<DeleteInvestorInvitePayload>;
  /** Deletes a single `InvestorInvite` using its globally unique id. */
  deleteInvestorInviteByNodeId?: Maybe<DeleteInvestorInvitePayload>;
  /** Deletes a single `LiquidityEvent` using a unique key. */
  deleteLiquidityEvent?: Maybe<DeleteLiquidityEventPayload>;
  /** Deletes a single `LiquidityEvent` using its globally unique id. */
  deleteLiquidityEventByNodeId?: Maybe<DeleteLiquidityEventPayload>;
  /** Deletes a single `LiquidityEventFaq` using a unique key. */
  deleteLiquidityEventFaq?: Maybe<DeleteLiquidityEventFaqPayload>;
  /** Deletes a single `LiquidityEventFaq` using its globally unique id. */
  deleteLiquidityEventFaqByNodeId?: Maybe<DeleteLiquidityEventFaqPayload>;
  /** Deletes a single `NdaMetadatum` using a unique key. */
  deleteNdaMetadatum?: Maybe<DeleteNdaMetadatumPayload>;
  /** Deletes a single `NdaMetadatum` using its globally unique id. */
  deleteNdaMetadatumByNodeId?: Maybe<DeleteNdaMetadatumPayload>;
  /** Deletes a single `OverallAllocation` using a unique key. */
  deleteOverallAllocation?: Maybe<DeleteOverallAllocationPayload>;
  /** Deletes a single `OverallAllocation` using its globally unique id. */
  deleteOverallAllocationByNodeId?: Maybe<DeleteOverallAllocationPayload>;
  /** Deletes a single `SellerOffer` using a unique key. */
  deleteSellerOffer?: Maybe<DeleteSellerOfferPayload>;
  /** Deletes a single `SellerOffer` using its globally unique id. */
  deleteSellerOfferByNodeId?: Maybe<DeleteSellerOfferPayload>;
  /** Deletes a single `SellerOnboarding` using a unique key. */
  deleteSellerOnboarding?: Maybe<DeleteSellerOnboardingPayload>;
  /** Deletes a single `SellerOnboarding` using its globally unique id. */
  deleteSellerOnboardingByNodeId?: Maybe<DeleteSellerOnboardingPayload>;
  /** Deletes a single `Stage2Access` using a unique key. */
  deleteStage2Access?: Maybe<DeleteStage2AccessPayload>;
  /** Deletes a single `Stage2Access` using its globally unique id. */
  deleteStage2AccessByNodeId?: Maybe<DeleteStage2AccessPayload>;
  /** Deletes a single `TaxRate` using a unique key. */
  deleteTaxRate?: Maybe<DeleteTaxRatePayload>;
  /** Deletes a single `TaxRate` using its globally unique id. */
  deleteTaxRateByNodeId?: Maybe<DeleteTaxRatePayload>;
  /** Deletes a single `Team` using a unique key. */
  deleteTeam?: Maybe<DeleteTeamPayload>;
  /** Deletes a single `Team` using its globally unique id. */
  deleteTeamByNodeId?: Maybe<DeleteTeamPayload>;
  /** Deletes a single `TeamMembership` using a unique key. */
  deleteTeamMembership?: Maybe<DeleteTeamMembershipPayload>;
  /** Deletes a single `TeamMembership` using its globally unique id. */
  deleteTeamMembershipByNodeId?: Maybe<DeleteTeamMembershipPayload>;
  /** Deletes a single `Transaction` using a unique key. */
  deleteTransaction?: Maybe<DeleteTransactionPayload>;
  /** Deletes a single `TransactionAllowance` using a unique key. */
  deleteTransactionAllowance?: Maybe<DeleteTransactionAllowancePayload>;
  /** Deletes a single `TransactionAllowance` using its globally unique id. */
  deleteTransactionAllowanceByNodeId?: Maybe<DeleteTransactionAllowancePayload>;
  /** Deletes a single `TransactionApproval` using a unique key. */
  deleteTransactionApproval?: Maybe<DeleteTransactionApprovalPayload>;
  /** Deletes a single `TransactionApproval` using its globally unique id. */
  deleteTransactionApprovalByNodeId?: Maybe<DeleteTransactionApprovalPayload>;
  /** Deletes a single `Transaction` using its globally unique id. */
  deleteTransactionByNodeId?: Maybe<DeleteTransactionPayload>;
  /** Deletes a single `TransactionDocument` using a unique key. */
  deleteTransactionDocument?: Maybe<DeleteTransactionDocumentPayload>;
  /** Deletes a single `TransactionDocument` using its globally unique id. */
  deleteTransactionDocumentByNodeId?: Maybe<DeleteTransactionDocumentPayload>;
  /** Deletes a single `TransactionDocumentField` using a unique key. */
  deleteTransactionDocumentField?: Maybe<DeleteTransactionDocumentFieldPayload>;
  /** Deletes a single `TransactionDocumentField` using its globally unique id. */
  deleteTransactionDocumentFieldByNodeId?: Maybe<DeleteTransactionDocumentFieldPayload>;
  /** Deletes a single `TransactionDocumentSignature` using a unique key. */
  deleteTransactionDocumentSignature?: Maybe<DeleteTransactionDocumentSignaturePayload>;
  /** Deletes a single `TransactionDocumentSignature` using its globally unique id. */
  deleteTransactionDocumentSignatureByNodeId?: Maybe<DeleteTransactionDocumentSignaturePayload>;
  /** Deletes a single `TransactionFee` using a unique key. */
  deleteTransactionFee?: Maybe<DeleteTransactionFeePayload>;
  /** Deletes a single `TransactionFee` using its globally unique id. */
  deleteTransactionFeeByNodeId?: Maybe<DeleteTransactionFeePayload>;
  /** Deletes a single `TransactionGroup` using a unique key. */
  deleteTransactionGroup?: Maybe<DeleteTransactionGroupPayload>;
  /** Deletes a single `TransactionGroup` using its globally unique id. */
  deleteTransactionGroupByNodeId?: Maybe<DeleteTransactionGroupPayload>;
  /** Deletes a single `TransactionNote` using a unique key. */
  deleteTransactionNote?: Maybe<DeleteTransactionNotePayload>;
  /** Deletes a single `TransactionNote` using its globally unique id. */
  deleteTransactionNoteByNodeId?: Maybe<DeleteTransactionNotePayload>;
  /** Deletes a single `TransactionTaxRate` using a unique key. */
  deleteTransactionTaxRate?: Maybe<DeleteTransactionTaxRatePayload>;
  /** Deletes a single `TransactionTaxRate` using its globally unique id. */
  deleteTransactionTaxRateByNodeId?: Maybe<DeleteTransactionTaxRatePayload>;
  /** Deletes a single `User` using a unique key. */
  deleteUser?: Maybe<DeleteUserPayload>;
  /** Deletes a single `User` using its globally unique id. */
  deleteUserByNodeId?: Maybe<DeleteUserPayload>;
  /** Deletes a single `Vesting` using a unique key. */
  deleteVesting?: Maybe<DeleteVestingPayload>;
  /** Deletes a single `Vesting` using its globally unique id. */
  deleteVestingByNodeId?: Maybe<DeleteVestingPayload>;
  importSeller?: Maybe<ImportSellerPayload>;
  investorViewTlp?: Maybe<InvestorViewTlpPayload>;
  makeInvestorInvite?: Maybe<MakeInvestorInvitePayload>;
  transactionReadyForSigning?: Maybe<TransactionReadyForSigningPayload>;
  /** Updates a single `BuyerOffer` using a unique key and a patch. */
  updateBuyerOffer?: Maybe<UpdateBuyerOfferPayload>;
  /** Updates a single `BuyerOffer` using its globally unique id and a patch. */
  updateBuyerOfferByNodeId?: Maybe<UpdateBuyerOfferPayload>;
  /** Updates a single `BuyerOnboarding` using a unique key and a patch. */
  updateBuyerOnboarding?: Maybe<UpdateBuyerOnboardingPayload>;
  /** Updates a single `BuyerOnboarding` using its globally unique id and a patch. */
  updateBuyerOnboardingByNodeId?: Maybe<UpdateBuyerOnboardingPayload>;
  /** Updates a single `Company` using a unique key and a patch. */
  updateCompany?: Maybe<UpdateCompanyPayload>;
  /** Updates a single `Company` using its globally unique id and a patch. */
  updateCompanyByNodeId?: Maybe<UpdateCompanyPayload>;
  /** Updates a single `CompanyKeyPerson` using a unique key and a patch. */
  updateCompanyKeyPerson?: Maybe<UpdateCompanyKeyPersonPayload>;
  /** Updates a single `CompanyKeyPerson` using its globally unique id and a patch. */
  updateCompanyKeyPersonByNodeId?: Maybe<UpdateCompanyKeyPersonPayload>;
  /** Updates a single `Employment` using a unique key and a patch. */
  updateEmployment?: Maybe<UpdateEmploymentPayload>;
  /** Updates a single `Employment` using its globally unique id and a patch. */
  updateEmploymentByNodeId?: Maybe<UpdateEmploymentPayload>;
  /** Updates a single `EquityGrant` using a unique key and a patch. */
  updateEquityGrant?: Maybe<UpdateEquityGrantPayload>;
  /** Updates a single `EquityGrant` using a unique key and a patch. */
  updateEquityGrantByHolderTeamIdAndEquityScheme?: Maybe<UpdateEquityGrantPayload>;
  /** Updates a single `EquityGrant` using its globally unique id and a patch. */
  updateEquityGrantByNodeId?: Maybe<UpdateEquityGrantPayload>;
  /** Updates a single `EquityType` using a unique key and a patch. */
  updateEquityType?: Maybe<UpdateEquityTypePayload>;
  /** Updates a single `EquityType` using its globally unique id and a patch. */
  updateEquityTypeByNodeId?: Maybe<UpdateEquityTypePayload>;
  /** Updates a single `EquityTypeInfo` using a unique key and a patch. */
  updateEquityTypeInfo?: Maybe<UpdateEquityTypeInfoPayload>;
  /** Updates a single `EquityTypeInfo` using its globally unique id and a patch. */
  updateEquityTypeInfoByNodeId?: Maybe<UpdateEquityTypeInfoPayload>;
  /** Updates a single `File` using a unique key and a patch. */
  updateFile?: Maybe<UpdateFilePayload>;
  /** Updates a single `File` using its globally unique id and a patch. */
  updateFileByNodeId?: Maybe<UpdateFilePayload>;
  /** Updates a single `InvestorApproval` using a unique key and a patch. */
  updateInvestorApproval?: Maybe<UpdateInvestorApprovalPayload>;
  /** Updates a single `InvestorApproval` using its globally unique id and a patch. */
  updateInvestorApprovalByNodeId?: Maybe<UpdateInvestorApprovalPayload>;
  /** Updates a single `InvestorInvite` using a unique key and a patch. */
  updateInvestorInvite?: Maybe<UpdateInvestorInvitePayload>;
  /** Updates a single `InvestorInvite` using its globally unique id and a patch. */
  updateInvestorInviteByNodeId?: Maybe<UpdateInvestorInvitePayload>;
  /** Updates a single `LiquidityEvent` using a unique key and a patch. */
  updateLiquidityEvent?: Maybe<UpdateLiquidityEventPayload>;
  /** Updates a single `LiquidityEvent` using its globally unique id and a patch. */
  updateLiquidityEventByNodeId?: Maybe<UpdateLiquidityEventPayload>;
  /** Updates a single `LiquidityEventFaq` using a unique key and a patch. */
  updateLiquidityEventFaq?: Maybe<UpdateLiquidityEventFaqPayload>;
  /** Updates a single `LiquidityEventFaq` using its globally unique id and a patch. */
  updateLiquidityEventFaqByNodeId?: Maybe<UpdateLiquidityEventFaqPayload>;
  /** Updates a single `NdaMetadatum` using a unique key and a patch. */
  updateNdaMetadatum?: Maybe<UpdateNdaMetadatumPayload>;
  /** Updates a single `NdaMetadatum` using its globally unique id and a patch. */
  updateNdaMetadatumByNodeId?: Maybe<UpdateNdaMetadatumPayload>;
  /** Updates a single `OverallAllocation` using a unique key and a patch. */
  updateOverallAllocation?: Maybe<UpdateOverallAllocationPayload>;
  /** Updates a single `OverallAllocation` using its globally unique id and a patch. */
  updateOverallAllocationByNodeId?: Maybe<UpdateOverallAllocationPayload>;
  /** Updates a single `SellerOffer` using a unique key and a patch. */
  updateSellerOffer?: Maybe<UpdateSellerOfferPayload>;
  /** Updates a single `SellerOffer` using its globally unique id and a patch. */
  updateSellerOfferByNodeId?: Maybe<UpdateSellerOfferPayload>;
  /** Updates a single `SellerOnboarding` using a unique key and a patch. */
  updateSellerOnboarding?: Maybe<UpdateSellerOnboardingPayload>;
  /** Updates a single `SellerOnboarding` using its globally unique id and a patch. */
  updateSellerOnboardingByNodeId?: Maybe<UpdateSellerOnboardingPayload>;
  /** Updates a single `Stage2Access` using a unique key and a patch. */
  updateStage2Access?: Maybe<UpdateStage2AccessPayload>;
  /** Updates a single `Stage2Access` using its globally unique id and a patch. */
  updateStage2AccessByNodeId?: Maybe<UpdateStage2AccessPayload>;
  /** Updates a single `TaxRate` using a unique key and a patch. */
  updateTaxRate?: Maybe<UpdateTaxRatePayload>;
  /** Updates a single `TaxRate` using its globally unique id and a patch. */
  updateTaxRateByNodeId?: Maybe<UpdateTaxRatePayload>;
  /** Updates a single `Team` using a unique key and a patch. */
  updateTeam?: Maybe<UpdateTeamPayload>;
  /** Updates a single `Team` using its globally unique id and a patch. */
  updateTeamByNodeId?: Maybe<UpdateTeamPayload>;
  /** Updates a single `TeamMembership` using a unique key and a patch. */
  updateTeamMembership?: Maybe<UpdateTeamMembershipPayload>;
  /** Updates a single `TeamMembership` using its globally unique id and a patch. */
  updateTeamMembershipByNodeId?: Maybe<UpdateTeamMembershipPayload>;
  /** Updates a single `Transaction` using a unique key and a patch. */
  updateTransaction?: Maybe<UpdateTransactionPayload>;
  /** Updates a single `TransactionAllowance` using a unique key and a patch. */
  updateTransactionAllowance?: Maybe<UpdateTransactionAllowancePayload>;
  /** Updates a single `TransactionAllowance` using its globally unique id and a patch. */
  updateTransactionAllowanceByNodeId?: Maybe<UpdateTransactionAllowancePayload>;
  /** Updates a single `TransactionApproval` using a unique key and a patch. */
  updateTransactionApproval?: Maybe<UpdateTransactionApprovalPayload>;
  /** Updates a single `TransactionApproval` using its globally unique id and a patch. */
  updateTransactionApprovalByNodeId?: Maybe<UpdateTransactionApprovalPayload>;
  /** Updates a single `Transaction` using its globally unique id and a patch. */
  updateTransactionByNodeId?: Maybe<UpdateTransactionPayload>;
  /** Updates a single `TransactionDocument` using a unique key and a patch. */
  updateTransactionDocument?: Maybe<UpdateTransactionDocumentPayload>;
  /** Updates a single `TransactionDocument` using its globally unique id and a patch. */
  updateTransactionDocumentByNodeId?: Maybe<UpdateTransactionDocumentPayload>;
  /** Updates a single `TransactionDocumentField` using a unique key and a patch. */
  updateTransactionDocumentField?: Maybe<UpdateTransactionDocumentFieldPayload>;
  /** Updates a single `TransactionDocumentField` using its globally unique id and a patch. */
  updateTransactionDocumentFieldByNodeId?: Maybe<UpdateTransactionDocumentFieldPayload>;
  /** Updates a single `TransactionDocumentSignature` using a unique key and a patch. */
  updateTransactionDocumentSignature?: Maybe<UpdateTransactionDocumentSignaturePayload>;
  /** Updates a single `TransactionDocumentSignature` using its globally unique id and a patch. */
  updateTransactionDocumentSignatureByNodeId?: Maybe<UpdateTransactionDocumentSignaturePayload>;
  /** Updates a single `TransactionFee` using a unique key and a patch. */
  updateTransactionFee?: Maybe<UpdateTransactionFeePayload>;
  /** Updates a single `TransactionFee` using its globally unique id and a patch. */
  updateTransactionFeeByNodeId?: Maybe<UpdateTransactionFeePayload>;
  /** Updates a single `TransactionGroup` using a unique key and a patch. */
  updateTransactionGroup?: Maybe<UpdateTransactionGroupPayload>;
  /** Updates a single `TransactionGroup` using its globally unique id and a patch. */
  updateTransactionGroupByNodeId?: Maybe<UpdateTransactionGroupPayload>;
  /** Updates a single `TransactionNote` using a unique key and a patch. */
  updateTransactionNote?: Maybe<UpdateTransactionNotePayload>;
  /** Updates a single `TransactionNote` using its globally unique id and a patch. */
  updateTransactionNoteByNodeId?: Maybe<UpdateTransactionNotePayload>;
  /** Updates a single `TransactionTaxRate` using a unique key and a patch. */
  updateTransactionTaxRate?: Maybe<UpdateTransactionTaxRatePayload>;
  /** Updates a single `TransactionTaxRate` using its globally unique id and a patch. */
  updateTransactionTaxRateByNodeId?: Maybe<UpdateTransactionTaxRatePayload>;
  /** Updates a single `User` using a unique key and a patch. */
  updateUser?: Maybe<UpdateUserPayload>;
  /** Updates a single `User` using its globally unique id and a patch. */
  updateUserByNodeId?: Maybe<UpdateUserPayload>;
  /** Updates a single `Vesting` using a unique key and a patch. */
  updateVesting?: Maybe<UpdateVestingPayload>;
  /** Updates a single `Vesting` using its globally unique id and a patch. */
  updateVestingByNodeId?: Maybe<UpdateVestingPayload>;
  /** Upserts a single `BuyerOffer`. */
  upsertBuyerOffer?: Maybe<UpsertBuyerOfferPayload>;
  /** Upserts a single `BuyerOnboarding`. */
  upsertBuyerOnboarding?: Maybe<UpsertBuyerOnboardingPayload>;
  /** Upserts a single `Company`. */
  upsertCompany?: Maybe<UpsertCompanyPayload>;
  /** Upserts a single `CompanyKeyPerson`. */
  upsertCompanyKeyPerson?: Maybe<UpsertCompanyKeyPersonPayload>;
  /** Upserts a single `Employment`. */
  upsertEmployment?: Maybe<UpsertEmploymentPayload>;
  /** Upserts a single `EquityGrant`. */
  upsertEquityGrant?: Maybe<UpsertEquityGrantPayload>;
  /** Upserts a single `EquityType`. */
  upsertEquityType?: Maybe<UpsertEquityTypePayload>;
  /** Upserts a single `EquityTypeInfo`. */
  upsertEquityTypeInfo?: Maybe<UpsertEquityTypeInfoPayload>;
  /** Upserts a single `File`. */
  upsertFile?: Maybe<UpsertFilePayload>;
  /** Upserts a single `InvestorApproval`. */
  upsertInvestorApproval?: Maybe<UpsertInvestorApprovalPayload>;
  /** Upserts a single `InvestorInvite`. */
  upsertInvestorInvite?: Maybe<UpsertInvestorInvitePayload>;
  /** Upserts a single `LiquidityEvent`. */
  upsertLiquidityEvent?: Maybe<UpsertLiquidityEventPayload>;
  /** Upserts a single `LiquidityEventFaq`. */
  upsertLiquidityEventFaq?: Maybe<UpsertLiquidityEventFaqPayload>;
  /** Upserts a single `NdaMetadatum`. */
  upsertNdaMetadatum?: Maybe<UpsertNdaMetadatumPayload>;
  /** Upserts a single `OverallAllocation`. */
  upsertOverallAllocation?: Maybe<UpsertOverallAllocationPayload>;
  /** Upserts a single `SellerOffer`. */
  upsertSellerOffer?: Maybe<UpsertSellerOfferPayload>;
  /** Upserts a single `SellerOnboarding`. */
  upsertSellerOnboarding?: Maybe<UpsertSellerOnboardingPayload>;
  /** Upserts a single `Stage2Access`. */
  upsertStage2Access?: Maybe<UpsertStage2AccessPayload>;
  /** Upserts a single `TaxRate`. */
  upsertTaxRate?: Maybe<UpsertTaxRatePayload>;
  /** Upserts a single `Team`. */
  upsertTeam?: Maybe<UpsertTeamPayload>;
  /** Upserts a single `TeamMembership`. */
  upsertTeamMembership?: Maybe<UpsertTeamMembershipPayload>;
  /** Upserts a single `Transaction`. */
  upsertTransaction?: Maybe<UpsertTransactionPayload>;
  /** Upserts a single `TransactionAllowance`. */
  upsertTransactionAllowance?: Maybe<UpsertTransactionAllowancePayload>;
  /** Upserts a single `TransactionApproval`. */
  upsertTransactionApproval?: Maybe<UpsertTransactionApprovalPayload>;
  /** Upserts a single `TransactionDocument`. */
  upsertTransactionDocument?: Maybe<UpsertTransactionDocumentPayload>;
  /** Upserts a single `TransactionDocumentField`. */
  upsertTransactionDocumentField?: Maybe<UpsertTransactionDocumentFieldPayload>;
  /** Upserts a single `TransactionDocumentSignature`. */
  upsertTransactionDocumentSignature?: Maybe<UpsertTransactionDocumentSignaturePayload>;
  /** Upserts a single `TransactionFee`. */
  upsertTransactionFee?: Maybe<UpsertTransactionFeePayload>;
  /** Upserts a single `TransactionGroup`. */
  upsertTransactionGroup?: Maybe<UpsertTransactionGroupPayload>;
  /** Upserts a single `TransactionNote`. */
  upsertTransactionNote?: Maybe<UpsertTransactionNotePayload>;
  /** Upserts a single `TransactionTaxRate`. */
  upsertTransactionTaxRate?: Maybe<UpsertTransactionTaxRatePayload>;
  /** Upserts a single `User`. */
  upsertUser?: Maybe<UpsertUserPayload>;
  /** Upserts a single `Vesting`. */
  upsertVesting?: Maybe<UpsertVestingPayload>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationApproveTransactionArgs = {
  input: ApproveTransactionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationClaimInvestorInviteArgs = {
  input: ClaimInvestorInviteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationConfirmMyOfferArgs = {
  input: ConfirmMyOfferInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationConfirmOfferInTransactionArgs = {
  input: ConfirmOfferInTransactionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateBlacklistedUsersForEventArgs = {
  input: CreateBlacklistedUsersForEventInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateBuyerOfferArgs = {
  input: CreateBuyerOfferInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateBuyerOnboardingArgs = {
  input: CreateBuyerOnboardingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateBuyerPurchaseAgreementArgs = {
  input: CreateBuyerPurchaseAgreementInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCommentsAboutTeamArgs = {
  input: CreateCommentsAboutTeamInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCompanyKeyPersonArgs = {
  input: CreateCompanyKeyPersonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCurrentUserArgs = {
  input: CreateCurrentUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEmailArgs = {
  input: CreateEmailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEmploymentArgs = {
  input: CreateEmploymentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEquityGrantArgs = {
  input: CreateEquityGrantInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEquitySummaryArgs = {
  input: CreateEquitySummaryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEquityTypeArgs = {
  input: CreateEquityTypeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEquityTypeInfoArgs = {
  input: CreateEquityTypeInfoInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFileArgs = {
  input: CreateFileInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFileViewArgs = {
  input: CreateFileViewInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateInvestorApprovalArgs = {
  input: CreateInvestorApprovalInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateInvestorInviteArgs = {
  input: CreateInvestorInviteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLiquidityEventArgs = {
  input: CreateLiquidityEventInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLiquidityEventFaqArgs = {
  input: CreateLiquidityEventFaqInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateNdaMetadatumArgs = {
  input: CreateNdaMetadatumInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateOverallAllocationArgs = {
  input: CreateOverallAllocationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePermissionArgs = {
  input: CreatePermissionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSellerArgs = {
  input: CreateSellerInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSellerOfferArgs = {
  input: CreateSellerOfferInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSellerOnboardingArgs = {
  input: CreateSellerOnboardingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSignedDocumentArgs = {
  input: CreateSignedDocumentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateStage2AccessArgs = {
  input: CreateStage2AccessInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSuggestedTaxArgs = {
  input: CreateSuggestedTaxInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTaxRateArgs = {
  input: CreateTaxRateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTaxVariableArgs = {
  input: CreateTaxVariableInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTeamArgs = {
  input: CreateTeamInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTeamMembershipArgs = {
  input: CreateTeamMembershipInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTransactionArgs = {
  input: CreateTransactionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTransactionAllowanceArgs = {
  input: CreateTransactionAllowanceInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTransactionApprovalArgs = {
  input: CreateTransactionApprovalInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTransactionDetailArgs = {
  input: CreateTransactionDetailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTransactionDocumentArgs = {
  input: CreateTransactionDocumentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTransactionDocumentFieldArgs = {
  input: CreateTransactionDocumentFieldInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTransactionDocumentSignatureArgs = {
  input: CreateTransactionDocumentSignatureInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTransactionFeeArgs = {
  input: CreateTransactionFeeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTransactionGroupArgs = {
  input: CreateTransactionGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTransactionGroupCurrencyArgs = {
  input: CreateTransactionGroupCurrencyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTransactionNoteArgs = {
  input: CreateTransactionNoteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTransactionSummaryExerciseSaleArgs = {
  input: CreateTransactionSummaryExerciseSaleInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTransactionTaxRateArgs = {
  input: CreateTransactionTaxRateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateVestingArgs = {
  input: CreateVestingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateVestingScheduleArgs = {
  input: CreateVestingScheduleInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBuyerOfferArgs = {
  input: DeleteBuyerOfferInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBuyerOfferByNodeIdArgs = {
  input: DeleteBuyerOfferByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBuyerOnboardingArgs = {
  input: DeleteBuyerOnboardingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBuyerOnboardingByNodeIdArgs = {
  input: DeleteBuyerOnboardingByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyArgs = {
  input: DeleteCompanyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyByNodeIdArgs = {
  input: DeleteCompanyByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyKeyPersonArgs = {
  input: DeleteCompanyKeyPersonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCompanyKeyPersonByNodeIdArgs = {
  input: DeleteCompanyKeyPersonByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEmploymentArgs = {
  input: DeleteEmploymentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEmploymentByNodeIdArgs = {
  input: DeleteEmploymentByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEquityGrantArgs = {
  input: DeleteEquityGrantInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEquityGrantByHolderTeamIdAndEquitySchemeArgs = {
  input: DeleteEquityGrantByHolderTeamIdAndEquitySchemeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEquityGrantByNodeIdArgs = {
  input: DeleteEquityGrantByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEquityTypeArgs = {
  input: DeleteEquityTypeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEquityTypeByNodeIdArgs = {
  input: DeleteEquityTypeByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEquityTypeInfoArgs = {
  input: DeleteEquityTypeInfoInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEquityTypeInfoByNodeIdArgs = {
  input: DeleteEquityTypeInfoByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFileArgs = {
  input: DeleteFileInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFileByNodeIdArgs = {
  input: DeleteFileByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteInvestorApprovalArgs = {
  input: DeleteInvestorApprovalInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteInvestorApprovalByNodeIdArgs = {
  input: DeleteInvestorApprovalByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteInvestorInviteArgs = {
  input: DeleteInvestorInviteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteInvestorInviteByNodeIdArgs = {
  input: DeleteInvestorInviteByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLiquidityEventArgs = {
  input: DeleteLiquidityEventInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLiquidityEventByNodeIdArgs = {
  input: DeleteLiquidityEventByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLiquidityEventFaqArgs = {
  input: DeleteLiquidityEventFaqInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLiquidityEventFaqByNodeIdArgs = {
  input: DeleteLiquidityEventFaqByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNdaMetadatumArgs = {
  input: DeleteNdaMetadatumInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNdaMetadatumByNodeIdArgs = {
  input: DeleteNdaMetadatumByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOverallAllocationArgs = {
  input: DeleteOverallAllocationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOverallAllocationByNodeIdArgs = {
  input: DeleteOverallAllocationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSellerOfferArgs = {
  input: DeleteSellerOfferInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSellerOfferByNodeIdArgs = {
  input: DeleteSellerOfferByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSellerOnboardingArgs = {
  input: DeleteSellerOnboardingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSellerOnboardingByNodeIdArgs = {
  input: DeleteSellerOnboardingByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteStage2AccessArgs = {
  input: DeleteStage2AccessInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteStage2AccessByNodeIdArgs = {
  input: DeleteStage2AccessByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTaxRateArgs = {
  input: DeleteTaxRateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTaxRateByNodeIdArgs = {
  input: DeleteTaxRateByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTeamArgs = {
  input: DeleteTeamInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTeamByNodeIdArgs = {
  input: DeleteTeamByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTeamMembershipArgs = {
  input: DeleteTeamMembershipInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTeamMembershipByNodeIdArgs = {
  input: DeleteTeamMembershipByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTransactionArgs = {
  input: DeleteTransactionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTransactionAllowanceArgs = {
  input: DeleteTransactionAllowanceInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTransactionAllowanceByNodeIdArgs = {
  input: DeleteTransactionAllowanceByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTransactionApprovalArgs = {
  input: DeleteTransactionApprovalInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTransactionApprovalByNodeIdArgs = {
  input: DeleteTransactionApprovalByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTransactionByNodeIdArgs = {
  input: DeleteTransactionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTransactionDocumentArgs = {
  input: DeleteTransactionDocumentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTransactionDocumentByNodeIdArgs = {
  input: DeleteTransactionDocumentByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTransactionDocumentFieldArgs = {
  input: DeleteTransactionDocumentFieldInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTransactionDocumentFieldByNodeIdArgs = {
  input: DeleteTransactionDocumentFieldByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTransactionDocumentSignatureArgs = {
  input: DeleteTransactionDocumentSignatureInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTransactionDocumentSignatureByNodeIdArgs = {
  input: DeleteTransactionDocumentSignatureByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTransactionFeeArgs = {
  input: DeleteTransactionFeeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTransactionFeeByNodeIdArgs = {
  input: DeleteTransactionFeeByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTransactionGroupArgs = {
  input: DeleteTransactionGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTransactionGroupByNodeIdArgs = {
  input: DeleteTransactionGroupByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTransactionNoteArgs = {
  input: DeleteTransactionNoteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTransactionNoteByNodeIdArgs = {
  input: DeleteTransactionNoteByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTransactionTaxRateArgs = {
  input: DeleteTransactionTaxRateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTransactionTaxRateByNodeIdArgs = {
  input: DeleteTransactionTaxRateByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserByNodeIdArgs = {
  input: DeleteUserByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteVestingArgs = {
  input: DeleteVestingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteVestingByNodeIdArgs = {
  input: DeleteVestingByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationImportSellerArgs = {
  input: ImportSellerInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationInvestorViewTlpArgs = {
  input: InvestorViewTlpInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationMakeInvestorInviteArgs = {
  input: MakeInvestorInviteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationTransactionReadyForSigningArgs = {
  input: TransactionReadyForSigningInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBuyerOfferArgs = {
  input: UpdateBuyerOfferInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBuyerOfferByNodeIdArgs = {
  input: UpdateBuyerOfferByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBuyerOnboardingArgs = {
  input: UpdateBuyerOnboardingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBuyerOnboardingByNodeIdArgs = {
  input: UpdateBuyerOnboardingByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyByNodeIdArgs = {
  input: UpdateCompanyByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyKeyPersonArgs = {
  input: UpdateCompanyKeyPersonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCompanyKeyPersonByNodeIdArgs = {
  input: UpdateCompanyKeyPersonByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEmploymentArgs = {
  input: UpdateEmploymentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEmploymentByNodeIdArgs = {
  input: UpdateEmploymentByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEquityGrantArgs = {
  input: UpdateEquityGrantInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEquityGrantByHolderTeamIdAndEquitySchemeArgs = {
  input: UpdateEquityGrantByHolderTeamIdAndEquitySchemeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEquityGrantByNodeIdArgs = {
  input: UpdateEquityGrantByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEquityTypeArgs = {
  input: UpdateEquityTypeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEquityTypeByNodeIdArgs = {
  input: UpdateEquityTypeByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEquityTypeInfoArgs = {
  input: UpdateEquityTypeInfoInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEquityTypeInfoByNodeIdArgs = {
  input: UpdateEquityTypeInfoByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFileArgs = {
  input: UpdateFileInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFileByNodeIdArgs = {
  input: UpdateFileByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateInvestorApprovalArgs = {
  input: UpdateInvestorApprovalInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateInvestorApprovalByNodeIdArgs = {
  input: UpdateInvestorApprovalByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateInvestorInviteArgs = {
  input: UpdateInvestorInviteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateInvestorInviteByNodeIdArgs = {
  input: UpdateInvestorInviteByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLiquidityEventArgs = {
  input: UpdateLiquidityEventInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLiquidityEventByNodeIdArgs = {
  input: UpdateLiquidityEventByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLiquidityEventFaqArgs = {
  input: UpdateLiquidityEventFaqInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLiquidityEventFaqByNodeIdArgs = {
  input: UpdateLiquidityEventFaqByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNdaMetadatumArgs = {
  input: UpdateNdaMetadatumInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNdaMetadatumByNodeIdArgs = {
  input: UpdateNdaMetadatumByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOverallAllocationArgs = {
  input: UpdateOverallAllocationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOverallAllocationByNodeIdArgs = {
  input: UpdateOverallAllocationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSellerOfferArgs = {
  input: UpdateSellerOfferInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSellerOfferByNodeIdArgs = {
  input: UpdateSellerOfferByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSellerOnboardingArgs = {
  input: UpdateSellerOnboardingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSellerOnboardingByNodeIdArgs = {
  input: UpdateSellerOnboardingByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateStage2AccessArgs = {
  input: UpdateStage2AccessInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateStage2AccessByNodeIdArgs = {
  input: UpdateStage2AccessByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTaxRateArgs = {
  input: UpdateTaxRateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTaxRateByNodeIdArgs = {
  input: UpdateTaxRateByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTeamArgs = {
  input: UpdateTeamInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTeamByNodeIdArgs = {
  input: UpdateTeamByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTeamMembershipArgs = {
  input: UpdateTeamMembershipInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTeamMembershipByNodeIdArgs = {
  input: UpdateTeamMembershipByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTransactionArgs = {
  input: UpdateTransactionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTransactionAllowanceArgs = {
  input: UpdateTransactionAllowanceInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTransactionAllowanceByNodeIdArgs = {
  input: UpdateTransactionAllowanceByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTransactionApprovalArgs = {
  input: UpdateTransactionApprovalInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTransactionApprovalByNodeIdArgs = {
  input: UpdateTransactionApprovalByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTransactionByNodeIdArgs = {
  input: UpdateTransactionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTransactionDocumentArgs = {
  input: UpdateTransactionDocumentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTransactionDocumentByNodeIdArgs = {
  input: UpdateTransactionDocumentByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTransactionDocumentFieldArgs = {
  input: UpdateTransactionDocumentFieldInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTransactionDocumentFieldByNodeIdArgs = {
  input: UpdateTransactionDocumentFieldByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTransactionDocumentSignatureArgs = {
  input: UpdateTransactionDocumentSignatureInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTransactionDocumentSignatureByNodeIdArgs = {
  input: UpdateTransactionDocumentSignatureByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTransactionFeeArgs = {
  input: UpdateTransactionFeeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTransactionFeeByNodeIdArgs = {
  input: UpdateTransactionFeeByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTransactionGroupArgs = {
  input: UpdateTransactionGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTransactionGroupByNodeIdArgs = {
  input: UpdateTransactionGroupByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTransactionNoteArgs = {
  input: UpdateTransactionNoteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTransactionNoteByNodeIdArgs = {
  input: UpdateTransactionNoteByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTransactionTaxRateArgs = {
  input: UpdateTransactionTaxRateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTransactionTaxRateByNodeIdArgs = {
  input: UpdateTransactionTaxRateByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserByNodeIdArgs = {
  input: UpdateUserByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVestingArgs = {
  input: UpdateVestingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVestingByNodeIdArgs = {
  input: UpdateVestingByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertBuyerOfferArgs = {
  input: UpsertBuyerOfferInput;
  where?: InputMaybe<UpsertBuyerOfferWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertBuyerOnboardingArgs = {
  input: UpsertBuyerOnboardingInput;
  where?: InputMaybe<UpsertBuyerOnboardingWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertCompanyArgs = {
  input: UpsertCompanyInput;
  where?: InputMaybe<UpsertCompanyWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertCompanyKeyPersonArgs = {
  input: UpsertCompanyKeyPersonInput;
  where?: InputMaybe<UpsertCompanyKeyPersonWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertEmploymentArgs = {
  input: UpsertEmploymentInput;
  where?: InputMaybe<UpsertEmploymentWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertEquityGrantArgs = {
  input: UpsertEquityGrantInput;
  where?: InputMaybe<UpsertEquityGrantWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertEquityTypeArgs = {
  input: UpsertEquityTypeInput;
  where?: InputMaybe<UpsertEquityTypeWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertEquityTypeInfoArgs = {
  input: UpsertEquityTypeInfoInput;
  where?: InputMaybe<UpsertEquityTypeInfoWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertFileArgs = {
  input: UpsertFileInput;
  where?: InputMaybe<UpsertFileWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertInvestorApprovalArgs = {
  input: UpsertInvestorApprovalInput;
  where?: InputMaybe<UpsertInvestorApprovalWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertInvestorInviteArgs = {
  input: UpsertInvestorInviteInput;
  where?: InputMaybe<UpsertInvestorInviteWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertLiquidityEventArgs = {
  input: UpsertLiquidityEventInput;
  where?: InputMaybe<UpsertLiquidityEventWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertLiquidityEventFaqArgs = {
  input: UpsertLiquidityEventFaqInput;
  where?: InputMaybe<UpsertLiquidityEventFaqWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertNdaMetadatumArgs = {
  input: UpsertNdaMetadatumInput;
  where?: InputMaybe<UpsertNdaMetadatumWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertOverallAllocationArgs = {
  input: UpsertOverallAllocationInput;
  where?: InputMaybe<UpsertOverallAllocationWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertSellerOfferArgs = {
  input: UpsertSellerOfferInput;
  where?: InputMaybe<UpsertSellerOfferWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertSellerOnboardingArgs = {
  input: UpsertSellerOnboardingInput;
  where?: InputMaybe<UpsertSellerOnboardingWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertStage2AccessArgs = {
  input: UpsertStage2AccessInput;
  where?: InputMaybe<UpsertStage2AccessWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertTaxRateArgs = {
  input: UpsertTaxRateInput;
  where?: InputMaybe<UpsertTaxRateWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertTeamArgs = {
  input: UpsertTeamInput;
  where?: InputMaybe<UpsertTeamWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertTeamMembershipArgs = {
  input: UpsertTeamMembershipInput;
  where?: InputMaybe<UpsertTeamMembershipWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertTransactionArgs = {
  input: UpsertTransactionInput;
  where?: InputMaybe<UpsertTransactionWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertTransactionAllowanceArgs = {
  input: UpsertTransactionAllowanceInput;
  where?: InputMaybe<UpsertTransactionAllowanceWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertTransactionApprovalArgs = {
  input: UpsertTransactionApprovalInput;
  where?: InputMaybe<UpsertTransactionApprovalWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertTransactionDocumentArgs = {
  input: UpsertTransactionDocumentInput;
  where?: InputMaybe<UpsertTransactionDocumentWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertTransactionDocumentFieldArgs = {
  input: UpsertTransactionDocumentFieldInput;
  where?: InputMaybe<UpsertTransactionDocumentFieldWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertTransactionDocumentSignatureArgs = {
  input: UpsertTransactionDocumentSignatureInput;
  where?: InputMaybe<UpsertTransactionDocumentSignatureWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertTransactionFeeArgs = {
  input: UpsertTransactionFeeInput;
  where?: InputMaybe<UpsertTransactionFeeWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertTransactionGroupArgs = {
  input: UpsertTransactionGroupInput;
  where?: InputMaybe<UpsertTransactionGroupWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertTransactionNoteArgs = {
  input: UpsertTransactionNoteInput;
  where?: InputMaybe<UpsertTransactionNoteWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertTransactionTaxRateArgs = {
  input: UpsertTransactionTaxRateInput;
  where?: InputMaybe<UpsertTransactionTaxRateWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertUserArgs = {
  input: UpsertUserInput;
  where?: InputMaybe<UpsertUserWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertVestingArgs = {
  input: UpsertVestingInput;
  where?: InputMaybe<UpsertVestingWhere>;
};

/** A connection to a list of `NdaMetadatum` values. */
export type NdaMetadataConnection = {
  __typename?: 'NdaMetadataConnection';
  /** A list of edges which contains the `NdaMetadatum` and cursor to aid in pagination. */
  edges: Array<NdaMetadataEdge>;
  /** A list of `NdaMetadatum` objects. */
  nodes: Array<NdaMetadatum>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `NdaMetadatum` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `NdaMetadatum` edge in the connection. */
export type NdaMetadataEdge = {
  __typename?: 'NdaMetadataEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `NdaMetadatum` at the end of the edge. */
  node: NdaMetadatum;
};

/** Methods to use when ordering `NdaMetadatum`. */
export enum NdaMetadataOrderBy {
  LiquidityEventAsc = 'LIQUIDITY_EVENT_ASC',
  LiquidityEventDesc = 'LIQUIDITY_EVENT_DESC',
  MetadataAsc = 'METADATA_ASC',
  MetadataDesc = 'METADATA_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TeamIdAsc = 'TEAM_ID_ASC',
  TeamIdDesc = 'TEAM_ID_DESC'
}

export type NdaMetadatum = Node & {
  __typename?: 'NdaMetadatum';
  liquidityEvent: Scalars['String'];
  /** Reads a single `LiquidityEvent` that is related to this `NdaMetadatum`. */
  liquidityEventByLiquidityEvent?: Maybe<LiquidityEvent>;
  metadata?: Maybe<Scalars['JSON']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Team` that is related to this `NdaMetadatum`. */
  team?: Maybe<Team>;
  teamId: Scalars['String'];
};

/**
 * A condition to be used against `NdaMetadatum` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type NdaMetadatumCondition = {
  /** Checks for equality with the object’s `liquidityEvent` field. */
  liquidityEvent?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `metadata` field. */
  metadata?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `teamId` field. */
  teamId?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `NdaMetadatum` object types. All fields are combined with a logical ‘and.’ */
export type NdaMetadatumFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<NdaMetadatumFilter>>;
  /** Filter by the object’s `liquidityEvent` field. */
  liquidityEvent?: InputMaybe<StringFilter>;
  /** Filter by the object’s `metadata` field. */
  metadata?: InputMaybe<JsonFilter>;
  /** Negates the expression. */
  not?: InputMaybe<NdaMetadatumFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<NdaMetadatumFilter>>;
  /** Filter by the object’s `teamId` field. */
  teamId?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `NdaMetadatum` */
export type NdaMetadatumInput = {
  liquidityEvent: Scalars['String'];
  metadata?: InputMaybe<Scalars['JSON']>;
  teamId: Scalars['String'];
};

/** Represents an update to a `NdaMetadatum`. Fields that are set will be updated. */
export type NdaMetadatumPatch = {
  liquidityEvent?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  teamId?: InputMaybe<Scalars['String']>;
};

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

/** This is a dummy table for postgraphile, don't write/read from it \n @omit all */
export type NonSellersInTransactionGroup = {
  __typename?: 'NonSellersInTransactionGroup';
  allocation?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  equityGranted?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  originalCrowdcubeId?: Maybe<Scalars['Int']>;
  /** Reads a single `Team` that is related to this `NonSellersInTransactionGroup`. */
  team?: Maybe<Team>;
  teamId?: Maybe<Scalars['String']>;
  /** Reads a single `TransactionGroup` that is related to this `NonSellersInTransactionGroup`. */
  transactionGroup?: Maybe<TransactionGroup>;
  transactionGroupId?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `NonSellersInTransactionGroup`. */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

/** A filter to be used against `NonSellersInTransactionGroup` object types. All fields are combined with a logical ‘and.’ */
export type NonSellersInTransactionGroupFilter = {
  /** Filter by the object’s `allocation` field. */
  allocation?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<NonSellersInTransactionGroupFilter>>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Filter by the object’s `equityGranted` field. */
  equityGranted?: InputMaybe<IntFilter>;
  /** Filter by the object’s `fullName` field. */
  fullName?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<NonSellersInTransactionGroupFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<NonSellersInTransactionGroupFilter>>;
  /** Filter by the object’s `originalCrowdcubeId` field. */
  originalCrowdcubeId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `teamId` field. */
  teamId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `transactionGroupId` field. */
  transactionGroupId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<StringFilter>;
};

/** A connection to a list of `NonSellersInTransactionGroup` values. */
export type NonSellersInTransactionGroupsConnection = {
  __typename?: 'NonSellersInTransactionGroupsConnection';
  /** A list of edges which contains the `NonSellersInTransactionGroup` and cursor to aid in pagination. */
  edges: Array<NonSellersInTransactionGroupsEdge>;
  /** A list of `NonSellersInTransactionGroup` objects. */
  nodes: Array<NonSellersInTransactionGroup>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `NonSellersInTransactionGroup` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `NonSellersInTransactionGroup` edge in the connection. */
export type NonSellersInTransactionGroupsEdge = {
  __typename?: 'NonSellersInTransactionGroupsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `NonSellersInTransactionGroup` at the end of the edge. */
  node: NonSellersInTransactionGroup;
};

/** Methods to use when ordering `NonSellersInTransactionGroup`. */
export enum NonSellersInTransactionGroupsOrderBy {
  AllocationAsc = 'ALLOCATION_ASC',
  AllocationDesc = 'ALLOCATION_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  EquityGrantedAsc = 'EQUITY_GRANTED_ASC',
  EquityGrantedDesc = 'EQUITY_GRANTED_DESC',
  FullNameAsc = 'FULL_NAME_ASC',
  FullNameDesc = 'FULL_NAME_DESC',
  Natural = 'NATURAL',
  OriginalCrowdcubeIdAsc = 'ORIGINAL_CROWDCUBE_ID_ASC',
  OriginalCrowdcubeIdDesc = 'ORIGINAL_CROWDCUBE_ID_DESC',
  TeamIdAsc = 'TEAM_ID_ASC',
  TeamIdDesc = 'TEAM_ID_DESC',
  TransactionGroupIdAsc = 'TRANSACTION_GROUP_ID_ASC',
  TransactionGroupIdDesc = 'TRANSACTION_GROUP_ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type OverallAllocation = Node & {
  __typename?: 'OverallAllocation';
  amount: Scalars['Int'];
  applied: Scalars['Boolean'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  seller: Scalars['String'];
  /** Reads a single `Team` that is related to this `OverallAllocation`. */
  teamBySeller?: Maybe<Team>;
  /** Reads a single `TransactionGroup` that is related to this `OverallAllocation`. */
  transactionGroup?: Maybe<TransactionGroup>;
  transactionGroupId: Scalars['String'];
};

/**
 * A condition to be used against `OverallAllocation` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type OverallAllocationCondition = {
  /** Checks for equality with the object’s `amount` field. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `applied` field. */
  applied?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `seller` field. */
  seller?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `transactionGroupId` field. */
  transactionGroupId?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `OverallAllocation` object types. All fields are combined with a logical ‘and.’ */
export type OverallAllocationFilter = {
  /** Filter by the object’s `amount` field. */
  amount?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OverallAllocationFilter>>;
  /** Filter by the object’s `applied` field. */
  applied?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OverallAllocationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OverallAllocationFilter>>;
  /** Filter by the object’s `seller` field. */
  seller?: InputMaybe<StringFilter>;
  /** Filter by the object’s `transactionGroupId` field. */
  transactionGroupId?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `OverallAllocation` */
export type OverallAllocationInput = {
  amount: Scalars['Int'];
  applied?: InputMaybe<Scalars['Boolean']>;
  seller: Scalars['String'];
  transactionGroupId: Scalars['String'];
};

/** Represents an update to a `OverallAllocation`. Fields that are set will be updated. */
export type OverallAllocationPatch = {
  amount?: InputMaybe<Scalars['Int']>;
  applied?: InputMaybe<Scalars['Boolean']>;
  seller?: InputMaybe<Scalars['String']>;
  transactionGroupId?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `OverallAllocation` values. */
export type OverallAllocationsConnection = {
  __typename?: 'OverallAllocationsConnection';
  /** A list of edges which contains the `OverallAllocation` and cursor to aid in pagination. */
  edges: Array<OverallAllocationsEdge>;
  /** A list of `OverallAllocation` objects. */
  nodes: Array<OverallAllocation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OverallAllocation` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `OverallAllocation` edge in the connection. */
export type OverallAllocationsEdge = {
  __typename?: 'OverallAllocationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `OverallAllocation` at the end of the edge. */
  node: OverallAllocation;
};

/** Methods to use when ordering `OverallAllocation`. */
export enum OverallAllocationsOrderBy {
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  AppliedAsc = 'APPLIED_ASC',
  AppliedDesc = 'APPLIED_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SellerAsc = 'SELLER_ASC',
  SellerDesc = 'SELLER_DESC',
  TransactionGroupIdAsc = 'TRANSACTION_GROUP_ID_ASC',
  TransactionGroupIdDesc = 'TRANSACTION_GROUP_ID_DESC'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']>;
};

export type Permission = {
  __typename?: 'Permission';
  admin: Scalars['Boolean'];
  /** Reads a single `User` that is related to this `Permission`. */
  user?: Maybe<User>;
  userId: Scalars['String'];
};

/**
 * A condition to be used against `Permission` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type PermissionCondition = {
  /** Checks for equality with the object’s `admin` field. */
  admin?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `Permission` object types. All fields are combined with a logical ‘and.’ */
export type PermissionFilter = {
  /** Filter by the object’s `admin` field. */
  admin?: InputMaybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PermissionFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<PermissionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PermissionFilter>>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `Permission` */
export type PermissionInput = {
  admin?: InputMaybe<Scalars['Boolean']>;
  userId: Scalars['String'];
};

/** A connection to a list of `Permission` values. */
export type PermissionsConnection = {
  __typename?: 'PermissionsConnection';
  /** A list of edges which contains the `Permission` and cursor to aid in pagination. */
  edges: Array<PermissionsEdge>;
  /** A list of `Permission` objects. */
  nodes: Array<Permission>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Permission` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Permission` edge in the connection. */
export type PermissionsEdge = {
  __typename?: 'PermissionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Permission` at the end of the edge. */
  node: Permission;
};

/** Methods to use when ordering `Permission`. */
export enum PermissionsOrderBy {
  AdminAsc = 'ADMIN_ASC',
  AdminDesc = 'ADMIN_DESC',
  Natural = 'NATURAL',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** A connection to a list of `PreviouslyExercisedRecord` values. */
export type PreviouslyExercisedConnection = {
  __typename?: 'PreviouslyExercisedConnection';
  /** A list of edges which contains the `PreviouslyExercisedRecord` and cursor to aid in pagination. */
  edges: Array<PreviouslyExercisedEdge>;
  /** A list of `PreviouslyExercisedRecord` objects. */
  nodes: Array<PreviouslyExercisedRecord>;
  /** The count of *all* `PreviouslyExercisedRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PreviouslyExercisedRecord` edge in the connection. */
export type PreviouslyExercisedEdge = {
  __typename?: 'PreviouslyExercisedEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PreviouslyExercisedRecord` at the end of the edge. */
  node: PreviouslyExercisedRecord;
};

/** The return type of our `previouslyExercised` query. */
export type PreviouslyExercisedRecord = {
  __typename?: 'PreviouslyExercisedRecord';
  amount?: Maybe<Scalars['Int']>;
  equityScheme?: Maybe<Scalars['String']>;
};

/** A filter to be used against `PreviouslyExercisedRecord` object types. All fields are combined with a logical ‘and.’ */
export type PreviouslyExercisedRecordFilter = {
  /** Filter by the object’s `amount` field. */
  amount?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PreviouslyExercisedRecordFilter>>;
  /** Filter by the object’s `equityScheme` field. */
  equityScheme?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PreviouslyExercisedRecordFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PreviouslyExercisedRecordFilter>>;
};

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: 'Query';
  allowedToViewDeals?: Maybe<Scalars['Boolean']>;
  amountExercisable?: Maybe<AmountExercisableConnection>;
  amountExerciseable?: Maybe<AmountExerciseableConnection>;
  amountOwned?: Maybe<AmountOwnedConnection>;
  amountSold?: Maybe<AmountSoldConnection>;
  amountSoldByOriginalEquity?: Maybe<AmountSoldByOriginalEquityConnection>;
  amountThatWillBeExercisableInTheFuture?: Maybe<AmountThatWillBeExercisableInTheFutureConnection>;
  amountThatWillBeExerciseableInTheFuture?: Maybe<AmountThatWillBeExerciseableInTheFutureConnection>;
  /** Reads and enables pagination through a set of `BlacklistedUsersForEvent`. */
  blacklistedUsersForEvents?: Maybe<BlacklistedUsersForEventsConnection>;
  buyerOffer?: Maybe<BuyerOffer>;
  /** Reads a single `BuyerOffer` using its globally unique `ID`. */
  buyerOfferByNodeId?: Maybe<BuyerOffer>;
  /** Reads and enables pagination through a set of `BuyerOffer`. */
  buyerOffers?: Maybe<BuyerOffersConnection>;
  buyerOnboarding?: Maybe<BuyerOnboarding>;
  /** Reads a single `BuyerOnboarding` using its globally unique `ID`. */
  buyerOnboardingByNodeId?: Maybe<BuyerOnboarding>;
  /** Reads and enables pagination through a set of `BuyerOnboarding`. */
  buyerOnboardings?: Maybe<BuyerOnboardingsConnection>;
  /** Reads and enables pagination through a set of `BuyerPurchaseAgreement`. */
  buyerPurchaseAgreements?: Maybe<BuyerPurchaseAgreementsConnection>;
  changeTransactionGroupStatus?: Maybe<Scalars['String']>;
  checkTransactionGroupReadyForSigning?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `CommentsAboutTeam`. */
  commentsAboutTeams?: Maybe<CommentsAboutTeamsConnection>;
  /** Reads and enables pagination through a set of `Company`. */
  companies?: Maybe<CompaniesConnection>;
  company?: Maybe<Company>;
  /** Reads a single `Company` using its globally unique `ID`. */
  companyByNodeId?: Maybe<Company>;
  /** Reads and enables pagination through a set of `CompanyKeyPerson`. */
  companyKeyPeople?: Maybe<CompanyKeyPeopleConnection>;
  companyKeyPerson?: Maybe<CompanyKeyPerson>;
  /** Reads a single `CompanyKeyPerson` using its globally unique `ID`. */
  companyKeyPersonByNodeId?: Maybe<CompanyKeyPerson>;
  countOutstandingOffers?: Maybe<Scalars['Int']>;
  currentTeamId?: Maybe<Scalars['String']>;
  currentUser?: Maybe<MeResult>;
  currentUserId?: Maybe<Scalars['String']>;
  currentUserIsAdmin?: Maybe<Scalars['Boolean']>;
  currentUserIsTeamMember?: Maybe<Scalars['Boolean']>;
  /** Reads and enables pagination through a set of `CurrentUser`. */
  currentUsers?: Maybe<CurrentUsersConnection>;
  documentIdsToSign?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Email`. */
  emails?: Maybe<EmailsConnection>;
  employment?: Maybe<Employment>;
  /** Reads a single `Employment` using its globally unique `ID`. */
  employmentByNodeId?: Maybe<Employment>;
  /** Reads and enables pagination through a set of `Employment`. */
  employments?: Maybe<EmploymentsConnection>;
  equityGrant?: Maybe<EquityGrant>;
  equityGrantByHolderTeamIdAndEquityScheme?: Maybe<EquityGrant>;
  /** Reads a single `EquityGrant` using its globally unique `ID`. */
  equityGrantByNodeId?: Maybe<EquityGrant>;
  /** Reads and enables pagination through a set of `EquityGrant`. */
  equityGrants?: Maybe<EquityGrantsConnection>;
  /** Reads and enables pagination through a set of `EquitySummary`. */
  equitySummaries?: Maybe<EquitySummariesConnection>;
  /** Reads and enables pagination through a set of `EquitySummary`. */
  equitySummary?: Maybe<EquitySummariesConnection>;
  equityType?: Maybe<EquityType>;
  /** Reads a single `EquityType` using its globally unique `ID`. */
  equityTypeByNodeId?: Maybe<EquityType>;
  equityTypeInfo?: Maybe<EquityTypeInfo>;
  /** Reads a single `EquityTypeInfo` using its globally unique `ID`. */
  equityTypeInfoByNodeId?: Maybe<EquityTypeInfo>;
  /** Reads and enables pagination through a set of `EquityTypeInfo`. */
  equityTypeInfos?: Maybe<EquityTypeInfosConnection>;
  /** Reads and enables pagination through a set of `EquityType`. */
  equityTypes?: Maybe<EquityTypesConnection>;
  file?: Maybe<File>;
  /** Reads a single `File` using its globally unique `ID`. */
  fileByNodeId?: Maybe<File>;
  /** Reads and enables pagination through a set of `FileView`. */
  fileViews?: Maybe<FileViewsConnection>;
  /** Reads and enables pagination through a set of `File`. */
  files?: Maybe<FilesConnection>;
  /** Reads and enables pagination through a set of `Transaction`. */
  filteredTransactions?: Maybe<TransactionsConnection>;
  getInvestorInvite?: Maybe<InvestorInvite>;
  getTaxVariables?: Maybe<TaxVariable>;
  getTaxVariablesByGrant?: Maybe<TaxVariable>;
  getTaxes?: Maybe<GetTaxesConnection>;
  getTeamName?: Maybe<Scalars['String']>;
  getTransactionExercise?: Maybe<GetTransactionExerciseConnection>;
  getTransactionSales?: Maybe<GetTransactionSalesConnection>;
  grantedEquity?: Maybe<GrantedEquityConnection>;
  investorApproval?: Maybe<InvestorApproval>;
  /** Reads a single `InvestorApproval` using its globally unique `ID`. */
  investorApprovalByNodeId?: Maybe<InvestorApproval>;
  /** Reads and enables pagination through a set of `InvestorApproval`. */
  investorApprovals?: Maybe<InvestorApprovalsConnection>;
  investorInvite?: Maybe<InvestorInvite>;
  /** Reads a single `InvestorInvite` using its globally unique `ID`. */
  investorInviteByNodeId?: Maybe<InvestorInvite>;
  /** Reads and enables pagination through a set of `InvestorInvite`. */
  investorInvites?: Maybe<InvestorInvitesConnection>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isTaxWithheld?: Maybe<Scalars['Boolean']>;
  lapsedEquity?: Maybe<LapsedEquityConnection>;
  liquidityEvent?: Maybe<LiquidityEvent>;
  /** Reads a single `LiquidityEvent` using its globally unique `ID`. */
  liquidityEventByNodeId?: Maybe<LiquidityEvent>;
  liquidityEventFaq?: Maybe<LiquidityEventFaq>;
  /** Reads a single `LiquidityEventFaq` using its globally unique `ID`. */
  liquidityEventFaqByNodeId?: Maybe<LiquidityEventFaq>;
  /** Reads and enables pagination through a set of `LiquidityEventFaq`. */
  liquidityEventFaqs?: Maybe<LiquidityEventFaqsConnection>;
  /** Reads and enables pagination through a set of `LiquidityEvent`. */
  liquidityEvents?: Maybe<LiquidityEventsConnection>;
  loggedInUser?: Maybe<CurrentUser>;
  /** Reads and enables pagination through a set of `NdaMetadatum`. */
  ndaMetadata?: Maybe<NdaMetadataConnection>;
  ndaMetadatum?: Maybe<NdaMetadatum>;
  /** Reads a single `NdaMetadatum` using its globally unique `ID`. */
  ndaMetadatumByNodeId?: Maybe<NdaMetadatum>;
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `NonSellersInTransactionGroup`. */
  nonSellersInTransactionGroup?: Maybe<NonSellersInTransactionGroupsConnection>;
  originalScheme?: Maybe<Scalars['String']>;
  overallAllocation?: Maybe<OverallAllocation>;
  /** Reads a single `OverallAllocation` using its globally unique `ID`. */
  overallAllocationByNodeId?: Maybe<OverallAllocation>;
  /** Reads and enables pagination through a set of `OverallAllocation`. */
  overallAllocations?: Maybe<OverallAllocationsConnection>;
  /** Reads and enables pagination through a set of `Permission`. */
  permissions?: Maybe<PermissionsConnection>;
  previouslyExercised?: Maybe<PreviouslyExercisedConnection>;
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  /** Reads and enables pagination through a set of `SellOffersForTransactionGroup`. */
  sellOffersForTransactionGroup?: Maybe<SellOffersForTransactionGroupsConnection>;
  sellerOffer?: Maybe<SellerOffer>;
  /** Reads a single `SellerOffer` using its globally unique `ID`. */
  sellerOfferByNodeId?: Maybe<SellerOffer>;
  /** Reads and enables pagination through a set of `SellerOffer`. */
  sellerOffers?: Maybe<SellerOffersConnection>;
  sellerOnboarding?: Maybe<SellerOnboarding>;
  /** Reads a single `SellerOnboarding` using its globally unique `ID`. */
  sellerOnboardingByNodeId?: Maybe<SellerOnboarding>;
  /** Reads and enables pagination through a set of `SellerOnboarding`. */
  sellerOnboardings?: Maybe<SellerOnboardingsConnection>;
  /** Reads and enables pagination through a set of `SignedDocument`. */
  signedDocuments?: Maybe<SignedDocumentsConnection>;
  stage2Access?: Maybe<Stage2Access>;
  /** Reads a single `Stage2Access` using its globally unique `ID`. */
  stage2AccessByNodeId?: Maybe<Stage2Access>;
  /** Reads and enables pagination through a set of `Stage2Access`. */
  stage2Accesses?: Maybe<Stage2AccessesConnection>;
  summarizeTransactionGroupDeclines?: Maybe<SummarizeTransactionGroupDeclinesConnection>;
  summarizeTransactionGroupOffers?: Maybe<SummarizeTransactionGroupOffersConnection>;
  summarizeTransactionGroupOffersByDate?: Maybe<SummarizeTransactionGroupOffersByDateConnection>;
  taxRate?: Maybe<TaxRate>;
  /** Reads a single `TaxRate` using its globally unique `ID`. */
  taxRateByNodeId?: Maybe<TaxRate>;
  /** Reads and enables pagination through a set of `TaxRate`. */
  taxRates?: Maybe<TaxRatesConnection>;
  team?: Maybe<Team>;
  /** Reads a single `Team` using its globally unique `ID`. */
  teamByNodeId?: Maybe<Team>;
  teamId?: Maybe<Scalars['String']>;
  teamMembership?: Maybe<TeamMembership>;
  /** Reads a single `TeamMembership` using its globally unique `ID`. */
  teamMembershipByNodeId?: Maybe<TeamMembership>;
  /** Reads and enables pagination through a set of `TeamMembership`. */
  teamMemberships?: Maybe<TeamMembershipsConnection>;
  teamUserIsIn?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Team`. */
  teams?: Maybe<TeamsConnection>;
  transaction?: Maybe<Transaction>;
  transactionAllowance?: Maybe<TransactionAllowance>;
  /** Reads a single `TransactionAllowance` using its globally unique `ID`. */
  transactionAllowanceByNodeId?: Maybe<TransactionAllowance>;
  /** Reads and enables pagination through a set of `TransactionAllowance`. */
  transactionAllowances?: Maybe<TransactionAllowancesConnection>;
  transactionApproval?: Maybe<TransactionApproval>;
  /** Reads a single `TransactionApproval` using its globally unique `ID`. */
  transactionApprovalByNodeId?: Maybe<TransactionApproval>;
  /** Reads and enables pagination through a set of `TransactionApproval`. */
  transactionApprovals?: Maybe<TransactionApprovalsConnection>;
  /** Reads a single `Transaction` using its globally unique `ID`. */
  transactionByNodeId?: Maybe<Transaction>;
  /** Reads and enables pagination through a set of `TransactionDetail`. */
  transactionDetails?: Maybe<TransactionDetailsConnection>;
  transactionDocument?: Maybe<TransactionDocument>;
  /** Reads a single `TransactionDocument` using its globally unique `ID`. */
  transactionDocumentByNodeId?: Maybe<TransactionDocument>;
  transactionDocumentField?: Maybe<TransactionDocumentField>;
  /** Reads a single `TransactionDocumentField` using its globally unique `ID`. */
  transactionDocumentFieldByNodeId?: Maybe<TransactionDocumentField>;
  /** Reads and enables pagination through a set of `TransactionDocumentField`. */
  transactionDocumentFields?: Maybe<TransactionDocumentFieldsConnection>;
  transactionDocumentSignature?: Maybe<TransactionDocumentSignature>;
  /** Reads a single `TransactionDocumentSignature` using its globally unique `ID`. */
  transactionDocumentSignatureByNodeId?: Maybe<TransactionDocumentSignature>;
  /** Reads and enables pagination through a set of `TransactionDocumentSignature`. */
  transactionDocumentSignatures?: Maybe<TransactionDocumentSignaturesConnection>;
  /** Reads and enables pagination through a set of `TransactionDocument`. */
  transactionDocuments?: Maybe<TransactionDocumentsConnection>;
  transactionFee?: Maybe<TransactionFee>;
  /** Reads a single `TransactionFee` using its globally unique `ID`. */
  transactionFeeByNodeId?: Maybe<TransactionFee>;
  /** Reads and enables pagination through a set of `TransactionFee`. */
  transactionFees?: Maybe<TransactionFeesConnection>;
  transactionGroup?: Maybe<TransactionGroup>;
  /** Reads a single `TransactionGroup` using its globally unique `ID`. */
  transactionGroupByNodeId?: Maybe<TransactionGroup>;
  /** Reads and enables pagination through a set of `TransactionGroupCurrency`. */
  transactionGroupCurrencies?: Maybe<TransactionGroupCurrenciesConnection>;
  /** Reads and enables pagination through a set of `TransactionGroup`. */
  transactionGroups?: Maybe<TransactionGroupsConnection>;
  transactionGroupsForTeamAndEquity?: Maybe<TransactionGroupsForTeamAndEquityConnection>;
  transactionNote?: Maybe<TransactionNote>;
  /** Reads a single `TransactionNote` using its globally unique `ID`. */
  transactionNoteByNodeId?: Maybe<TransactionNote>;
  /** Reads and enables pagination through a set of `TransactionNote`. */
  transactionNotes?: Maybe<TransactionNotesConnection>;
  /** Reads and enables pagination through a set of `TransactionSummaryExerciseSale`. */
  transactionSummaryExerciseSale?: Maybe<TransactionSummaryExerciseSalesConnection>;
  transactionTaxRate?: Maybe<TransactionTaxRate>;
  /** Reads a single `TransactionTaxRate` using its globally unique `ID`. */
  transactionTaxRateByNodeId?: Maybe<TransactionTaxRate>;
  /** Reads and enables pagination through a set of `TransactionTaxRate`. */
  transactionTaxRates?: Maybe<TransactionTaxRatesConnection>;
  /** Reads and enables pagination through a set of `Transaction`. */
  transactions?: Maybe<TransactionsConnection>;
  /** Reads and enables pagination through a set of `Transaction`. */
  transactionsForTeam?: Maybe<TransactionsConnection>;
  user?: Maybe<User>;
  /** Reads a single `User` using its globally unique `ID`. */
  userByNodeId?: Maybe<User>;
  /** Reads and enables pagination through a set of `User`. */
  users?: Maybe<UsersConnection>;
  vesting?: Maybe<Vesting>;
  /** Reads a single `Vesting` using its globally unique `ID`. */
  vestingByNodeId?: Maybe<Vesting>;
  /** Reads and enables pagination through a set of `VestingSchedule`. */
  vestingSchedules?: Maybe<VestingSchedulesConnection>;
  /** Reads and enables pagination through a set of `Vesting`. */
  vestings?: Maybe<VestingsConnection>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAmountExercisableArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<AmountExercisableRecordFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  seller?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAmountExerciseableArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<AmountExerciseableRecordFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  seller?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAmountOwnedArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<AmountOwnedRecordFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  seller?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAmountSoldArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<AmountSoldRecordFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  seller?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAmountSoldByOriginalEquityArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<AmountSoldByOriginalEquityRecordFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  seller?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAmountThatWillBeExercisableInTheFutureArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<AmountThatWillBeExercisableInTheFutureRecordFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  seller?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAmountThatWillBeExerciseableInTheFutureArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<AmountThatWillBeExerciseableInTheFutureRecordFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  seller?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryBlacklistedUsersForEventsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BlacklistedUsersForEventCondition>;
  filter?: InputMaybe<BlacklistedUsersForEventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BlacklistedUsersForEventsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryBuyerOfferArgs = {
  liquidityEvent: Scalars['String'];
  teamId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBuyerOfferByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBuyerOffersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BuyerOfferCondition>;
  filter?: InputMaybe<BuyerOfferFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BuyerOffersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryBuyerOnboardingArgs = {
  teamId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBuyerOnboardingByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBuyerOnboardingsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BuyerOnboardingCondition>;
  filter?: InputMaybe<BuyerOnboardingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BuyerOnboardingsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryBuyerPurchaseAgreementsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BuyerPurchaseAgreementCondition>;
  filter?: InputMaybe<BuyerPurchaseAgreementFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BuyerPurchaseAgreementsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryChangeTransactionGroupStatusArgs = {
  status?: InputMaybe<TransactionGroupStatus>;
  transactionGroup?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCheckTransactionGroupReadyForSigningArgs = {
  transactionGroupId?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCommentsAboutTeamsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CommentsAboutTeamCondition>;
  filter?: InputMaybe<CommentsAboutTeamFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CommentsAboutTeamsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompaniesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CompanyCondition>;
  filter?: InputMaybe<CompanyFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CompaniesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyArgs = {
  id: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyKeyPeopleArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CompanyKeyPersonCondition>;
  filter?: InputMaybe<CompanyKeyPersonFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CompanyKeyPeopleOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyKeyPersonArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyKeyPersonByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCountOutstandingOffersArgs = {
  transactionGroup?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCurrentUserIsTeamMemberArgs = {
  teamId?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCurrentUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CurrentUserCondition>;
  filter?: InputMaybe<CurrentUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrentUsersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDocumentIdsToSignArgs = {
  transactionId?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryEmailsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EmailCondition>;
  filter?: InputMaybe<EmailFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EmailsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryEmploymentArgs = {
  companyTeamId: Scalars['String'];
  employeeTeamId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEmploymentByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEmploymentsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EmploymentCondition>;
  filter?: InputMaybe<EmploymentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EmploymentsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryEquityGrantArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEquityGrantByHolderTeamIdAndEquitySchemeArgs = {
  equityScheme: Scalars['String'];
  holderTeamId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEquityGrantByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEquityGrantsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EquityGrantCondition>;
  filter?: InputMaybe<EquityGrantFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EquityGrantsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryEquitySummariesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EquitySummaryCondition>;
  filter?: InputMaybe<EquitySummaryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EquitySummariesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryEquitySummaryArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<EquitySummaryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sellerId?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryEquityTypeArgs = {
  id: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEquityTypeByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEquityTypeInfoArgs = {
  id: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEquityTypeInfoByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEquityTypeInfosArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EquityTypeInfoCondition>;
  filter?: InputMaybe<EquityTypeInfoFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EquityTypeInfosOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryEquityTypesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EquityTypeCondition>;
  filter?: InputMaybe<EquityTypeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EquityTypesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFileArgs = {
  liquidityEventId: Scalars['String'];
  slug: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFileByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFileViewsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FileViewCondition>;
  filter?: InputMaybe<FileViewFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FileViewsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFilesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FileCondition>;
  filter?: InputMaybe<FileFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFilteredTransactionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  equityScheme?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TransactionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  team?: InputMaybe<Scalars['String']>;
  transactionGroup?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetInvestorInviteArgs = {
  code?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetTaxVariablesArgs = {
  equityScheme?: InputMaybe<Scalars['String']>;
  inTransactionGroup?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetTaxVariablesByGrantArgs = {
  equityGrant?: InputMaybe<Scalars['Int']>;
  teamId?: InputMaybe<Scalars['String']>;
  transactionGroup?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetTaxesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  exerciseAllowance: Scalars['BigFloat'];
  exerciseTaxRate: Scalars['BigFloat'];
  exerciseTaxWithheld: Scalars['Boolean'];
  filter?: InputMaybe<GetTaxesRecordFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  marketValueAtExercise: Scalars['BigFloat'];
  numberExercised: Scalars['Int'];
  numberSold: Scalars['Int'];
  offset?: InputMaybe<Scalars['Int']>;
  rcaTaxRate: Scalars['BigFloat'];
  rcaTaxWithheld: Scalars['Boolean'];
  saleAllowance: Scalars['BigFloat'];
  salePrice: Scalars['BigFloat'];
  saleTaxRate: Scalars['BigFloat'];
  saleTaxWithheld: Scalars['Boolean'];
  semperFeeRate: Scalars['BigFloat'];
  semperFeesReduceCapitalGain: Scalars['Boolean'];
  strikePrice: Scalars['BigFloat'];
  subscriptionPrice: Scalars['BigFloat'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetTeamNameArgs = {
  inputTeamId?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetTransactionExerciseArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<GetTransactionExerciseRecordFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  transactionGroup?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetTransactionSalesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<GetTransactionSalesRecordFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  transactionGroup?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGrantedEquityArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<GrantedEquityRecordFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  seller?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryInvestorApprovalArgs = {
  teamId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInvestorApprovalByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInvestorApprovalsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<InvestorApprovalCondition>;
  filter?: InputMaybe<InvestorApprovalFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InvestorApprovalsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryInvestorInviteArgs = {
  inviteCode: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInvestorInviteByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInvestorInvitesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<InvestorInviteCondition>;
  filter?: InputMaybe<InvestorInviteFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InvestorInvitesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryIsTaxWithheldArgs = {
  equityGrant?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLapsedEquityArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<LapsedEquityRecordFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  seller?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLiquidityEventArgs = {
  id: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLiquidityEventByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLiquidityEventFaqArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLiquidityEventFaqByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLiquidityEventFaqsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LiquidityEventFaqCondition>;
  filter?: InputMaybe<LiquidityEventFaqFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LiquidityEventFaqsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLiquidityEventsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LiquidityEventCondition>;
  filter?: InputMaybe<LiquidityEventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LiquidityEventsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryNdaMetadataArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<NdaMetadatumCondition>;
  filter?: InputMaybe<NdaMetadatumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NdaMetadataOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryNdaMetadatumArgs = {
  liquidityEvent: Scalars['String'];
  teamId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNdaMetadatumByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNonSellersInTransactionGroupArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<NonSellersInTransactionGroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NonSellersInTransactionGroupsOrderBy>>;
  transactionGroupId?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryOriginalSchemeArgs = {
  transactionFrom?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryOverallAllocationArgs = {
  seller: Scalars['String'];
  transactionGroupId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOverallAllocationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOverallAllocationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<OverallAllocationCondition>;
  filter?: InputMaybe<OverallAllocationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OverallAllocationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPermissionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PermissionCondition>;
  filter?: InputMaybe<PermissionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PermissionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPreviouslyExercisedArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<PreviouslyExercisedRecordFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  seller?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySellOffersForTransactionGroupArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<SellOffersForTransactionGroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SellOffersForTransactionGroupsOrderBy>>;
  transactionGroupId?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySellerOfferArgs = {
  grantId: Scalars['Int'];
  sellerId: Scalars['String'];
  transactionGroup: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySellerOfferByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySellerOffersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SellerOfferCondition>;
  filter?: InputMaybe<SellerOfferFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SellerOffersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySellerOnboardingArgs = {
  teamId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySellerOnboardingByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySellerOnboardingsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SellerOnboardingCondition>;
  filter?: InputMaybe<SellerOnboardingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SellerOnboardingsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySignedDocumentsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SignedDocumentCondition>;
  filter?: InputMaybe<SignedDocumentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SignedDocumentsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryStage2AccessArgs = {
  liquidityEvent: Scalars['String'];
  teamId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryStage2AccessByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryStage2AccessesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<Stage2AccessCondition>;
  filter?: InputMaybe<Stage2AccessFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Stage2AccessesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySummarizeTransactionGroupDeclinesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<SummarizeTransactionGroupDeclinesRecordFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  transactionGroupId?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySummarizeTransactionGroupOffersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<SummarizeTransactionGroupOffersRecordFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  transactionGroupId?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySummarizeTransactionGroupOffersByDateArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<SummarizeTransactionGroupOffersByDateRecordFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  transactionGroupId?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTaxRateArgs = {
  equityType: Scalars['String'];
  fiscalResidency: IsoCountry;
  moreThanXMonthsEmployed: Scalars['Int'];
  moreThanXMonthsHeldGrant: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTaxRateByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTaxRatesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TaxRateCondition>;
  filter?: InputMaybe<TaxRateFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TaxRatesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTeamArgs = {
  id: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTeamByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTeamMembershipArgs = {
  userId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTeamMembershipByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTeamMembershipsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TeamMembershipCondition>;
  filter?: InputMaybe<TeamMembershipFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TeamMembershipsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTeamUserIsInArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTeamsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TeamCondition>;
  filter?: InputMaybe<TeamFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionAllowanceArgs = {
  teamId: Scalars['String'];
  transactionGroup: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionAllowanceByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionAllowancesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionAllowanceCondition>;
  filter?: InputMaybe<TransactionAllowanceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionAllowancesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionApprovalArgs = {
  transactionId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionApprovalByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionApprovalsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionApprovalCondition>;
  filter?: InputMaybe<TransactionApprovalFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionApprovalsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionDetailsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<TransactionDetailFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  teamId?: InputMaybe<Scalars['String']>;
  transactionGroup?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionDocumentArgs = {
  id: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionDocumentByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionDocumentFieldArgs = {
  documentId: Scalars['String'];
  variable: TransactionDocumentFieldType;
  xStart: Scalars['BigFloat'];
  yStart: Scalars['BigFloat'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionDocumentFieldByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionDocumentFieldsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionDocumentFieldCondition>;
  filter?: InputMaybe<TransactionDocumentFieldFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionDocumentFieldsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionDocumentSignatureArgs = {
  documentId: Scalars['String'];
  userId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionDocumentSignatureByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionDocumentSignaturesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionDocumentSignatureCondition>;
  filter?: InputMaybe<TransactionDocumentSignatureFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionDocumentSignaturesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionDocumentsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionDocumentCondition>;
  filter?: InputMaybe<TransactionDocumentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionDocumentsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionFeeArgs = {
  transactionGroup: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionFeeByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionFeesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionFeeCondition>;
  filter?: InputMaybe<TransactionFeeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionFeesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionGroupArgs = {
  id: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionGroupByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionGroupCurrenciesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionGroupCurrencyCondition>;
  filter?: InputMaybe<TransactionGroupCurrencyFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionGroupCurrenciesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionGroupsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionGroupCondition>;
  filter?: InputMaybe<TransactionGroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionGroupsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionGroupsForTeamAndEquityArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  equityId?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<StringFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  teamId?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionNoteArgs = {
  transactionId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionNoteByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionNotesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionNoteCondition>;
  filter?: InputMaybe<TransactionNoteFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionNotesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionSummaryExerciseSaleArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<TransactionSummaryExerciseSaleFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  transactionGroup?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionTaxRateArgs = {
  equityId: Scalars['Int'];
  transactionGroup: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionTaxRateByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionTaxRatesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionTaxRateCondition>;
  filter?: InputMaybe<TransactionTaxRateFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionTaxRatesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionCondition>;
  filter?: InputMaybe<TransactionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionsForTeamArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<TransactionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUserArgs = {
  id: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryVestingArgs = {
  id: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVestingByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVestingSchedulesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<VestingScheduleCondition>;
  filter?: InputMaybe<VestingScheduleFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VestingSchedulesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryVestingsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<VestingCondition>;
  filter?: InputMaybe<VestingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VestingsOrderBy>>;
};

/** This is a dummy table for postgraphile, don't write/read from it \n @omit all */
export type SellOffersForTransactionGroup = {
  __typename?: 'SellOffersForTransactionGroup';
  /** Reads a single `OverallAllocation` that is related to this `SellOffersForTransactionGroup`. */
  allocation?: Maybe<OverallAllocation>;
  createdAt?: Maybe<Scalars['Datetime']>;
  email?: Maybe<Scalars['String']>;
  /** Reads a single `EquityGrant` that is related to this `SellOffersForTransactionGroup`. */
  equityGrant?: Maybe<EquityGrant>;
  equityGrantId?: Maybe<Scalars['Int']>;
  equityRemaining?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  originalCrowdcubeId?: Maybe<Scalars['Int']>;
  sellerOffer?: Maybe<Scalars['Int']>;
  /** Reads a single `Team` that is related to this `SellOffersForTransactionGroup`. */
  team?: Maybe<Team>;
  teamId?: Maybe<Scalars['String']>;
  /** Reads a single `TransactionGroup` that is related to this `SellOffersForTransactionGroup`. */
  transactionGroup?: Maybe<TransactionGroup>;
  transactionGroupId?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `SellOffersForTransactionGroup`. */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

/** A filter to be used against `SellOffersForTransactionGroup` object types. All fields are combined with a logical ‘and.’ */
export type SellOffersForTransactionGroupFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SellOffersForTransactionGroupFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Filter by the object’s `equityGrantId` field. */
  equityGrantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `equityRemaining` field. */
  equityRemaining?: InputMaybe<IntFilter>;
  /** Filter by the object’s `fullName` field. */
  fullName?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SellOffersForTransactionGroupFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SellOffersForTransactionGroupFilter>>;
  /** Filter by the object’s `originalCrowdcubeId` field. */
  originalCrowdcubeId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `sellerOffer` field. */
  sellerOffer?: InputMaybe<IntFilter>;
  /** Filter by the object’s `teamId` field. */
  teamId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `transactionGroupId` field. */
  transactionGroupId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<StringFilter>;
};

/** A connection to a list of `SellOffersForTransactionGroup` values. */
export type SellOffersForTransactionGroupsConnection = {
  __typename?: 'SellOffersForTransactionGroupsConnection';
  /** A list of edges which contains the `SellOffersForTransactionGroup` and cursor to aid in pagination. */
  edges: Array<SellOffersForTransactionGroupsEdge>;
  /** A list of `SellOffersForTransactionGroup` objects. */
  nodes: Array<SellOffersForTransactionGroup>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SellOffersForTransactionGroup` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SellOffersForTransactionGroup` edge in the connection. */
export type SellOffersForTransactionGroupsEdge = {
  __typename?: 'SellOffersForTransactionGroupsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SellOffersForTransactionGroup` at the end of the edge. */
  node: SellOffersForTransactionGroup;
};

/** Methods to use when ordering `SellOffersForTransactionGroup`. */
export enum SellOffersForTransactionGroupsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  EquityGrantIdAsc = 'EQUITY_GRANT_ID_ASC',
  EquityGrantIdDesc = 'EQUITY_GRANT_ID_DESC',
  EquityRemainingAsc = 'EQUITY_REMAINING_ASC',
  EquityRemainingDesc = 'EQUITY_REMAINING_DESC',
  FullNameAsc = 'FULL_NAME_ASC',
  FullNameDesc = 'FULL_NAME_DESC',
  Natural = 'NATURAL',
  OriginalCrowdcubeIdAsc = 'ORIGINAL_CROWDCUBE_ID_ASC',
  OriginalCrowdcubeIdDesc = 'ORIGINAL_CROWDCUBE_ID_DESC',
  SellerOfferAsc = 'SELLER_OFFER_ASC',
  SellerOfferDesc = 'SELLER_OFFER_DESC',
  TeamIdAsc = 'TEAM_ID_ASC',
  TeamIdDesc = 'TEAM_ID_DESC',
  TransactionGroupIdAsc = 'TRANSACTION_GROUP_ID_ASC',
  TransactionGroupIdDesc = 'TRANSACTION_GROUP_ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type SellerOffer = Node & {
  __typename?: 'SellerOffer';
  createdAt: Scalars['Datetime'];
  /** Reads a single `EquityGrant` that is related to this `SellerOffer`. */
  grant?: Maybe<EquityGrant>;
  grantId: Scalars['Int'];
  maxSell: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  orderPriority: Scalars['Int'];
  /** Reads a single `Team` that is related to this `SellerOffer`. */
  seller?: Maybe<Team>;
  sellerId: Scalars['String'];
  transactionGroup: Scalars['String'];
  /** Reads a single `TransactionGroup` that is related to this `SellerOffer`. */
  transactionGroupByTransactionGroup?: Maybe<TransactionGroup>;
};

/**
 * A condition to be used against `SellerOffer` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type SellerOfferCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `grantId` field. */
  grantId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `maxSell` field. */
  maxSell?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `orderPriority` field. */
  orderPriority?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `sellerId` field. */
  sellerId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `transactionGroup` field. */
  transactionGroup?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `SellerOffer` object types. All fields are combined with a logical ‘and.’ */
export type SellerOfferFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SellerOfferFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `grantId` field. */
  grantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `maxSell` field. */
  maxSell?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SellerOfferFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SellerOfferFilter>>;
  /** Filter by the object’s `orderPriority` field. */
  orderPriority?: InputMaybe<IntFilter>;
  /** Filter by the object’s `sellerId` field. */
  sellerId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `transactionGroup` field. */
  transactionGroup?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `SellerOffer` */
export type SellerOfferInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  grantId: Scalars['Int'];
  maxSell: Scalars['Int'];
  orderPriority: Scalars['Int'];
  sellerId: Scalars['String'];
  transactionGroup: Scalars['String'];
};

/** Represents an update to a `SellerOffer`. Fields that are set will be updated. */
export type SellerOfferPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  grantId?: InputMaybe<Scalars['Int']>;
  maxSell?: InputMaybe<Scalars['Int']>;
  orderPriority?: InputMaybe<Scalars['Int']>;
  sellerId?: InputMaybe<Scalars['String']>;
  transactionGroup?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `SellerOffer` values. */
export type SellerOffersConnection = {
  __typename?: 'SellerOffersConnection';
  /** A list of edges which contains the `SellerOffer` and cursor to aid in pagination. */
  edges: Array<SellerOffersEdge>;
  /** A list of `SellerOffer` objects. */
  nodes: Array<SellerOffer>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SellerOffer` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SellerOffer` edge in the connection. */
export type SellerOffersEdge = {
  __typename?: 'SellerOffersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SellerOffer` at the end of the edge. */
  node: SellerOffer;
};

/** Methods to use when ordering `SellerOffer`. */
export enum SellerOffersOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  GrantIdAsc = 'GRANT_ID_ASC',
  GrantIdDesc = 'GRANT_ID_DESC',
  MaxSellAsc = 'MAX_SELL_ASC',
  MaxSellDesc = 'MAX_SELL_DESC',
  Natural = 'NATURAL',
  OrderPriorityAsc = 'ORDER_PRIORITY_ASC',
  OrderPriorityDesc = 'ORDER_PRIORITY_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SellerIdAsc = 'SELLER_ID_ASC',
  SellerIdDesc = 'SELLER_ID_DESC',
  TransactionGroupAsc = 'TRANSACTION_GROUP_ASC',
  TransactionGroupDesc = 'TRANSACTION_GROUP_DESC'
}

export type SellerOnboarding = Node & {
  __typename?: 'SellerOnboarding';
  address?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  equityConfirmed: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  nationality?: Maybe<IsoCountry>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  phone?: Maybe<Scalars['String']>;
  sellerOnboardingCompleted?: Maybe<Scalars['Boolean']>;
  taxResidence: IsoCountry;
  /** Reads a single `Team` that is related to this `SellerOnboarding`. */
  team?: Maybe<Team>;
  teamId: Scalars['String'];
};

/**
 * A condition to be used against `SellerOnboarding` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SellerOnboardingCondition = {
  /** Checks for equality with the object’s `address` field. */
  address?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `country` field. */
  country?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `equityConfirmed` field. */
  equityConfirmed?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `nationality` field. */
  nationality?: InputMaybe<IsoCountry>;
  /** Checks for equality with the object’s `phone` field. */
  phone?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `sellerOnboardingCompleted` field. */
  sellerOnboardingCompleted?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `taxResidence` field. */
  taxResidence?: InputMaybe<IsoCountry>;
  /** Checks for equality with the object’s `teamId` field. */
  teamId?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `SellerOnboarding` object types. All fields are combined with a logical ‘and.’ */
export type SellerOnboardingFilter = {
  /** Filter by the object’s `address` field. */
  address?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SellerOnboardingFilter>>;
  /** Filter by the object’s `country` field. */
  country?: InputMaybe<StringFilter>;
  /** Filter by the object’s `equityConfirmed` field. */
  equityConfirmed?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Filter by the object’s `nationality` field. */
  nationality?: InputMaybe<IsoCountryFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SellerOnboardingFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SellerOnboardingFilter>>;
  /** Filter by the object’s `phone` field. */
  phone?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sellerOnboardingCompleted` field. */
  sellerOnboardingCompleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `taxResidence` field. */
  taxResidence?: InputMaybe<IsoCountryFilter>;
  /** Filter by the object’s `teamId` field. */
  teamId?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `SellerOnboarding` */
export type SellerOnboardingInput = {
  address?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  equityConfirmed?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  nationality?: InputMaybe<IsoCountry>;
  phone?: InputMaybe<Scalars['String']>;
  sellerOnboardingCompleted?: InputMaybe<Scalars['Boolean']>;
  taxResidence: IsoCountry;
  teamId: Scalars['String'];
};

/** Represents an update to a `SellerOnboarding`. Fields that are set will be updated. */
export type SellerOnboardingPatch = {
  address?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  equityConfirmed?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  nationality?: InputMaybe<IsoCountry>;
  phone?: InputMaybe<Scalars['String']>;
  sellerOnboardingCompleted?: InputMaybe<Scalars['Boolean']>;
  taxResidence?: InputMaybe<IsoCountry>;
  teamId?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `SellerOnboarding` values. */
export type SellerOnboardingsConnection = {
  __typename?: 'SellerOnboardingsConnection';
  /** A list of edges which contains the `SellerOnboarding` and cursor to aid in pagination. */
  edges: Array<SellerOnboardingsEdge>;
  /** A list of `SellerOnboarding` objects. */
  nodes: Array<SellerOnboarding>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SellerOnboarding` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SellerOnboarding` edge in the connection. */
export type SellerOnboardingsEdge = {
  __typename?: 'SellerOnboardingsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SellerOnboarding` at the end of the edge. */
  node: SellerOnboarding;
};

/** Methods to use when ordering `SellerOnboarding`. */
export enum SellerOnboardingsOrderBy {
  AddressAsc = 'ADDRESS_ASC',
  AddressDesc = 'ADDRESS_DESC',
  CountryAsc = 'COUNTRY_ASC',
  CountryDesc = 'COUNTRY_DESC',
  EquityConfirmedAsc = 'EQUITY_CONFIRMED_ASC',
  EquityConfirmedDesc = 'EQUITY_CONFIRMED_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  NationalityAsc = 'NATIONALITY_ASC',
  NationalityDesc = 'NATIONALITY_DESC',
  Natural = 'NATURAL',
  PhoneAsc = 'PHONE_ASC',
  PhoneDesc = 'PHONE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SellerOnboardingCompletedAsc = 'SELLER_ONBOARDING_COMPLETED_ASC',
  SellerOnboardingCompletedDesc = 'SELLER_ONBOARDING_COMPLETED_DESC',
  TaxResidenceAsc = 'TAX_RESIDENCE_ASC',
  TaxResidenceDesc = 'TAX_RESIDENCE_DESC',
  TeamIdAsc = 'TEAM_ID_ASC',
  TeamIdDesc = 'TEAM_ID_DESC'
}

export type SignedDocument = {
  __typename?: 'SignedDocument';
  documentSlug: Scalars['String'];
  metadata: Scalars['JSON'];
  /** Reads a single `User` that is related to this `SignedDocument`. */
  user?: Maybe<User>;
  userId: Scalars['String'];
};

/**
 * A condition to be used against `SignedDocument` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SignedDocumentCondition = {
  /** Checks for equality with the object’s `documentSlug` field. */
  documentSlug?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `metadata` field. */
  metadata?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `SignedDocument` object types. All fields are combined with a logical ‘and.’ */
export type SignedDocumentFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SignedDocumentFilter>>;
  /** Filter by the object’s `documentSlug` field. */
  documentSlug?: InputMaybe<StringFilter>;
  /** Filter by the object’s `metadata` field. */
  metadata?: InputMaybe<JsonFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SignedDocumentFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SignedDocumentFilter>>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `SignedDocument` */
export type SignedDocumentInput = {
  documentSlug: Scalars['String'];
  metadata: Scalars['JSON'];
  userId: Scalars['String'];
};

/** A connection to a list of `SignedDocument` values. */
export type SignedDocumentsConnection = {
  __typename?: 'SignedDocumentsConnection';
  /** A list of edges which contains the `SignedDocument` and cursor to aid in pagination. */
  edges: Array<SignedDocumentsEdge>;
  /** A list of `SignedDocument` objects. */
  nodes: Array<SignedDocument>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SignedDocument` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SignedDocument` edge in the connection. */
export type SignedDocumentsEdge = {
  __typename?: 'SignedDocumentsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SignedDocument` at the end of the edge. */
  node: SignedDocument;
};

/** Methods to use when ordering `SignedDocument`. */
export enum SignedDocumentsOrderBy {
  DocumentSlugAsc = 'DOCUMENT_SLUG_ASC',
  DocumentSlugDesc = 'DOCUMENT_SLUG_DESC',
  MetadataAsc = 'METADATA_ASC',
  MetadataDesc = 'METADATA_DESC',
  Natural = 'NATURAL',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type Stage2Access = Node & {
  __typename?: 'Stage2Access';
  liquidityEvent: Scalars['String'];
  /** Reads a single `LiquidityEvent` that is related to this `Stage2Access`. */
  liquidityEventByLiquidityEvent?: Maybe<LiquidityEvent>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Team` that is related to this `Stage2Access`. */
  team?: Maybe<Team>;
  teamId: Scalars['String'];
};

/**
 * A condition to be used against `Stage2Access` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type Stage2AccessCondition = {
  /** Checks for equality with the object’s `liquidityEvent` field. */
  liquidityEvent?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `teamId` field. */
  teamId?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `Stage2Access` object types. All fields are combined with a logical ‘and.’ */
export type Stage2AccessFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<Stage2AccessFilter>>;
  /** Filter by the object’s `liquidityEvent` field. */
  liquidityEvent?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<Stage2AccessFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<Stage2AccessFilter>>;
  /** Filter by the object’s `teamId` field. */
  teamId?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `Stage2Access` */
export type Stage2AccessInput = {
  liquidityEvent: Scalars['String'];
  teamId: Scalars['String'];
};

/** Represents an update to a `Stage2Access`. Fields that are set will be updated. */
export type Stage2AccessPatch = {
  liquidityEvent?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `Stage2Access` values. */
export type Stage2AccessesConnection = {
  __typename?: 'Stage2AccessesConnection';
  /** A list of edges which contains the `Stage2Access` and cursor to aid in pagination. */
  edges: Array<Stage2AccessesEdge>;
  /** A list of `Stage2Access` objects. */
  nodes: Array<Stage2Access>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Stage2Access` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Stage2Access` edge in the connection. */
export type Stage2AccessesEdge = {
  __typename?: 'Stage2AccessesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Stage2Access` at the end of the edge. */
  node: Stage2Access;
};

/** Methods to use when ordering `Stage2Access`. */
export enum Stage2AccessesOrderBy {
  LiquidityEventAsc = 'LIQUIDITY_EVENT_ASC',
  LiquidityEventDesc = 'LIQUIDITY_EVENT_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TeamIdAsc = 'TEAM_ID_ASC',
  TeamIdDesc = 'TEAM_ID_DESC'
}

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: InputMaybe<Scalars['String']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: InputMaybe<Scalars['String']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: InputMaybe<Scalars['String']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['String']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: InputMaybe<Scalars['String']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['String']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['String']>>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: InputMaybe<Array<Scalars['String']>>;
  /** Contains the specified string (case-sensitive). */
  includes?: InputMaybe<Scalars['String']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: InputMaybe<Scalars['String']>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['String']>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: InputMaybe<Scalars['String']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['String']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: InputMaybe<Scalars['String']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: InputMaybe<Scalars['String']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['String']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: InputMaybe<Array<Scalars['String']>>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: InputMaybe<Scalars['String']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: InputMaybe<Scalars['String']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: InputMaybe<Scalars['String']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: InputMaybe<Scalars['String']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against String List fields. All fields are combined with a logical ‘and.’ */
export type StringListFilter = {
  /** Any array item is equal to the specified value. */
  anyEqualTo?: InputMaybe<Scalars['String']>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan?: InputMaybe<Scalars['String']>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo?: InputMaybe<Scalars['String']>;
  /** Any array item is less than the specified value. */
  anyLessThan?: InputMaybe<Scalars['String']>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo?: InputMaybe<Scalars['String']>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo?: InputMaybe<Scalars['String']>;
  /** Contained by the specified list of values. */
  containedBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Contains the specified list of values. */
  contains?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Overlaps the specified list of values. */
  overlaps?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SuggestedTax = {
  __typename?: 'SuggestedTax';
  isWithholding?: Maybe<Scalars['Boolean']>;
  rate?: Maybe<Scalars['BigFloat']>;
  semperFeesReduceTaxableGain?: Maybe<Scalars['Boolean']>;
};

/** An input for mutations affecting `SuggestedTax` */
export type SuggestedTaxInput = {
  isWithholding?: InputMaybe<Scalars['Boolean']>;
  rate?: InputMaybe<Scalars['BigFloat']>;
  semperFeesReduceTaxableGain?: InputMaybe<Scalars['Boolean']>;
};

/** A `SuggestedTax` edge in the connection. */
export type SuggestedTaxesEdge = {
  __typename?: 'SuggestedTaxesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SuggestedTax` at the end of the edge. */
  node: SuggestedTax;
};

/** Methods to use when ordering `SuggestedTax`. */
export enum SuggestedTaxesOrderBy {
  IsWithholdingAsc = 'IS_WITHHOLDING_ASC',
  IsWithholdingDesc = 'IS_WITHHOLDING_DESC',
  Natural = 'NATURAL',
  RateAsc = 'RATE_ASC',
  RateDesc = 'RATE_DESC',
  SemperFeesReduceTaxableGainAsc = 'SEMPER_FEES_REDUCE_TAXABLE_GAIN_ASC',
  SemperFeesReduceTaxableGainDesc = 'SEMPER_FEES_REDUCE_TAXABLE_GAIN_DESC'
}

/** A `SummarizeTransactionGroupDeclinesRecord` edge in the connection. */
export type SummarizeTransactionGroupDeclineEdge = {
  __typename?: 'SummarizeTransactionGroupDeclineEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SummarizeTransactionGroupDeclinesRecord` at the end of the edge. */
  node: SummarizeTransactionGroupDeclinesRecord;
};

/** A connection to a list of `SummarizeTransactionGroupDeclinesRecord` values. */
export type SummarizeTransactionGroupDeclinesConnection = {
  __typename?: 'SummarizeTransactionGroupDeclinesConnection';
  /** A list of edges which contains the `SummarizeTransactionGroupDeclinesRecord` and cursor to aid in pagination. */
  edges: Array<SummarizeTransactionGroupDeclineEdge>;
  /** A list of `SummarizeTransactionGroupDeclinesRecord` objects. */
  nodes: Array<SummarizeTransactionGroupDeclinesRecord>;
  /** The count of *all* `SummarizeTransactionGroupDeclinesRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** The return type of our `summarizeTransactionGroupDeclines` query. */
export type SummarizeTransactionGroupDeclinesRecord = {
  __typename?: 'SummarizeTransactionGroupDeclinesRecord';
  amount?: Maybe<Scalars['Int']>;
  offers?: Maybe<Scalars['Int']>;
  saleValue?: Maybe<Scalars['BigFloat']>;
};

/** A filter to be used against `SummarizeTransactionGroupDeclinesRecord` object types. All fields are combined with a logical ‘and.’ */
export type SummarizeTransactionGroupDeclinesRecordFilter = {
  /** Filter by the object’s `amount` field. */
  amount?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SummarizeTransactionGroupDeclinesRecordFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<SummarizeTransactionGroupDeclinesRecordFilter>;
  /** Filter by the object’s `offers` field. */
  offers?: InputMaybe<IntFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SummarizeTransactionGroupDeclinesRecordFilter>>;
  /** Filter by the object’s `saleValue` field. */
  saleValue?: InputMaybe<BigFloatFilter>;
};

/** A `SummarizeTransactionGroupOffersRecord` edge in the connection. */
export type SummarizeTransactionGroupOfferEdge = {
  __typename?: 'SummarizeTransactionGroupOfferEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SummarizeTransactionGroupOffersRecord` at the end of the edge. */
  node: SummarizeTransactionGroupOffersRecord;
};

/** A connection to a list of `SummarizeTransactionGroupOffersByDateRecord` values. */
export type SummarizeTransactionGroupOffersByDateConnection = {
  __typename?: 'SummarizeTransactionGroupOffersByDateConnection';
  /** A list of edges which contains the `SummarizeTransactionGroupOffersByDateRecord` and cursor to aid in pagination. */
  edges: Array<SummarizeTransactionGroupOffersByDateEdge>;
  /** A list of `SummarizeTransactionGroupOffersByDateRecord` objects. */
  nodes: Array<SummarizeTransactionGroupOffersByDateRecord>;
  /** The count of *all* `SummarizeTransactionGroupOffersByDateRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SummarizeTransactionGroupOffersByDateRecord` edge in the connection. */
export type SummarizeTransactionGroupOffersByDateEdge = {
  __typename?: 'SummarizeTransactionGroupOffersByDateEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SummarizeTransactionGroupOffersByDateRecord` at the end of the edge. */
  node: SummarizeTransactionGroupOffersByDateRecord;
};

/** The return type of our `summarizeTransactionGroupOffersByDate` query. */
export type SummarizeTransactionGroupOffersByDateRecord = {
  __typename?: 'SummarizeTransactionGroupOffersByDateRecord';
  amount?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['Date']>;
  fee?: Maybe<Scalars['BigFloat']>;
  offers?: Maybe<Scalars['Int']>;
  saleValue?: Maybe<Scalars['BigFloat']>;
};

/** A filter to be used against `SummarizeTransactionGroupOffersByDateRecord` object types. All fields are combined with a logical ‘and.’ */
export type SummarizeTransactionGroupOffersByDateRecordFilter = {
  /** Filter by the object’s `amount` field. */
  amount?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SummarizeTransactionGroupOffersByDateRecordFilter>>;
  /** Filter by the object’s `date` field. */
  date?: InputMaybe<DateFilter>;
  /** Filter by the object’s `fee` field. */
  fee?: InputMaybe<BigFloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SummarizeTransactionGroupOffersByDateRecordFilter>;
  /** Filter by the object’s `offers` field. */
  offers?: InputMaybe<IntFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SummarizeTransactionGroupOffersByDateRecordFilter>>;
  /** Filter by the object’s `saleValue` field. */
  saleValue?: InputMaybe<BigFloatFilter>;
};

/** A connection to a list of `SummarizeTransactionGroupOffersRecord` values. */
export type SummarizeTransactionGroupOffersConnection = {
  __typename?: 'SummarizeTransactionGroupOffersConnection';
  /** A list of edges which contains the `SummarizeTransactionGroupOffersRecord` and cursor to aid in pagination. */
  edges: Array<SummarizeTransactionGroupOfferEdge>;
  /** A list of `SummarizeTransactionGroupOffersRecord` objects. */
  nodes: Array<SummarizeTransactionGroupOffersRecord>;
  /** The count of *all* `SummarizeTransactionGroupOffersRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** The return type of our `summarizeTransactionGroupOffers` query. */
export type SummarizeTransactionGroupOffersRecord = {
  __typename?: 'SummarizeTransactionGroupOffersRecord';
  amount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['BigFloat']>;
  offers?: Maybe<Scalars['Int']>;
  saleValue?: Maybe<Scalars['BigFloat']>;
};

/** A filter to be used against `SummarizeTransactionGroupOffersRecord` object types. All fields are combined with a logical ‘and.’ */
export type SummarizeTransactionGroupOffersRecordFilter = {
  /** Filter by the object’s `amount` field. */
  amount?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SummarizeTransactionGroupOffersRecordFilter>>;
  /** Filter by the object’s `fee` field. */
  fee?: InputMaybe<BigFloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SummarizeTransactionGroupOffersRecordFilter>;
  /** Filter by the object’s `offers` field. */
  offers?: InputMaybe<IntFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SummarizeTransactionGroupOffersRecordFilter>>;
  /** Filter by the object’s `saleValue` field. */
  saleValue?: InputMaybe<BigFloatFilter>;
};

export type TaxRate = Node & {
  __typename?: 'TaxRate';
  equityType: Scalars['String'];
  /** Reads a single `EquityType` that is related to this `TaxRate`. */
  equityTypeByEquityType?: Maybe<EquityType>;
  exerciseRate: Scalars['BigFloat'];
  exerciseWithheld: Scalars['Boolean'];
  fiscalResidency: IsoCountry;
  moreThanXMonthsEmployed: Scalars['Int'];
  moreThanXMonthsHeldGrant: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  saleRate: Scalars['BigFloat'];
  saleWithheld: Scalars['Boolean'];
  semperFeesReduceTaxableGain: Scalars['Boolean'];
  taxAdvantagedExercise: Scalars['Boolean'];
};

/** A condition to be used against `TaxRate` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type TaxRateCondition = {
  /** Checks for equality with the object’s `equityType` field. */
  equityType?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `exerciseRate` field. */
  exerciseRate?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `exerciseWithheld` field. */
  exerciseWithheld?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `fiscalResidency` field. */
  fiscalResidency?: InputMaybe<IsoCountry>;
  /** Checks for equality with the object’s `moreThanXMonthsEmployed` field. */
  moreThanXMonthsEmployed?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `moreThanXMonthsHeldGrant` field. */
  moreThanXMonthsHeldGrant?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `saleRate` field. */
  saleRate?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `saleWithheld` field. */
  saleWithheld?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `semperFeesReduceTaxableGain` field. */
  semperFeesReduceTaxableGain?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `taxAdvantagedExercise` field. */
  taxAdvantagedExercise?: InputMaybe<Scalars['Boolean']>;
};

/** A filter to be used against `TaxRate` object types. All fields are combined with a logical ‘and.’ */
export type TaxRateFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TaxRateFilter>>;
  /** Filter by the object’s `equityType` field. */
  equityType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `exerciseRate` field. */
  exerciseRate?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `exerciseWithheld` field. */
  exerciseWithheld?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `fiscalResidency` field. */
  fiscalResidency?: InputMaybe<IsoCountryFilter>;
  /** Filter by the object’s `moreThanXMonthsEmployed` field. */
  moreThanXMonthsEmployed?: InputMaybe<IntFilter>;
  /** Filter by the object’s `moreThanXMonthsHeldGrant` field. */
  moreThanXMonthsHeldGrant?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TaxRateFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TaxRateFilter>>;
  /** Filter by the object’s `saleRate` field. */
  saleRate?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `saleWithheld` field. */
  saleWithheld?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `semperFeesReduceTaxableGain` field. */
  semperFeesReduceTaxableGain?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `taxAdvantagedExercise` field. */
  taxAdvantagedExercise?: InputMaybe<BooleanFilter>;
};

/** An input for mutations affecting `TaxRate` */
export type TaxRateInput = {
  equityType: Scalars['String'];
  exerciseRate: Scalars['BigFloat'];
  exerciseWithheld: Scalars['Boolean'];
  fiscalResidency: IsoCountry;
  moreThanXMonthsEmployed: Scalars['Int'];
  moreThanXMonthsHeldGrant: Scalars['Int'];
  saleRate: Scalars['BigFloat'];
  saleWithheld: Scalars['Boolean'];
  semperFeesReduceTaxableGain: Scalars['Boolean'];
  taxAdvantagedExercise: Scalars['Boolean'];
};

/** Represents an update to a `TaxRate`. Fields that are set will be updated. */
export type TaxRatePatch = {
  equityType?: InputMaybe<Scalars['String']>;
  exerciseRate?: InputMaybe<Scalars['BigFloat']>;
  exerciseWithheld?: InputMaybe<Scalars['Boolean']>;
  fiscalResidency?: InputMaybe<IsoCountry>;
  moreThanXMonthsEmployed?: InputMaybe<Scalars['Int']>;
  moreThanXMonthsHeldGrant?: InputMaybe<Scalars['Int']>;
  saleRate?: InputMaybe<Scalars['BigFloat']>;
  saleWithheld?: InputMaybe<Scalars['Boolean']>;
  semperFeesReduceTaxableGain?: InputMaybe<Scalars['Boolean']>;
  taxAdvantagedExercise?: InputMaybe<Scalars['Boolean']>;
};

/** A connection to a list of `TaxRate` values. */
export type TaxRatesConnection = {
  __typename?: 'TaxRatesConnection';
  /** A list of edges which contains the `TaxRate` and cursor to aid in pagination. */
  edges: Array<TaxRatesEdge>;
  /** A list of `TaxRate` objects. */
  nodes: Array<TaxRate>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TaxRate` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TaxRate` edge in the connection. */
export type TaxRatesEdge = {
  __typename?: 'TaxRatesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TaxRate` at the end of the edge. */
  node: TaxRate;
};

/** Methods to use when ordering `TaxRate`. */
export enum TaxRatesOrderBy {
  EquityTypeAsc = 'EQUITY_TYPE_ASC',
  EquityTypeDesc = 'EQUITY_TYPE_DESC',
  ExerciseRateAsc = 'EXERCISE_RATE_ASC',
  ExerciseRateDesc = 'EXERCISE_RATE_DESC',
  ExerciseWithheldAsc = 'EXERCISE_WITHHELD_ASC',
  ExerciseWithheldDesc = 'EXERCISE_WITHHELD_DESC',
  FiscalResidencyAsc = 'FISCAL_RESIDENCY_ASC',
  FiscalResidencyDesc = 'FISCAL_RESIDENCY_DESC',
  MoreThanXMonthsEmployedAsc = 'MORE_THAN_X_MONTHS_EMPLOYED_ASC',
  MoreThanXMonthsEmployedDesc = 'MORE_THAN_X_MONTHS_EMPLOYED_DESC',
  MoreThanXMonthsHeldGrantAsc = 'MORE_THAN_X_MONTHS_HELD_GRANT_ASC',
  MoreThanXMonthsHeldGrantDesc = 'MORE_THAN_X_MONTHS_HELD_GRANT_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SaleRateAsc = 'SALE_RATE_ASC',
  SaleRateDesc = 'SALE_RATE_DESC',
  SaleWithheldAsc = 'SALE_WITHHELD_ASC',
  SaleWithheldDesc = 'SALE_WITHHELD_DESC',
  SemperFeesReduceTaxableGainAsc = 'SEMPER_FEES_REDUCE_TAXABLE_GAIN_ASC',
  SemperFeesReduceTaxableGainDesc = 'SEMPER_FEES_REDUCE_TAXABLE_GAIN_DESC',
  TaxAdvantagedExerciseAsc = 'TAX_ADVANTAGED_EXERCISE_ASC',
  TaxAdvantagedExerciseDesc = 'TAX_ADVANTAGED_EXERCISE_DESC'
}

export type TaxVariable = {
  __typename?: 'TaxVariable';
  exerciseTaxRate?: Maybe<Scalars['BigFloat']>;
  exerciseTaxWithheld?: Maybe<Scalars['Boolean']>;
  marketValueAtExercise?: Maybe<Currency>;
  rcaTaxRate?: Maybe<Scalars['BigFloat']>;
  rcaTaxWithheld?: Maybe<Scalars['Boolean']>;
  salePrice?: Maybe<Currency>;
  saleTaxRate?: Maybe<Scalars['BigFloat']>;
  saleTaxWithheld?: Maybe<Scalars['Boolean']>;
  semperFeeRate?: Maybe<Scalars['BigFloat']>;
  semperFeesReduceTaxableGain?: Maybe<Scalars['Boolean']>;
  strikePrice?: Maybe<Currency>;
  subscriptionPrice?: Maybe<Currency>;
};

/** An input for mutations affecting `TaxVariable` */
export type TaxVariableInput = {
  exerciseTaxRate?: InputMaybe<Scalars['BigFloat']>;
  exerciseTaxWithheld?: InputMaybe<Scalars['Boolean']>;
  marketValueAtExercise?: InputMaybe<CurrencyInput>;
  rcaTaxRate?: InputMaybe<Scalars['BigFloat']>;
  rcaTaxWithheld?: InputMaybe<Scalars['Boolean']>;
  salePrice?: InputMaybe<CurrencyInput>;
  saleTaxRate?: InputMaybe<Scalars['BigFloat']>;
  saleTaxWithheld?: InputMaybe<Scalars['Boolean']>;
  semperFeeRate?: InputMaybe<Scalars['BigFloat']>;
  semperFeesReduceTaxableGain?: InputMaybe<Scalars['Boolean']>;
  strikePrice?: InputMaybe<CurrencyInput>;
  subscriptionPrice?: InputMaybe<CurrencyInput>;
};

/** A `TaxVariable` edge in the connection. */
export type TaxVariablesEdge = {
  __typename?: 'TaxVariablesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TaxVariable` at the end of the edge. */
  node: TaxVariable;
};

/** Methods to use when ordering `TaxVariable`. */
export enum TaxVariablesOrderBy {
  ExerciseTaxRateAsc = 'EXERCISE_TAX_RATE_ASC',
  ExerciseTaxRateDesc = 'EXERCISE_TAX_RATE_DESC',
  ExerciseTaxWithheldAsc = 'EXERCISE_TAX_WITHHELD_ASC',
  ExerciseTaxWithheldDesc = 'EXERCISE_TAX_WITHHELD_DESC',
  MarketValueAtExerciseAsc = 'MARKET_VALUE_AT_EXERCISE_ASC',
  MarketValueAtExerciseDesc = 'MARKET_VALUE_AT_EXERCISE_DESC',
  Natural = 'NATURAL',
  RcaTaxRateAsc = 'RCA_TAX_RATE_ASC',
  RcaTaxRateDesc = 'RCA_TAX_RATE_DESC',
  RcaTaxWithheldAsc = 'RCA_TAX_WITHHELD_ASC',
  RcaTaxWithheldDesc = 'RCA_TAX_WITHHELD_DESC',
  SalePriceAsc = 'SALE_PRICE_ASC',
  SalePriceDesc = 'SALE_PRICE_DESC',
  SaleTaxRateAsc = 'SALE_TAX_RATE_ASC',
  SaleTaxRateDesc = 'SALE_TAX_RATE_DESC',
  SaleTaxWithheldAsc = 'SALE_TAX_WITHHELD_ASC',
  SaleTaxWithheldDesc = 'SALE_TAX_WITHHELD_DESC',
  SemperFeesReduceTaxableGainAsc = 'SEMPER_FEES_REDUCE_TAXABLE_GAIN_ASC',
  SemperFeesReduceTaxableGainDesc = 'SEMPER_FEES_REDUCE_TAXABLE_GAIN_DESC',
  SemperFeeRateAsc = 'SEMPER_FEE_RATE_ASC',
  SemperFeeRateDesc = 'SEMPER_FEE_RATE_DESC',
  StrikePriceAsc = 'STRIKE_PRICE_ASC',
  StrikePriceDesc = 'STRIKE_PRICE_DESC',
  SubscriptionPriceAsc = 'SUBSCRIPTION_PRICE_ASC',
  SubscriptionPriceDesc = 'SUBSCRIPTION_PRICE_DESC'
}

export type Team = Node & {
  __typename?: 'Team';
  activeTransactionGroup?: Maybe<TransactionGroup>;
  allowedToViewDeals?: Maybe<Scalars['Boolean']>;
  /** Reads and enables pagination through a set of `BlacklistedUsersForEvent`. */
  blacklistedUsersForEvents: BlacklistedUsersForEventsConnection;
  /** Reads and enables pagination through a set of `BuyerOffer`. */
  buyerOffers: BuyerOffersConnection;
  /** Reads a single `BuyerOnboarding` that is related to this `Team`. */
  buyerOnboarding?: Maybe<BuyerOnboarding>;
  /**
   * Reads and enables pagination through a set of `BuyerOnboarding`.
   * @deprecated Please use buyerOnboarding instead
   */
  buyerOnboardings: BuyerOnboardingsConnection;
  /** Reads and enables pagination through a set of `BuyerPurchaseAgreement`. */
  buyerPurchaseAgreementsByBuyerTeamId: BuyerPurchaseAgreementsConnection;
  /** Reads and enables pagination through a set of `CommentsAboutTeam`. */
  commentsAboutTeams: CommentsAboutTeamsConnection;
  /** Reads and enables pagination through a set of `CurrentUser`. */
  currentUsers: CurrentUsersConnection;
  /** Reads and enables pagination through a set of `Employment`. */
  employmentsByCompanyTeamId: EmploymentsConnection;
  /** Reads and enables pagination through a set of `Employment`. */
  employmentsByEmployeeTeamId: EmploymentsConnection;
  /** Reads and enables pagination through a set of `EquityGrant`. */
  equityGrantsByHolderTeamId: EquityGrantsConnection;
  /** Reads and enables pagination through a set of `EquitySummary`. */
  equitySummary: EquitySummariesConnection;
  id: Scalars['String'];
  /** Reads a single `InvestorApproval` that is related to this `Team`. */
  investorApproval?: Maybe<InvestorApproval>;
  /**
   * Reads and enables pagination through a set of `InvestorApproval`.
   * @deprecated Please use investorApproval instead
   */
  investorApprovals: InvestorApprovalsConnection;
  inviteCode?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `NdaMetadatum`. */
  ndaMetadata: NdaMetadataConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `OverallAllocation`. */
  overallAllocationsBySeller: OverallAllocationsConnection;
  /** Reads and enables pagination through a set of `SellerOffer`. */
  sellerOffersBySellerId: SellerOffersConnection;
  /** Reads a single `SellerOnboarding` that is related to this `Team`. */
  sellerOnboarding?: Maybe<SellerOnboarding>;
  /**
   * Reads and enables pagination through a set of `SellerOnboarding`.
   * @deprecated Please use sellerOnboarding instead
   */
  sellerOnboardings: SellerOnboardingsConnection;
  /** Reads and enables pagination through a set of `Stage2Access`. */
  stage2Accesses: Stage2AccessesConnection;
  /** Reads and enables pagination through a set of `TeamMembership`. */
  teamMemberships: TeamMembershipsConnection;
  teamName?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `TransactionAllowance`. */
  transactionAllowances: TransactionAllowancesConnection;
  /** Reads and enables pagination through a set of `TransactionFee`. */
  transactionFeesByReciever: TransactionFeesConnection;
  /** Reads and enables pagination through a set of `TransactionGroup`. */
  transactionGroupsByBuyerTeamId: TransactionGroupsConnection;
  /** Reads and enables pagination through a set of `TransactionSummaryExerciseSale`. */
  transactionSummaryExerciseSalesBySeller: TransactionSummaryExerciseSalesConnection;
  /** Reads and enables pagination through a set of `Transaction`. */
  transactionsByPartyA: TransactionsConnection;
  /** Reads and enables pagination through a set of `Transaction`. */
  transactionsByPartyB: TransactionsConnection;
};


export type TeamBlacklistedUsersForEventsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BlacklistedUsersForEventCondition>;
  filter?: InputMaybe<BlacklistedUsersForEventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BlacklistedUsersForEventsOrderBy>>;
};


export type TeamBuyerOffersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BuyerOfferCondition>;
  filter?: InputMaybe<BuyerOfferFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BuyerOffersOrderBy>>;
};


export type TeamBuyerOnboardingsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BuyerOnboardingCondition>;
  filter?: InputMaybe<BuyerOnboardingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BuyerOnboardingsOrderBy>>;
};


export type TeamBuyerPurchaseAgreementsByBuyerTeamIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BuyerPurchaseAgreementCondition>;
  filter?: InputMaybe<BuyerPurchaseAgreementFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BuyerPurchaseAgreementsOrderBy>>;
};


export type TeamCommentsAboutTeamsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CommentsAboutTeamCondition>;
  filter?: InputMaybe<CommentsAboutTeamFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CommentsAboutTeamsOrderBy>>;
};


export type TeamCurrentUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CurrentUserCondition>;
  filter?: InputMaybe<CurrentUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrentUsersOrderBy>>;
};


export type TeamEmploymentsByCompanyTeamIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EmploymentCondition>;
  filter?: InputMaybe<EmploymentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EmploymentsOrderBy>>;
};


export type TeamEmploymentsByEmployeeTeamIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EmploymentCondition>;
  filter?: InputMaybe<EmploymentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EmploymentsOrderBy>>;
};


export type TeamEquityGrantsByHolderTeamIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EquityGrantCondition>;
  filter?: InputMaybe<EquityGrantFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EquityGrantsOrderBy>>;
};


export type TeamEquitySummaryArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<EquitySummaryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type TeamInvestorApprovalsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<InvestorApprovalCondition>;
  filter?: InputMaybe<InvestorApprovalFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InvestorApprovalsOrderBy>>;
};


export type TeamNdaMetadataArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<NdaMetadatumCondition>;
  filter?: InputMaybe<NdaMetadatumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NdaMetadataOrderBy>>;
};


export type TeamOverallAllocationsBySellerArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<OverallAllocationCondition>;
  filter?: InputMaybe<OverallAllocationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OverallAllocationsOrderBy>>;
};


export type TeamSellerOffersBySellerIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SellerOfferCondition>;
  filter?: InputMaybe<SellerOfferFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SellerOffersOrderBy>>;
};


export type TeamSellerOnboardingsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SellerOnboardingCondition>;
  filter?: InputMaybe<SellerOnboardingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SellerOnboardingsOrderBy>>;
};


export type TeamStage2AccessesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<Stage2AccessCondition>;
  filter?: InputMaybe<Stage2AccessFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Stage2AccessesOrderBy>>;
};


export type TeamTeamMembershipsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TeamMembershipCondition>;
  filter?: InputMaybe<TeamMembershipFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TeamMembershipsOrderBy>>;
};


export type TeamTransactionAllowancesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionAllowanceCondition>;
  filter?: InputMaybe<TransactionAllowanceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionAllowancesOrderBy>>;
};


export type TeamTransactionFeesByRecieverArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionFeeCondition>;
  filter?: InputMaybe<TransactionFeeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionFeesOrderBy>>;
};


export type TeamTransactionGroupsByBuyerTeamIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionGroupCondition>;
  filter?: InputMaybe<TransactionGroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionGroupsOrderBy>>;
};


export type TeamTransactionSummaryExerciseSalesBySellerArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionSummaryExerciseSaleCondition>;
  filter?: InputMaybe<TransactionSummaryExerciseSaleFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionSummaryExerciseSalesOrderBy>>;
};


export type TeamTransactionsByPartyAArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionCondition>;
  filter?: InputMaybe<TransactionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
};


export type TeamTransactionsByPartyBArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionCondition>;
  filter?: InputMaybe<TransactionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
};

/** A condition to be used against `Team` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type TeamCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `inviteCode` field. */
  inviteCode?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `Team` object types. All fields are combined with a logical ‘and.’ */
export type TeamFilter = {
  /** Filter by the object’s `allowedToViewDeals` field. */
  allowedToViewDeals?: InputMaybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TeamFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `inviteCode` field. */
  inviteCode?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TeamFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TeamFilter>>;
  /** Filter by the object’s `teamName` field. */
  teamName?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `Team` */
export type TeamInput = {
  id: Scalars['String'];
  inviteCode?: InputMaybe<Scalars['String']>;
};

export type TeamMembership = Node & {
  __typename?: 'TeamMembership';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Team` that is related to this `TeamMembership`. */
  team?: Maybe<Team>;
  teamId: Scalars['String'];
  /** Reads a single `User` that is related to this `TeamMembership`. */
  user?: Maybe<User>;
  userId: Scalars['String'];
};

/**
 * A condition to be used against `TeamMembership` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TeamMembershipCondition = {
  /** Checks for equality with the object’s `teamId` field. */
  teamId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `TeamMembership` object types. All fields are combined with a logical ‘and.’ */
export type TeamMembershipFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TeamMembershipFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<TeamMembershipFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TeamMembershipFilter>>;
  /** Filter by the object’s `teamId` field. */
  teamId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `TeamMembership` */
export type TeamMembershipInput = {
  teamId: Scalars['String'];
  userId: Scalars['String'];
};

/** Represents an update to a `TeamMembership`. Fields that are set will be updated. */
export type TeamMembershipPatch = {
  teamId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `TeamMembership` values. */
export type TeamMembershipsConnection = {
  __typename?: 'TeamMembershipsConnection';
  /** A list of edges which contains the `TeamMembership` and cursor to aid in pagination. */
  edges: Array<TeamMembershipsEdge>;
  /** A list of `TeamMembership` objects. */
  nodes: Array<TeamMembership>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TeamMembership` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TeamMembership` edge in the connection. */
export type TeamMembershipsEdge = {
  __typename?: 'TeamMembershipsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TeamMembership` at the end of the edge. */
  node: TeamMembership;
};

/** Methods to use when ordering `TeamMembership`. */
export enum TeamMembershipsOrderBy {
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TeamIdAsc = 'TEAM_ID_ASC',
  TeamIdDesc = 'TEAM_ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** Represents an update to a `Team`. Fields that are set will be updated. */
export type TeamPatch = {
  id?: InputMaybe<Scalars['String']>;
  inviteCode?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `Team` values. */
export type TeamsConnection = {
  __typename?: 'TeamsConnection';
  /** A list of edges which contains the `Team` and cursor to aid in pagination. */
  edges: Array<TeamsEdge>;
  /** A list of `Team` objects. */
  nodes: Array<Team>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Team` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Team` edge in the connection. */
export type TeamsEdge = {
  __typename?: 'TeamsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Team` at the end of the edge. */
  node: Team;
};

/** Methods to use when ordering `Team`. */
export enum TeamsOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InviteCodeAsc = 'INVITE_CODE_ASC',
  InviteCodeDesc = 'INVITE_CODE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type Transaction = Node & {
  __typename?: 'Transaction';
  date: Scalars['Datetime'];
  /** Reads a single `EquityTypeInfo` that is related to this `Transaction`. */
  equityTypeInfoByPartyAEquityIncreaseScheme?: Maybe<EquityTypeInfo>;
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `EquityGrant` that is related to this `Transaction`. */
  originalGrant?: Maybe<EquityGrant>;
  originalGrantId?: Maybe<Scalars['Int']>;
  partyA: Scalars['String'];
  partyACashIncrease?: Maybe<Currency>;
  partyAEquityIncrease?: Maybe<Equity>;
  partyAEquityIncreaseAmount?: Maybe<Scalars['Int']>;
  partyAEquityIncreaseScheme?: Maybe<Scalars['String']>;
  partyAEquityTransactionFrom?: Maybe<Scalars['Int']>;
  partyB: Scalars['String'];
  /** Reads a single `Team` that is related to this `Transaction`. */
  teamByPartyA?: Maybe<Team>;
  /** Reads a single `Team` that is related to this `Transaction`. */
  teamByPartyB?: Maybe<Team>;
  /** Reads a single `TransactionApproval` that is related to this `Transaction`. */
  transactionApproval?: Maybe<TransactionApproval>;
  /**
   * Reads and enables pagination through a set of `TransactionApproval`.
   * @deprecated Please use transactionApproval instead
   */
  transactionApprovals: TransactionApprovalsConnection;
  /** Reads a single `Transaction` that is related to this `Transaction`. */
  transactionByPartyAEquityTransactionFrom?: Maybe<Transaction>;
  /** Reads a single `TransactionGroup` that is related to this `Transaction`. */
  transactionGroup?: Maybe<TransactionGroup>;
  transactionGroupId: Scalars['String'];
  /** Reads a single `TransactionNote` that is related to this `Transaction`. */
  transactionNote?: Maybe<TransactionNote>;
  /**
   * Reads and enables pagination through a set of `TransactionNote`.
   * @deprecated Please use transactionNote instead
   */
  transactionNotes: TransactionNotesConnection;
  transactionType: TransactionType;
  /** Reads and enables pagination through a set of `Transaction`. */
  transactionsByPartyAEquityTransactionFrom: TransactionsConnection;
};


export type TransactionTransactionApprovalsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionApprovalCondition>;
  filter?: InputMaybe<TransactionApprovalFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionApprovalsOrderBy>>;
};


export type TransactionTransactionNotesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionNoteCondition>;
  filter?: InputMaybe<TransactionNoteFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionNotesOrderBy>>;
};


export type TransactionTransactionsByPartyAEquityTransactionFromArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionCondition>;
  filter?: InputMaybe<TransactionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
};

export type TransactionAllowance = Node & {
  __typename?: 'TransactionAllowance';
  exerciseAllowance: Currency;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  saleAllowance: Currency;
  /** Reads a single `Team` that is related to this `TransactionAllowance`. */
  team?: Maybe<Team>;
  teamId: Scalars['String'];
  transactionGroup: Scalars['String'];
  /** Reads a single `TransactionGroup` that is related to this `TransactionAllowance`. */
  transactionGroupByTransactionGroup?: Maybe<TransactionGroup>;
};

/**
 * A condition to be used against `TransactionAllowance` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type TransactionAllowanceCondition = {
  /** Checks for equality with the object’s `exerciseAllowance` field. */
  exerciseAllowance?: InputMaybe<CurrencyInput>;
  /** Checks for equality with the object’s `saleAllowance` field. */
  saleAllowance?: InputMaybe<CurrencyInput>;
  /** Checks for equality with the object’s `teamId` field. */
  teamId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `transactionGroup` field. */
  transactionGroup?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `TransactionAllowance` object types. All fields are combined with a logical ‘and.’ */
export type TransactionAllowanceFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TransactionAllowanceFilter>>;
  /** Filter by the object’s `exerciseAllowance` field. */
  exerciseAllowance?: InputMaybe<CurrencyFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TransactionAllowanceFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TransactionAllowanceFilter>>;
  /** Filter by the object’s `saleAllowance` field. */
  saleAllowance?: InputMaybe<CurrencyFilter>;
  /** Filter by the object’s `teamId` field. */
  teamId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `transactionGroup` field. */
  transactionGroup?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `TransactionAllowance` */
export type TransactionAllowanceInput = {
  exerciseAllowance?: InputMaybe<CurrencyInput>;
  saleAllowance?: InputMaybe<CurrencyInput>;
  teamId: Scalars['String'];
  transactionGroup: Scalars['String'];
};

/** Represents an update to a `TransactionAllowance`. Fields that are set will be updated. */
export type TransactionAllowancePatch = {
  exerciseAllowance?: InputMaybe<CurrencyInput>;
  saleAllowance?: InputMaybe<CurrencyInput>;
  teamId?: InputMaybe<Scalars['String']>;
  transactionGroup?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `TransactionAllowance` values. */
export type TransactionAllowancesConnection = {
  __typename?: 'TransactionAllowancesConnection';
  /** A list of edges which contains the `TransactionAllowance` and cursor to aid in pagination. */
  edges: Array<TransactionAllowancesEdge>;
  /** A list of `TransactionAllowance` objects. */
  nodes: Array<TransactionAllowance>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TransactionAllowance` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TransactionAllowance` edge in the connection. */
export type TransactionAllowancesEdge = {
  __typename?: 'TransactionAllowancesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TransactionAllowance` at the end of the edge. */
  node: TransactionAllowance;
};

/** Methods to use when ordering `TransactionAllowance`. */
export enum TransactionAllowancesOrderBy {
  ExerciseAllowanceAsc = 'EXERCISE_ALLOWANCE_ASC',
  ExerciseAllowanceDesc = 'EXERCISE_ALLOWANCE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SaleAllowanceAsc = 'SALE_ALLOWANCE_ASC',
  SaleAllowanceDesc = 'SALE_ALLOWANCE_DESC',
  TeamIdAsc = 'TEAM_ID_ASC',
  TeamIdDesc = 'TEAM_ID_DESC',
  TransactionGroupAsc = 'TRANSACTION_GROUP_ASC',
  TransactionGroupDesc = 'TRANSACTION_GROUP_DESC'
}

export type TransactionApproval = Node & {
  __typename?: 'TransactionApproval';
  approvedBy: Scalars['String'];
  approvedOn: Scalars['Datetime'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Transaction` that is related to this `TransactionApproval`. */
  transaction?: Maybe<Transaction>;
  transactionId: Scalars['Int'];
  /** Reads a single `User` that is related to this `TransactionApproval`. */
  userByApprovedBy?: Maybe<User>;
};

/**
 * A condition to be used against `TransactionApproval` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type TransactionApprovalCondition = {
  /** Checks for equality with the object’s `approvedBy` field. */
  approvedBy?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `approvedOn` field. */
  approvedOn?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `transactionId` field. */
  transactionId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `TransactionApproval` object types. All fields are combined with a logical ‘and.’ */
export type TransactionApprovalFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TransactionApprovalFilter>>;
  /** Filter by the object’s `approvedBy` field. */
  approvedBy?: InputMaybe<StringFilter>;
  /** Filter by the object’s `approvedOn` field. */
  approvedOn?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TransactionApprovalFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TransactionApprovalFilter>>;
  /** Filter by the object’s `transactionId` field. */
  transactionId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `TransactionApproval` */
export type TransactionApprovalInput = {
  approvedBy?: InputMaybe<Scalars['String']>;
  approvedOn: Scalars['Datetime'];
  transactionId: Scalars['Int'];
};

/** Represents an update to a `TransactionApproval`. Fields that are set will be updated. */
export type TransactionApprovalPatch = {
  approvedBy?: InputMaybe<Scalars['String']>;
  approvedOn?: InputMaybe<Scalars['Datetime']>;
  transactionId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `TransactionApproval` values. */
export type TransactionApprovalsConnection = {
  __typename?: 'TransactionApprovalsConnection';
  /** A list of edges which contains the `TransactionApproval` and cursor to aid in pagination. */
  edges: Array<TransactionApprovalsEdge>;
  /** A list of `TransactionApproval` objects. */
  nodes: Array<TransactionApproval>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TransactionApproval` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TransactionApproval` edge in the connection. */
export type TransactionApprovalsEdge = {
  __typename?: 'TransactionApprovalsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TransactionApproval` at the end of the edge. */
  node: TransactionApproval;
};

/** Methods to use when ordering `TransactionApproval`. */
export enum TransactionApprovalsOrderBy {
  ApprovedByAsc = 'APPROVED_BY_ASC',
  ApprovedByDesc = 'APPROVED_BY_DESC',
  ApprovedOnAsc = 'APPROVED_ON_ASC',
  ApprovedOnDesc = 'APPROVED_ON_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TransactionIdAsc = 'TRANSACTION_ID_ASC',
  TransactionIdDesc = 'TRANSACTION_ID_DESC'
}

/**
 * A condition to be used against `Transaction` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TransactionCondition = {
  /** Checks for equality with the object’s `date` field. */
  date?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `originalGrantId` field. */
  originalGrantId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `partyA` field. */
  partyA?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `partyACashIncrease` field. */
  partyACashIncrease?: InputMaybe<CurrencyInput>;
  /** Checks for equality with the object’s `partyAEquityIncrease` field. */
  partyAEquityIncrease?: InputMaybe<EquityInput>;
  /** Checks for equality with the object’s `partyAEquityIncreaseAmount` field. */
  partyAEquityIncreaseAmount?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `partyAEquityIncreaseScheme` field. */
  partyAEquityIncreaseScheme?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `partyAEquityTransactionFrom` field. */
  partyAEquityTransactionFrom?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `partyB` field. */
  partyB?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `transactionGroupId` field. */
  transactionGroupId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `transactionType` field. */
  transactionType?: InputMaybe<TransactionType>;
};

export type TransactionDetail = {
  __typename?: 'TransactionDetail';
  amount?: Maybe<Scalars['Int']>;
  /** Reads a single `EquityTypeInfo` that is related to this `TransactionDetail`. */
  equityTypeInfoByScheme?: Maybe<EquityTypeInfo>;
  scheme?: Maybe<Scalars['String']>;
  transactionType?: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `TransactionDetail` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TransactionDetailCondition = {
  /** Checks for equality with the object’s `amount` field. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `scheme` field. */
  scheme?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `transactionType` field. */
  transactionType?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `TransactionDetail` object types. All fields are combined with a logical ‘and.’ */
export type TransactionDetailFilter = {
  /** Filter by the object’s `amount` field. */
  amount?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TransactionDetailFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<TransactionDetailFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TransactionDetailFilter>>;
  /** Filter by the object’s `scheme` field. */
  scheme?: InputMaybe<StringFilter>;
  /** Filter by the object’s `transactionType` field. */
  transactionType?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `TransactionDetail` */
export type TransactionDetailInput = {
  amount?: InputMaybe<Scalars['Int']>;
  scheme?: InputMaybe<Scalars['String']>;
  transactionType?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `TransactionDetail` values. */
export type TransactionDetailsConnection = {
  __typename?: 'TransactionDetailsConnection';
  /** A list of edges which contains the `TransactionDetail` and cursor to aid in pagination. */
  edges: Array<TransactionDetailsEdge>;
  /** A list of `TransactionDetail` objects. */
  nodes: Array<TransactionDetail>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TransactionDetail` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TransactionDetail` edge in the connection. */
export type TransactionDetailsEdge = {
  __typename?: 'TransactionDetailsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TransactionDetail` at the end of the edge. */
  node: TransactionDetail;
};

/** Methods to use when ordering `TransactionDetail`. */
export enum TransactionDetailsOrderBy {
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  Natural = 'NATURAL',
  SchemeAsc = 'SCHEME_ASC',
  SchemeDesc = 'SCHEME_DESC',
  TransactionTypeAsc = 'TRANSACTION_TYPE_ASC',
  TransactionTypeDesc = 'TRANSACTION_TYPE_DESC'
}

export type TransactionDocument = Node & {
  __typename?: 'TransactionDocument';
  displayName: Scalars['String'];
  displayOrder: Scalars['Int'];
  equityScheme?: Maybe<Scalars['String']>;
  /** Reads a single `EquityTypeInfo` that is related to this `TransactionDocument`. */
  equityTypeInfoByEquityScheme?: Maybe<EquityTypeInfo>;
  explainerText: Scalars['String'];
  fileName: Scalars['String'];
  id: Scalars['String'];
  needsToBeSignedByYou?: Maybe<Scalars['Boolean']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  signedByYou?: Maybe<Scalars['Boolean']>;
  /** Reads and enables pagination through a set of `TransactionDocumentField`. */
  transactionDocumentFieldsByDocumentId: TransactionDocumentFieldsConnection;
  /** Reads and enables pagination through a set of `TransactionDocumentSignature`. */
  transactionDocumentSignaturesByDocumentId: TransactionDocumentSignaturesConnection;
  /** Reads a single `TransactionGroup` that is related to this `TransactionDocument`. */
  transactionGroup?: Maybe<TransactionGroup>;
  transactionGroupId: Scalars['String'];
  transactionType: TransactionType;
};


export type TransactionDocumentTransactionDocumentFieldsByDocumentIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionDocumentFieldCondition>;
  filter?: InputMaybe<TransactionDocumentFieldFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionDocumentFieldsOrderBy>>;
};


export type TransactionDocumentTransactionDocumentSignaturesByDocumentIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionDocumentSignatureCondition>;
  filter?: InputMaybe<TransactionDocumentSignatureFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionDocumentSignaturesOrderBy>>;
};

/**
 * A condition to be used against `TransactionDocument` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type TransactionDocumentCondition = {
  /** Checks for equality with the object’s `displayName` field. */
  displayName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `displayOrder` field. */
  displayOrder?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `equityScheme` field. */
  equityScheme?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `explainerText` field. */
  explainerText?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `fileName` field. */
  fileName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `transactionGroupId` field. */
  transactionGroupId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `transactionType` field. */
  transactionType?: InputMaybe<TransactionType>;
};

export type TransactionDocumentField = Node & {
  __typename?: 'TransactionDocumentField';
  colour: Colour;
  /** Reads a single `TransactionDocument` that is related to this `TransactionDocumentField`. */
  document?: Maybe<TransactionDocument>;
  documentId: Scalars['String'];
  flip: Scalars['Boolean'];
  font: Font;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  pageNumber: Scalars['Int'];
  size: Scalars['Int'];
  variable: TransactionDocumentFieldType;
  xStart: Scalars['BigFloat'];
  yStart: Scalars['BigFloat'];
};

/**
 * A condition to be used against `TransactionDocumentField` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type TransactionDocumentFieldCondition = {
  /** Checks for equality with the object’s `colour` field. */
  colour?: InputMaybe<Colour>;
  /** Checks for equality with the object’s `documentId` field. */
  documentId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `flip` field. */
  flip?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `font` field. */
  font?: InputMaybe<Font>;
  /** Checks for equality with the object’s `pageNumber` field. */
  pageNumber?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `size` field. */
  size?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `variable` field. */
  variable?: InputMaybe<TransactionDocumentFieldType>;
  /** Checks for equality with the object’s `xStart` field. */
  xStart?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `yStart` field. */
  yStart?: InputMaybe<Scalars['BigFloat']>;
};

/** A filter to be used against `TransactionDocumentField` object types. All fields are combined with a logical ‘and.’ */
export type TransactionDocumentFieldFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TransactionDocumentFieldFilter>>;
  /** Filter by the object’s `colour` field. */
  colour?: InputMaybe<ColourFilter>;
  /** Filter by the object’s `documentId` field. */
  documentId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `flip` field. */
  flip?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `font` field. */
  font?: InputMaybe<FontFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TransactionDocumentFieldFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TransactionDocumentFieldFilter>>;
  /** Filter by the object’s `pageNumber` field. */
  pageNumber?: InputMaybe<IntFilter>;
  /** Filter by the object’s `size` field. */
  size?: InputMaybe<IntFilter>;
  /** Filter by the object’s `variable` field. */
  variable?: InputMaybe<TransactionDocumentFieldTypeFilter>;
  /** Filter by the object’s `xStart` field. */
  xStart?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `yStart` field. */
  yStart?: InputMaybe<BigFloatFilter>;
};

/** An input for mutations affecting `TransactionDocumentField` */
export type TransactionDocumentFieldInput = {
  colour: Colour;
  documentId: Scalars['String'];
  flip: Scalars['Boolean'];
  font: Font;
  pageNumber: Scalars['Int'];
  size: Scalars['Int'];
  variable: TransactionDocumentFieldType;
  xStart: Scalars['BigFloat'];
  yStart: Scalars['BigFloat'];
};

/** Represents an update to a `TransactionDocumentField`. Fields that are set will be updated. */
export type TransactionDocumentFieldPatch = {
  colour?: InputMaybe<Colour>;
  documentId?: InputMaybe<Scalars['String']>;
  flip?: InputMaybe<Scalars['Boolean']>;
  font?: InputMaybe<Font>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
  variable?: InputMaybe<TransactionDocumentFieldType>;
  xStart?: InputMaybe<Scalars['BigFloat']>;
  yStart?: InputMaybe<Scalars['BigFloat']>;
};

export enum TransactionDocumentFieldType {
  Date = 'DATE',
  EquityType = 'EQUITY_TYPE',
  InvoiceNumber = 'INVOICE_NUMBER',
  NumberExercised = 'NUMBER_EXERCISED',
  PlanName = 'PLAN_NAME',
  SellerFullName = 'SELLER_FULL_NAME',
  SellerSignature = 'SELLER_SIGNATURE',
  SharesSold = 'SHARES_SOLD',
  SharesSoldInWords = 'SHARES_SOLD_IN_WORDS',
  StrikePrice = 'STRIKE_PRICE',
  TotalFee = 'TOTAL_FEE',
  TotalStrikePrice = 'TOTAL_STRIKE_PRICE',
  UnexercisedSharesHeld = 'UNEXERCISED_SHARES_HELD',
  Vat = 'VAT'
}

/** A filter to be used against TransactionDocumentFieldType fields. All fields are combined with a logical ‘and.’ */
export type TransactionDocumentFieldTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<TransactionDocumentFieldType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<TransactionDocumentFieldType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<TransactionDocumentFieldType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<TransactionDocumentFieldType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<TransactionDocumentFieldType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<TransactionDocumentFieldType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<TransactionDocumentFieldType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<TransactionDocumentFieldType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<TransactionDocumentFieldType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<TransactionDocumentFieldType>>;
};

/** A connection to a list of `TransactionDocumentField` values. */
export type TransactionDocumentFieldsConnection = {
  __typename?: 'TransactionDocumentFieldsConnection';
  /** A list of edges which contains the `TransactionDocumentField` and cursor to aid in pagination. */
  edges: Array<TransactionDocumentFieldsEdge>;
  /** A list of `TransactionDocumentField` objects. */
  nodes: Array<TransactionDocumentField>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TransactionDocumentField` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TransactionDocumentField` edge in the connection. */
export type TransactionDocumentFieldsEdge = {
  __typename?: 'TransactionDocumentFieldsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TransactionDocumentField` at the end of the edge. */
  node: TransactionDocumentField;
};

/** Methods to use when ordering `TransactionDocumentField`. */
export enum TransactionDocumentFieldsOrderBy {
  ColourAsc = 'COLOUR_ASC',
  ColourDesc = 'COLOUR_DESC',
  DocumentIdAsc = 'DOCUMENT_ID_ASC',
  DocumentIdDesc = 'DOCUMENT_ID_DESC',
  FlipAsc = 'FLIP_ASC',
  FlipDesc = 'FLIP_DESC',
  FontAsc = 'FONT_ASC',
  FontDesc = 'FONT_DESC',
  Natural = 'NATURAL',
  PageNumberAsc = 'PAGE_NUMBER_ASC',
  PageNumberDesc = 'PAGE_NUMBER_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SizeAsc = 'SIZE_ASC',
  SizeDesc = 'SIZE_DESC',
  VariableAsc = 'VARIABLE_ASC',
  VariableDesc = 'VARIABLE_DESC',
  XStartAsc = 'X_START_ASC',
  XStartDesc = 'X_START_DESC',
  YStartAsc = 'Y_START_ASC',
  YStartDesc = 'Y_START_DESC'
}

/** A filter to be used against `TransactionDocument` object types. All fields are combined with a logical ‘and.’ */
export type TransactionDocumentFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TransactionDocumentFilter>>;
  /** Filter by the object’s `displayName` field. */
  displayName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `displayOrder` field. */
  displayOrder?: InputMaybe<IntFilter>;
  /** Filter by the object’s `equityScheme` field. */
  equityScheme?: InputMaybe<StringFilter>;
  /** Filter by the object’s `explainerText` field. */
  explainerText?: InputMaybe<StringFilter>;
  /** Filter by the object’s `fileName` field. */
  fileName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `needsToBeSignedByYou` field. */
  needsToBeSignedByYou?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TransactionDocumentFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TransactionDocumentFilter>>;
  /** Filter by the object’s `signedByYou` field. */
  signedByYou?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `transactionGroupId` field. */
  transactionGroupId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `transactionType` field. */
  transactionType?: InputMaybe<TransactionTypeFilter>;
};

/** An input for mutations affecting `TransactionDocument` */
export type TransactionDocumentInput = {
  displayName: Scalars['String'];
  displayOrder: Scalars['Int'];
  equityScheme?: InputMaybe<Scalars['String']>;
  explainerText: Scalars['String'];
  fileName: Scalars['String'];
  id: Scalars['String'];
  transactionGroupId: Scalars['String'];
  transactionType: TransactionType;
};

/** Represents an update to a `TransactionDocument`. Fields that are set will be updated. */
export type TransactionDocumentPatch = {
  displayName?: InputMaybe<Scalars['String']>;
  displayOrder?: InputMaybe<Scalars['Int']>;
  equityScheme?: InputMaybe<Scalars['String']>;
  explainerText?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  transactionGroupId?: InputMaybe<Scalars['String']>;
  transactionType?: InputMaybe<TransactionType>;
};

export type TransactionDocumentSignature = Node & {
  __typename?: 'TransactionDocumentSignature';
  /** Reads a single `TransactionDocument` that is related to this `TransactionDocumentSignature`. */
  document?: Maybe<TransactionDocument>;
  documentId: Scalars['String'];
  metadata: Scalars['JSON'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  s3Key: Scalars['String'];
  /** Reads a single `User` that is related to this `TransactionDocumentSignature`. */
  user?: Maybe<User>;
  userId: Scalars['String'];
};

/**
 * A condition to be used against `TransactionDocumentSignature` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type TransactionDocumentSignatureCondition = {
  /** Checks for equality with the object’s `documentId` field. */
  documentId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `metadata` field. */
  metadata?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `s3Key` field. */
  s3Key?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `TransactionDocumentSignature` object types. All fields are combined with a logical ‘and.’ */
export type TransactionDocumentSignatureFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TransactionDocumentSignatureFilter>>;
  /** Filter by the object’s `documentId` field. */
  documentId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `metadata` field. */
  metadata?: InputMaybe<JsonFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TransactionDocumentSignatureFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TransactionDocumentSignatureFilter>>;
  /** Filter by the object’s `s3Key` field. */
  s3Key?: InputMaybe<StringFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `TransactionDocumentSignature` */
export type TransactionDocumentSignatureInput = {
  documentId: Scalars['String'];
  metadata: Scalars['JSON'];
  s3Key: Scalars['String'];
  userId: Scalars['String'];
};

/** Represents an update to a `TransactionDocumentSignature`. Fields that are set will be updated. */
export type TransactionDocumentSignaturePatch = {
  documentId?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  s3Key?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `TransactionDocumentSignature` values. */
export type TransactionDocumentSignaturesConnection = {
  __typename?: 'TransactionDocumentSignaturesConnection';
  /** A list of edges which contains the `TransactionDocumentSignature` and cursor to aid in pagination. */
  edges: Array<TransactionDocumentSignaturesEdge>;
  /** A list of `TransactionDocumentSignature` objects. */
  nodes: Array<TransactionDocumentSignature>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TransactionDocumentSignature` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TransactionDocumentSignature` edge in the connection. */
export type TransactionDocumentSignaturesEdge = {
  __typename?: 'TransactionDocumentSignaturesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TransactionDocumentSignature` at the end of the edge. */
  node: TransactionDocumentSignature;
};

/** Methods to use when ordering `TransactionDocumentSignature`. */
export enum TransactionDocumentSignaturesOrderBy {
  DocumentIdAsc = 'DOCUMENT_ID_ASC',
  DocumentIdDesc = 'DOCUMENT_ID_DESC',
  MetadataAsc = 'METADATA_ASC',
  MetadataDesc = 'METADATA_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  S3KeyAsc = 'S3_KEY_ASC',
  S3KeyDesc = 'S3_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** A connection to a list of `TransactionDocument` values. */
export type TransactionDocumentsConnection = {
  __typename?: 'TransactionDocumentsConnection';
  /** A list of edges which contains the `TransactionDocument` and cursor to aid in pagination. */
  edges: Array<TransactionDocumentsEdge>;
  /** A list of `TransactionDocument` objects. */
  nodes: Array<TransactionDocument>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TransactionDocument` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TransactionDocument` edge in the connection. */
export type TransactionDocumentsEdge = {
  __typename?: 'TransactionDocumentsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TransactionDocument` at the end of the edge. */
  node: TransactionDocument;
};

/** Methods to use when ordering `TransactionDocument`. */
export enum TransactionDocumentsOrderBy {
  DisplayNameAsc = 'DISPLAY_NAME_ASC',
  DisplayNameDesc = 'DISPLAY_NAME_DESC',
  DisplayOrderAsc = 'DISPLAY_ORDER_ASC',
  DisplayOrderDesc = 'DISPLAY_ORDER_DESC',
  EquitySchemeAsc = 'EQUITY_SCHEME_ASC',
  EquitySchemeDesc = 'EQUITY_SCHEME_DESC',
  ExplainerTextAsc = 'EXPLAINER_TEXT_ASC',
  ExplainerTextDesc = 'EXPLAINER_TEXT_DESC',
  FileNameAsc = 'FILE_NAME_ASC',
  FileNameDesc = 'FILE_NAME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TransactionGroupIdAsc = 'TRANSACTION_GROUP_ID_ASC',
  TransactionGroupIdDesc = 'TRANSACTION_GROUP_ID_DESC',
  TransactionTypeAsc = 'TRANSACTION_TYPE_ASC',
  TransactionTypeDesc = 'TRANSACTION_TYPE_DESC'
}

export type TransactionFee = Node & {
  __typename?: 'TransactionFee';
  description: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  rate: Scalars['BigFloat'];
  reciever: Scalars['String'];
  /** Reads a single `Team` that is related to this `TransactionFee`. */
  teamByReciever?: Maybe<Team>;
  transactionGroup: Scalars['String'];
  /** Reads a single `TransactionGroup` that is related to this `TransactionFee`. */
  transactionGroupByTransactionGroup?: Maybe<TransactionGroup>;
};

/**
 * A condition to be used against `TransactionFee` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TransactionFeeCondition = {
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `rate` field. */
  rate?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `reciever` field. */
  reciever?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `transactionGroup` field. */
  transactionGroup?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `TransactionFee` object types. All fields are combined with a logical ‘and.’ */
export type TransactionFeeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TransactionFeeFilter>>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TransactionFeeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TransactionFeeFilter>>;
  /** Filter by the object’s `rate` field. */
  rate?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `reciever` field. */
  reciever?: InputMaybe<StringFilter>;
  /** Filter by the object’s `transactionGroup` field. */
  transactionGroup?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `TransactionFee` */
export type TransactionFeeInput = {
  description: Scalars['String'];
  rate: Scalars['BigFloat'];
  reciever: Scalars['String'];
  transactionGroup: Scalars['String'];
};

/** Represents an update to a `TransactionFee`. Fields that are set will be updated. */
export type TransactionFeePatch = {
  description?: InputMaybe<Scalars['String']>;
  rate?: InputMaybe<Scalars['BigFloat']>;
  reciever?: InputMaybe<Scalars['String']>;
  transactionGroup?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `TransactionFee` values. */
export type TransactionFeesConnection = {
  __typename?: 'TransactionFeesConnection';
  /** A list of edges which contains the `TransactionFee` and cursor to aid in pagination. */
  edges: Array<TransactionFeesEdge>;
  /** A list of `TransactionFee` objects. */
  nodes: Array<TransactionFee>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TransactionFee` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TransactionFee` edge in the connection. */
export type TransactionFeesEdge = {
  __typename?: 'TransactionFeesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TransactionFee` at the end of the edge. */
  node: TransactionFee;
};

/** Methods to use when ordering `TransactionFee`. */
export enum TransactionFeesOrderBy {
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RateAsc = 'RATE_ASC',
  RateDesc = 'RATE_DESC',
  RecieverAsc = 'RECIEVER_ASC',
  RecieverDesc = 'RECIEVER_DESC',
  TransactionGroupAsc = 'TRANSACTION_GROUP_ASC',
  TransactionGroupDesc = 'TRANSACTION_GROUP_DESC'
}

/** A filter to be used against `Transaction` object types. All fields are combined with a logical ‘and.’ */
export type TransactionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TransactionFilter>>;
  /** Filter by the object’s `date` field. */
  date?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TransactionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TransactionFilter>>;
  /** Filter by the object’s `originalGrantId` field. */
  originalGrantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `partyA` field. */
  partyA?: InputMaybe<StringFilter>;
  /** Filter by the object’s `partyACashIncrease` field. */
  partyACashIncrease?: InputMaybe<CurrencyFilter>;
  /** Filter by the object’s `partyAEquityIncrease` field. */
  partyAEquityIncrease?: InputMaybe<EquityFilter>;
  /** Filter by the object’s `partyAEquityIncreaseAmount` field. */
  partyAEquityIncreaseAmount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `partyAEquityIncreaseScheme` field. */
  partyAEquityIncreaseScheme?: InputMaybe<StringFilter>;
  /** Filter by the object’s `partyAEquityTransactionFrom` field. */
  partyAEquityTransactionFrom?: InputMaybe<IntFilter>;
  /** Filter by the object’s `partyB` field. */
  partyB?: InputMaybe<StringFilter>;
  /** Filter by the object’s `transactionGroupId` field. */
  transactionGroupId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `transactionType` field. */
  transactionType?: InputMaybe<TransactionTypeFilter>;
};

export type TransactionGroup = Node & {
  __typename?: 'TransactionGroup';
  /** Reads a single `Team` that is related to this `TransactionGroup`. */
  buyerTeam?: Maybe<Team>;
  buyerTeamId: Scalars['String'];
  /** Reads a single `Company` that is related to this `TransactionGroup`. */
  company?: Maybe<Company>;
  companyId: Scalars['String'];
  escrowTeamId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** Reads a single `LiquidityEvent` that is related to this `TransactionGroup`. */
  liquidityEvent?: Maybe<LiquidityEvent>;
  liquidityEventId?: Maybe<Scalars['String']>;
  /** Maximum sell percentage of available equity */
  maximumSell: Scalars['Float'];
  minimumSell: Scalars['Int'];
  /** Reads and enables pagination through a set of `EquitySummary`. */
  myActiveEquity: EquitySummariesConnection;
  myAllowances?: Maybe<TransactionAllowance>;
  /** Reads and enables pagination through a set of `TransactionDetail`. */
  myTransactionDetails: TransactionDetailsConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `OverallAllocation`. */
  overallAllocations: OverallAllocationsConnection;
  pricePerShare: Currency;
  sellerClosing?: Maybe<Scalars['Datetime']>;
  sellerExecuteDeadline?: Maybe<Scalars['Datetime']>;
  sellerOfferDeadline?: Maybe<Scalars['Datetime']>;
  /** Reads and enables pagination through a set of `SellerOffer`. */
  sellerOffersByTransactionGroup: SellerOffersConnection;
  sellerOpening?: Maybe<Scalars['Datetime']>;
  sellerPaymentDeadline?: Maybe<Scalars['Datetime']>;
  semperFeeRate?: Maybe<Scalars['BigFloat']>;
  sharesToBeBought: Scalars['Int'];
  /** Reads and enables pagination through a set of `TransactionAllowance`. */
  transactionAllowancesByTransactionGroup: TransactionAllowancesConnection;
  transactionCurrency: CurrencyCode;
  transactionDate?: Maybe<Scalars['Datetime']>;
  /** Reads and enables pagination through a set of `TransactionDocument`. */
  transactionDocuments: TransactionDocumentsConnection;
  /** Reads a single `TransactionFee` that is related to this `TransactionGroup`. */
  transactionFeeByTransactionGroup?: Maybe<TransactionFee>;
  /**
   * Reads and enables pagination through a set of `TransactionFee`.
   * @deprecated Please use transactionFeeByTransactionGroup instead
   */
  transactionFeesByTransactionGroup: TransactionFeesConnection;
  /** Reads and enables pagination through a set of `TransactionGroupCurrency`. */
  transactionGroupCurrenciesByTransactionGroup: TransactionGroupCurrenciesConnection;
  transactionGroupStatus: TransactionGroupStatus;
  transactionGroupType: TransactionGroupType;
  /** Reads and enables pagination through a set of `TransactionTaxRate`. */
  transactionTaxRatesByTransactionGroup: TransactionTaxRatesConnection;
  /** Reads and enables pagination through a set of `Transaction`. */
  transactions: TransactionsConnection;
};


export type TransactionGroupMyActiveEquityArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<EquitySummaryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type TransactionGroupMyTransactionDetailsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<TransactionDetailFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type TransactionGroupOverallAllocationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<OverallAllocationCondition>;
  filter?: InputMaybe<OverallAllocationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OverallAllocationsOrderBy>>;
};


export type TransactionGroupSellerOffersByTransactionGroupArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SellerOfferCondition>;
  filter?: InputMaybe<SellerOfferFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SellerOffersOrderBy>>;
};


export type TransactionGroupTransactionAllowancesByTransactionGroupArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionAllowanceCondition>;
  filter?: InputMaybe<TransactionAllowanceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionAllowancesOrderBy>>;
};


export type TransactionGroupTransactionDocumentsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionDocumentCondition>;
  filter?: InputMaybe<TransactionDocumentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionDocumentsOrderBy>>;
};


export type TransactionGroupTransactionFeesByTransactionGroupArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionFeeCondition>;
  filter?: InputMaybe<TransactionFeeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionFeesOrderBy>>;
};


export type TransactionGroupTransactionGroupCurrenciesByTransactionGroupArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionGroupCurrencyCondition>;
  filter?: InputMaybe<TransactionGroupCurrencyFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionGroupCurrenciesOrderBy>>;
};


export type TransactionGroupTransactionTaxRatesByTransactionGroupArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionTaxRateCondition>;
  filter?: InputMaybe<TransactionTaxRateFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionTaxRatesOrderBy>>;
};


export type TransactionGroupTransactionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionCondition>;
  filter?: InputMaybe<TransactionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
};

/**
 * A condition to be used against `TransactionGroup` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TransactionGroupCondition = {
  /** Checks for equality with the object’s `buyerTeamId` field. */
  buyerTeamId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `escrowTeamId` field. */
  escrowTeamId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `liquidityEventId` field. */
  liquidityEventId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `maximumSell` field. */
  maximumSell?: InputMaybe<Scalars['Float']>;
  /** Checks for equality with the object’s `minimumSell` field. */
  minimumSell?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `pricePerShare` field. */
  pricePerShare?: InputMaybe<CurrencyInput>;
  /** Checks for equality with the object’s `sellerClosing` field. */
  sellerClosing?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `sellerExecuteDeadline` field. */
  sellerExecuteDeadline?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `sellerOfferDeadline` field. */
  sellerOfferDeadline?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `sellerOpening` field. */
  sellerOpening?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `sellerPaymentDeadline` field. */
  sellerPaymentDeadline?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `semperFeeRate` field. */
  semperFeeRate?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `sharesToBeBought` field. */
  sharesToBeBought?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `transactionCurrency` field. */
  transactionCurrency?: InputMaybe<CurrencyCode>;
  /** Checks for equality with the object’s `transactionDate` field. */
  transactionDate?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `transactionGroupStatus` field. */
  transactionGroupStatus?: InputMaybe<TransactionGroupStatus>;
  /** Checks for equality with the object’s `transactionGroupType` field. */
  transactionGroupType?: InputMaybe<TransactionGroupType>;
};

/** A connection to a list of `TransactionGroupCurrency` values. */
export type TransactionGroupCurrenciesConnection = {
  __typename?: 'TransactionGroupCurrenciesConnection';
  /** A list of edges which contains the `TransactionGroupCurrency` and cursor to aid in pagination. */
  edges: Array<TransactionGroupCurrenciesEdge>;
  /** A list of `TransactionGroupCurrency` objects. */
  nodes: Array<TransactionGroupCurrency>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TransactionGroupCurrency` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TransactionGroupCurrency` edge in the connection. */
export type TransactionGroupCurrenciesEdge = {
  __typename?: 'TransactionGroupCurrenciesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TransactionGroupCurrency` at the end of the edge. */
  node: TransactionGroupCurrency;
};

/** Methods to use when ordering `TransactionGroupCurrency`. */
export enum TransactionGroupCurrenciesOrderBy {
  ExchangeRateAsc = 'EXCHANGE_RATE_ASC',
  ExchangeRateDesc = 'EXCHANGE_RATE_DESC',
  Natural = 'NATURAL',
  SourceCurrencyAsc = 'SOURCE_CURRENCY_ASC',
  SourceCurrencyDesc = 'SOURCE_CURRENCY_DESC',
  TargetCurrencyAsc = 'TARGET_CURRENCY_ASC',
  TargetCurrencyDesc = 'TARGET_CURRENCY_DESC',
  TransactionGroupAsc = 'TRANSACTION_GROUP_ASC',
  TransactionGroupDesc = 'TRANSACTION_GROUP_DESC'
}

export type TransactionGroupCurrency = {
  __typename?: 'TransactionGroupCurrency';
  exchangeRate?: Maybe<Scalars['BigFloat']>;
  sourceCurrency: CurrencyCode;
  targetCurrency?: Maybe<CurrencyCode>;
  transactionGroup?: Maybe<Scalars['String']>;
  /** Reads a single `TransactionGroup` that is related to this `TransactionGroupCurrency`. */
  transactionGroupByTransactionGroup?: Maybe<TransactionGroup>;
};

/**
 * A condition to be used against `TransactionGroupCurrency` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type TransactionGroupCurrencyCondition = {
  /** Checks for equality with the object’s `exchangeRate` field. */
  exchangeRate?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `sourceCurrency` field. */
  sourceCurrency?: InputMaybe<CurrencyCode>;
  /** Checks for equality with the object’s `targetCurrency` field. */
  targetCurrency?: InputMaybe<CurrencyCode>;
  /** Checks for equality with the object’s `transactionGroup` field. */
  transactionGroup?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `TransactionGroupCurrency` object types. All fields are combined with a logical ‘and.’ */
export type TransactionGroupCurrencyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TransactionGroupCurrencyFilter>>;
  /** Filter by the object’s `exchangeRate` field. */
  exchangeRate?: InputMaybe<BigFloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TransactionGroupCurrencyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TransactionGroupCurrencyFilter>>;
  /** Filter by the object’s `sourceCurrency` field. */
  sourceCurrency?: InputMaybe<CurrencyCodeFilter>;
  /** Filter by the object’s `targetCurrency` field. */
  targetCurrency?: InputMaybe<CurrencyCodeFilter>;
  /** Filter by the object’s `transactionGroup` field. */
  transactionGroup?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `TransactionGroupCurrency` */
export type TransactionGroupCurrencyInput = {
  exchangeRate?: InputMaybe<Scalars['BigFloat']>;
  sourceCurrency: CurrencyCode;
  targetCurrency?: InputMaybe<CurrencyCode>;
  transactionGroup?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `TransactionGroup` object types. All fields are combined with a logical ‘and.’ */
export type TransactionGroupFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TransactionGroupFilter>>;
  /** Filter by the object’s `buyerTeamId` field. */
  buyerTeamId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `escrowTeamId` field. */
  escrowTeamId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `liquidityEventId` field. */
  liquidityEventId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `maximumSell` field. */
  maximumSell?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `minimumSell` field. */
  minimumSell?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TransactionGroupFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TransactionGroupFilter>>;
  /** Filter by the object’s `pricePerShare` field. */
  pricePerShare?: InputMaybe<CurrencyFilter>;
  /** Filter by the object’s `sellerClosing` field. */
  sellerClosing?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `sellerExecuteDeadline` field. */
  sellerExecuteDeadline?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `sellerOfferDeadline` field. */
  sellerOfferDeadline?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `sellerOpening` field. */
  sellerOpening?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `sellerPaymentDeadline` field. */
  sellerPaymentDeadline?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `semperFeeRate` field. */
  semperFeeRate?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `sharesToBeBought` field. */
  sharesToBeBought?: InputMaybe<IntFilter>;
  /** Filter by the object’s `transactionCurrency` field. */
  transactionCurrency?: InputMaybe<CurrencyCodeFilter>;
  /** Filter by the object’s `transactionDate` field. */
  transactionDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `transactionGroupStatus` field. */
  transactionGroupStatus?: InputMaybe<TransactionGroupStatusFilter>;
  /** Filter by the object’s `transactionGroupType` field. */
  transactionGroupType?: InputMaybe<TransactionGroupTypeFilter>;
};

/** An input for mutations affecting `TransactionGroup` */
export type TransactionGroupInput = {
  buyerTeamId: Scalars['String'];
  companyId: Scalars['String'];
  escrowTeamId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  liquidityEventId?: InputMaybe<Scalars['String']>;
  /** Maximum sell percentage of available equity */
  maximumSell?: InputMaybe<Scalars['Float']>;
  minimumSell?: InputMaybe<Scalars['Int']>;
  pricePerShare: CurrencyInput;
  sellerClosing?: InputMaybe<Scalars['Datetime']>;
  sellerExecuteDeadline?: InputMaybe<Scalars['Datetime']>;
  sellerOfferDeadline?: InputMaybe<Scalars['Datetime']>;
  sellerOpening?: InputMaybe<Scalars['Datetime']>;
  sellerPaymentDeadline?: InputMaybe<Scalars['Datetime']>;
  semperFeeRate?: InputMaybe<Scalars['BigFloat']>;
  sharesToBeBought?: InputMaybe<Scalars['Int']>;
  transactionCurrency?: InputMaybe<CurrencyCode>;
  transactionDate?: InputMaybe<Scalars['Datetime']>;
  transactionGroupStatus?: InputMaybe<TransactionGroupStatus>;
  transactionGroupType?: InputMaybe<TransactionGroupType>;
};

/** Represents an update to a `TransactionGroup`. Fields that are set will be updated. */
export type TransactionGroupPatch = {
  buyerTeamId?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['String']>;
  escrowTeamId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  liquidityEventId?: InputMaybe<Scalars['String']>;
  /** Maximum sell percentage of available equity */
  maximumSell?: InputMaybe<Scalars['Float']>;
  minimumSell?: InputMaybe<Scalars['Int']>;
  pricePerShare?: InputMaybe<CurrencyInput>;
  sellerClosing?: InputMaybe<Scalars['Datetime']>;
  sellerExecuteDeadline?: InputMaybe<Scalars['Datetime']>;
  sellerOfferDeadline?: InputMaybe<Scalars['Datetime']>;
  sellerOpening?: InputMaybe<Scalars['Datetime']>;
  sellerPaymentDeadline?: InputMaybe<Scalars['Datetime']>;
  semperFeeRate?: InputMaybe<Scalars['BigFloat']>;
  sharesToBeBought?: InputMaybe<Scalars['Int']>;
  transactionCurrency?: InputMaybe<CurrencyCode>;
  transactionDate?: InputMaybe<Scalars['Datetime']>;
  transactionGroupStatus?: InputMaybe<TransactionGroupStatus>;
  transactionGroupType?: InputMaybe<TransactionGroupType>;
};

export enum TransactionGroupStatus {
  Allocating = 'ALLOCATING',
  CanAcceptOffers = 'CAN_ACCEPT_OFFERS',
  Completed = 'COMPLETED',
  Hidden = 'HIDDEN',
  ReadyForSigning = 'READY_FOR_SIGNING'
}

/** A filter to be used against TransactionGroupStatus fields. All fields are combined with a logical ‘and.’ */
export type TransactionGroupStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<TransactionGroupStatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<TransactionGroupStatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<TransactionGroupStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<TransactionGroupStatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<TransactionGroupStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<TransactionGroupStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<TransactionGroupStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<TransactionGroupStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<TransactionGroupStatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<TransactionGroupStatus>>;
};

export enum TransactionGroupType {
  AllOrNothing = 'ALL_OR_NOTHING',
  Standard = 'STANDARD'
}

/** A filter to be used against TransactionGroupType fields. All fields are combined with a logical ‘and.’ */
export type TransactionGroupTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<TransactionGroupType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<TransactionGroupType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<TransactionGroupType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<TransactionGroupType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<TransactionGroupType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<TransactionGroupType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<TransactionGroupType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<TransactionGroupType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<TransactionGroupType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<TransactionGroupType>>;
};

/** A connection to a list of `TransactionGroup` values. */
export type TransactionGroupsConnection = {
  __typename?: 'TransactionGroupsConnection';
  /** A list of edges which contains the `TransactionGroup` and cursor to aid in pagination. */
  edges: Array<TransactionGroupsEdge>;
  /** A list of `TransactionGroup` objects. */
  nodes: Array<TransactionGroup>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TransactionGroup` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TransactionGroup` edge in the connection. */
export type TransactionGroupsEdge = {
  __typename?: 'TransactionGroupsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TransactionGroup` at the end of the edge. */
  node: TransactionGroup;
};

/** A connection to a list of `String` values. */
export type TransactionGroupsForTeamAndEquityConnection = {
  __typename?: 'TransactionGroupsForTeamAndEquityConnection';
  /** A list of edges which contains the `String` and cursor to aid in pagination. */
  edges: Array<TransactionGroupsForTeamAndEquityEdge>;
  /** A list of `String` objects. */
  nodes: Array<Maybe<Scalars['String']>>;
  /** The count of *all* `String` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `String` edge in the connection. */
export type TransactionGroupsForTeamAndEquityEdge = {
  __typename?: 'TransactionGroupsForTeamAndEquityEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `String` at the end of the edge. */
  node?: Maybe<Scalars['String']>;
};

/** Methods to use when ordering `TransactionGroup`. */
export enum TransactionGroupsOrderBy {
  BuyerTeamIdAsc = 'BUYER_TEAM_ID_ASC',
  BuyerTeamIdDesc = 'BUYER_TEAM_ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  EscrowTeamIdAsc = 'ESCROW_TEAM_ID_ASC',
  EscrowTeamIdDesc = 'ESCROW_TEAM_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LiquidityEventIdAsc = 'LIQUIDITY_EVENT_ID_ASC',
  LiquidityEventIdDesc = 'LIQUIDITY_EVENT_ID_DESC',
  MaximumSellAsc = 'MAXIMUM_SELL_ASC',
  MaximumSellDesc = 'MAXIMUM_SELL_DESC',
  MinimumSellAsc = 'MINIMUM_SELL_ASC',
  MinimumSellDesc = 'MINIMUM_SELL_DESC',
  Natural = 'NATURAL',
  PricePerShareAsc = 'PRICE_PER_SHARE_ASC',
  PricePerShareDesc = 'PRICE_PER_SHARE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SellerClosingAsc = 'SELLER_CLOSING_ASC',
  SellerClosingDesc = 'SELLER_CLOSING_DESC',
  SellerExecuteDeadlineAsc = 'SELLER_EXECUTE_DEADLINE_ASC',
  SellerExecuteDeadlineDesc = 'SELLER_EXECUTE_DEADLINE_DESC',
  SellerOfferDeadlineAsc = 'SELLER_OFFER_DEADLINE_ASC',
  SellerOfferDeadlineDesc = 'SELLER_OFFER_DEADLINE_DESC',
  SellerOpeningAsc = 'SELLER_OPENING_ASC',
  SellerOpeningDesc = 'SELLER_OPENING_DESC',
  SellerPaymentDeadlineAsc = 'SELLER_PAYMENT_DEADLINE_ASC',
  SellerPaymentDeadlineDesc = 'SELLER_PAYMENT_DEADLINE_DESC',
  SemperFeeRateAsc = 'SEMPER_FEE_RATE_ASC',
  SemperFeeRateDesc = 'SEMPER_FEE_RATE_DESC',
  SharesToBeBoughtAsc = 'SHARES_TO_BE_BOUGHT_ASC',
  SharesToBeBoughtDesc = 'SHARES_TO_BE_BOUGHT_DESC',
  TransactionCurrencyAsc = 'TRANSACTION_CURRENCY_ASC',
  TransactionCurrencyDesc = 'TRANSACTION_CURRENCY_DESC',
  TransactionDateAsc = 'TRANSACTION_DATE_ASC',
  TransactionDateDesc = 'TRANSACTION_DATE_DESC',
  TransactionGroupStatusAsc = 'TRANSACTION_GROUP_STATUS_ASC',
  TransactionGroupStatusDesc = 'TRANSACTION_GROUP_STATUS_DESC',
  TransactionGroupTypeAsc = 'TRANSACTION_GROUP_TYPE_ASC',
  TransactionGroupTypeDesc = 'TRANSACTION_GROUP_TYPE_DESC'
}

/** An input for mutations affecting `Transaction` */
export type TransactionInput = {
  date: Scalars['Datetime'];
  id?: InputMaybe<Scalars['Int']>;
  originalGrantId?: InputMaybe<Scalars['Int']>;
  partyA: Scalars['String'];
  partyACashIncrease?: InputMaybe<CurrencyInput>;
  partyAEquityIncrease?: InputMaybe<EquityInput>;
  partyAEquityIncreaseAmount?: InputMaybe<Scalars['Int']>;
  partyAEquityIncreaseScheme?: InputMaybe<Scalars['String']>;
  partyAEquityTransactionFrom?: InputMaybe<Scalars['Int']>;
  partyB: Scalars['String'];
  transactionGroupId: Scalars['String'];
  transactionType: TransactionType;
};

export type TransactionNote = Node & {
  __typename?: 'TransactionNote';
  createdOn: Scalars['Datetime'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  notes: Scalars['String'];
  /** Reads a single `Transaction` that is related to this `TransactionNote`. */
  transaction?: Maybe<Transaction>;
  transactionId: Scalars['Int'];
  /** Reads a single `User` that is related to this `TransactionNote`. */
  user?: Maybe<User>;
  userId: Scalars['String'];
};

/**
 * A condition to be used against `TransactionNote` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TransactionNoteCondition = {
  /** Checks for equality with the object’s `createdOn` field. */
  createdOn?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `notes` field. */
  notes?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `transactionId` field. */
  transactionId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `TransactionNote` object types. All fields are combined with a logical ‘and.’ */
export type TransactionNoteFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TransactionNoteFilter>>;
  /** Filter by the object’s `createdOn` field. */
  createdOn?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TransactionNoteFilter>;
  /** Filter by the object’s `notes` field. */
  notes?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TransactionNoteFilter>>;
  /** Filter by the object’s `transactionId` field. */
  transactionId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `TransactionNote` */
export type TransactionNoteInput = {
  createdOn?: InputMaybe<Scalars['Datetime']>;
  notes: Scalars['String'];
  transactionId: Scalars['Int'];
  userId?: InputMaybe<Scalars['String']>;
};

/** Represents an update to a `TransactionNote`. Fields that are set will be updated. */
export type TransactionNotePatch = {
  createdOn?: InputMaybe<Scalars['Datetime']>;
  notes?: InputMaybe<Scalars['String']>;
  transactionId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `TransactionNote` values. */
export type TransactionNotesConnection = {
  __typename?: 'TransactionNotesConnection';
  /** A list of edges which contains the `TransactionNote` and cursor to aid in pagination. */
  edges: Array<TransactionNotesEdge>;
  /** A list of `TransactionNote` objects. */
  nodes: Array<TransactionNote>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TransactionNote` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TransactionNote` edge in the connection. */
export type TransactionNotesEdge = {
  __typename?: 'TransactionNotesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TransactionNote` at the end of the edge. */
  node: TransactionNote;
};

/** Methods to use when ordering `TransactionNote`. */
export enum TransactionNotesOrderBy {
  CreatedOnAsc = 'CREATED_ON_ASC',
  CreatedOnDesc = 'CREATED_ON_DESC',
  Natural = 'NATURAL',
  NotesAsc = 'NOTES_ASC',
  NotesDesc = 'NOTES_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TransactionIdAsc = 'TRANSACTION_ID_ASC',
  TransactionIdDesc = 'TRANSACTION_ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** Represents an update to a `Transaction`. Fields that are set will be updated. */
export type TransactionPatch = {
  date?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  originalGrantId?: InputMaybe<Scalars['Int']>;
  partyA?: InputMaybe<Scalars['String']>;
  partyACashIncrease?: InputMaybe<CurrencyInput>;
  partyAEquityIncrease?: InputMaybe<EquityInput>;
  partyAEquityIncreaseAmount?: InputMaybe<Scalars['Int']>;
  partyAEquityIncreaseScheme?: InputMaybe<Scalars['String']>;
  partyAEquityTransactionFrom?: InputMaybe<Scalars['Int']>;
  partyB?: InputMaybe<Scalars['String']>;
  transactionGroupId?: InputMaybe<Scalars['String']>;
  transactionType?: InputMaybe<TransactionType>;
};

/** All input for the `transactionReadyForSigning` mutation. */
export type TransactionReadyForSigningInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  transactionGroup?: InputMaybe<Scalars['String']>;
};

/** The output of our `transactionReadyForSigning` mutation. */
export type TransactionReadyForSigningPayload = {
  __typename?: 'TransactionReadyForSigningPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type TransactionSummaryExerciseSale = {
  __typename?: 'TransactionSummaryExerciseSale';
  equityGrant?: Maybe<Scalars['Int']>;
  /** Reads a single `EquityGrant` that is related to this `TransactionSummaryExerciseSale`. */
  equityGrantByEquityGrant?: Maybe<EquityGrant>;
  equityScheme?: Maybe<Scalars['String']>;
  /** Reads a single `EquityTypeInfo` that is related to this `TransactionSummaryExerciseSale`. */
  equityTypeInfoByEquityScheme?: Maybe<EquityTypeInfo>;
  exercised?: Maybe<Scalars['Int']>;
  orderPriority?: Maybe<Scalars['Int']>;
  seller?: Maybe<Scalars['String']>;
  sold?: Maybe<Scalars['Int']>;
  /** Reads a single `Team` that is related to this `TransactionSummaryExerciseSale`. */
  teamBySeller?: Maybe<Team>;
};

/**
 * A condition to be used against `TransactionSummaryExerciseSale` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type TransactionSummaryExerciseSaleCondition = {
  /** Checks for equality with the object’s `equityGrant` field. */
  equityGrant?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `equityScheme` field. */
  equityScheme?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `exercised` field. */
  exercised?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `orderPriority` field. */
  orderPriority?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `seller` field. */
  seller?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `sold` field. */
  sold?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `TransactionSummaryExerciseSale` object types. All fields are combined with a logical ‘and.’ */
export type TransactionSummaryExerciseSaleFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TransactionSummaryExerciseSaleFilter>>;
  /** Filter by the object’s `equityGrant` field. */
  equityGrant?: InputMaybe<IntFilter>;
  /** Filter by the object’s `equityScheme` field. */
  equityScheme?: InputMaybe<StringFilter>;
  /** Filter by the object’s `exercised` field. */
  exercised?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TransactionSummaryExerciseSaleFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TransactionSummaryExerciseSaleFilter>>;
  /** Filter by the object’s `orderPriority` field. */
  orderPriority?: InputMaybe<IntFilter>;
  /** Filter by the object’s `seller` field. */
  seller?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sold` field. */
  sold?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `TransactionSummaryExerciseSale` */
export type TransactionSummaryExerciseSaleInput = {
  equityGrant?: InputMaybe<Scalars['Int']>;
  equityScheme?: InputMaybe<Scalars['String']>;
  exercised?: InputMaybe<Scalars['Int']>;
  orderPriority?: InputMaybe<Scalars['Int']>;
  seller?: InputMaybe<Scalars['String']>;
  sold?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `TransactionSummaryExerciseSale` values. */
export type TransactionSummaryExerciseSalesConnection = {
  __typename?: 'TransactionSummaryExerciseSalesConnection';
  /** A list of edges which contains the `TransactionSummaryExerciseSale` and cursor to aid in pagination. */
  edges: Array<TransactionSummaryExerciseSalesEdge>;
  /** A list of `TransactionSummaryExerciseSale` objects. */
  nodes: Array<TransactionSummaryExerciseSale>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TransactionSummaryExerciseSale` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TransactionSummaryExerciseSale` edge in the connection. */
export type TransactionSummaryExerciseSalesEdge = {
  __typename?: 'TransactionSummaryExerciseSalesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TransactionSummaryExerciseSale` at the end of the edge. */
  node: TransactionSummaryExerciseSale;
};

/** Methods to use when ordering `TransactionSummaryExerciseSale`. */
export enum TransactionSummaryExerciseSalesOrderBy {
  EquityGrantAsc = 'EQUITY_GRANT_ASC',
  EquityGrantDesc = 'EQUITY_GRANT_DESC',
  EquitySchemeAsc = 'EQUITY_SCHEME_ASC',
  EquitySchemeDesc = 'EQUITY_SCHEME_DESC',
  ExercisedAsc = 'EXERCISED_ASC',
  ExercisedDesc = 'EXERCISED_DESC',
  Natural = 'NATURAL',
  OrderPriorityAsc = 'ORDER_PRIORITY_ASC',
  OrderPriorityDesc = 'ORDER_PRIORITY_DESC',
  SellerAsc = 'SELLER_ASC',
  SellerDesc = 'SELLER_DESC',
  SoldAsc = 'SOLD_ASC',
  SoldDesc = 'SOLD_DESC'
}

export type TransactionTaxRate = Node & {
  __typename?: 'TransactionTaxRate';
  /** Reads a single `EquityGrant` that is related to this `TransactionTaxRate`. */
  equity?: Maybe<EquityGrant>;
  equityId: Scalars['Int'];
  exerciseTaxRate?: Maybe<Scalars['BigFloat']>;
  exerciseTaxWithheld?: Maybe<Scalars['Boolean']>;
  marketValueAtExercise?: Maybe<Currency>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  rcaTaxRate?: Maybe<Scalars['BigFloat']>;
  rcaTaxWithheld?: Maybe<Scalars['Boolean']>;
  saleTaxRate?: Maybe<Scalars['BigFloat']>;
  saleTaxWithheld?: Maybe<Scalars['Boolean']>;
  semperFeesReduceTaxableGain?: Maybe<Scalars['Boolean']>;
  transactionGroup: Scalars['String'];
  /** Reads a single `TransactionGroup` that is related to this `TransactionTaxRate`. */
  transactionGroupByTransactionGroup?: Maybe<TransactionGroup>;
};

/**
 * A condition to be used against `TransactionTaxRate` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TransactionTaxRateCondition = {
  /** Checks for equality with the object’s `equityId` field. */
  equityId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `exerciseTaxRate` field. */
  exerciseTaxRate?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `exerciseTaxWithheld` field. */
  exerciseTaxWithheld?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `marketValueAtExercise` field. */
  marketValueAtExercise?: InputMaybe<CurrencyInput>;
  /** Checks for equality with the object’s `rcaTaxRate` field. */
  rcaTaxRate?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `rcaTaxWithheld` field. */
  rcaTaxWithheld?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `saleTaxRate` field. */
  saleTaxRate?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `saleTaxWithheld` field. */
  saleTaxWithheld?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `semperFeesReduceTaxableGain` field. */
  semperFeesReduceTaxableGain?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `transactionGroup` field. */
  transactionGroup?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `TransactionTaxRate` object types. All fields are combined with a logical ‘and.’ */
export type TransactionTaxRateFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TransactionTaxRateFilter>>;
  /** Filter by the object’s `equityId` field. */
  equityId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `exerciseTaxRate` field. */
  exerciseTaxRate?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `exerciseTaxWithheld` field. */
  exerciseTaxWithheld?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `marketValueAtExercise` field. */
  marketValueAtExercise?: InputMaybe<CurrencyFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TransactionTaxRateFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TransactionTaxRateFilter>>;
  /** Filter by the object’s `rcaTaxRate` field. */
  rcaTaxRate?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `rcaTaxWithheld` field. */
  rcaTaxWithheld?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `saleTaxRate` field. */
  saleTaxRate?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `saleTaxWithheld` field. */
  saleTaxWithheld?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `semperFeesReduceTaxableGain` field. */
  semperFeesReduceTaxableGain?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `transactionGroup` field. */
  transactionGroup?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `TransactionTaxRate` */
export type TransactionTaxRateInput = {
  equityId: Scalars['Int'];
  exerciseTaxRate?: InputMaybe<Scalars['BigFloat']>;
  exerciseTaxWithheld?: InputMaybe<Scalars['Boolean']>;
  marketValueAtExercise?: InputMaybe<CurrencyInput>;
  rcaTaxRate?: InputMaybe<Scalars['BigFloat']>;
  rcaTaxWithheld?: InputMaybe<Scalars['Boolean']>;
  saleTaxRate?: InputMaybe<Scalars['BigFloat']>;
  saleTaxWithheld?: InputMaybe<Scalars['Boolean']>;
  semperFeesReduceTaxableGain?: InputMaybe<Scalars['Boolean']>;
  transactionGroup: Scalars['String'];
};

/** Represents an update to a `TransactionTaxRate`. Fields that are set will be updated. */
export type TransactionTaxRatePatch = {
  equityId?: InputMaybe<Scalars['Int']>;
  exerciseTaxRate?: InputMaybe<Scalars['BigFloat']>;
  exerciseTaxWithheld?: InputMaybe<Scalars['Boolean']>;
  marketValueAtExercise?: InputMaybe<CurrencyInput>;
  rcaTaxRate?: InputMaybe<Scalars['BigFloat']>;
  rcaTaxWithheld?: InputMaybe<Scalars['Boolean']>;
  saleTaxRate?: InputMaybe<Scalars['BigFloat']>;
  saleTaxWithheld?: InputMaybe<Scalars['Boolean']>;
  semperFeesReduceTaxableGain?: InputMaybe<Scalars['Boolean']>;
  transactionGroup?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `TransactionTaxRate` values. */
export type TransactionTaxRatesConnection = {
  __typename?: 'TransactionTaxRatesConnection';
  /** A list of edges which contains the `TransactionTaxRate` and cursor to aid in pagination. */
  edges: Array<TransactionTaxRatesEdge>;
  /** A list of `TransactionTaxRate` objects. */
  nodes: Array<TransactionTaxRate>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TransactionTaxRate` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TransactionTaxRate` edge in the connection. */
export type TransactionTaxRatesEdge = {
  __typename?: 'TransactionTaxRatesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TransactionTaxRate` at the end of the edge. */
  node: TransactionTaxRate;
};

/** Methods to use when ordering `TransactionTaxRate`. */
export enum TransactionTaxRatesOrderBy {
  EquityIdAsc = 'EQUITY_ID_ASC',
  EquityIdDesc = 'EQUITY_ID_DESC',
  ExerciseTaxRateAsc = 'EXERCISE_TAX_RATE_ASC',
  ExerciseTaxRateDesc = 'EXERCISE_TAX_RATE_DESC',
  ExerciseTaxWithheldAsc = 'EXERCISE_TAX_WITHHELD_ASC',
  ExerciseTaxWithheldDesc = 'EXERCISE_TAX_WITHHELD_DESC',
  MarketValueAtExerciseAsc = 'MARKET_VALUE_AT_EXERCISE_ASC',
  MarketValueAtExerciseDesc = 'MARKET_VALUE_AT_EXERCISE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RcaTaxRateAsc = 'RCA_TAX_RATE_ASC',
  RcaTaxRateDesc = 'RCA_TAX_RATE_DESC',
  RcaTaxWithheldAsc = 'RCA_TAX_WITHHELD_ASC',
  RcaTaxWithheldDesc = 'RCA_TAX_WITHHELD_DESC',
  SaleTaxRateAsc = 'SALE_TAX_RATE_ASC',
  SaleTaxRateDesc = 'SALE_TAX_RATE_DESC',
  SaleTaxWithheldAsc = 'SALE_TAX_WITHHELD_ASC',
  SaleTaxWithheldDesc = 'SALE_TAX_WITHHELD_DESC',
  SemperFeesReduceTaxableGainAsc = 'SEMPER_FEES_REDUCE_TAXABLE_GAIN_ASC',
  SemperFeesReduceTaxableGainDesc = 'SEMPER_FEES_REDUCE_TAXABLE_GAIN_DESC',
  TransactionGroupAsc = 'TRANSACTION_GROUP_ASC',
  TransactionGroupDesc = 'TRANSACTION_GROUP_DESC'
}

export enum TransactionType {
  Exercise = 'EXERCISE',
  Grant = 'GRANT',
  Lapse = 'LAPSE',
  Sale = 'SALE',
  StockGrant = 'STOCK_GRANT',
  Vesting = 'VESTING'
}

/** A filter to be used against TransactionType fields. All fields are combined with a logical ‘and.’ */
export type TransactionTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<TransactionType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<TransactionType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<TransactionType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<TransactionType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<TransactionType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<TransactionType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<TransactionType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<TransactionType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<TransactionType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<TransactionType>>;
};

/** A connection to a list of `Transaction` values. */
export type TransactionsConnection = {
  __typename?: 'TransactionsConnection';
  /** A list of edges which contains the `Transaction` and cursor to aid in pagination. */
  edges: Array<TransactionsEdge>;
  /** A list of `Transaction` objects. */
  nodes: Array<Transaction>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Transaction` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Transaction` edge in the connection. */
export type TransactionsEdge = {
  __typename?: 'TransactionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Transaction` at the end of the edge. */
  node: Transaction;
};

/** Methods to use when ordering `Transaction`. */
export enum TransactionsOrderBy {
  DateAsc = 'DATE_ASC',
  DateDesc = 'DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  OriginalGrantIdAsc = 'ORIGINAL_GRANT_ID_ASC',
  OriginalGrantIdDesc = 'ORIGINAL_GRANT_ID_DESC',
  PartyAAsc = 'PARTY_A_ASC',
  PartyACashIncreaseAsc = 'PARTY_A_CASH_INCREASE_ASC',
  PartyACashIncreaseDesc = 'PARTY_A_CASH_INCREASE_DESC',
  PartyADesc = 'PARTY_A_DESC',
  PartyAEquityIncreaseAmountAsc = 'PARTY_A_EQUITY_INCREASE_AMOUNT_ASC',
  PartyAEquityIncreaseAmountDesc = 'PARTY_A_EQUITY_INCREASE_AMOUNT_DESC',
  PartyAEquityIncreaseAsc = 'PARTY_A_EQUITY_INCREASE_ASC',
  PartyAEquityIncreaseDesc = 'PARTY_A_EQUITY_INCREASE_DESC',
  PartyAEquityIncreaseSchemeAsc = 'PARTY_A_EQUITY_INCREASE_SCHEME_ASC',
  PartyAEquityIncreaseSchemeDesc = 'PARTY_A_EQUITY_INCREASE_SCHEME_DESC',
  PartyAEquityTransactionFromAsc = 'PARTY_A_EQUITY_TRANSACTION_FROM_ASC',
  PartyAEquityTransactionFromDesc = 'PARTY_A_EQUITY_TRANSACTION_FROM_DESC',
  PartyBAsc = 'PARTY_B_ASC',
  PartyBDesc = 'PARTY_B_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TransactionGroupIdAsc = 'TRANSACTION_GROUP_ID_ASC',
  TransactionGroupIdDesc = 'TRANSACTION_GROUP_ID_DESC',
  TransactionTypeAsc = 'TRANSACTION_TYPE_ASC',
  TransactionTypeDesc = 'TRANSACTION_TYPE_DESC'
}

/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['UUID']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['UUID']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['UUID']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['UUID']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['UUID']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['UUID']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['UUID']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['UUID']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['UUID']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['UUID']>>;
};

/** All input for the `updateBuyerOfferByNodeId` mutation. */
export type UpdateBuyerOfferByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `BuyerOffer` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `BuyerOffer` being updated. */
  patch: BuyerOfferPatch;
};

/** All input for the `updateBuyerOffer` mutation. */
export type UpdateBuyerOfferInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  liquidityEvent: Scalars['String'];
  /** An object where the defined keys will be set on the `BuyerOffer` being updated. */
  patch: BuyerOfferPatch;
  teamId: Scalars['String'];
};

/** The output of our update `BuyerOffer` mutation. */
export type UpdateBuyerOfferPayload = {
  __typename?: 'UpdateBuyerOfferPayload';
  /** The `BuyerOffer` that was updated by this mutation. */
  buyerOffer?: Maybe<BuyerOffer>;
  /** An edge for our `BuyerOffer`. May be used by Relay 1. */
  buyerOfferEdge?: Maybe<BuyerOffersEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `LiquidityEvent` that is related to this `BuyerOffer`. */
  liquidityEventByLiquidityEvent?: Maybe<LiquidityEvent>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `BuyerOffer`. */
  team?: Maybe<Team>;
};


/** The output of our update `BuyerOffer` mutation. */
export type UpdateBuyerOfferPayloadBuyerOfferEdgeArgs = {
  orderBy?: InputMaybe<Array<BuyerOffersOrderBy>>;
};

/** All input for the `updateBuyerOnboardingByNodeId` mutation. */
export type UpdateBuyerOnboardingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `BuyerOnboarding` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `BuyerOnboarding` being updated. */
  patch: BuyerOnboardingPatch;
};

/** All input for the `updateBuyerOnboarding` mutation. */
export type UpdateBuyerOnboardingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `BuyerOnboarding` being updated. */
  patch: BuyerOnboardingPatch;
  teamId: Scalars['String'];
};

/** The output of our update `BuyerOnboarding` mutation. */
export type UpdateBuyerOnboardingPayload = {
  __typename?: 'UpdateBuyerOnboardingPayload';
  /** The `BuyerOnboarding` that was updated by this mutation. */
  buyerOnboarding?: Maybe<BuyerOnboarding>;
  /** An edge for our `BuyerOnboarding`. May be used by Relay 1. */
  buyerOnboardingEdge?: Maybe<BuyerOnboardingsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `BuyerOnboarding`. */
  team?: Maybe<Team>;
};


/** The output of our update `BuyerOnboarding` mutation. */
export type UpdateBuyerOnboardingPayloadBuyerOnboardingEdgeArgs = {
  orderBy?: InputMaybe<Array<BuyerOnboardingsOrderBy>>;
};

/** All input for the `updateCompanyByNodeId` mutation. */
export type UpdateCompanyByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Company` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Company` being updated. */
  patch: CompanyPatch;
};

/** All input for the `updateCompany` mutation. */
export type UpdateCompanyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  /** An object where the defined keys will be set on the `Company` being updated. */
  patch: CompanyPatch;
};

/** All input for the `updateCompanyKeyPersonByNodeId` mutation. */
export type UpdateCompanyKeyPersonByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CompanyKeyPerson` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CompanyKeyPerson` being updated. */
  patch: CompanyKeyPersonPatch;
};

/** All input for the `updateCompanyKeyPerson` mutation. */
export type UpdateCompanyKeyPersonInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `CompanyKeyPerson` being updated. */
  patch: CompanyKeyPersonPatch;
};

/** The output of our update `CompanyKeyPerson` mutation. */
export type UpdateCompanyKeyPersonPayload = {
  __typename?: 'UpdateCompanyKeyPersonPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Company` that is related to this `CompanyKeyPerson`. */
  company?: Maybe<Company>;
  /** The `CompanyKeyPerson` that was updated by this mutation. */
  companyKeyPerson?: Maybe<CompanyKeyPerson>;
  /** An edge for our `CompanyKeyPerson`. May be used by Relay 1. */
  companyKeyPersonEdge?: Maybe<CompanyKeyPeopleEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `CompanyKeyPerson` mutation. */
export type UpdateCompanyKeyPersonPayloadCompanyKeyPersonEdgeArgs = {
  orderBy?: InputMaybe<Array<CompanyKeyPeopleOrderBy>>;
};

/** The output of our update `Company` mutation. */
export type UpdateCompanyPayload = {
  __typename?: 'UpdateCompanyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Company` that was updated by this mutation. */
  company?: Maybe<Company>;
  /** An edge for our `Company`. May be used by Relay 1. */
  companyEdge?: Maybe<CompaniesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `Company` mutation. */
export type UpdateCompanyPayloadCompanyEdgeArgs = {
  orderBy?: InputMaybe<Array<CompaniesOrderBy>>;
};

/** All input for the `updateEmploymentByNodeId` mutation. */
export type UpdateEmploymentByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Employment` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Employment` being updated. */
  patch: EmploymentPatch;
};

/** All input for the `updateEmployment` mutation. */
export type UpdateEmploymentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyTeamId: Scalars['String'];
  employeeTeamId: Scalars['String'];
  /** An object where the defined keys will be set on the `Employment` being updated. */
  patch: EmploymentPatch;
};

/** The output of our update `Employment` mutation. */
export type UpdateEmploymentPayload = {
  __typename?: 'UpdateEmploymentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Team` that is related to this `Employment`. */
  companyTeam?: Maybe<Team>;
  /** Reads a single `Team` that is related to this `Employment`. */
  employeeTeam?: Maybe<Team>;
  /** The `Employment` that was updated by this mutation. */
  employment?: Maybe<Employment>;
  /** An edge for our `Employment`. May be used by Relay 1. */
  employmentEdge?: Maybe<EmploymentsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `Employment` mutation. */
export type UpdateEmploymentPayloadEmploymentEdgeArgs = {
  orderBy?: InputMaybe<Array<EmploymentsOrderBy>>;
};

/** All input for the `updateEquityGrantByHolderTeamIdAndEquityScheme` mutation. */
export type UpdateEquityGrantByHolderTeamIdAndEquitySchemeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  equityScheme: Scalars['String'];
  holderTeamId: Scalars['String'];
  /** An object where the defined keys will be set on the `EquityGrant` being updated. */
  patch: EquityGrantPatch;
};

/** All input for the `updateEquityGrantByNodeId` mutation. */
export type UpdateEquityGrantByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `EquityGrant` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `EquityGrant` being updated. */
  patch: EquityGrantPatch;
};

/** All input for the `updateEquityGrant` mutation. */
export type UpdateEquityGrantInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `EquityGrant` being updated. */
  patch: EquityGrantPatch;
};

/** The output of our update `EquityGrant` mutation. */
export type UpdateEquityGrantPayload = {
  __typename?: 'UpdateEquityGrantPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EquityGrant` that was updated by this mutation. */
  equityGrant?: Maybe<EquityGrant>;
  /** An edge for our `EquityGrant`. May be used by Relay 1. */
  equityGrantEdge?: Maybe<EquityGrantsEdge>;
  /** Reads a single `EquityTypeInfo` that is related to this `EquityGrant`. */
  equityTypeInfoByEquityScheme?: Maybe<EquityTypeInfo>;
  /** Reads a single `Team` that is related to this `EquityGrant`. */
  holderTeam?: Maybe<Team>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `EquityGrant` mutation. */
export type UpdateEquityGrantPayloadEquityGrantEdgeArgs = {
  orderBy?: InputMaybe<Array<EquityGrantsOrderBy>>;
};

/** All input for the `updateEquityTypeByNodeId` mutation. */
export type UpdateEquityTypeByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `EquityType` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `EquityType` being updated. */
  patch: EquityTypePatch;
};

/** All input for the `updateEquityTypeInfoByNodeId` mutation. */
export type UpdateEquityTypeInfoByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `EquityTypeInfo` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `EquityTypeInfo` being updated. */
  patch: EquityTypeInfoPatch;
};

/** All input for the `updateEquityTypeInfo` mutation. */
export type UpdateEquityTypeInfoInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  /** An object where the defined keys will be set on the `EquityTypeInfo` being updated. */
  patch: EquityTypeInfoPatch;
};

/** The output of our update `EquityTypeInfo` mutation. */
export type UpdateEquityTypeInfoPayload = {
  __typename?: 'UpdateEquityTypeInfoPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `EquityType` that is related to this `EquityTypeInfo`. */
  equityTypeByTypeOfEquity?: Maybe<EquityType>;
  /** The `EquityTypeInfo` that was updated by this mutation. */
  equityTypeInfo?: Maybe<EquityTypeInfo>;
  /** Reads a single `EquityTypeInfo` that is related to this `EquityTypeInfo`. */
  equityTypeInfoByConvertsTo?: Maybe<EquityTypeInfo>;
  /** An edge for our `EquityTypeInfo`. May be used by Relay 1. */
  equityTypeInfoEdge?: Maybe<EquityTypeInfosEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `EquityTypeInfo` mutation. */
export type UpdateEquityTypeInfoPayloadEquityTypeInfoEdgeArgs = {
  orderBy?: InputMaybe<Array<EquityTypeInfosOrderBy>>;
};

/** All input for the `updateEquityType` mutation. */
export type UpdateEquityTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  /** An object where the defined keys will be set on the `EquityType` being updated. */
  patch: EquityTypePatch;
};

/** The output of our update `EquityType` mutation. */
export type UpdateEquityTypePayload = {
  __typename?: 'UpdateEquityTypePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EquityType` that was updated by this mutation. */
  equityType?: Maybe<EquityType>;
  /** An edge for our `EquityType`. May be used by Relay 1. */
  equityTypeEdge?: Maybe<EquityTypesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `EquityType` mutation. */
export type UpdateEquityTypePayloadEquityTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<EquityTypesOrderBy>>;
};

/** All input for the `updateFileByNodeId` mutation. */
export type UpdateFileByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `File` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `File` being updated. */
  patch: FilePatch;
};

/** All input for the `updateFile` mutation. */
export type UpdateFileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  liquidityEventId: Scalars['String'];
  /** An object where the defined keys will be set on the `File` being updated. */
  patch: FilePatch;
  slug: Scalars['String'];
};

/** The output of our update `File` mutation. */
export type UpdateFilePayload = {
  __typename?: 'UpdateFilePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `File` that was updated by this mutation. */
  file?: Maybe<File>;
  /** An edge for our `File`. May be used by Relay 1. */
  fileEdge?: Maybe<FilesEdge>;
  /** Reads a single `LiquidityEvent` that is related to this `File`. */
  liquidityEvent?: Maybe<LiquidityEvent>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `File` mutation. */
export type UpdateFilePayloadFileEdgeArgs = {
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** All input for the `updateInvestorApprovalByNodeId` mutation. */
export type UpdateInvestorApprovalByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `InvestorApproval` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `InvestorApproval` being updated. */
  patch: InvestorApprovalPatch;
};

/** All input for the `updateInvestorApproval` mutation. */
export type UpdateInvestorApprovalInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `InvestorApproval` being updated. */
  patch: InvestorApprovalPatch;
  teamId: Scalars['String'];
};

/** The output of our update `InvestorApproval` mutation. */
export type UpdateInvestorApprovalPayload = {
  __typename?: 'UpdateInvestorApprovalPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `InvestorApproval` that was updated by this mutation. */
  investorApproval?: Maybe<InvestorApproval>;
  /** An edge for our `InvestorApproval`. May be used by Relay 1. */
  investorApprovalEdge?: Maybe<InvestorApprovalsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `InvestorApproval`. */
  team?: Maybe<Team>;
  /** Reads a single `User` that is related to this `InvestorApproval`. */
  userByApprovedBy?: Maybe<User>;
};


/** The output of our update `InvestorApproval` mutation. */
export type UpdateInvestorApprovalPayloadInvestorApprovalEdgeArgs = {
  orderBy?: InputMaybe<Array<InvestorApprovalsOrderBy>>;
};

/** All input for the `updateInvestorInviteByNodeId` mutation. */
export type UpdateInvestorInviteByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `InvestorInvite` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `InvestorInvite` being updated. */
  patch: InvestorInvitePatch;
};

/** All input for the `updateInvestorInvite` mutation. */
export type UpdateInvestorInviteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  inviteCode: Scalars['String'];
  /** An object where the defined keys will be set on the `InvestorInvite` being updated. */
  patch: InvestorInvitePatch;
};

/** The output of our update `InvestorInvite` mutation. */
export type UpdateInvestorInvitePayload = {
  __typename?: 'UpdateInvestorInvitePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `InvestorInvite` that was updated by this mutation. */
  investorInvite?: Maybe<InvestorInvite>;
  /** An edge for our `InvestorInvite`. May be used by Relay 1. */
  investorInviteEdge?: Maybe<InvestorInvitesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `InvestorInvite`. */
  userByUsedBy?: Maybe<User>;
};


/** The output of our update `InvestorInvite` mutation. */
export type UpdateInvestorInvitePayloadInvestorInviteEdgeArgs = {
  orderBy?: InputMaybe<Array<InvestorInvitesOrderBy>>;
};

/** All input for the `updateLiquidityEventByNodeId` mutation. */
export type UpdateLiquidityEventByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LiquidityEvent` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `LiquidityEvent` being updated. */
  patch: LiquidityEventPatch;
};

/** All input for the `updateLiquidityEventFaqByNodeId` mutation. */
export type UpdateLiquidityEventFaqByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LiquidityEventFaq` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `LiquidityEventFaq` being updated. */
  patch: LiquidityEventFaqPatch;
};

/** All input for the `updateLiquidityEventFaq` mutation. */
export type UpdateLiquidityEventFaqInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `LiquidityEventFaq` being updated. */
  patch: LiquidityEventFaqPatch;
};

/** The output of our update `LiquidityEventFaq` mutation. */
export type UpdateLiquidityEventFaqPayload = {
  __typename?: 'UpdateLiquidityEventFaqPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `LiquidityEvent` that is related to this `LiquidityEventFaq`. */
  liquidityEvent?: Maybe<LiquidityEvent>;
  /** The `LiquidityEventFaq` that was updated by this mutation. */
  liquidityEventFaq?: Maybe<LiquidityEventFaq>;
  /** An edge for our `LiquidityEventFaq`. May be used by Relay 1. */
  liquidityEventFaqEdge?: Maybe<LiquidityEventFaqsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `LiquidityEventFaq` mutation. */
export type UpdateLiquidityEventFaqPayloadLiquidityEventFaqEdgeArgs = {
  orderBy?: InputMaybe<Array<LiquidityEventFaqsOrderBy>>;
};

/** All input for the `updateLiquidityEvent` mutation. */
export type UpdateLiquidityEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  /** An object where the defined keys will be set on the `LiquidityEvent` being updated. */
  patch: LiquidityEventPatch;
};

/** The output of our update `LiquidityEvent` mutation. */
export type UpdateLiquidityEventPayload = {
  __typename?: 'UpdateLiquidityEventPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Company` that is related to this `LiquidityEvent`. */
  company?: Maybe<Company>;
  /** The `LiquidityEvent` that was updated by this mutation. */
  liquidityEvent?: Maybe<LiquidityEvent>;
  /** An edge for our `LiquidityEvent`. May be used by Relay 1. */
  liquidityEventEdge?: Maybe<LiquidityEventsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `LiquidityEvent` mutation. */
export type UpdateLiquidityEventPayloadLiquidityEventEdgeArgs = {
  orderBy?: InputMaybe<Array<LiquidityEventsOrderBy>>;
};

/** All input for the `updateNdaMetadatumByNodeId` mutation. */
export type UpdateNdaMetadatumByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `NdaMetadatum` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `NdaMetadatum` being updated. */
  patch: NdaMetadatumPatch;
};

/** All input for the `updateNdaMetadatum` mutation. */
export type UpdateNdaMetadatumInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  liquidityEvent: Scalars['String'];
  /** An object where the defined keys will be set on the `NdaMetadatum` being updated. */
  patch: NdaMetadatumPatch;
  teamId: Scalars['String'];
};

/** The output of our update `NdaMetadatum` mutation. */
export type UpdateNdaMetadatumPayload = {
  __typename?: 'UpdateNdaMetadatumPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `LiquidityEvent` that is related to this `NdaMetadatum`. */
  liquidityEventByLiquidityEvent?: Maybe<LiquidityEvent>;
  /** The `NdaMetadatum` that was updated by this mutation. */
  ndaMetadatum?: Maybe<NdaMetadatum>;
  /** An edge for our `NdaMetadatum`. May be used by Relay 1. */
  ndaMetadatumEdge?: Maybe<NdaMetadataEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `NdaMetadatum`. */
  team?: Maybe<Team>;
};


/** The output of our update `NdaMetadatum` mutation. */
export type UpdateNdaMetadatumPayloadNdaMetadatumEdgeArgs = {
  orderBy?: InputMaybe<Array<NdaMetadataOrderBy>>;
};

/** All input for the `updateOverallAllocationByNodeId` mutation. */
export type UpdateOverallAllocationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `OverallAllocation` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `OverallAllocation` being updated. */
  patch: OverallAllocationPatch;
};

/** All input for the `updateOverallAllocation` mutation. */
export type UpdateOverallAllocationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `OverallAllocation` being updated. */
  patch: OverallAllocationPatch;
  seller: Scalars['String'];
  transactionGroupId: Scalars['String'];
};

/** The output of our update `OverallAllocation` mutation. */
export type UpdateOverallAllocationPayload = {
  __typename?: 'UpdateOverallAllocationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `OverallAllocation` that was updated by this mutation. */
  overallAllocation?: Maybe<OverallAllocation>;
  /** An edge for our `OverallAllocation`. May be used by Relay 1. */
  overallAllocationEdge?: Maybe<OverallAllocationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `OverallAllocation`. */
  teamBySeller?: Maybe<Team>;
  /** Reads a single `TransactionGroup` that is related to this `OverallAllocation`. */
  transactionGroup?: Maybe<TransactionGroup>;
};


/** The output of our update `OverallAllocation` mutation. */
export type UpdateOverallAllocationPayloadOverallAllocationEdgeArgs = {
  orderBy?: InputMaybe<Array<OverallAllocationsOrderBy>>;
};

/** All input for the `updateSellerOfferByNodeId` mutation. */
export type UpdateSellerOfferByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SellerOffer` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `SellerOffer` being updated. */
  patch: SellerOfferPatch;
};

/** All input for the `updateSellerOffer` mutation. */
export type UpdateSellerOfferInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  grantId: Scalars['Int'];
  /** An object where the defined keys will be set on the `SellerOffer` being updated. */
  patch: SellerOfferPatch;
  sellerId: Scalars['String'];
  transactionGroup: Scalars['String'];
};

/** The output of our update `SellerOffer` mutation. */
export type UpdateSellerOfferPayload = {
  __typename?: 'UpdateSellerOfferPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `EquityGrant` that is related to this `SellerOffer`. */
  grant?: Maybe<EquityGrant>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `SellerOffer`. */
  seller?: Maybe<Team>;
  /** The `SellerOffer` that was updated by this mutation. */
  sellerOffer?: Maybe<SellerOffer>;
  /** An edge for our `SellerOffer`. May be used by Relay 1. */
  sellerOfferEdge?: Maybe<SellerOffersEdge>;
  /** Reads a single `TransactionGroup` that is related to this `SellerOffer`. */
  transactionGroupByTransactionGroup?: Maybe<TransactionGroup>;
};


/** The output of our update `SellerOffer` mutation. */
export type UpdateSellerOfferPayloadSellerOfferEdgeArgs = {
  orderBy?: InputMaybe<Array<SellerOffersOrderBy>>;
};

/** All input for the `updateSellerOnboardingByNodeId` mutation. */
export type UpdateSellerOnboardingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SellerOnboarding` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `SellerOnboarding` being updated. */
  patch: SellerOnboardingPatch;
};

/** All input for the `updateSellerOnboarding` mutation. */
export type UpdateSellerOnboardingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `SellerOnboarding` being updated. */
  patch: SellerOnboardingPatch;
  teamId: Scalars['String'];
};

/** The output of our update `SellerOnboarding` mutation. */
export type UpdateSellerOnboardingPayload = {
  __typename?: 'UpdateSellerOnboardingPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SellerOnboarding` that was updated by this mutation. */
  sellerOnboarding?: Maybe<SellerOnboarding>;
  /** An edge for our `SellerOnboarding`. May be used by Relay 1. */
  sellerOnboardingEdge?: Maybe<SellerOnboardingsEdge>;
  /** Reads a single `Team` that is related to this `SellerOnboarding`. */
  team?: Maybe<Team>;
};


/** The output of our update `SellerOnboarding` mutation. */
export type UpdateSellerOnboardingPayloadSellerOnboardingEdgeArgs = {
  orderBy?: InputMaybe<Array<SellerOnboardingsOrderBy>>;
};

/** All input for the `updateStage2AccessByNodeId` mutation. */
export type UpdateStage2AccessByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Stage2Access` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Stage2Access` being updated. */
  patch: Stage2AccessPatch;
};

/** All input for the `updateStage2Access` mutation. */
export type UpdateStage2AccessInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  liquidityEvent: Scalars['String'];
  /** An object where the defined keys will be set on the `Stage2Access` being updated. */
  patch: Stage2AccessPatch;
  teamId: Scalars['String'];
};

/** The output of our update `Stage2Access` mutation. */
export type UpdateStage2AccessPayload = {
  __typename?: 'UpdateStage2AccessPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `LiquidityEvent` that is related to this `Stage2Access`. */
  liquidityEventByLiquidityEvent?: Maybe<LiquidityEvent>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Stage2Access` that was updated by this mutation. */
  stage2Access?: Maybe<Stage2Access>;
  /** An edge for our `Stage2Access`. May be used by Relay 1. */
  stage2AccessEdge?: Maybe<Stage2AccessesEdge>;
  /** Reads a single `Team` that is related to this `Stage2Access`. */
  team?: Maybe<Team>;
};


/** The output of our update `Stage2Access` mutation. */
export type UpdateStage2AccessPayloadStage2AccessEdgeArgs = {
  orderBy?: InputMaybe<Array<Stage2AccessesOrderBy>>;
};

/** All input for the `updateTaxRateByNodeId` mutation. */
export type UpdateTaxRateByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TaxRate` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TaxRate` being updated. */
  patch: TaxRatePatch;
};

/** All input for the `updateTaxRate` mutation. */
export type UpdateTaxRateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  equityType: Scalars['String'];
  fiscalResidency: IsoCountry;
  moreThanXMonthsEmployed: Scalars['Int'];
  moreThanXMonthsHeldGrant: Scalars['Int'];
  /** An object where the defined keys will be set on the `TaxRate` being updated. */
  patch: TaxRatePatch;
};

/** The output of our update `TaxRate` mutation. */
export type UpdateTaxRatePayload = {
  __typename?: 'UpdateTaxRatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `EquityType` that is related to this `TaxRate`. */
  equityTypeByEquityType?: Maybe<EquityType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TaxRate` that was updated by this mutation. */
  taxRate?: Maybe<TaxRate>;
  /** An edge for our `TaxRate`. May be used by Relay 1. */
  taxRateEdge?: Maybe<TaxRatesEdge>;
};


/** The output of our update `TaxRate` mutation. */
export type UpdateTaxRatePayloadTaxRateEdgeArgs = {
  orderBy?: InputMaybe<Array<TaxRatesOrderBy>>;
};

/** All input for the `updateTeamByNodeId` mutation. */
export type UpdateTeamByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Team` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Team` being updated. */
  patch: TeamPatch;
};

/** All input for the `updateTeam` mutation. */
export type UpdateTeamInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  /** An object where the defined keys will be set on the `Team` being updated. */
  patch: TeamPatch;
};

/** All input for the `updateTeamMembershipByNodeId` mutation. */
export type UpdateTeamMembershipByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TeamMembership` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TeamMembership` being updated. */
  patch: TeamMembershipPatch;
};

/** All input for the `updateTeamMembership` mutation. */
export type UpdateTeamMembershipInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `TeamMembership` being updated. */
  patch: TeamMembershipPatch;
  userId: Scalars['String'];
};

/** The output of our update `TeamMembership` mutation. */
export type UpdateTeamMembershipPayload = {
  __typename?: 'UpdateTeamMembershipPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `TeamMembership`. */
  team?: Maybe<Team>;
  /** The `TeamMembership` that was updated by this mutation. */
  teamMembership?: Maybe<TeamMembership>;
  /** An edge for our `TeamMembership`. May be used by Relay 1. */
  teamMembershipEdge?: Maybe<TeamMembershipsEdge>;
  /** Reads a single `User` that is related to this `TeamMembership`. */
  user?: Maybe<User>;
};


/** The output of our update `TeamMembership` mutation. */
export type UpdateTeamMembershipPayloadTeamMembershipEdgeArgs = {
  orderBy?: InputMaybe<Array<TeamMembershipsOrderBy>>;
};

/** The output of our update `Team` mutation. */
export type UpdateTeamPayload = {
  __typename?: 'UpdateTeamPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Team` that was updated by this mutation. */
  team?: Maybe<Team>;
  /** An edge for our `Team`. May be used by Relay 1. */
  teamEdge?: Maybe<TeamsEdge>;
};


/** The output of our update `Team` mutation. */
export type UpdateTeamPayloadTeamEdgeArgs = {
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
};

/** All input for the `updateTransactionAllowanceByNodeId` mutation. */
export type UpdateTransactionAllowanceByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TransactionAllowance` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TransactionAllowance` being updated. */
  patch: TransactionAllowancePatch;
};

/** All input for the `updateTransactionAllowance` mutation. */
export type UpdateTransactionAllowanceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `TransactionAllowance` being updated. */
  patch: TransactionAllowancePatch;
  teamId: Scalars['String'];
  transactionGroup: Scalars['String'];
};

/** The output of our update `TransactionAllowance` mutation. */
export type UpdateTransactionAllowancePayload = {
  __typename?: 'UpdateTransactionAllowancePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `TransactionAllowance`. */
  team?: Maybe<Team>;
  /** The `TransactionAllowance` that was updated by this mutation. */
  transactionAllowance?: Maybe<TransactionAllowance>;
  /** An edge for our `TransactionAllowance`. May be used by Relay 1. */
  transactionAllowanceEdge?: Maybe<TransactionAllowancesEdge>;
  /** Reads a single `TransactionGroup` that is related to this `TransactionAllowance`. */
  transactionGroupByTransactionGroup?: Maybe<TransactionGroup>;
};


/** The output of our update `TransactionAllowance` mutation. */
export type UpdateTransactionAllowancePayloadTransactionAllowanceEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionAllowancesOrderBy>>;
};

/** All input for the `updateTransactionApprovalByNodeId` mutation. */
export type UpdateTransactionApprovalByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TransactionApproval` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TransactionApproval` being updated. */
  patch: TransactionApprovalPatch;
};

/** All input for the `updateTransactionApproval` mutation. */
export type UpdateTransactionApprovalInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `TransactionApproval` being updated. */
  patch: TransactionApprovalPatch;
  transactionId: Scalars['Int'];
};

/** The output of our update `TransactionApproval` mutation. */
export type UpdateTransactionApprovalPayload = {
  __typename?: 'UpdateTransactionApprovalPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Transaction` that is related to this `TransactionApproval`. */
  transaction?: Maybe<Transaction>;
  /** The `TransactionApproval` that was updated by this mutation. */
  transactionApproval?: Maybe<TransactionApproval>;
  /** An edge for our `TransactionApproval`. May be used by Relay 1. */
  transactionApprovalEdge?: Maybe<TransactionApprovalsEdge>;
  /** Reads a single `User` that is related to this `TransactionApproval`. */
  userByApprovedBy?: Maybe<User>;
};


/** The output of our update `TransactionApproval` mutation. */
export type UpdateTransactionApprovalPayloadTransactionApprovalEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionApprovalsOrderBy>>;
};

/** All input for the `updateTransactionByNodeId` mutation. */
export type UpdateTransactionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Transaction` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Transaction` being updated. */
  patch: TransactionPatch;
};

/** All input for the `updateTransactionDocumentByNodeId` mutation. */
export type UpdateTransactionDocumentByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TransactionDocument` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TransactionDocument` being updated. */
  patch: TransactionDocumentPatch;
};

/** All input for the `updateTransactionDocumentFieldByNodeId` mutation. */
export type UpdateTransactionDocumentFieldByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TransactionDocumentField` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TransactionDocumentField` being updated. */
  patch: TransactionDocumentFieldPatch;
};

/** All input for the `updateTransactionDocumentField` mutation. */
export type UpdateTransactionDocumentFieldInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  documentId: Scalars['String'];
  /** An object where the defined keys will be set on the `TransactionDocumentField` being updated. */
  patch: TransactionDocumentFieldPatch;
  variable: TransactionDocumentFieldType;
  xStart: Scalars['BigFloat'];
  yStart: Scalars['BigFloat'];
};

/** The output of our update `TransactionDocumentField` mutation. */
export type UpdateTransactionDocumentFieldPayload = {
  __typename?: 'UpdateTransactionDocumentFieldPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `TransactionDocument` that is related to this `TransactionDocumentField`. */
  document?: Maybe<TransactionDocument>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TransactionDocumentField` that was updated by this mutation. */
  transactionDocumentField?: Maybe<TransactionDocumentField>;
  /** An edge for our `TransactionDocumentField`. May be used by Relay 1. */
  transactionDocumentFieldEdge?: Maybe<TransactionDocumentFieldsEdge>;
};


/** The output of our update `TransactionDocumentField` mutation. */
export type UpdateTransactionDocumentFieldPayloadTransactionDocumentFieldEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionDocumentFieldsOrderBy>>;
};

/** All input for the `updateTransactionDocument` mutation. */
export type UpdateTransactionDocumentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  /** An object where the defined keys will be set on the `TransactionDocument` being updated. */
  patch: TransactionDocumentPatch;
};

/** The output of our update `TransactionDocument` mutation. */
export type UpdateTransactionDocumentPayload = {
  __typename?: 'UpdateTransactionDocumentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `EquityTypeInfo` that is related to this `TransactionDocument`. */
  equityTypeInfoByEquityScheme?: Maybe<EquityTypeInfo>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TransactionDocument` that was updated by this mutation. */
  transactionDocument?: Maybe<TransactionDocument>;
  /** An edge for our `TransactionDocument`. May be used by Relay 1. */
  transactionDocumentEdge?: Maybe<TransactionDocumentsEdge>;
  /** Reads a single `TransactionGroup` that is related to this `TransactionDocument`. */
  transactionGroup?: Maybe<TransactionGroup>;
};


/** The output of our update `TransactionDocument` mutation. */
export type UpdateTransactionDocumentPayloadTransactionDocumentEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionDocumentsOrderBy>>;
};

/** All input for the `updateTransactionDocumentSignatureByNodeId` mutation. */
export type UpdateTransactionDocumentSignatureByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TransactionDocumentSignature` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TransactionDocumentSignature` being updated. */
  patch: TransactionDocumentSignaturePatch;
};

/** All input for the `updateTransactionDocumentSignature` mutation. */
export type UpdateTransactionDocumentSignatureInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  documentId: Scalars['String'];
  /** An object where the defined keys will be set on the `TransactionDocumentSignature` being updated. */
  patch: TransactionDocumentSignaturePatch;
  userId: Scalars['String'];
};

/** The output of our update `TransactionDocumentSignature` mutation. */
export type UpdateTransactionDocumentSignaturePayload = {
  __typename?: 'UpdateTransactionDocumentSignaturePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `TransactionDocument` that is related to this `TransactionDocumentSignature`. */
  document?: Maybe<TransactionDocument>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TransactionDocumentSignature` that was updated by this mutation. */
  transactionDocumentSignature?: Maybe<TransactionDocumentSignature>;
  /** An edge for our `TransactionDocumentSignature`. May be used by Relay 1. */
  transactionDocumentSignatureEdge?: Maybe<TransactionDocumentSignaturesEdge>;
  /** Reads a single `User` that is related to this `TransactionDocumentSignature`. */
  user?: Maybe<User>;
};


/** The output of our update `TransactionDocumentSignature` mutation. */
export type UpdateTransactionDocumentSignaturePayloadTransactionDocumentSignatureEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionDocumentSignaturesOrderBy>>;
};

/** All input for the `updateTransactionFeeByNodeId` mutation. */
export type UpdateTransactionFeeByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TransactionFee` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TransactionFee` being updated. */
  patch: TransactionFeePatch;
};

/** All input for the `updateTransactionFee` mutation. */
export type UpdateTransactionFeeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `TransactionFee` being updated. */
  patch: TransactionFeePatch;
  transactionGroup: Scalars['String'];
};

/** The output of our update `TransactionFee` mutation. */
export type UpdateTransactionFeePayload = {
  __typename?: 'UpdateTransactionFeePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `TransactionFee`. */
  teamByReciever?: Maybe<Team>;
  /** The `TransactionFee` that was updated by this mutation. */
  transactionFee?: Maybe<TransactionFee>;
  /** An edge for our `TransactionFee`. May be used by Relay 1. */
  transactionFeeEdge?: Maybe<TransactionFeesEdge>;
  /** Reads a single `TransactionGroup` that is related to this `TransactionFee`. */
  transactionGroupByTransactionGroup?: Maybe<TransactionGroup>;
};


/** The output of our update `TransactionFee` mutation. */
export type UpdateTransactionFeePayloadTransactionFeeEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionFeesOrderBy>>;
};

/** All input for the `updateTransactionGroupByNodeId` mutation. */
export type UpdateTransactionGroupByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TransactionGroup` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TransactionGroup` being updated. */
  patch: TransactionGroupPatch;
};

/** All input for the `updateTransactionGroup` mutation. */
export type UpdateTransactionGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  /** An object where the defined keys will be set on the `TransactionGroup` being updated. */
  patch: TransactionGroupPatch;
};

/** The output of our update `TransactionGroup` mutation. */
export type UpdateTransactionGroupPayload = {
  __typename?: 'UpdateTransactionGroupPayload';
  /** Reads a single `Team` that is related to this `TransactionGroup`. */
  buyerTeam?: Maybe<Team>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Company` that is related to this `TransactionGroup`. */
  company?: Maybe<Company>;
  /** Reads a single `LiquidityEvent` that is related to this `TransactionGroup`. */
  liquidityEvent?: Maybe<LiquidityEvent>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TransactionGroup` that was updated by this mutation. */
  transactionGroup?: Maybe<TransactionGroup>;
  /** An edge for our `TransactionGroup`. May be used by Relay 1. */
  transactionGroupEdge?: Maybe<TransactionGroupsEdge>;
};


/** The output of our update `TransactionGroup` mutation. */
export type UpdateTransactionGroupPayloadTransactionGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionGroupsOrderBy>>;
};

/** All input for the `updateTransaction` mutation. */
export type UpdateTransactionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Transaction` being updated. */
  patch: TransactionPatch;
};

/** All input for the `updateTransactionNoteByNodeId` mutation. */
export type UpdateTransactionNoteByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TransactionNote` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TransactionNote` being updated. */
  patch: TransactionNotePatch;
};

/** All input for the `updateTransactionNote` mutation. */
export type UpdateTransactionNoteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `TransactionNote` being updated. */
  patch: TransactionNotePatch;
  transactionId: Scalars['Int'];
};

/** The output of our update `TransactionNote` mutation. */
export type UpdateTransactionNotePayload = {
  __typename?: 'UpdateTransactionNotePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Transaction` that is related to this `TransactionNote`. */
  transaction?: Maybe<Transaction>;
  /** The `TransactionNote` that was updated by this mutation. */
  transactionNote?: Maybe<TransactionNote>;
  /** An edge for our `TransactionNote`. May be used by Relay 1. */
  transactionNoteEdge?: Maybe<TransactionNotesEdge>;
  /** Reads a single `User` that is related to this `TransactionNote`. */
  user?: Maybe<User>;
};


/** The output of our update `TransactionNote` mutation. */
export type UpdateTransactionNotePayloadTransactionNoteEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionNotesOrderBy>>;
};

/** The output of our update `Transaction` mutation. */
export type UpdateTransactionPayload = {
  __typename?: 'UpdateTransactionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `EquityTypeInfo` that is related to this `Transaction`. */
  equityTypeInfoByPartyAEquityIncreaseScheme?: Maybe<EquityTypeInfo>;
  /** Reads a single `EquityGrant` that is related to this `Transaction`. */
  originalGrant?: Maybe<EquityGrant>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `Transaction`. */
  teamByPartyA?: Maybe<Team>;
  /** Reads a single `Team` that is related to this `Transaction`. */
  teamByPartyB?: Maybe<Team>;
  /** The `Transaction` that was updated by this mutation. */
  transaction?: Maybe<Transaction>;
  /** Reads a single `Transaction` that is related to this `Transaction`. */
  transactionByPartyAEquityTransactionFrom?: Maybe<Transaction>;
  /** An edge for our `Transaction`. May be used by Relay 1. */
  transactionEdge?: Maybe<TransactionsEdge>;
  /** Reads a single `TransactionGroup` that is related to this `Transaction`. */
  transactionGroup?: Maybe<TransactionGroup>;
};


/** The output of our update `Transaction` mutation. */
export type UpdateTransactionPayloadTransactionEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
};

/** All input for the `updateTransactionTaxRateByNodeId` mutation. */
export type UpdateTransactionTaxRateByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TransactionTaxRate` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TransactionTaxRate` being updated. */
  patch: TransactionTaxRatePatch;
};

/** All input for the `updateTransactionTaxRate` mutation. */
export type UpdateTransactionTaxRateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  equityId: Scalars['Int'];
  /** An object where the defined keys will be set on the `TransactionTaxRate` being updated. */
  patch: TransactionTaxRatePatch;
  transactionGroup: Scalars['String'];
};

/** The output of our update `TransactionTaxRate` mutation. */
export type UpdateTransactionTaxRatePayload = {
  __typename?: 'UpdateTransactionTaxRatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `EquityGrant` that is related to this `TransactionTaxRate`. */
  equity?: Maybe<EquityGrant>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `TransactionGroup` that is related to this `TransactionTaxRate`. */
  transactionGroupByTransactionGroup?: Maybe<TransactionGroup>;
  /** The `TransactionTaxRate` that was updated by this mutation. */
  transactionTaxRate?: Maybe<TransactionTaxRate>;
  /** An edge for our `TransactionTaxRate`. May be used by Relay 1. */
  transactionTaxRateEdge?: Maybe<TransactionTaxRatesEdge>;
};


/** The output of our update `TransactionTaxRate` mutation. */
export type UpdateTransactionTaxRatePayloadTransactionTaxRateEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionTaxRatesOrderBy>>;
};

/** All input for the `updateUserByNodeId` mutation. */
export type UpdateUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `User` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `User` being updated. */
  patch: UserPatch;
};

/** All input for the `updateUser` mutation. */
export type UpdateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  /** An object where the defined keys will be set on the `User` being updated. */
  patch: UserPatch;
};

/** The output of our update `User` mutation. */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `User` that was updated by this mutation. */
  user?: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our update `User` mutation. */
export type UpdateUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** All input for the `updateVestingByNodeId` mutation. */
export type UpdateVestingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Vesting` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Vesting` being updated. */
  patch: VestingPatch;
};

/** All input for the `updateVesting` mutation. */
export type UpdateVestingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  /** An object where the defined keys will be set on the `Vesting` being updated. */
  patch: VestingPatch;
};

/** The output of our update `Vesting` mutation. */
export type UpdateVestingPayload = {
  __typename?: 'UpdateVestingPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Vesting` that was updated by this mutation. */
  vesting?: Maybe<Vesting>;
  /** An edge for our `Vesting`. May be used by Relay 1. */
  vestingEdge?: Maybe<VestingsEdge>;
};


/** The output of our update `Vesting` mutation. */
export type UpdateVestingPayloadVestingEdgeArgs = {
  orderBy?: InputMaybe<Array<VestingsOrderBy>>;
};

/** All input for the upsert `BuyerOffer` mutation. */
export type UpsertBuyerOfferInput = {
  /** The `BuyerOffer` to be upserted by this mutation. */
  buyerOffer: BuyerOfferInput;
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our upsert `BuyerOffer` mutation. */
export type UpsertBuyerOfferPayload = {
  __typename?: 'UpsertBuyerOfferPayload';
  /** The `BuyerOffer` that was upserted by this mutation. */
  buyerOffer?: Maybe<BuyerOffer>;
  /** An edge for our `BuyerOffer`. May be used by Relay 1. */
  buyerOfferEdge?: Maybe<BuyerOffersEdge>;
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `LiquidityEvent` that is related to this `BuyerOffer`. */
  liquidityEventByLiquidityEvent?: Maybe<LiquidityEvent>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `BuyerOffer`. */
  team?: Maybe<Team>;
};


/** The output of our upsert `BuyerOffer` mutation. */
export type UpsertBuyerOfferPayloadBuyerOfferEdgeArgs = {
  orderBy?: InputMaybe<Array<BuyerOffersOrderBy>>;
};

/** Where conditions for the upsert `BuyerOffer` mutation. */
export type UpsertBuyerOfferWhere = {
  liquidityEvent?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['String']>;
};

/** All input for the upsert `BuyerOnboarding` mutation. */
export type UpsertBuyerOnboardingInput = {
  /** The `BuyerOnboarding` to be upserted by this mutation. */
  buyerOnboarding: BuyerOnboardingInput;
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our upsert `BuyerOnboarding` mutation. */
export type UpsertBuyerOnboardingPayload = {
  __typename?: 'UpsertBuyerOnboardingPayload';
  /** The `BuyerOnboarding` that was upserted by this mutation. */
  buyerOnboarding?: Maybe<BuyerOnboarding>;
  /** An edge for our `BuyerOnboarding`. May be used by Relay 1. */
  buyerOnboardingEdge?: Maybe<BuyerOnboardingsEdge>;
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `BuyerOnboarding`. */
  team?: Maybe<Team>;
};


/** The output of our upsert `BuyerOnboarding` mutation. */
export type UpsertBuyerOnboardingPayloadBuyerOnboardingEdgeArgs = {
  orderBy?: InputMaybe<Array<BuyerOnboardingsOrderBy>>;
};

/** Where conditions for the upsert `BuyerOnboarding` mutation. */
export type UpsertBuyerOnboardingWhere = {
  teamId?: InputMaybe<Scalars['String']>;
};

/** All input for the upsert `Company` mutation. */
export type UpsertCompanyInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Company` to be upserted by this mutation. */
  company: CompanyInput;
};

/** All input for the upsert `CompanyKeyPerson` mutation. */
export type UpsertCompanyKeyPersonInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `CompanyKeyPerson` to be upserted by this mutation. */
  companyKeyPerson: CompanyKeyPersonInput;
};

/** The output of our upsert `CompanyKeyPerson` mutation. */
export type UpsertCompanyKeyPersonPayload = {
  __typename?: 'UpsertCompanyKeyPersonPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Company` that is related to this `CompanyKeyPerson`. */
  company?: Maybe<Company>;
  /** The `CompanyKeyPerson` that was upserted by this mutation. */
  companyKeyPerson?: Maybe<CompanyKeyPerson>;
  /** An edge for our `CompanyKeyPerson`. May be used by Relay 1. */
  companyKeyPersonEdge?: Maybe<CompanyKeyPeopleEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our upsert `CompanyKeyPerson` mutation. */
export type UpsertCompanyKeyPersonPayloadCompanyKeyPersonEdgeArgs = {
  orderBy?: InputMaybe<Array<CompanyKeyPeopleOrderBy>>;
};

/** Where conditions for the upsert `CompanyKeyPerson` mutation. */
export type UpsertCompanyKeyPersonWhere = {
  id?: InputMaybe<Scalars['Int']>;
};

/** The output of our upsert `Company` mutation. */
export type UpsertCompanyPayload = {
  __typename?: 'UpsertCompanyPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Company` that was upserted by this mutation. */
  company?: Maybe<Company>;
  /** An edge for our `Company`. May be used by Relay 1. */
  companyEdge?: Maybe<CompaniesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our upsert `Company` mutation. */
export type UpsertCompanyPayloadCompanyEdgeArgs = {
  orderBy?: InputMaybe<Array<CompaniesOrderBy>>;
};

/** Where conditions for the upsert `Company` mutation. */
export type UpsertCompanyWhere = {
  id?: InputMaybe<Scalars['String']>;
};

/** All input for the upsert `Employment` mutation. */
export type UpsertEmploymentInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Employment` to be upserted by this mutation. */
  employment: EmploymentInput;
};

/** The output of our upsert `Employment` mutation. */
export type UpsertEmploymentPayload = {
  __typename?: 'UpsertEmploymentPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Team` that is related to this `Employment`. */
  companyTeam?: Maybe<Team>;
  /** Reads a single `Team` that is related to this `Employment`. */
  employeeTeam?: Maybe<Team>;
  /** The `Employment` that was upserted by this mutation. */
  employment?: Maybe<Employment>;
  /** An edge for our `Employment`. May be used by Relay 1. */
  employmentEdge?: Maybe<EmploymentsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our upsert `Employment` mutation. */
export type UpsertEmploymentPayloadEmploymentEdgeArgs = {
  orderBy?: InputMaybe<Array<EmploymentsOrderBy>>;
};

/** Where conditions for the upsert `Employment` mutation. */
export type UpsertEmploymentWhere = {
  companyTeamId?: InputMaybe<Scalars['String']>;
  employeeTeamId?: InputMaybe<Scalars['String']>;
};

/** All input for the upsert `EquityGrant` mutation. */
export type UpsertEquityGrantInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `EquityGrant` to be upserted by this mutation. */
  equityGrant: EquityGrantInput;
};

/** The output of our upsert `EquityGrant` mutation. */
export type UpsertEquityGrantPayload = {
  __typename?: 'UpsertEquityGrantPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EquityGrant` that was upserted by this mutation. */
  equityGrant?: Maybe<EquityGrant>;
  /** An edge for our `EquityGrant`. May be used by Relay 1. */
  equityGrantEdge?: Maybe<EquityGrantsEdge>;
  /** Reads a single `EquityTypeInfo` that is related to this `EquityGrant`. */
  equityTypeInfoByEquityScheme?: Maybe<EquityTypeInfo>;
  /** Reads a single `Team` that is related to this `EquityGrant`. */
  holderTeam?: Maybe<Team>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our upsert `EquityGrant` mutation. */
export type UpsertEquityGrantPayloadEquityGrantEdgeArgs = {
  orderBy?: InputMaybe<Array<EquityGrantsOrderBy>>;
};

/** Where conditions for the upsert `EquityGrant` mutation. */
export type UpsertEquityGrantWhere = {
  equityScheme?: InputMaybe<Scalars['String']>;
  holderTeamId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** All input for the upsert `EquityTypeInfo` mutation. */
export type UpsertEquityTypeInfoInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `EquityTypeInfo` to be upserted by this mutation. */
  equityTypeInfo: EquityTypeInfoInput;
};

/** The output of our upsert `EquityTypeInfo` mutation. */
export type UpsertEquityTypeInfoPayload = {
  __typename?: 'UpsertEquityTypeInfoPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `EquityType` that is related to this `EquityTypeInfo`. */
  equityTypeByTypeOfEquity?: Maybe<EquityType>;
  /** The `EquityTypeInfo` that was upserted by this mutation. */
  equityTypeInfo?: Maybe<EquityTypeInfo>;
  /** Reads a single `EquityTypeInfo` that is related to this `EquityTypeInfo`. */
  equityTypeInfoByConvertsTo?: Maybe<EquityTypeInfo>;
  /** An edge for our `EquityTypeInfo`. May be used by Relay 1. */
  equityTypeInfoEdge?: Maybe<EquityTypeInfosEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our upsert `EquityTypeInfo` mutation. */
export type UpsertEquityTypeInfoPayloadEquityTypeInfoEdgeArgs = {
  orderBy?: InputMaybe<Array<EquityTypeInfosOrderBy>>;
};

/** Where conditions for the upsert `EquityTypeInfo` mutation. */
export type UpsertEquityTypeInfoWhere = {
  id?: InputMaybe<Scalars['String']>;
};

/** All input for the upsert `EquityType` mutation. */
export type UpsertEquityTypeInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `EquityType` to be upserted by this mutation. */
  equityType: EquityTypeInput;
};

/** The output of our upsert `EquityType` mutation. */
export type UpsertEquityTypePayload = {
  __typename?: 'UpsertEquityTypePayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EquityType` that was upserted by this mutation. */
  equityType?: Maybe<EquityType>;
  /** An edge for our `EquityType`. May be used by Relay 1. */
  equityTypeEdge?: Maybe<EquityTypesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our upsert `EquityType` mutation. */
export type UpsertEquityTypePayloadEquityTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<EquityTypesOrderBy>>;
};

/** Where conditions for the upsert `EquityType` mutation. */
export type UpsertEquityTypeWhere = {
  id?: InputMaybe<Scalars['String']>;
};

/** All input for the upsert `File` mutation. */
export type UpsertFileInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `File` to be upserted by this mutation. */
  file: FileInput;
};

/** The output of our upsert `File` mutation. */
export type UpsertFilePayload = {
  __typename?: 'UpsertFilePayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `File` that was upserted by this mutation. */
  file?: Maybe<File>;
  /** An edge for our `File`. May be used by Relay 1. */
  fileEdge?: Maybe<FilesEdge>;
  /** Reads a single `LiquidityEvent` that is related to this `File`. */
  liquidityEvent?: Maybe<LiquidityEvent>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our upsert `File` mutation. */
export type UpsertFilePayloadFileEdgeArgs = {
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** Where conditions for the upsert `File` mutation. */
export type UpsertFileWhere = {
  liquidityEventId?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** All input for the upsert `InvestorApproval` mutation. */
export type UpsertInvestorApprovalInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `InvestorApproval` to be upserted by this mutation. */
  investorApproval: InvestorApprovalInput;
};

/** The output of our upsert `InvestorApproval` mutation. */
export type UpsertInvestorApprovalPayload = {
  __typename?: 'UpsertInvestorApprovalPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `InvestorApproval` that was upserted by this mutation. */
  investorApproval?: Maybe<InvestorApproval>;
  /** An edge for our `InvestorApproval`. May be used by Relay 1. */
  investorApprovalEdge?: Maybe<InvestorApprovalsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `InvestorApproval`. */
  team?: Maybe<Team>;
  /** Reads a single `User` that is related to this `InvestorApproval`. */
  userByApprovedBy?: Maybe<User>;
};


/** The output of our upsert `InvestorApproval` mutation. */
export type UpsertInvestorApprovalPayloadInvestorApprovalEdgeArgs = {
  orderBy?: InputMaybe<Array<InvestorApprovalsOrderBy>>;
};

/** Where conditions for the upsert `InvestorApproval` mutation. */
export type UpsertInvestorApprovalWhere = {
  teamId?: InputMaybe<Scalars['String']>;
};

/** All input for the upsert `InvestorInvite` mutation. */
export type UpsertInvestorInviteInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `InvestorInvite` to be upserted by this mutation. */
  investorInvite: InvestorInviteInput;
};

/** The output of our upsert `InvestorInvite` mutation. */
export type UpsertInvestorInvitePayload = {
  __typename?: 'UpsertInvestorInvitePayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `InvestorInvite` that was upserted by this mutation. */
  investorInvite?: Maybe<InvestorInvite>;
  /** An edge for our `InvestorInvite`. May be used by Relay 1. */
  investorInviteEdge?: Maybe<InvestorInvitesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `InvestorInvite`. */
  userByUsedBy?: Maybe<User>;
};


/** The output of our upsert `InvestorInvite` mutation. */
export type UpsertInvestorInvitePayloadInvestorInviteEdgeArgs = {
  orderBy?: InputMaybe<Array<InvestorInvitesOrderBy>>;
};

/** Where conditions for the upsert `InvestorInvite` mutation. */
export type UpsertInvestorInviteWhere = {
  inviteCode?: InputMaybe<Scalars['String']>;
};

/** All input for the upsert `LiquidityEventFaq` mutation. */
export type UpsertLiquidityEventFaqInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `LiquidityEventFaq` to be upserted by this mutation. */
  liquidityEventFaq: LiquidityEventFaqInput;
};

/** The output of our upsert `LiquidityEventFaq` mutation. */
export type UpsertLiquidityEventFaqPayload = {
  __typename?: 'UpsertLiquidityEventFaqPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `LiquidityEvent` that is related to this `LiquidityEventFaq`. */
  liquidityEvent?: Maybe<LiquidityEvent>;
  /** The `LiquidityEventFaq` that was upserted by this mutation. */
  liquidityEventFaq?: Maybe<LiquidityEventFaq>;
  /** An edge for our `LiquidityEventFaq`. May be used by Relay 1. */
  liquidityEventFaqEdge?: Maybe<LiquidityEventFaqsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our upsert `LiquidityEventFaq` mutation. */
export type UpsertLiquidityEventFaqPayloadLiquidityEventFaqEdgeArgs = {
  orderBy?: InputMaybe<Array<LiquidityEventFaqsOrderBy>>;
};

/** Where conditions for the upsert `LiquidityEventFaq` mutation. */
export type UpsertLiquidityEventFaqWhere = {
  id?: InputMaybe<Scalars['UUID']>;
};

/** All input for the upsert `LiquidityEvent` mutation. */
export type UpsertLiquidityEventInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `LiquidityEvent` to be upserted by this mutation. */
  liquidityEvent: LiquidityEventInput;
};

/** The output of our upsert `LiquidityEvent` mutation. */
export type UpsertLiquidityEventPayload = {
  __typename?: 'UpsertLiquidityEventPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Company` that is related to this `LiquidityEvent`. */
  company?: Maybe<Company>;
  /** The `LiquidityEvent` that was upserted by this mutation. */
  liquidityEvent?: Maybe<LiquidityEvent>;
  /** An edge for our `LiquidityEvent`. May be used by Relay 1. */
  liquidityEventEdge?: Maybe<LiquidityEventsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our upsert `LiquidityEvent` mutation. */
export type UpsertLiquidityEventPayloadLiquidityEventEdgeArgs = {
  orderBy?: InputMaybe<Array<LiquidityEventsOrderBy>>;
};

/** Where conditions for the upsert `LiquidityEvent` mutation. */
export type UpsertLiquidityEventWhere = {
  id?: InputMaybe<Scalars['String']>;
};

/** All input for the upsert `NdaMetadatum` mutation. */
export type UpsertNdaMetadatumInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `NdaMetadatum` to be upserted by this mutation. */
  ndaMetadatum: NdaMetadatumInput;
};

/** The output of our upsert `NdaMetadatum` mutation. */
export type UpsertNdaMetadatumPayload = {
  __typename?: 'UpsertNdaMetadatumPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `LiquidityEvent` that is related to this `NdaMetadatum`. */
  liquidityEventByLiquidityEvent?: Maybe<LiquidityEvent>;
  /** The `NdaMetadatum` that was upserted by this mutation. */
  ndaMetadatum?: Maybe<NdaMetadatum>;
  /** An edge for our `NdaMetadatum`. May be used by Relay 1. */
  ndaMetadatumEdge?: Maybe<NdaMetadataEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `NdaMetadatum`. */
  team?: Maybe<Team>;
};


/** The output of our upsert `NdaMetadatum` mutation. */
export type UpsertNdaMetadatumPayloadNdaMetadatumEdgeArgs = {
  orderBy?: InputMaybe<Array<NdaMetadataOrderBy>>;
};

/** Where conditions for the upsert `NdaMetadatum` mutation. */
export type UpsertNdaMetadatumWhere = {
  liquidityEvent?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['String']>;
};

/** All input for the upsert `OverallAllocation` mutation. */
export type UpsertOverallAllocationInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `OverallAllocation` to be upserted by this mutation. */
  overallAllocation: OverallAllocationInput;
};

/** The output of our upsert `OverallAllocation` mutation. */
export type UpsertOverallAllocationPayload = {
  __typename?: 'UpsertOverallAllocationPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `OverallAllocation` that was upserted by this mutation. */
  overallAllocation?: Maybe<OverallAllocation>;
  /** An edge for our `OverallAllocation`. May be used by Relay 1. */
  overallAllocationEdge?: Maybe<OverallAllocationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `OverallAllocation`. */
  teamBySeller?: Maybe<Team>;
  /** Reads a single `TransactionGroup` that is related to this `OverallAllocation`. */
  transactionGroup?: Maybe<TransactionGroup>;
};


/** The output of our upsert `OverallAllocation` mutation. */
export type UpsertOverallAllocationPayloadOverallAllocationEdgeArgs = {
  orderBy?: InputMaybe<Array<OverallAllocationsOrderBy>>;
};

/** Where conditions for the upsert `OverallAllocation` mutation. */
export type UpsertOverallAllocationWhere = {
  seller?: InputMaybe<Scalars['String']>;
  transactionGroupId?: InputMaybe<Scalars['String']>;
};

/** All input for the upsert `SellerOffer` mutation. */
export type UpsertSellerOfferInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `SellerOffer` to be upserted by this mutation. */
  sellerOffer: SellerOfferInput;
};

/** The output of our upsert `SellerOffer` mutation. */
export type UpsertSellerOfferPayload = {
  __typename?: 'UpsertSellerOfferPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `EquityGrant` that is related to this `SellerOffer`. */
  grant?: Maybe<EquityGrant>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `SellerOffer`. */
  seller?: Maybe<Team>;
  /** The `SellerOffer` that was upserted by this mutation. */
  sellerOffer?: Maybe<SellerOffer>;
  /** An edge for our `SellerOffer`. May be used by Relay 1. */
  sellerOfferEdge?: Maybe<SellerOffersEdge>;
  /** Reads a single `TransactionGroup` that is related to this `SellerOffer`. */
  transactionGroupByTransactionGroup?: Maybe<TransactionGroup>;
};


/** The output of our upsert `SellerOffer` mutation. */
export type UpsertSellerOfferPayloadSellerOfferEdgeArgs = {
  orderBy?: InputMaybe<Array<SellerOffersOrderBy>>;
};

/** Where conditions for the upsert `SellerOffer` mutation. */
export type UpsertSellerOfferWhere = {
  grantId?: InputMaybe<Scalars['Int']>;
  sellerId?: InputMaybe<Scalars['String']>;
  transactionGroup?: InputMaybe<Scalars['String']>;
};

/** All input for the upsert `SellerOnboarding` mutation. */
export type UpsertSellerOnboardingInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `SellerOnboarding` to be upserted by this mutation. */
  sellerOnboarding: SellerOnboardingInput;
};

/** The output of our upsert `SellerOnboarding` mutation. */
export type UpsertSellerOnboardingPayload = {
  __typename?: 'UpsertSellerOnboardingPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SellerOnboarding` that was upserted by this mutation. */
  sellerOnboarding?: Maybe<SellerOnboarding>;
  /** An edge for our `SellerOnboarding`. May be used by Relay 1. */
  sellerOnboardingEdge?: Maybe<SellerOnboardingsEdge>;
  /** Reads a single `Team` that is related to this `SellerOnboarding`. */
  team?: Maybe<Team>;
};


/** The output of our upsert `SellerOnboarding` mutation. */
export type UpsertSellerOnboardingPayloadSellerOnboardingEdgeArgs = {
  orderBy?: InputMaybe<Array<SellerOnboardingsOrderBy>>;
};

/** Where conditions for the upsert `SellerOnboarding` mutation. */
export type UpsertSellerOnboardingWhere = {
  teamId?: InputMaybe<Scalars['String']>;
};

/** All input for the upsert `Stage2Access` mutation. */
export type UpsertStage2AccessInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Stage2Access` to be upserted by this mutation. */
  stage2Access: Stage2AccessInput;
};

/** The output of our upsert `Stage2Access` mutation. */
export type UpsertStage2AccessPayload = {
  __typename?: 'UpsertStage2AccessPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `LiquidityEvent` that is related to this `Stage2Access`. */
  liquidityEventByLiquidityEvent?: Maybe<LiquidityEvent>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Stage2Access` that was upserted by this mutation. */
  stage2Access?: Maybe<Stage2Access>;
  /** An edge for our `Stage2Access`. May be used by Relay 1. */
  stage2AccessEdge?: Maybe<Stage2AccessesEdge>;
  /** Reads a single `Team` that is related to this `Stage2Access`. */
  team?: Maybe<Team>;
};


/** The output of our upsert `Stage2Access` mutation. */
export type UpsertStage2AccessPayloadStage2AccessEdgeArgs = {
  orderBy?: InputMaybe<Array<Stage2AccessesOrderBy>>;
};

/** Where conditions for the upsert `Stage2Access` mutation. */
export type UpsertStage2AccessWhere = {
  liquidityEvent?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['String']>;
};

/** All input for the upsert `TaxRate` mutation. */
export type UpsertTaxRateInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TaxRate` to be upserted by this mutation. */
  taxRate: TaxRateInput;
};

/** The output of our upsert `TaxRate` mutation. */
export type UpsertTaxRatePayload = {
  __typename?: 'UpsertTaxRatePayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `EquityType` that is related to this `TaxRate`. */
  equityTypeByEquityType?: Maybe<EquityType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TaxRate` that was upserted by this mutation. */
  taxRate?: Maybe<TaxRate>;
  /** An edge for our `TaxRate`. May be used by Relay 1. */
  taxRateEdge?: Maybe<TaxRatesEdge>;
};


/** The output of our upsert `TaxRate` mutation. */
export type UpsertTaxRatePayloadTaxRateEdgeArgs = {
  orderBy?: InputMaybe<Array<TaxRatesOrderBy>>;
};

/** Where conditions for the upsert `TaxRate` mutation. */
export type UpsertTaxRateWhere = {
  equityType?: InputMaybe<Scalars['String']>;
  fiscalResidency?: InputMaybe<IsoCountry>;
  moreThanXMonthsEmployed?: InputMaybe<Scalars['Int']>;
  moreThanXMonthsHeldGrant?: InputMaybe<Scalars['Int']>;
};

/** All input for the upsert `Team` mutation. */
export type UpsertTeamInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Team` to be upserted by this mutation. */
  team: TeamInput;
};

/** All input for the upsert `TeamMembership` mutation. */
export type UpsertTeamMembershipInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TeamMembership` to be upserted by this mutation. */
  teamMembership: TeamMembershipInput;
};

/** The output of our upsert `TeamMembership` mutation. */
export type UpsertTeamMembershipPayload = {
  __typename?: 'UpsertTeamMembershipPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `TeamMembership`. */
  team?: Maybe<Team>;
  /** The `TeamMembership` that was upserted by this mutation. */
  teamMembership?: Maybe<TeamMembership>;
  /** An edge for our `TeamMembership`. May be used by Relay 1. */
  teamMembershipEdge?: Maybe<TeamMembershipsEdge>;
  /** Reads a single `User` that is related to this `TeamMembership`. */
  user?: Maybe<User>;
};


/** The output of our upsert `TeamMembership` mutation. */
export type UpsertTeamMembershipPayloadTeamMembershipEdgeArgs = {
  orderBy?: InputMaybe<Array<TeamMembershipsOrderBy>>;
};

/** Where conditions for the upsert `TeamMembership` mutation. */
export type UpsertTeamMembershipWhere = {
  userId?: InputMaybe<Scalars['String']>;
};

/** The output of our upsert `Team` mutation. */
export type UpsertTeamPayload = {
  __typename?: 'UpsertTeamPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Team` that was upserted by this mutation. */
  team?: Maybe<Team>;
  /** An edge for our `Team`. May be used by Relay 1. */
  teamEdge?: Maybe<TeamsEdge>;
};


/** The output of our upsert `Team` mutation. */
export type UpsertTeamPayloadTeamEdgeArgs = {
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
};

/** Where conditions for the upsert `Team` mutation. */
export type UpsertTeamWhere = {
  id?: InputMaybe<Scalars['String']>;
};

/** All input for the upsert `TransactionAllowance` mutation. */
export type UpsertTransactionAllowanceInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TransactionAllowance` to be upserted by this mutation. */
  transactionAllowance: TransactionAllowanceInput;
};

/** The output of our upsert `TransactionAllowance` mutation. */
export type UpsertTransactionAllowancePayload = {
  __typename?: 'UpsertTransactionAllowancePayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `TransactionAllowance`. */
  team?: Maybe<Team>;
  /** The `TransactionAllowance` that was upserted by this mutation. */
  transactionAllowance?: Maybe<TransactionAllowance>;
  /** An edge for our `TransactionAllowance`. May be used by Relay 1. */
  transactionAllowanceEdge?: Maybe<TransactionAllowancesEdge>;
  /** Reads a single `TransactionGroup` that is related to this `TransactionAllowance`. */
  transactionGroupByTransactionGroup?: Maybe<TransactionGroup>;
};


/** The output of our upsert `TransactionAllowance` mutation. */
export type UpsertTransactionAllowancePayloadTransactionAllowanceEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionAllowancesOrderBy>>;
};

/** Where conditions for the upsert `TransactionAllowance` mutation. */
export type UpsertTransactionAllowanceWhere = {
  teamId?: InputMaybe<Scalars['String']>;
  transactionGroup?: InputMaybe<Scalars['String']>;
};

/** All input for the upsert `TransactionApproval` mutation. */
export type UpsertTransactionApprovalInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TransactionApproval` to be upserted by this mutation. */
  transactionApproval: TransactionApprovalInput;
};

/** The output of our upsert `TransactionApproval` mutation. */
export type UpsertTransactionApprovalPayload = {
  __typename?: 'UpsertTransactionApprovalPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Transaction` that is related to this `TransactionApproval`. */
  transaction?: Maybe<Transaction>;
  /** The `TransactionApproval` that was upserted by this mutation. */
  transactionApproval?: Maybe<TransactionApproval>;
  /** An edge for our `TransactionApproval`. May be used by Relay 1. */
  transactionApprovalEdge?: Maybe<TransactionApprovalsEdge>;
  /** Reads a single `User` that is related to this `TransactionApproval`. */
  userByApprovedBy?: Maybe<User>;
};


/** The output of our upsert `TransactionApproval` mutation. */
export type UpsertTransactionApprovalPayloadTransactionApprovalEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionApprovalsOrderBy>>;
};

/** Where conditions for the upsert `TransactionApproval` mutation. */
export type UpsertTransactionApprovalWhere = {
  transactionId?: InputMaybe<Scalars['Int']>;
};

/** All input for the upsert `TransactionDocumentField` mutation. */
export type UpsertTransactionDocumentFieldInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TransactionDocumentField` to be upserted by this mutation. */
  transactionDocumentField: TransactionDocumentFieldInput;
};

/** The output of our upsert `TransactionDocumentField` mutation. */
export type UpsertTransactionDocumentFieldPayload = {
  __typename?: 'UpsertTransactionDocumentFieldPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `TransactionDocument` that is related to this `TransactionDocumentField`. */
  document?: Maybe<TransactionDocument>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TransactionDocumentField` that was upserted by this mutation. */
  transactionDocumentField?: Maybe<TransactionDocumentField>;
  /** An edge for our `TransactionDocumentField`. May be used by Relay 1. */
  transactionDocumentFieldEdge?: Maybe<TransactionDocumentFieldsEdge>;
};


/** The output of our upsert `TransactionDocumentField` mutation. */
export type UpsertTransactionDocumentFieldPayloadTransactionDocumentFieldEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionDocumentFieldsOrderBy>>;
};

/** Where conditions for the upsert `TransactionDocumentField` mutation. */
export type UpsertTransactionDocumentFieldWhere = {
  documentId?: InputMaybe<Scalars['String']>;
  variable?: InputMaybe<TransactionDocumentFieldType>;
  xStart?: InputMaybe<Scalars['BigFloat']>;
  yStart?: InputMaybe<Scalars['BigFloat']>;
};

/** All input for the upsert `TransactionDocument` mutation. */
export type UpsertTransactionDocumentInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TransactionDocument` to be upserted by this mutation. */
  transactionDocument: TransactionDocumentInput;
};

/** The output of our upsert `TransactionDocument` mutation. */
export type UpsertTransactionDocumentPayload = {
  __typename?: 'UpsertTransactionDocumentPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `EquityTypeInfo` that is related to this `TransactionDocument`. */
  equityTypeInfoByEquityScheme?: Maybe<EquityTypeInfo>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TransactionDocument` that was upserted by this mutation. */
  transactionDocument?: Maybe<TransactionDocument>;
  /** An edge for our `TransactionDocument`. May be used by Relay 1. */
  transactionDocumentEdge?: Maybe<TransactionDocumentsEdge>;
  /** Reads a single `TransactionGroup` that is related to this `TransactionDocument`. */
  transactionGroup?: Maybe<TransactionGroup>;
};


/** The output of our upsert `TransactionDocument` mutation. */
export type UpsertTransactionDocumentPayloadTransactionDocumentEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionDocumentsOrderBy>>;
};

/** All input for the upsert `TransactionDocumentSignature` mutation. */
export type UpsertTransactionDocumentSignatureInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TransactionDocumentSignature` to be upserted by this mutation. */
  transactionDocumentSignature: TransactionDocumentSignatureInput;
};

/** The output of our upsert `TransactionDocumentSignature` mutation. */
export type UpsertTransactionDocumentSignaturePayload = {
  __typename?: 'UpsertTransactionDocumentSignaturePayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `TransactionDocument` that is related to this `TransactionDocumentSignature`. */
  document?: Maybe<TransactionDocument>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TransactionDocumentSignature` that was upserted by this mutation. */
  transactionDocumentSignature?: Maybe<TransactionDocumentSignature>;
  /** An edge for our `TransactionDocumentSignature`. May be used by Relay 1. */
  transactionDocumentSignatureEdge?: Maybe<TransactionDocumentSignaturesEdge>;
  /** Reads a single `User` that is related to this `TransactionDocumentSignature`. */
  user?: Maybe<User>;
};


/** The output of our upsert `TransactionDocumentSignature` mutation. */
export type UpsertTransactionDocumentSignaturePayloadTransactionDocumentSignatureEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionDocumentSignaturesOrderBy>>;
};

/** Where conditions for the upsert `TransactionDocumentSignature` mutation. */
export type UpsertTransactionDocumentSignatureWhere = {
  documentId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** Where conditions for the upsert `TransactionDocument` mutation. */
export type UpsertTransactionDocumentWhere = {
  id?: InputMaybe<Scalars['String']>;
};

/** All input for the upsert `TransactionFee` mutation. */
export type UpsertTransactionFeeInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TransactionFee` to be upserted by this mutation. */
  transactionFee: TransactionFeeInput;
};

/** The output of our upsert `TransactionFee` mutation. */
export type UpsertTransactionFeePayload = {
  __typename?: 'UpsertTransactionFeePayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `TransactionFee`. */
  teamByReciever?: Maybe<Team>;
  /** The `TransactionFee` that was upserted by this mutation. */
  transactionFee?: Maybe<TransactionFee>;
  /** An edge for our `TransactionFee`. May be used by Relay 1. */
  transactionFeeEdge?: Maybe<TransactionFeesEdge>;
  /** Reads a single `TransactionGroup` that is related to this `TransactionFee`. */
  transactionGroupByTransactionGroup?: Maybe<TransactionGroup>;
};


/** The output of our upsert `TransactionFee` mutation. */
export type UpsertTransactionFeePayloadTransactionFeeEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionFeesOrderBy>>;
};

/** Where conditions for the upsert `TransactionFee` mutation. */
export type UpsertTransactionFeeWhere = {
  transactionGroup?: InputMaybe<Scalars['String']>;
};

/** All input for the upsert `TransactionGroup` mutation. */
export type UpsertTransactionGroupInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TransactionGroup` to be upserted by this mutation. */
  transactionGroup: TransactionGroupInput;
};

/** The output of our upsert `TransactionGroup` mutation. */
export type UpsertTransactionGroupPayload = {
  __typename?: 'UpsertTransactionGroupPayload';
  /** Reads a single `Team` that is related to this `TransactionGroup`. */
  buyerTeam?: Maybe<Team>;
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Company` that is related to this `TransactionGroup`. */
  company?: Maybe<Company>;
  /** Reads a single `LiquidityEvent` that is related to this `TransactionGroup`. */
  liquidityEvent?: Maybe<LiquidityEvent>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TransactionGroup` that was upserted by this mutation. */
  transactionGroup?: Maybe<TransactionGroup>;
  /** An edge for our `TransactionGroup`. May be used by Relay 1. */
  transactionGroupEdge?: Maybe<TransactionGroupsEdge>;
};


/** The output of our upsert `TransactionGroup` mutation. */
export type UpsertTransactionGroupPayloadTransactionGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionGroupsOrderBy>>;
};

/** Where conditions for the upsert `TransactionGroup` mutation. */
export type UpsertTransactionGroupWhere = {
  id?: InputMaybe<Scalars['String']>;
};

/** All input for the upsert `Transaction` mutation. */
export type UpsertTransactionInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Transaction` to be upserted by this mutation. */
  transaction: TransactionInput;
};

/** All input for the upsert `TransactionNote` mutation. */
export type UpsertTransactionNoteInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TransactionNote` to be upserted by this mutation. */
  transactionNote: TransactionNoteInput;
};

/** The output of our upsert `TransactionNote` mutation. */
export type UpsertTransactionNotePayload = {
  __typename?: 'UpsertTransactionNotePayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Transaction` that is related to this `TransactionNote`. */
  transaction?: Maybe<Transaction>;
  /** The `TransactionNote` that was upserted by this mutation. */
  transactionNote?: Maybe<TransactionNote>;
  /** An edge for our `TransactionNote`. May be used by Relay 1. */
  transactionNoteEdge?: Maybe<TransactionNotesEdge>;
  /** Reads a single `User` that is related to this `TransactionNote`. */
  user?: Maybe<User>;
};


/** The output of our upsert `TransactionNote` mutation. */
export type UpsertTransactionNotePayloadTransactionNoteEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionNotesOrderBy>>;
};

/** Where conditions for the upsert `TransactionNote` mutation. */
export type UpsertTransactionNoteWhere = {
  transactionId?: InputMaybe<Scalars['Int']>;
};

/** The output of our upsert `Transaction` mutation. */
export type UpsertTransactionPayload = {
  __typename?: 'UpsertTransactionPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `EquityTypeInfo` that is related to this `Transaction`. */
  equityTypeInfoByPartyAEquityIncreaseScheme?: Maybe<EquityTypeInfo>;
  /** Reads a single `EquityGrant` that is related to this `Transaction`. */
  originalGrant?: Maybe<EquityGrant>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `Transaction`. */
  teamByPartyA?: Maybe<Team>;
  /** Reads a single `Team` that is related to this `Transaction`. */
  teamByPartyB?: Maybe<Team>;
  /** The `Transaction` that was upserted by this mutation. */
  transaction?: Maybe<Transaction>;
  /** Reads a single `Transaction` that is related to this `Transaction`. */
  transactionByPartyAEquityTransactionFrom?: Maybe<Transaction>;
  /** An edge for our `Transaction`. May be used by Relay 1. */
  transactionEdge?: Maybe<TransactionsEdge>;
  /** Reads a single `TransactionGroup` that is related to this `Transaction`. */
  transactionGroup?: Maybe<TransactionGroup>;
};


/** The output of our upsert `Transaction` mutation. */
export type UpsertTransactionPayloadTransactionEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
};

/** All input for the upsert `TransactionTaxRate` mutation. */
export type UpsertTransactionTaxRateInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TransactionTaxRate` to be upserted by this mutation. */
  transactionTaxRate: TransactionTaxRateInput;
};

/** The output of our upsert `TransactionTaxRate` mutation. */
export type UpsertTransactionTaxRatePayload = {
  __typename?: 'UpsertTransactionTaxRatePayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `EquityGrant` that is related to this `TransactionTaxRate`. */
  equity?: Maybe<EquityGrant>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `TransactionGroup` that is related to this `TransactionTaxRate`. */
  transactionGroupByTransactionGroup?: Maybe<TransactionGroup>;
  /** The `TransactionTaxRate` that was upserted by this mutation. */
  transactionTaxRate?: Maybe<TransactionTaxRate>;
  /** An edge for our `TransactionTaxRate`. May be used by Relay 1. */
  transactionTaxRateEdge?: Maybe<TransactionTaxRatesEdge>;
};


/** The output of our upsert `TransactionTaxRate` mutation. */
export type UpsertTransactionTaxRatePayloadTransactionTaxRateEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionTaxRatesOrderBy>>;
};

/** Where conditions for the upsert `TransactionTaxRate` mutation. */
export type UpsertTransactionTaxRateWhere = {
  equityId?: InputMaybe<Scalars['Int']>;
  transactionGroup?: InputMaybe<Scalars['String']>;
};

/** Where conditions for the upsert `Transaction` mutation. */
export type UpsertTransactionWhere = {
  id?: InputMaybe<Scalars['Int']>;
};

/** All input for the upsert `User` mutation. */
export type UpsertUserInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `User` to be upserted by this mutation. */
  user: UserInput;
};

/** The output of our upsert `User` mutation. */
export type UpsertUserPayload = {
  __typename?: 'UpsertUserPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `User` that was upserted by this mutation. */
  user?: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our upsert `User` mutation. */
export type UpsertUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** Where conditions for the upsert `User` mutation. */
export type UpsertUserWhere = {
  id?: InputMaybe<Scalars['String']>;
};

/** All input for the upsert `Vesting` mutation. */
export type UpsertVestingInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Vesting` to be upserted by this mutation. */
  vesting: VestingInput;
};

/** The output of our upsert `Vesting` mutation. */
export type UpsertVestingPayload = {
  __typename?: 'UpsertVestingPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Vesting` that was upserted by this mutation. */
  vesting?: Maybe<Vesting>;
  /** An edge for our `Vesting`. May be used by Relay 1. */
  vestingEdge?: Maybe<VestingsEdge>;
};


/** The output of our upsert `Vesting` mutation. */
export type UpsertVestingPayloadVestingEdgeArgs = {
  orderBy?: InputMaybe<Array<VestingsOrderBy>>;
};

/** Where conditions for the upsert `Vesting` mutation. */
export type UpsertVestingWhere = {
  id?: InputMaybe<Scalars['String']>;
};

export type User = Node & {
  __typename?: 'User';
  biometric?: Maybe<Scalars['JSON']>;
  /** Reads and enables pagination through a set of `CommentsAboutTeam`. */
  commentsAboutTeams: CommentsAboutTeamsConnection;
  /** Reads and enables pagination through a set of `CurrentUser`. */
  currentUsers: CurrentUsersConnection;
  dateImported?: Maybe<Scalars['Datetime']>;
  email: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  /** Reads and enables pagination through a set of `Email`. */
  emails: EmailsConnection;
  /** Reads and enables pagination through a set of `FileView`. */
  fileViews: FileViewsConnection;
  fullName: Scalars['String'];
  id: Scalars['String'];
  /** Reads and enables pagination through a set of `InvestorApproval`. */
  investorApprovalsByApprovedBy: InvestorApprovalsConnection;
  /** Reads and enables pagination through a set of `InvestorInvite`. */
  investorInvitesByUsedBy: InvestorInvitesConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  originalCrowdcubeId?: Maybe<Scalars['Int']>;
  password?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Permission`. */
  permissions: PermissionsConnection;
  shortName: Scalars['String'];
  /** Reads and enables pagination through a set of `SignedDocument`. */
  signedDocuments: SignedDocumentsConnection;
  /** Reads a single `TeamMembership` that is related to this `User`. */
  teamMembership?: Maybe<TeamMembership>;
  /**
   * Reads and enables pagination through a set of `TeamMembership`.
   * @deprecated Please use teamMembership instead
   */
  teamMemberships: TeamMembershipsConnection;
  /** Reads and enables pagination through a set of `TransactionApproval`. */
  transactionApprovalsByApprovedBy: TransactionApprovalsConnection;
  /** Reads and enables pagination through a set of `TransactionDocumentSignature`. */
  transactionDocumentSignatures: TransactionDocumentSignaturesConnection;
  /** Reads and enables pagination through a set of `TransactionNote`. */
  transactionNotes: TransactionNotesConnection;
};


export type UserCommentsAboutTeamsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CommentsAboutTeamCondition>;
  filter?: InputMaybe<CommentsAboutTeamFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CommentsAboutTeamsOrderBy>>;
};


export type UserCurrentUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CurrentUserCondition>;
  filter?: InputMaybe<CurrentUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrentUsersOrderBy>>;
};


export type UserEmailsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EmailCondition>;
  filter?: InputMaybe<EmailFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EmailsOrderBy>>;
};


export type UserFileViewsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FileViewCondition>;
  filter?: InputMaybe<FileViewFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FileViewsOrderBy>>;
};


export type UserInvestorApprovalsByApprovedByArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<InvestorApprovalCondition>;
  filter?: InputMaybe<InvestorApprovalFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InvestorApprovalsOrderBy>>;
};


export type UserInvestorInvitesByUsedByArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<InvestorInviteCondition>;
  filter?: InputMaybe<InvestorInviteFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InvestorInvitesOrderBy>>;
};


export type UserPermissionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PermissionCondition>;
  filter?: InputMaybe<PermissionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PermissionsOrderBy>>;
};


export type UserSignedDocumentsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SignedDocumentCondition>;
  filter?: InputMaybe<SignedDocumentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SignedDocumentsOrderBy>>;
};


export type UserTeamMembershipsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TeamMembershipCondition>;
  filter?: InputMaybe<TeamMembershipFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TeamMembershipsOrderBy>>;
};


export type UserTransactionApprovalsByApprovedByArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionApprovalCondition>;
  filter?: InputMaybe<TransactionApprovalFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionApprovalsOrderBy>>;
};


export type UserTransactionDocumentSignaturesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionDocumentSignatureCondition>;
  filter?: InputMaybe<TransactionDocumentSignatureFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionDocumentSignaturesOrderBy>>;
};


export type UserTransactionNotesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionNoteCondition>;
  filter?: InputMaybe<TransactionNoteFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionNotesOrderBy>>;
};

/** A condition to be used against `User` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type UserCondition = {
  /** Checks for equality with the object’s `biometric` field. */
  biometric?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `dateImported` field. */
  dateImported?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `emailVerified` field. */
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `fullName` field. */
  fullName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `originalCrowdcubeId` field. */
  originalCrowdcubeId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `password` field. */
  password?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `shortName` field. */
  shortName?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `User` object types. All fields are combined with a logical ‘and.’ */
export type UserFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserFilter>>;
  /** Filter by the object’s `biometric` field. */
  biometric?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `dateImported` field. */
  dateImported?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Filter by the object’s `emailVerified` field. */
  emailVerified?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `fullName` field. */
  fullName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserFilter>>;
  /** Filter by the object’s `originalCrowdcubeId` field. */
  originalCrowdcubeId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `password` field. */
  password?: InputMaybe<StringFilter>;
  /** Filter by the object’s `shortName` field. */
  shortName?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `User` */
export type UserInput = {
  biometric?: InputMaybe<Scalars['JSON']>;
  dateImported?: InputMaybe<Scalars['Datetime']>;
  email: Scalars['String'];
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  fullName: Scalars['String'];
  id: Scalars['String'];
  originalCrowdcubeId?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  shortName: Scalars['String'];
};

/** Represents an update to a `User`. Fields that are set will be updated. */
export type UserPatch = {
  biometric?: InputMaybe<Scalars['JSON']>;
  dateImported?: InputMaybe<Scalars['Datetime']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  originalCrowdcubeId?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  shortName?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `User` values. */
export type UsersConnection = {
  __typename?: 'UsersConnection';
  /** A list of edges which contains the `User` and cursor to aid in pagination. */
  edges: Array<UsersEdge>;
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection. */
export type UsersEdge = {
  __typename?: 'UsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
};

/** Methods to use when ordering `User`. */
export enum UsersOrderBy {
  BiometricAsc = 'BIOMETRIC_ASC',
  BiometricDesc = 'BIOMETRIC_DESC',
  DateImportedAsc = 'DATE_IMPORTED_ASC',
  DateImportedDesc = 'DATE_IMPORTED_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  EmailVerifiedAsc = 'EMAIL_VERIFIED_ASC',
  EmailVerifiedDesc = 'EMAIL_VERIFIED_DESC',
  FullNameAsc = 'FULL_NAME_ASC',
  FullNameDesc = 'FULL_NAME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  OriginalCrowdcubeIdAsc = 'ORIGINAL_CROWDCUBE_ID_ASC',
  OriginalCrowdcubeIdDesc = 'ORIGINAL_CROWDCUBE_ID_DESC',
  PasswordAsc = 'PASSWORD_ASC',
  PasswordDesc = 'PASSWORD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ShortNameAsc = 'SHORT_NAME_ASC',
  ShortNameDesc = 'SHORT_NAME_DESC'
}

export type Vesting = Node & {
  __typename?: 'Vesting';
  denominator: Scalars['Int'];
  id: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  roundingType: Scalars['String'];
  /** Reads and enables pagination through a set of `VestingSchedule`. */
  vestingSchedulesById: VestingSchedulesConnection;
};


export type VestingVestingSchedulesByIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<VestingScheduleCondition>;
  filter?: InputMaybe<VestingScheduleFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VestingSchedulesOrderBy>>;
};

/** A condition to be used against `Vesting` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type VestingCondition = {
  /** Checks for equality with the object’s `denominator` field. */
  denominator?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `roundingType` field. */
  roundingType?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `Vesting` object types. All fields are combined with a logical ‘and.’ */
export type VestingFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<VestingFilter>>;
  /** Filter by the object’s `denominator` field. */
  denominator?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<VestingFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<VestingFilter>>;
  /** Filter by the object’s `roundingType` field. */
  roundingType?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `Vesting` */
export type VestingInput = {
  denominator: Scalars['Int'];
  id: Scalars['String'];
  roundingType: Scalars['String'];
};

/** Represents an update to a `Vesting`. Fields that are set will be updated. */
export type VestingPatch = {
  denominator?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  roundingType?: InputMaybe<Scalars['String']>;
};

export type VestingSchedule = {
  __typename?: 'VestingSchedule';
  id: Scalars['String'];
  monthHeld: Scalars['Int'];
  numeratorThatWillVestAtEndOfMonth: Scalars['Int'];
  /** Reads a single `Vesting` that is related to this `VestingSchedule`. */
  vestingById?: Maybe<Vesting>;
};

/**
 * A condition to be used against `VestingSchedule` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type VestingScheduleCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `monthHeld` field. */
  monthHeld?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `numeratorThatWillVestAtEndOfMonth` field. */
  numeratorThatWillVestAtEndOfMonth?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `VestingSchedule` object types. All fields are combined with a logical ‘and.’ */
export type VestingScheduleFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<VestingScheduleFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `monthHeld` field. */
  monthHeld?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<VestingScheduleFilter>;
  /** Filter by the object’s `numeratorThatWillVestAtEndOfMonth` field. */
  numeratorThatWillVestAtEndOfMonth?: InputMaybe<IntFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<VestingScheduleFilter>>;
};

/** An input for mutations affecting `VestingSchedule` */
export type VestingScheduleInput = {
  id: Scalars['String'];
  monthHeld: Scalars['Int'];
  numeratorThatWillVestAtEndOfMonth: Scalars['Int'];
};

/** A connection to a list of `VestingSchedule` values. */
export type VestingSchedulesConnection = {
  __typename?: 'VestingSchedulesConnection';
  /** A list of edges which contains the `VestingSchedule` and cursor to aid in pagination. */
  edges: Array<VestingSchedulesEdge>;
  /** A list of `VestingSchedule` objects. */
  nodes: Array<VestingSchedule>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `VestingSchedule` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `VestingSchedule` edge in the connection. */
export type VestingSchedulesEdge = {
  __typename?: 'VestingSchedulesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `VestingSchedule` at the end of the edge. */
  node: VestingSchedule;
};

/** Methods to use when ordering `VestingSchedule`. */
export enum VestingSchedulesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MonthHeldAsc = 'MONTH_HELD_ASC',
  MonthHeldDesc = 'MONTH_HELD_DESC',
  Natural = 'NATURAL',
  NumeratorThatWillVestAtEndOfMonthAsc = 'NUMERATOR_THAT_WILL_VEST_AT_END_OF_MONTH_ASC',
  NumeratorThatWillVestAtEndOfMonthDesc = 'NUMERATOR_THAT_WILL_VEST_AT_END_OF_MONTH_DESC'
}

/** A connection to a list of `Vesting` values. */
export type VestingsConnection = {
  __typename?: 'VestingsConnection';
  /** A list of edges which contains the `Vesting` and cursor to aid in pagination. */
  edges: Array<VestingsEdge>;
  /** A list of `Vesting` objects. */
  nodes: Array<Vesting>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Vesting` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Vesting` edge in the connection. */
export type VestingsEdge = {
  __typename?: 'VestingsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Vesting` at the end of the edge. */
  node: Vesting;
};

/** Methods to use when ordering `Vesting`. */
export enum VestingsOrderBy {
  DenominatorAsc = 'DENOMINATOR_ASC',
  DenominatorDesc = 'DENOMINATOR_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RoundingTypeAsc = 'ROUNDING_TYPE_ASC',
  RoundingTypeDesc = 'ROUNDING_TYPE_DESC'
}

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', currentUserIsAdmin?: boolean | null, loggedInUser?: { __typename?: 'CurrentUser', userId?: string | null, user?: { __typename?: 'User', id: string, shortName: string, fullName: string, email: string, userId: string } | null, team?: { __typename?: 'Team', nodeId: string, allowedToViewDeals?: boolean | null, teamName?: string | null, teamId: string, buyerOnboarding?: { __typename?: 'BuyerOnboarding', nodeId: string, buyerOnboardingCompleted?: boolean | null } | null, sellerOnboarding?: { __typename?: 'SellerOnboarding', nodeId: string, taxResidence: IsoCountry, sellerOnboardingCompleted?: boolean | null } | null, activeTransactionGroup?: { __typename?: 'TransactionGroup', nodeId: string, id: string, liquidityEventId?: string | null } | null } | null } | null };


export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUserIsAdmin
  loggedInUser {
    userId
    user {
      id
      userId: id
      shortName
      fullName
      email
    }
    team {
      nodeId
      teamId: id
      allowedToViewDeals
      buyerOnboarding {
        nodeId
        buyerOnboardingCompleted
      }
      sellerOnboarding {
        nodeId
        taxResidence
        sellerOnboardingCompleted
      }
      teamName
      activeTransactionGroup {
        nodeId
        id
        liquidityEventId
      }
    }
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Node": [
      "BuyerOffer",
      "BuyerOnboarding",
      "Company",
      "CompanyKeyPerson",
      "Employment",
      "EquityGrant",
      "EquityType",
      "EquityTypeInfo",
      "File",
      "InvestorApproval",
      "InvestorInvite",
      "LiquidityEvent",
      "LiquidityEventFaq",
      "NdaMetadatum",
      "OverallAllocation",
      "Query",
      "SellerOffer",
      "SellerOnboarding",
      "Stage2Access",
      "TaxRate",
      "Team",
      "TeamMembership",
      "Transaction",
      "TransactionAllowance",
      "TransactionApproval",
      "TransactionDocument",
      "TransactionDocumentField",
      "TransactionDocumentSignature",
      "TransactionFee",
      "TransactionGroup",
      "TransactionNote",
      "TransactionTaxRate",
      "User",
      "Vesting"
    ]
  }
};
      export default result;
    