import { AuthPaths } from '@semper/shared-components';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { Retool } from './Retool';

export const AuthRouter = () => {
  const router = createBrowserRouter([
    {
      path: AuthPaths.retool,
      element: <Retool />,
    },
  ]);
  return <RouterProvider router={router} />;
};
