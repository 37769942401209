import { SiteHeaderWithPackagedTranslation } from '@crowdcube/flow-site-header/withPackagedTranslation';
import { extractTldFromUrlString } from '@crowdcube/helpers/tld';
import { AppProvider, store, useAppDispatch } from '@crowdcube/store';
import { documentActions } from '@crowdcube/store/slices/document';
import { Box, RootPage, useVariables } from '@semper/shared-components';
import { Outlet, ScrollRestoration } from 'react-router-dom';

export const AvoidRiskBanner = () => {
  const dispatch = useAppDispatch();

  dispatch(
    documentActions.setPageLayoutDisplay({
      section: ['fcaRiskWarning'],
      display: false,
    }),
  );

  return null;
};

export const SellRootPage = () => {
  const tld = extractTldFromUrlString(window.location.origin);
  const { crowdcube_website } = useVariables(tld);
  return (
    <RootPage
      header={
        <Box sx={{ width: '100%', display: 'block' }}>
          <AppProvider store={store}>
            <AvoidRiskBanner />
            <SiteHeaderWithPackagedTranslation
              nonRelativeUrl={crowdcube_website}
            />
          </AppProvider>
        </Box>
      }
    >
      <ScrollRestoration />
      <Outlet />
    </RootPage>
  );
};
